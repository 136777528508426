import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Container, Flex } from '../../UI/Base';

const StyledProfileBanner = styled(Flex)`
  width: calc(100% - 2px);
  margin: 24px auto -58px auto;
  padding: 32px 32px 50px 32px;
  border-radius: 16px;
  overflow: hidden;
`;

export const DetailHeader = ({ children }: PropsWithChildren<{}>) => (
  <Container className="gx-profile-container">
    <StyledProfileBanner width="100%" className="gx-profile-banner">
      <Container className="gx-profile-container">{children}</Container>
    </StyledProfileBanner>
  </Container>
);

import React from 'react';

// Props
import { TableTitleProps } from '../../../../hooks/Table/useTableProps';

// Components
import { Flex, Text } from '../../Base';

// Render
export const TableTitle = ({
  title,
  DeleteButton,
  hideDeleteButton,
  AddButton,
  EditButton,
  FilterSelect,
  ColumnsToggle,
}: TableTitleProps) => (
  <Flex justifyContent="space-between" alignItems="center">
    <Flex>
      <Text>{title}</Text>
    </Flex>
    <Flex>
      {FilterSelect && <FilterSelect />}
      {!hideDeleteButton && DeleteButton && <DeleteButton />}
      {EditButton && <EditButton />}
      {AddButton && <AddButton />}
      {ColumnsToggle && <ColumnsToggle />}
    </Flex>
  </Flex>
);

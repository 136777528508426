import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useKiosksList } from '../../hooks/Kiosks/useKiosksList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const KiosksPage = () => {
  const {
    updating,
    getBreadcrumbMenuProps,
    getKiosksDataProps,
    getKiosksTableProps,
    getKiosksCrudProps,
    getFormDrawerProps,
  } = useKiosksList();

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        <Row>
          <Col span={24}>
            <Table {...getKiosksDataProps()} {...getKiosksTableProps()} {...getKiosksCrudProps()} />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { Location } from '../../models/Location';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  createLocation,
  deleteLocation,
  deleteLocations,
  locationCreated,
  locationDeleted,
  locationNotCreated,
  locationNotDeleted,
  locationNotUpdated,
  locationsDeleted,
  locationsNotDeleted,
  locationUpdated,
  updateLocation,
} from './Locations.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createLocationSaga() {
  yield takeEvery(createLocation.type, createLocationRequest);
}

function* locationCreatedSaga() {
  yield takeLatest(locationCreated.type, createLocationResponse);
}

function* locationNotCreatedSaga() {
  yield takeLatest(locationNotCreated.type, createLocationError);
}

// Request
function* createLocationRequest(action: PayloadAction<Location>) {
  try {
    const { payload: location } = action;
    yield apiService.execute({
      url: 'Locations',
      method: ApiRequestType.POST,
      data: location,
    });
  } catch ({ message }) {
    yield put({ type: locationNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createLocationResponse() {
  notificationService.showSuccess('locations.notifications.create');
}

// Error
function createLocationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('locations.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateLocationSaga() {
  yield takeEvery(updateLocation.type, updateLocationRequest);
}

function* locationUpdatedSaga() {
  yield takeLatest(locationUpdated.type, updateLocationResponse);
}

function* locationNotUpdatedSaga() {
  yield takeLatest(locationNotUpdated.type, updateLocationError);
}

// Request
function* updateLocationRequest(action: PayloadAction<Location>) {
  try {
    const { payload: location } = action;
    yield apiService.execute({
      url: `Locations/${location.Id}`,
      method: ApiRequestType.PUT,
      data: location,
    });
  } catch ({ message }) {
    yield put({ type: locationNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateLocationResponse() {
  notificationService.showSuccess('locations.notifications.update');
}

// Error
function updateLocationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('locations.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteLocationSaga() {
  yield takeEvery(deleteLocation.type, deleteLocationRequest);
}

function* locationDeletedSaga() {
  yield takeLatest(locationDeleted.type, deleteLocationResponse);
}

function* locationNotDeletedSaga() {
  yield takeLatest(locationNotDeleted.type, deleteLocationError);
}

// Request
function* deleteLocationRequest(action: PayloadAction<Location>) {
  try {
    const { payload: location } = action;
    yield apiService.execute({
      url: `Locations/${location.Id}`,
      method: ApiRequestType.DELETE,
      data: location,
    });
  } catch ({ message }) {
    yield put({ type: locationNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteLocationResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('locations.notifications.delete');
  action.payload.history.push(`/Locations`);
}

// Error
function deleteLocationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('locations.notifications.deleteFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteLocationsSaga() {
  yield takeEvery(deleteLocations.type, deleteLocationsRequest);
}

function* locationsDeletedSaga() {
  yield takeLatest(locationsDeleted.type, deleteLocationsResponse);
}

function* locationsNotDeletedSaga() {
  yield takeLatest(locationsNotDeleted.type, deleteLocationsError);
}

// Request
function* deleteLocationsRequest(action: PayloadAction<Array<Location>>) {
  try {
    const { payload: locations } = action;
    yield apiService.execute({
      url: `Locations`,
      method: ApiRequestType.DELETE,
      data: { ids: locations.map((location) => location.Id) },
    });
  } catch ({ message }) {
    yield put({ type: locationsNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteLocationsResponse() {
  notificationService.showSuccess('locations.notifications.deleteAll');
}

// Error
function deleteLocationsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('locations.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createLocationSaga(),
    locationCreatedSaga(),
    locationNotCreatedSaga(),
    // Update
    updateLocationSaga(),
    locationUpdatedSaga(),
    locationNotUpdatedSaga(),
    // Delete
    deleteLocationSaga(),
    locationDeletedSaga(),
    locationNotDeletedSaga(),
    // Delete All
    deleteLocationsSaga(),
    locationsDeletedSaga(),
    locationsNotDeletedSaga(),
  ]);
}

import React from 'react';
import { Tag } from 'antd';

import { Translated } from '../Core/Translated';

// Props
interface CustomTagProps {
  color: string;
  translationId: string;
}

// Component
export const TranslatedTag = ({ color, translationId }: CustomTagProps) => (
  <Tag color={color}>
    <Translated id={translationId} />
  </Tag>
);

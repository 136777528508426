import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { AccessProfile } from '../../../models/AccessProfile';
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  assignPACSAccessProfiles,
  pacsAccessProfilesAssigned,
  pacsAccessProfilesNotAssigned,
  pacsAccessProfilesNotSynced,
  pacsAccessProfilesSynced,
  syncPACSAccessProfiles,
} from './PACSAccessProfiles.redux';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignPACSAccessProfilesSaga() {
  yield takeEvery(assignPACSAccessProfiles.type, assignPACSAccessProfilesRequest);
}

function* pacsAccessProfilesAssignedSaga() {
  yield takeLatest(pacsAccessProfilesAssigned.type, assignPACSAccessProfilesResponse);
}

function* pacsAccessProfilesNotAssignedSaga() {
  yield takeLatest(pacsAccessProfilesNotAssigned.type, assignPACSAccessProfilesError);
}

// Request
function* assignPACSAccessProfilesRequest(
  action: PayloadAction<{ accessProfile: AccessProfile; pacsAccessProfileIds: Array<string> }>
) {
  try {
    const {
      payload: { accessProfile, pacsAccessProfileIds },
    } = action;
    yield apiService.execute({
      url: `AccessProfiles/${accessProfile.Id}/PACS`,
      method: ApiRequestType.PUT,
      data: {
        accessProfileId: accessProfile.Id,
        pacsAccessProfileIds,
      },
    });
  } catch ({ message }) {
    yield put({ type: pacsAccessProfilesNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignPACSAccessProfilesResponse() {
  notificationService.showSuccess('accessProfiles.pacsAccessProfiles.notifications.assign');
}

// Error
function assignPACSAccessProfilesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'accessProfiles.pacsAccessProfiles.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* SYNC ***********************

// Worker Sagas
function* syncPACSAccessProfilesSaga() {
  yield takeEvery(syncPACSAccessProfiles.type, syncPACSAccessProfilesRequest);
}

function* pacsAccessProfilesSyncedSaga() {
  yield takeLatest(pacsAccessProfilesSynced.type, syncPACSAccessProfilesResponse);
}

function* pacsAccessProfilesNotSyncedSaga() {
  yield takeLatest(pacsAccessProfilesNotSynced.type, syncPACSAccessProfilesError);
}

// Request
function* syncPACSAccessProfilesRequest() {
  try {
    yield apiService.execute({
      url: `AccessProfiles/PACS/Sync`,
      method: ApiRequestType.POST,
      data: {},
    });
  } catch ({ message }) {
    yield put({ type: pacsAccessProfilesNotSynced.type, payload: { msg: { message } } });
  }
}

// Response
function syncPACSAccessProfilesResponse() {
  notificationService.showSuccess('accessProfiles.pacsAccessProfiles.notifications.synced');
}

// Error
function syncPACSAccessProfilesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'accessProfiles.pacsAccessProfiles.notifications.syncFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignPACSAccessProfilesSaga(),
    pacsAccessProfilesAssignedSaga(),
    pacsAccessProfilesNotAssignedSaga(),
    // Sync
    syncPACSAccessProfilesSaga(),
    pacsAccessProfilesSyncedSaga(),
    pacsAccessProfilesNotSyncedSaga(),
  ]);
}

import { Middleware } from 'redux';
import { History } from 'history';

// Middleware pipeline
export const signalRMiddleware: (history: History) => Middleware = () => () => (next) => async (action) => {
  switch (action.type) {
    default:
  }
  return next(action);
};

import React, { useCallback, useMemo } from 'react';

// Hooks
import { useAccessProfilesRedux } from './useAccessProfilesRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { accessProfileTableColumns } from '../../data/Table/AccessProfileTableColumns';

// Constants
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';

// Components
import { Translated } from '../../components/UI/Core';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Hook
export const useAccessProfilesList = <T extends object>() => {
  // Data
  const {
    data: accessProfiles,
    pageCount,
    totalCount,
    fetch,
    pending,
  } = useData(ApiEndpoints.accessProfiles.list, null);

  // Redux
  const { formOptions, updating, error, showDeleteAllConfirm } = useAccessProfilesRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.accessProfiles.list }), []);
  const getAccessProfilesDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.AccessProfiles.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-select" style={{ marginRight: 8 }} />
          <Translated id="appMenu.accessProfiles" />
        </>
      ),
      data: accessProfiles ?? [],
      columns: getTableColumns(accessProfileTableColumns, LocalStorageKeys.AccessProfiles.table.hiddenColumns),
      fetchData: fetch,
    }),
    [fetch, accessProfiles]
  );
  const getAccessProfilesTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getAccessProfilesCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getAccessProfilesDataProps,
      getAccessProfilesTableProps,
      getAccessProfilesCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getAccessProfilesDataProps,
      getAccessProfilesTableProps,
      getFormDrawerProps,
      getAccessProfilesCrudProps,
    ]
  );
};

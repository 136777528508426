import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { ApiConfiguration } from '../../models/ApiConfiguration';
import { SignalRAction } from '../../models/SignalRAction';

// Data
import { ServiceStatus } from '../../models/enums/ServiceStatus';

// Constants
import { LocalStorageKeys, saveToLocalStorage } from '../../constants/Utils/LocalStorage';
import { getCurrentUtcDate } from '../../constants/Utils/DateTime';

// Redux
import {
  apiConfigurationCreated,
  apiConfigurationDeleted,
  apiConfigurationNotCreated,
  apiConfigurationNotDeleted,
  apiConfigurationNotTested,
  apiConfigurationNotUpdated,
  apiConfigurationsDeleted,
  apiConfigurationsNotDeleted,
  apiConfigurationTested,
  apiConfigurationUpdated,
  createApiConfiguration,
  deleteApiConfiguration,
  deleteApiConfigurations,
  testApiConfiguration,
  testApiConfigurations,
  updateApiConfiguration,
} from './ApiConfigurations.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createApiConfigurationSaga() {
  yield takeEvery(createApiConfiguration.type, createApiConfigurationRequest);
}

function* apiConfigurationCreatedSaga() {
  yield takeLatest(apiConfigurationCreated.type, createApiConfigurationResponse);
}

function* apiConfigurationNotCreatedSaga() {
  yield takeLatest(apiConfigurationNotCreated.type, createApiConfigurationError);
}

// Request
function* createApiConfigurationRequest(action: PayloadAction<ApiConfiguration>) {
  try {
    const { payload: apiConfiguration } = action;
    yield apiService.execute({
      url: 'ApiConfigurations',
      method: ApiRequestType.POST,
      data: apiConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: apiConfigurationNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createApiConfigurationResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('apiConfigurations.notifications.create');
  action.payload.history.push(`/ApiConfigurations/${action.payload.msg.entityId}`);
}

// Error
function createApiConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'apiConfigurations.notifications.createFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateApiConfigurationSaga() {
  yield takeEvery(updateApiConfiguration.type, updateApiConfigurationRequest);
}

function* apiConfigurationUpdatedSaga() {
  yield takeLatest(apiConfigurationUpdated.type, updateApiConfigurationResponse);
}

function* apiConfigurationNotUpdatedSaga() {
  yield takeLatest(apiConfigurationNotUpdated.type, updateApiConfigurationError);
}

// Request
function* updateApiConfigurationRequest(action: PayloadAction<ApiConfiguration>) {
  try {
    const { payload: apiConfiguration } = action;
    yield apiService.execute({
      url: `ApiConfigurations/${apiConfiguration.Id}`,
      method: ApiRequestType.PUT,
      data: apiConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: apiConfigurationNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateApiConfigurationResponse() {
  notificationService.showSuccess('apiConfigurations.notifications.update');
}

// Error
function updateApiConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'apiConfigurations.notifications.updateFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteApiConfigurationSaga() {
  yield takeEvery(deleteApiConfiguration.type, deleteApiConfigurationRequest);
}

function* apiConfigurationDeletedSaga() {
  yield takeLatest(apiConfigurationDeleted.type, deleteApiConfigurationResponse);
}

function* apiConfigurationNotDeletedSaga() {
  yield takeLatest(apiConfigurationNotDeleted.type, deleteApiConfigurationError);
}

// Request
function* deleteApiConfigurationRequest(action: PayloadAction<ApiConfiguration>) {
  try {
    const { payload: apiConfiguration } = action;
    yield apiService.execute({
      url: `ApiConfigurations/${apiConfiguration.Id}`,
      method: ApiRequestType.DELETE,
      data: apiConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: apiConfigurationNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteApiConfigurationResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('apiConfigurations.notifications.delete');
  action.payload.history.push(`/ApiConfigurations`);
}

// Error
function deleteApiConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'apiConfigurations.notifications.deleteFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteApiConfigurationsSaga() {
  yield takeEvery(deleteApiConfigurations.type, deleteApiConfigurationsRequest);
}

function* apiConfigurationsDeletedSaga() {
  yield takeLatest(apiConfigurationsDeleted.type, deleteApiConfigurationsResponse);
}

function* apiConfigurationsNotDeletedSaga() {
  yield takeLatest(apiConfigurationsNotDeleted.type, deleteApiConfigurationsError);
}

// Request
function* deleteApiConfigurationsRequest(action: PayloadAction<Array<ApiConfiguration>>) {
  try {
    const { payload: apiConfigurations } = action;
    yield apiService.execute({
      url: `ApiConfigurations`,
      method: ApiRequestType.DELETE,
      data: { ids: apiConfigurations.map((apiConfiguration) => apiConfiguration.Id) },
    });
  } catch ({ message }) {
    yield put({ type: apiConfigurationsNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteApiConfigurationsResponse() {
  notificationService.showSuccess('apiConfigurations.notifications.deleteAll');
}

// Error
function deleteApiConfigurationsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'apiConfigurations.notifications.deleteAllFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* TEST ***********************

// Worker Sagas
function* testApiConfigurationSaga() {
  yield takeEvery(testApiConfiguration.type, testApiConfigurationRequest);
}

function* testApiConfigurationsSaga() {
  yield takeEvery(testApiConfigurations.type, testApiConfigurationsRequest);
}

function* apiConfigurationTestedSaga() {
  yield takeLatest(apiConfigurationTested.type, testApiConfigurationResponse);
}

function* apiConfigurationNotTestedSaga() {
  yield takeLatest(apiConfigurationNotTested.type, testApiConfigurationError);
}

// Request
function* testApiConfigurationRequest(action: PayloadAction<{ id: string }>) {
  try {
    const {
      payload: { id },
    } = action;

    yield apiService.execute({
      url: `ApiConfigurations/${id}/Status`,
      method: ApiRequestType.POST,
      data: {},
    });
  } catch ({ message }) {
    yield put({ type: apiConfigurationNotTested.type, payload: { msg: { message } } });
  }
}

function* testApiConfigurationsRequest() {
  try {
    yield apiService.execute({
      url: `ApiConfigurations/Status`,
      method: ApiRequestType.POST,
      data: {},
    });
  } catch ({ message }) {
    yield put({ type: apiConfigurationNotTested.type, payload: { msg: { message } } });
  }
}

// Response
function testApiConfigurationResponse(action: PayloadAction<SignalRAction>) {
  const {
    payload: { msg },
  } = action;

  if (msg.status === ServiceStatus.Operational) {
    saveToLocalStorage(LocalStorageKeys.AppSettings.lastChecked, getCurrentUtcDate());
    notificationService.showSuccessWithValues('apiConfigurations.notifications.testOnline', {
      name: msg.entityName,
    });
  } else {
    notificationService.showErrorWithContentAndValues('apiConfigurations.notifications.testOffline', msg.message, {
      name: msg.entityName,
    });
  }
}

// Error
function testApiConfigurationError(action: PayloadAction<SignalRAction>) {
  const {
    payload: { msg },
  } = action;

  notificationService.showErrorWithContentAndValues('apiConfigurations.notifications.testFailed', msg.message, {
    name: msg.entityName,
  });
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createApiConfigurationSaga(),
    apiConfigurationCreatedSaga(),
    apiConfigurationNotCreatedSaga(),
    // Update
    updateApiConfigurationSaga(),
    apiConfigurationUpdatedSaga(),
    apiConfigurationNotUpdatedSaga(),
    // Delete
    deleteApiConfigurationSaga(),
    apiConfigurationDeletedSaga(),
    apiConfigurationNotDeletedSaga(),
    // Delete All
    deleteApiConfigurationsSaga(),
    apiConfigurationsDeletedSaga(),
    apiConfigurationsNotDeletedSaga(),
    // Test
    testApiConfigurationSaga(),
    testApiConfigurationsSaga(),
    apiConfigurationTestedSaga(),
    apiConfigurationNotTestedSaga(),
  ]);
}

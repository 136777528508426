import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { ApiConfigurationResponse } from '../../../models/ApiConfiguration';

// Hooks
import { useSettingsRedux } from './useSettingsRedux';

// Props
interface SettingProps {
  apiConfigurationResponse: ApiConfigurationResponse | null;
  fetchApiConfiguration: (params: any) => Promise<void>;
  updatingApiConfiguration: boolean;
}

export const useSettings = ({
  apiConfigurationResponse,
  fetchApiConfiguration,
  updatingApiConfiguration,
}: SettingProps) => {
  // Redux
  const { SettingManagementForm, updating, error } = useSettingsRedux({
    apiConfigurationResponse,
  });
  const prevUpdating = usePrevious(updating);

  // Component Effects
  useEffect(() => {
    // Fetch after updating
    if (apiConfigurationResponse && prevUpdating === true && updating === false && !error) {
      fetchApiConfiguration({ id: apiConfigurationResponse.ApiConfiguration.Id });
    }
  }, [prevUpdating, updating, error, fetchApiConfiguration, apiConfigurationResponse]);

  // Return Hook
  return useMemo(
    () => ({
      SettingManagementForm,
      loading: !apiConfigurationResponse,
      updating: updating || updatingApiConfiguration,
    }),
    [SettingManagementForm, apiConfigurationResponse, updating, updatingApiConfiguration]
  );
};

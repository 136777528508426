import React from 'react';
import { ButtonSkeleton } from './ButtonSkeleton';
import { AvatarSkeleton } from './AvatarSkeleton';
import { Flex } from '../Base';

export const FiltersSkeleton = () => (
  <Flex direction="column">
    <Flex alignItems="center" className="gx-pl-3 gx-pr-3 gx-pt-2 gx-pb-2">
      <AvatarSkeleton size={38} />
      <ButtonSkeleton width={160} height={20} className="gx-ml-2" />
      <ButtonSkeleton width={40} height={16} className="gx-ml-auto" />
    </Flex>
    <Flex alignItems="center" className="gx-pl-3 gx-pr-3 gx-pt-2 gx-pb-2 gx-mt-4">
      <ButtonSkeleton width={210} height={20} className="gx-ml-0" />
      <ButtonSkeleton width={210} height={20} className="gx-ml-auto" />
    </Flex>
    <Flex alignItems="center" className="gx-pl-3 gx-pr-3 gx-pt-2 gx-pb-2 gx-mt-4">
      <ButtonSkeleton width={210} height={20} className="gx-ml-0" />
      <ButtonSkeleton width={210} height={20} className="gx-ml-auto" />
    </Flex>
  </Flex>
);

import { useEffect, useState } from 'react';

import { authService, AuthState } from '../../services/ApiAuthorization/AuthorizeService';

export const useAuth = () => {
  // State
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    isInitialized: false,
    userRoles: [],
  });

  // Init
  useEffect(() => {
    // Mount
    if (!authState.subscriptionId) {
      authService.subscribe(setAuthState);
    }

    // Unmount
    return () => {
      if (authState.subscriptionId) {
        authService.unsubscribe(authState.subscriptionId);
      }
    };
  }, [authState.subscriptionId]);

  return {
    authState,
  };
};

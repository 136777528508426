// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import { notificationService } from '../../services/Notifications/NotificationService';

// Exceptions
const SignalRExceptions = {
  ValidationFailed: 'ValidationFailedIntegrationException',
};

// Events
export const configureSignalREvents = ({ hubConnection }: SignalREvent) => {
  // Validation Failed
  hubConnection.on(SignalRExceptions.ValidationFailed, (msg) => {
    notificationService.showErrorWithContent('generic.notifications.validationFailed', msg.message);
  });
};

import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { ServiceConfigurationResponse } from '../../../models/ServiceConfiguration';

// Hooks
import { usePACSSettingsRedux } from './usePACSSettingsRedux';

// Props
interface PACSSettingProps {
  serviceConfigurationResponse: ServiceConfigurationResponse | null;
  fetchServiceConfiguration: (params: any) => Promise<void>;
  updatingServiceConfiguration: boolean;
}

export const usePACSSettings = ({
  serviceConfigurationResponse,
  fetchServiceConfiguration,
  updatingServiceConfiguration,
}: PACSSettingProps) => {
  // Redux
  const { PACSSettingManagementForm, AdvancedPACSSettingManagementForm, updating, error } = usePACSSettingsRedux({
    serviceConfigurationResponse,
  });
  const prevUpdating = usePrevious(updating);

  // Component Effects
  useEffect(() => {
    // Fetch after updating
    if (serviceConfigurationResponse && prevUpdating === true && updating === false && !error) {
      fetchServiceConfiguration({ id: serviceConfigurationResponse.ServiceConfiguration.Id });
    }
  }, [prevUpdating, updating, error, fetchServiceConfiguration, serviceConfigurationResponse]);

  // Return Hook
  return useMemo(
    () => ({
      PACSSettingManagementForm,
      AdvancedPACSSettingManagementForm,
      loading: !serviceConfigurationResponse,
      updating: updating || updatingServiceConfiguration,
    }),
    [
      AdvancedPACSSettingManagementForm,
      PACSSettingManagementForm,
      serviceConfigurationResponse,
      updating,
      updatingServiceConfiguration,
    ]
  );
};

import React from 'react';

import { TableColumn } from '../../types/Table';
import { ApiConfiguration } from '../../models/ApiConfiguration';
import { Translated } from '../../components/UI/Core';
import { ApiConfigurationTypes } from '../../models/enums/ApiConfigurationTypes';
import { ServiceStatusTag } from '../../components/UI/Tag/ServiceStatusTag';

export const apiConfigurationTableColumns: Array<TableColumn<ApiConfiguration>> = [
  {
    Header: <Translated id="apiConfigurations.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="apiConfigurations.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="apiConfigurations.apiConfigurationType" />,
    id: 'ApiConfigurationType',
    accessor: (row) => ApiConfigurationTypes.find((type) => type.Value === row.ApiConfigurationType)?.Name,
  },
  {
    Header: <Translated id="apiConfigurations.status" />,
    id: 'Status',
    accessor: (row) => <ServiceStatusTag service={row} />,
    disableSortBy: true,
    disableFilters: true,
  },
];

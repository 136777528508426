import * as React from 'react';
import styled from 'styled-components';

// Assets
import { ReactComponent as EyeOn } from '../../../../../assets/icons/eye.svg';
import { ReactComponent as EyeOff } from '../../../../../assets/icons/eye-off.svg';

// Props
interface HideIconProps {
  hidden: boolean;
}

// Styles
const iconStyles = {
  width: '16px',
};

// Styled
const Span = styled.span`
  width: 16px;
  height: 16px;
  line-height: 12px;
  display: flex;
`;

// Render
export const HideIcon = ({ hidden }: HideIconProps) => (
  <Span>{hidden ? <EyeOff {...iconStyles} /> : <EyeOn {...iconStyles} />}</Span>
);

import React from 'react';
import { HeaderProps } from 'react-table';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

export const TableExpandableHeader = <T extends object>({
  getToggleAllRowsExpandedProps,
  isAllRowsExpanded,
}: HeaderProps<T>) => {
  return (
    <Button
      size="small"
      className="gx-mb-0"
      icon={isAllRowsExpanded ? <MinusOutlined /> : <PlusOutlined />}
      {...getToggleAllRowsExpandedProps()}
    />
  );
};

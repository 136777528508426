/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IdentityUser } from '../../../models/IdentityUser';
import { SignalRAction } from '../../../models/SignalRAction';

// State
interface IdentityUserRolesState {
  updating: boolean;
  error: boolean;
}

const initialState: IdentityUserRolesState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@IDENTITY_USER_ROLES';

// Redux Actions|Reducers
const IdentityUserRolesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignIdentityUserRoles: (state, action: PayloadAction<{ identityUser: IdentityUser; roleIds: Array<string> }>) => {
      state.updating = true;
      state.error = false;
    },
    identityUserRolesAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identityUserRolesNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignIdentityUserRoles,
  identityUserRolesAssigned,
  identityUserRolesNotAssigned,
} = IdentityUserRolesSlice.actions;

// Export Reducer
export default IdentityUserRolesSlice.reducer;

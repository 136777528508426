import React, { useCallback, useMemo, useState } from 'react';
import { ColumnInstance, IdType } from 'react-table';
import { Button } from 'antd';
import styled from 'styled-components';

// Components
import { HideIcon } from '../../components/UI/Table/elements/Icons/HideIcon';
import { StyledTooltip } from '../../components/UI/Tooltip/StyledTooltip';
import { Translated } from '../../components/UI/Core';

// Props
interface UseHiddenColumnsProps<T extends object> {
  storageKey: string;
  allColumns: Array<ColumnInstance<T>>;
  hiddenColumns: IdType<T>[] | undefined;
  setHiddenColumns: (param: IdType<T>[]) => void;
  saveHiddenTableColumns: (hiddenColumns: IdType<T>[], key: string) => void;
}

export interface HiddenColumnsProps<T extends object> {
  showAllColumns: boolean;
  hiddenColumns: IdType<T>[] | undefined;
  onColumnHide: (column: ColumnInstance<T>) => void;
  ColumnToggle: ({ column }: ColumnToggleProps<T>) => JSX.Element;
}

interface ColumnToggleProps<T extends object> {
  column: ColumnInstance<T>;
}

// Styled
const StyledButton = styled(Button)`
  padding: 5px 10px;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  :focus,
  :active,
  :active:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

const StyledColumnButton = styled(StyledButton)`
  margin: 0px 2px 0px 8px;
  padding: 3px 4px;
  height: 24px;
`;

// Hook
export const useHiddenColumns = <T extends object>({
  storageKey,
  allColumns,
  hiddenColumns,
  setHiddenColumns,
  saveHiddenTableColumns,
}: UseHiddenColumnsProps<T>) => {
  // State
  const [showAllColumns, setShowAllColumns] = useState(false);

  // Callbacks
  const onColumnHide = useCallback(
    (column: ColumnInstance<T>) => {
      if (hiddenColumns) {
        const index = hiddenColumns.indexOf(column.id);
        if (index < 0) {
          hiddenColumns.push(column.id);
        } else {
          hiddenColumns.splice(index, 1);
        }
        setHiddenColumns([...hiddenColumns]);

        // Save HiddenColumns to Local Storage, if available
        if (saveHiddenTableColumns && storageKey) {
          saveHiddenTableColumns(hiddenColumns, storageKey);
        }
      }
    },
    [hiddenColumns, setHiddenColumns, saveHiddenTableColumns, storageKey]
  );

  const toggleShowAll = useCallback(() => {
    setShowAllColumns(!showAllColumns);
  }, [showAllColumns]);

  const toggleColumn = useCallback(
    (column: ColumnInstance<T>) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      onColumnHide(column);
    },
    [onColumnHide]
  );

  // Components
  const ColumnsToggle = useCallback(
    () => (
      <StyledTooltip title={<Translated id="table.showHideColumns" />} placement="bottomRight">
        <StyledButton type={showAllColumns ? 'primary' : 'default'} color="primary" onClick={() => toggleShowAll()}>
          <HideIcon hidden={showAllColumns} />
        </StyledButton>
      </StyledTooltip>
    ),
    [showAllColumns, toggleShowAll]
  );

  const ColumnToggle = useCallback(
    ({ column }: ColumnToggleProps<T>) => {
      const hidden = hiddenColumns?.includes(column.id) ?? false;
      return (
        <StyledColumnButton
          key={column.id}
          size="small"
          color={hidden ? 'secondary' : 'primary'}
          type={hidden ? 'default' : 'primary'}
          onClick={toggleColumn(column)}
        >
          <HideIcon hidden={hidden} />
        </StyledColumnButton>
      );
    },
    [hiddenColumns, toggleColumn]
  );

  // Props
  const getHiddenColumnsProps: () => HiddenColumnsProps<T> = useCallback(
    () => ({
      showAllColumns,
      hiddenColumns,
      onColumnHide,
      ColumnToggle,
    }),
    [showAllColumns, hiddenColumns, onColumnHide, ColumnToggle]
  );

  // Return
  return useMemo(
    () => ({
      showAllColumns,
      columnCount:
        allColumns && hiddenColumns
          ? // All columns, minus hidden columns (if not showing all), minus selection column
            allColumns.length - (showAllColumns ? 0 : hiddenColumns.length) - 1
          : // Otherwise zero
            0,
      getHiddenColumnsProps,
      ColumnsToggle,
    }),
    [showAllColumns, allColumns, hiddenColumns, getHiddenColumnsProps, ColumnsToggle]
  );
};

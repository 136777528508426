import React, { FunctionComponent, HTMLAttributes } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

export const CustomScrollbars: FunctionComponent<HTMLAttributes<Scrollbars>> = (props) => (
  <Scrollbars
    {...props}
    autoHide
    renderTrackHorizontal={(trackerProps: React.FC<any>) => (
      <div {...trackerProps} style={{ display: 'none' }} className="track-horizontal" />
    )}
  />
);

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { AccessProfile } from '../../models/AccessProfile';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface AccessProfilesState {
  updating: boolean;
  updatingSettings: boolean;
  error: boolean;
}

const initialState: AccessProfilesState = {
  updating: false,
  updatingSettings: false,
  error: false,
};

// Name
const storeName = '@ACCESS_PROFILES';

// Redux Actions|Reducers
const accessProfilesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createAccessProfile: (state, action: PayloadAction<AccessProfile>) => {
      state.updating = true;
      state.error = false;
    },
    accessProfileCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    accessProfileNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateAccessProfile: (state, action: PayloadAction<AccessProfile>) => {
      state.updating = true;
      state.error = false;
    },
    accessProfileUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    accessProfileNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteAccessProfile: (state, action: PayloadAction<AccessProfile>) => {
      state.updating = true;
      state.error = false;
    },
    accessProfileDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    accessProfileNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteAccessProfiles: (state, action: PayloadAction<Array<AccessProfile>>) => {
      state.updating = true;
      state.error = false;
    },
    accessProfilesDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    accessProfilesNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update Settings
    updateAccessProfileSettings: (state, action: PayloadAction<AccessProfile>) => {
      state.updatingSettings = true;
      state.error = false;
    },
    accessProfileSettingsUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingSettings = false;
      state.error = false;
    },
    accessProfileSettingsNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingSettings = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createAccessProfile,
  accessProfileCreated,
  accessProfileNotCreated,
  // Update
  updateAccessProfile,
  accessProfileUpdated,
  accessProfileNotUpdated,
  // Delete
  deleteAccessProfile,
  accessProfileDeleted,
  accessProfileNotDeleted,
  // Delete All
  deleteAccessProfiles,
  accessProfilesDeleted,
  accessProfilesNotDeleted,
  // Update
  updateAccessProfileSettings,
  accessProfileSettingsUpdated,
  accessProfileSettingsNotUpdated,
} = accessProfilesSlice.actions;

// Export Reducer
export default accessProfilesSlice.reducer;

import React, { useCallback, useMemo } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row as TableRow } from 'react-table';

// Types
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { AzureTenant } from '../../models/AzureTenant';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import {
  createAzureTenant,
  deleteAzureTenant,
  deleteAzureTenants,
  updateAzureTenant,
} from '../../store/AzureTenants/AzureTenants.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';

const { confirm } = Modal;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface AzureTenantsReduxProps {
  isEditing?: boolean;
  initialValues?: Store;
}

// Hooks
export const useAzureTenantsRedux = <T extends object>({ isEditing, initialValues }: AzureTenantsReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ azureTenants }) => azureTenants?.updating ?? false);
  const error = useAppSelector(({ azureTenants }) => azureTenants?.error ?? false);

  // Submit Handling
  const onSubmit = useCallback(
    (azureTenant: AzureTenant) => {
      if (isEditing) {
        const azureTenantToUpdate = initialValues as AzureTenant;
        azureTenantToUpdate.Name = azureTenant.Name;

        dispatch(updateAzureTenant(azureTenantToUpdate));
      } else {
        dispatch(createAzureTenant(azureTenant));
      }
    },
    [dispatch, isEditing, initialValues]
  );

  // Components
  const CreateAzureTenantForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as AzureTenant)}
        initialValues={initialValues}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="azureTenants.name" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'azureTenants.form.warnings.name' }),
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
          </Row>

          {!isEditing && (
            <Row>
              <Col>
                <Form.Item
                  name="AzureTenantId"
                  label={<Translated id="azureTenants.azureTenantId" />}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'azureTenants.form.warnings.AzureTenantId' }),
                    },
                  ]}
                >
                  <Input placeholder="Azure Tenant Id" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, initialValues, intl, isEditing]
  );
  // Form options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'AzureTenants',
        Form: CreateAzureTenantForm,
        labels: {
          createButton: <Translated id="azureTenants.form.create" />,
          drawerForm: <Translated id={isEditing ? 'azureTenants.form.edit' : 'azureTenants.form.create'} />,
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateAzureTenantForm, isEditing]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (azureTenant: AzureTenant | undefined) => {
      if (azureTenant) {
        confirm({
          title: intl.formatMessage({
            id: 'azureTenants.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'azureTenants.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteAzureTenant(azureTenant)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const azureTenants = selectedFlatRows.map((d) => d.original) as AzureTenant[];
      if (azureTenants) {
        confirm({
          title: intl.formatMessage({
            id: 'azureTenants.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'azureTenants.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteAzureTenants(azureTenants)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
      showDeleteAllConfirm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm]
  );
};

// App Settings
import AppSettingsReducer from './AppSettings/AppSettings.redux';
// Filters
import FiltersReducer from './Filters/Filters.redux';
// Access Profiles
import AccessProfilesReducer from './AccessProfiles/AccessProfiles.redux';
import PACSAccessProfilesReducer from './AccessProfiles/PACSAccessProfiles/PACSAccessProfiles.redux';
import AccessProfileTenantsReducer from './AccessProfiles/AccessProfileTenants/AccessProfileTenants.redux';
// Identifier Types
import IdentifierTypesReducer from './IdentifierTypes/IdentifierTypes.redux';
import PACSIdentifierTypesReducer from './IdentifierTypes/PACSIdentifierTypes/PACSIdentifierTypes.redux';
import IdentifierTypeTenantsReducer from './IdentifierTypes/IdentifierTypeTenants/IdentifierTypeTenants.redux';
// Schedule configurations
import ScheduleConfigurationsReducer from './ScheduleConfigurations/ScheduleConfigurations.redux';
// Service Configurations
import ServiceConfigurationsReducer from './ServiceConfigurations/ServiceConfigurations.redux';
import PACSSettingsReducer from './ServiceConfigurations/PACSSettings/PACSSettings.redux';
import ServiceConfigurationTenantsReducer from './ServiceConfigurations/ServiceConfigurationTenants/ServiceConfigurationTenants.redux';
// Api Configurations
import ApiConfigurationsReducer from './ApiConfigurations/ApiConfigurations.redux';
import ApiConfigurationTenantsReducer from './ApiConfigurations/ApiConfigurationTenants/ApiConfigurationTenants.redux';
// Kiosks
import KiosksReducer from './Kiosks/Kiosks.redux';
// Identity Users
import IdentityUsersReducer from './IdentityUsers/IdentityUsers.redux';
import IdentityUserTenantsReducer from './IdentityUsers/IdentityUserTenants/IdentityUserTenants.redux';
import IdentityUserRolesReducer from './IdentityUsers/IdentityUserRoles/IdentityUserRoles.redux';
// API Clients
import ApiClientsReducer from './ApiClients/ApiClients.redux';
// Companies
import CompaniesReducer from './Companies/Companies.redux';
// Locations
import LocationsReducer from './Locations/Locations.redux';
// Locations
import TenantsReducer from './Tenants/Tenants.redux';
// AzureTenant
import AzureTenantsReducer from './AzureTenants/AzureTenants.redux';
import AzureGroupsReducer from './AzureTenants/AzureGroups/AzureGroups.redux';
// CalendarEventConfiguration
import CalendarEventConfigurationsReducer from './CalendarEventConfigurations/CalendarEventConfigurations.redux';
// Identifier Actions
import ActionsReducer from './Actions/Actions.redux';
// Account
import AccountReducer from './Account/Account.redux';
// Business Clients
import BusinessClientReducer from './BusinessClients/BusinessClients.redux';

export const reducers = {
  // App Settings
  appSettings: AppSettingsReducer,
  // Filters
  filters: FiltersReducer,
  // Access Profiles
  accessProfiles: AccessProfilesReducer,
  pacsAccessProfiles: PACSAccessProfilesReducer,
  accessProfileTenants: AccessProfileTenantsReducer,
  // Identifier Types
  identifierTypes: IdentifierTypesReducer,
  pacsIdentifierTypes: PACSIdentifierTypesReducer,
  identifierTypeTenants: IdentifierTypeTenantsReducer,
  // Schedule Configurations
  scheduleConfigurations: ScheduleConfigurationsReducer,
  // Service Configurations
  serviceConfigurations: ServiceConfigurationsReducer,
  pacsSettings: PACSSettingsReducer,
  serviceConfigurationTenants: ServiceConfigurationTenantsReducer,
  // Api Configurations
  apiConfigurations: ApiConfigurationsReducer,
  apiConfigurationTenants: ApiConfigurationTenantsReducer,
  // Kiosks
  kiosks: KiosksReducer,
  // Identity Users
  identityUsers: IdentityUsersReducer,
  identityUserTenants: IdentityUserTenantsReducer,
  identityUserRoles: IdentityUserRolesReducer,
  // Clients
  apiClients: ApiClientsReducer,
  // Companies
  companies: CompaniesReducer,
  // Locations
  locations: LocationsReducer,
  // Tenants
  tenants: TenantsReducer,
  // AzureTenant
  azureTenants: AzureTenantsReducer,
  azureGroups: AzureGroupsReducer,
  // Calendar Event Configuration
  calendarEventConfigurations: CalendarEventConfigurationsReducer,
  // Identifier Actions
  actions: ActionsReducer,
  // Account
  account: AccountReducer,
  // Business Clients
  businessClients: BusinessClientReducer,
};

import { AuditOutlined, FileSearchOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Switch } from 'antd';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { IdentifierType, IdentifierTypeFormValues } from '../../models/IdentifierType';

import { Translated } from '../UI/Core';

import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { updateIdentifierTypeSettings } from '../../store/IdentifierTypes/IdentifierTypes.redux';

const { Item } = Form;

interface ContactCardProps {
  identifierType: IdentifierType | null;
  pending: boolean;
}

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;
const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0;
`;

export const ContactCard = ({ identifierType, pending }: ContactCardProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { updatingSettings } = useAppSelector(({ identifierTypes }) => identifierTypes);

  // Form Values
  const initialValues: IdentifierTypeFormValues = useMemo(
    () => ({
      Id: identifierType?.Id,
      Name: identifierType?.Name,
      ValueLabel: identifierType?.ValueLabel,
      DisplayLabel: identifierType?.DisplayLabel,
      DefaultForCheckIn: identifierType?.DefaultForCheckIn,
      ShownInSelfRegistration: identifierType?.ShownInSelfRegistration,
      AllowedToChangeInInvitation: identifierType?.AllowedToChangeInInvitation,
      ShowInParkingReservation: identifierType?.ShowInParkingReservation,
      RequiredInSelfRegistration: identifierType?.RequiredInSelfRegistration,
      RequiredInInvitation: identifierType?.RequiredInInvitation,
      RequiredInParkingReservation: identifierType?.RequiredInParkingReservation,
      Tenants: [],
    }),
    [identifierType]
  );

  const SettingsForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values: IdentifierType) => dispatch(updateIdentifierTypeSettings(values))}
      >
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>

        {/* Name */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <i className="icon icon-avatar gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="identifierTypes.name" />
            </span>
            <div className="gx-mb-0">{initialValues?.Name}</div>
          </div>
        </div>

        {/* Use as Default for Visits */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <AuditOutlined className="gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="identifierTypes.isDefaultForCheckIn" />
            </span>
            <div className="gx-mb-0 gx-mt-1">
              <NoMarginBottomFormItem name="DefaultForCheckIn" valuePropName="checked">
                <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
              </NoMarginBottomFormItem>
            </div>
          </div>
        </div>

        {/* Show identifiers in self- registration */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <FileSearchOutlined className="gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body" style={{ display: 'flex' }}>
            <Col span={12} style={{ padding: 0 }}>
              <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                <Translated id="identifierTypes.shownInSelfRegistration" />
              </span>
              <div className="gx-mb-0 gx-mt-1">
                <NoMarginBottomFormItem name="ShownInSelfRegistration" valuePropName="checked">
                  <Switch
                    checkedChildren={<Translated id="app.yes" />}
                    unCheckedChildren={<Translated id="app.no" />}
                  />
                </NoMarginBottomFormItem>
              </div>
            </Col>
            <Col span={12} style={{ padding: 0 }}>
              <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                <Translated id="identifierTypes.requiredInSelfRegistration" />
              </span>
              <div className="gx-mb-0 gx-mt-1">
                <NoMarginBottomFormItem name="RequiredInSelfRegistration" valuePropName="checked">
                  <Switch
                    checkedChildren={<Translated id="app.yes" />}
                    unCheckedChildren={<Translated id="app.no" />}
                  />
                </NoMarginBottomFormItem>
              </div>
            </Col>
          </div>
        </div>

        {/* Allowed to change in invitation */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <MailOutlined className="gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body" style={{ display: 'flex' }}>
            <Col span={12} style={{ padding: 0 }}>
              <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                <Translated id="identifierTypes.allowedToChangeInInvitation" />
              </span>
              <div className="gx-mb-0 gx-mt-1">
                <NoMarginBottomFormItem name="AllowedToChangeInInvitation" valuePropName="checked">
                  <Switch
                    checkedChildren={<Translated id="app.yes" />}
                    unCheckedChildren={<Translated id="app.no" />}
                  />
                </NoMarginBottomFormItem>
              </div>
            </Col>
            <Col span={12} style={{ padding: 0 }}>
              <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                <Translated id="identifierTypes.requiredInInvitation" />
              </span>
              <div className="gx-mb-0 gx-mt-1">
                <NoMarginBottomFormItem name="RequiredInInvitation" valuePropName="checked">
                  <Switch
                    checkedChildren={<Translated id="app.yes" />}
                    unCheckedChildren={<Translated id="app.no" />}
                  />
                </NoMarginBottomFormItem>
              </div>
            </Col>
          </div>
        </div>

        {/* Default for Parking Reservations */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <MailOutlined className="gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body" style={{ display: 'flex' }}>
            <Col span={12} style={{ padding: 0 }}>
              <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                <Translated id="identifierTypes.showInParkingReservation" />
              </span>
              <div className="gx-mb-0 gx-mt-1">
                <NoMarginBottomFormItem name="ShowInParkingReservation" valuePropName="checked">
                  <Switch
                    checkedChildren={<Translated id="app.yes" />}
                    unCheckedChildren={<Translated id="app.no" />}
                  />
                </NoMarginBottomFormItem>
              </div>
            </Col>
            <Col span={12} style={{ padding: 0 }}>
              <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                <Translated id="identifierTypes.requiredInParkingReservation" />
              </span>
              <div className="gx-mb-0 gx-mt-1">
                <NoMarginBottomFormItem name="RequiredInParkingReservation" valuePropName="checked">
                  <Switch
                    checkedChildren={<Translated id="app.yes" />}
                    unCheckedChildren={<Translated id="app.no" />}
                  />
                </NoMarginBottomFormItem>
              </div>
            </Col>
          </div>
        </div>

        <Divider />
        <StyledButton type="primary" htmlType="submit">
          <Translated id="form.confirmButton" />
        </StyledButton>
      </Form>
    ),
    [initialValues, dispatch]
  );

  return (
    <Widget title={<Translated id="app.identifierType" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updatingSettings || pending}>
        <SettingsForm />
      </Spinner>
    </Widget>
  );
};

import { useEffect } from 'react';
import { FormInstance } from 'antd/lib/form';
import { usePrevious } from 'react-use';

// reset form fields when modal is opened
export const useResetFormOnOpen = ({ form, open }: { form: FormInstance; open: boolean }) => {
  const prevOpen = usePrevious(open);
  useEffect(() => {
    if (open && !prevOpen) {
      form.resetFields();
    }
  }, [form, open, prevOpen]);
};

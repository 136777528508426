import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { IdentityUserResponse } from '../../../models/IdentityUser';

// Hooks
import { useIdentityUserRolesRedux } from './useIdentityUserRolesRedux';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface IdentityUserRolesProps {
  identityUserResponse: IdentityUserResponse | null;
  fetchIdentityUser: (params: any) => Promise<void>;
  updatingIdentityUser: boolean;
}

export const useIdentityUserRoles = ({
  identityUserResponse,
  fetchIdentityUser,
  updatingIdentityUser,
}: IdentityUserRolesProps) => {
  // Data
  const {
    data: identityUserRoles,
    fetch: fetchIdentityUserRoles,
    pending,
  } = useData(ApiEndpoints.identityRoles.list, null);

  // Redux
  const { IdentityUserRolesManagementForm, updating, error } = useIdentityUserRolesRedux({
    identityUserResponse,
    identityUserRoles,
  });
  const prevUpdating = usePrevious(updating);

  // Component State
  const loading = pending && !identityUserResponse;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchIdentityUserRoles({});
  }, [fetchIdentityUserRoles]);

  useEffect(() => {
    // Fetch after updating
    if (identityUserResponse && prevUpdating === true && updating === false && !error) {
      fetchIdentityUser({ id: identityUserResponse.Identity.Id });
      fetchIdentityUserRoles({});
    }
  }, [prevUpdating, updating, error, fetchIdentityUser, fetchIdentityUserRoles, identityUserResponse]);

  // Return Hook
  return useMemo(
    () => ({
      identityUserRoles,
      IdentityUserRolesManagementForm,
      loading,
      updating: updating || updatingIdentityUser,
    }),
    [identityUserRoles, IdentityUserRolesManagementForm, loading, updating, updatingIdentityUser]
  );
};

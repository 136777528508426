/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled, { css } from 'styled-components';

// Props
export interface GenericTableRowProps {
  pending?: boolean;
  noHover?: boolean;
}

// Render
const GenericTableRow = ({ children, pending, noHover, ...rest }: React.PropsWithChildren<GenericTableRowProps>) => (
  <tr {...rest}>{children}</tr>
);

// Styled
export const TableRow = styled(GenericTableRow)`
  ${({ noHover, pending }) =>
    !noHover &&
    css`
      cursor: pointer;

      :hover {
        ${!pending &&
        css`
          background: #f2f2f2;
        `}
      }
    `}
`;

GenericTableRow.defaultProps = {
  pending: undefined,
  noHover: undefined,
};

export enum ScheduleConfigurationJobType {
  ImportAzureAdUsers = 1,
  FetchInvitationEmails = 2,
  CheckOutVisitors = 3,
}

export interface ScheduleConfigurationType {
  Name: string;
  Value: number;
  ScheduleConfiguration: ScheduleConfigurationJobType;
}

export const ScheduleConfigurationTypes: ScheduleConfigurationType[] = [
  {
    Name: 'Import Microsoft Entra AD users',
    Value: 1,
    ScheduleConfiguration: ScheduleConfigurationJobType.ImportAzureAdUsers,
  },
  {
    Name: 'Fetch invitation emails',
    Value: 2,
    ScheduleConfiguration: ScheduleConfigurationJobType.FetchInvitationEmails,
  },
  {
    Name: 'Checkout visitors',
    Value: 3,
    ScheduleConfiguration: ScheduleConfigurationJobType.CheckOutVisitors,
  },
];

// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import { identityUserRolesAssigned, identityUserRolesNotAssigned } from './IdentityUserRoles.redux';

// Events
const SignalREvents = {
  IdentityUserRolesUpdated: `IdentityUserRolesUpdatedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  IdentityTenantsNotAssigned: `IdentityTenantsNotAssignedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.IdentityUserRolesUpdated, (msg) => {
    store.dispatch(identityUserRolesAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.IdentityTenantsNotAssigned, (msg) => {
    store.dispatch(identityUserRolesNotAssigned({ history, msg }));
  });
};

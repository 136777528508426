// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  locationCreated,
  locationDeleted,
  locationNotCreated,
  locationNotDeleted,
  locationNotUpdated,
  locationsDeleted,
  locationsNotDeleted,
  locationUpdated,
} from './Locations.redux';

// Events
const SignalREvents = {
  LocationCreated: `LocationCreatedIntegrationEvent`,
  LocationUpdated: `LocationUpdatedIntegrationEvent`,
  LocationDeleted: `LocationDeletedIntegrationEvent`,
  LocationsDeleted: `LocationsDeletedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  LocationNotCreated: `LocationNotCreatedIntegrationException`,
  LocationNotUpdated: `LocationNotUpdatedIntegrationException`,
  LocationNotDeleted: `LocationNotDeletedIntegrationException`,
  LocationsNotDeleted: `LocationsNotDeletedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.LocationCreated, (msg) => {
    store.dispatch(locationCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.LocationNotCreated, (msg) => {
    store.dispatch(locationNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.LocationUpdated, (msg) => {
    store.dispatch(locationUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.LocationNotUpdated, (msg) => {
    store.dispatch(locationNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.LocationDeleted, (msg) => {
    store.dispatch(locationDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.LocationNotDeleted, (msg) => {
    store.dispatch(locationNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.LocationsDeleted, (msg) => {
    store.dispatch(locationsDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.LocationsNotDeleted, (msg) => {
    store.dispatch(locationsNotDeleted({ history, msg }));
  });
};

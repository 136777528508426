import React from 'react';

import { TableColumn } from '../../types/Table';
import { Location } from '../../models';
import { Translated } from '../../components/UI/Core';

export const locationTableColumns: Array<TableColumn<Location>> = [
  {
    Header: <Translated id="locations.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="locations.name" />,
    id: 'Name',
    accessor: 'Name',
  },
];

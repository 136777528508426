export enum DashboardDataTypes {
  // Management Stats
  ApplicationUsers = 'Application Users',
  Environments = 'Environments',
  Companies = 'Companies',
  Locations = 'Locations',

  // Billing Stats
  ServiceConfigurations = 'Service Configurations',
  AccessProfiles = 'Access Profiles',
  IdentifierTypes = 'Identifier Types',
}

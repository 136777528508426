import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { useScheduleConfigurationsRedux } from './useScheduleConfigurationsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { useAppSelector } from '../App/useRedux';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Props
interface ScheduleConfigurationDetailProps {
  id?: string;
}

export const useScheduleConfigurationsDetail = <T extends object>({ id }: ScheduleConfigurationDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Redux
  const { updatingEntityId } = useAppSelector(({ scheduleConfigurations }) => scheduleConfigurations);
  const prevUpdatingEntityId = usePrevious(updatingEntityId);

  // Data
  const {
    data: scheduleConfigurationResponse,
    fetch,
    pending,
  } = useData(ApiEndpoints.scheduleConfigurations.detail, null);
  const prevPending = usePrevious(pending);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useScheduleConfigurationsRedux<T>({
    isEditing: true,
    initialValues: scheduleConfigurationResponse as Store,
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.scheduleConfigurations.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      scheduleConfiguration: scheduleConfigurationResponse ?? null,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [scheduleConfigurationResponse, pending, getFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      scheduleConfigurationData: scheduleConfigurationResponse ?? null,
      pending,
    }),
    [scheduleConfigurationResponse, pending]
  );
  const getScheduleConfigurationProps = useCallback(
    () => ({
      scheduleConfigurationResponse,
      fetchScheduleConfiguration: fetch,
      updatingScheduleConfiguration: pending,
    }),
    [scheduleConfigurationResponse, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && pending === false && !scheduleConfigurationResponse) {
      notificationService.showError('scheduleConfigurations.notFound');
      navigate('/ScheduleConfigurations');
    }
  }, [prevPending, pending, scheduleConfigurationResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (prevUpdating === true && updating === false && !error) {
      fetch({ id });
    }
  }, [prevUpdating, updating, error, fetch, id]);

  useEffect(() => {
    // Fetch after updating a service configurations status
    if (prevUpdatingEntityId != null && updatingEntityId == null && !error) {
      fetch({ id });
    }
  }, [id, prevUpdatingEntityId, updatingEntityId, error, fetch]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getFormDrawerProps,
      getContactCardProps,
      getScheduleConfigurationProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getFormDrawerProps,
      getContactCardProps,
      getScheduleConfigurationProps,
    ]
  );
};

/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { Skeleton, Space } from 'antd';

// Utils
import { randomInteger } from '../../../../constants/Utils/Math';

// Constants
const skeletonCount = 20;

// Props
interface TableSkeletonProps {
  columnCount: number;
}

// Components
const skeletons = [...Array(skeletonCount)].map((w, j) => (
  <Skeleton.Button key={`col-${j}`} active size="small" shape="square" style={{ width: `${randomInteger(21, 84)}%` }} />
));

// Styled
const StyledTableSkeleton = styled(Space)`
  display: flex;
  padding: 12px 0px 8px 32px;

  & .ant-space-item {
    flex: 1;

    & .ant-skeleton-element {
      display: flex;
    }
  }
`;

// Render
export const TableSkeleton = ({ columnCount }: React.PropsWithChildren<TableSkeletonProps>) => (
  <>
    {[...Array(10)].map((v, i) => (
      <StyledTableSkeleton key={`row-${i}`}>
        {[...Array(columnCount)].map((w, j) => skeletons[(i + j) % skeletonCount])}
      </StyledTableSkeleton>
    ))}
  </>
);

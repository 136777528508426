import React from 'react';
import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';

// Models
import { EnvironmentCardContent } from '../../../models/Dashboard/Content/EnvironmentCardContent';

// Components
import { EnvironmentCard } from './EnvironmentCard';
import { EnvironmentsEmpty } from '../../UI/Empty/EnvironmentsEmpty';
import { EnvironmentsSkeleton } from '../../UI/Skeleton/EnvironmentsSkeleton';

interface EnvironmentStatsProps {
  content: Array<EnvironmentCardContent> | undefined;
  isFiltering: boolean;
  loading: boolean;
}

export const EnvironmentStats = ({ loading, content, isFiltering }: EnvironmentStatsProps) => (
  <Row>
    {!loading && content ? (
      isEmpty(content) ? (
        <EnvironmentsEmpty isFiltering={isFiltering} />
      ) : (
        content?.map((item) => (
          <Col key={item.id} xl={12} lg={24} md={12} sm={12} xs={24}>
            <EnvironmentCard {...item} />
          </Col>
        ))
      )
    ) : (
      <EnvironmentsSkeleton count={3} />
    )}
  </Row>
);

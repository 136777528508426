import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { IdentifierTypeResponse } from '../../../models/IdentifierType';

// Hooks
import { useIdentifierTypeTenantsRedux } from './useIdentifierTypeTenantsRedux';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface IdentifierTypeTenantsProps {
  identifierTypeResponse: IdentifierTypeResponse | null;
  fetchIdentifierType: (params: any) => Promise<void>;
  updatingIdentifierType: boolean;
}

export const useIdentifierTypeTenants = ({
  identifierTypeResponse: identifierTypeTenantsResponse,
  fetchIdentifierType,
  updatingIdentifierType: updatingIdentifierTypeTenant,
}: IdentifierTypeTenantsProps) => {
  // Data
  const {
    data: identifierTypeTenants,
    fetch: fetchIdentifierTypeTenants,
    pending,
  } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const { IdentifierTypeTenantsManagementForm, updating, error } = useIdentifierTypeTenantsRedux({
    identifierTypeTenantsResponse,
    identifierTypeTenants,
  });
  const prevUpdating = usePrevious(updating);

  // Component State
  const loading = pending && !identifierTypeTenantsResponse;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchIdentifierTypeTenants();
  }, [fetchIdentifierTypeTenants]);

  useEffect(() => {
    // Fetch after updating
    if (identifierTypeTenantsResponse && prevUpdating === true && updating === false && !error) {
      fetchIdentifierType({ id: identifierTypeTenantsResponse.IdentifierType.Id });
      fetchIdentifierTypeTenants();
    }
  }, [prevUpdating, updating, error, fetchIdentifierType, fetchIdentifierTypeTenants, identifierTypeTenantsResponse]);

  // Return Hook
  return useMemo(
    () => ({
      identifierTypeTenants,
      IdentifierTypeTenantsManagementForm,
      loading,
      updating: updating || updatingIdentifierTypeTenant,
    }),
    [identifierTypeTenants, IdentifierTypeTenantsManagementForm, loading, updating, updatingIdentifierTypeTenant]
  );
};

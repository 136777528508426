// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import {
  azureGroupsAssigned,
  azureGroupsNotAssigned,
  azureGroupsNotSynced,
  azureGroupsSynced,
} from './AzureGroups.redux';

// Events
const SignalREvents = {
  AzureGroupsAssigned: `AzureGroupsAssignedIntegrationEvent`,
  AzureGroupsSynced: `AzureGroupsSyncedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  AzureGroupsNotAssigned: `AzureGroupsNotAssignedIntegrationException`,
  AzureGroupsNotSynced: `AzureGroupsNotSyncedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.AzureGroupsAssigned, (msg) => {
    store.dispatch(azureGroupsAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AzureGroupsNotAssigned, (msg) => {
    store.dispatch(azureGroupsNotAssigned({ history, msg }));
  });

  // Sync
  hubConnection.on(SignalREvents.AzureGroupsSynced, (msg) => {
    store.dispatch(azureGroupsSynced({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AzureGroupsNotSynced, (msg) => {
    store.dispatch(azureGroupsNotSynced({ history, msg }));
  });
};

// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import { apiConfigurationTenantsAssigned, apiConfigurationTenantsNotAssigned } from './ApiConfigurationTenants.redux';

// Events
const SignalREvents = {
  ApiConfigurationTenantsAssigned: `ApiConfigurationTenantsAssignedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  ApiConfigurationTenantsNotAssigned: `ApiConfigurationTenantsNotAssignedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.ApiConfigurationTenantsAssigned, (msg) => {
    store.dispatch(apiConfigurationTenantsAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ApiConfigurationTenantsNotAssigned, (msg) => {
    store.dispatch(apiConfigurationTenantsNotAssigned({ history, msg }));
  });
};

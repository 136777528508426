import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

// Components
import { AppHeader } from '../AppHeader/AppHeader';
import { MobileMenu } from '../AppMenu/MobileMenu';
import { AppFooter } from '../AppFooter/AppFooter';
import { StyledToastContainer } from '../../UI/Toast/ToastContainer';

// Subcomponents
const { Content } = Layout;

// Styled
const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const StyledContent = styled(Content)`
  overflow: auto;
`;

// Component
export const AppLayout = ({ children }: PropsWithChildren<{}>) => (
  <StyledLayout>
    <MobileMenu />
    <Layout>
      <AppHeader />
      <StyledContent>
        {children}
        <StyledToastContainer theme="colored" />
      </StyledContent>
      <AppFooter />
    </Layout>
  </StyledLayout>
);

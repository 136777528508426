// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  apiConfigurationCreated,
  apiConfigurationDeleted,
  apiConfigurationNotCreated,
  apiConfigurationNotDeleted,
  apiConfigurationNotTested,
  apiConfigurationNotUpdated,
  apiConfigurationsDeleted,
  apiConfigurationsNotDeleted,
  apiConfigurationTested,
  apiConfigurationUpdated,
} from './ApiConfigurations.redux';

// Events
const SignalREvents = {
  ApiConfigurationCreated: `ApiConfigurationCreatedIntegrationEvent`,
  ApiConfigurationUpdated: `ApiConfigurationUpdatedIntegrationEvent`,
  ApiConfigurationDeleted: `ApiConfigurationDeletedIntegrationEvent`,
  ApiConfigurationsDeleted: `ApiConfigurationsDeletedIntegrationEvent`,
  ApiConfigurationStatusSet: `ApiConfigurationStatusSetIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  ApiConfigurationNotCreated: `ApiConfigurationNotCreatedIntegrationException`,
  ApiConfigurationNotUpdated: `ApiConfigurationNotUpdatedIntegrationException`,
  ApiConfigurationNotDeleted: `ApiConfigurationNotDeletedIntegrationException`,
  ApiConfigurationsNotDeleted: `ApiConfigurationsNotDeletedIntegrationException`,
  ApiConfigurationStatusNotSet: `ApiConfigurationStatusNotSetIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Test
  hubConnection.on(SignalREvents.ApiConfigurationStatusSet, (msg) => {
    store.dispatch(apiConfigurationTested({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ApiConfigurationStatusNotSet, (msg) => {
    store.dispatch(apiConfigurationNotTested({ history, msg }));
  });

  // Create
  hubConnection.on(SignalREvents.ApiConfigurationCreated, (msg) => {
    store.dispatch(apiConfigurationCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ApiConfigurationNotCreated, (msg) => {
    store.dispatch(apiConfigurationNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.ApiConfigurationUpdated, (msg) => {
    store.dispatch(apiConfigurationUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ApiConfigurationNotUpdated, (msg) => {
    store.dispatch(apiConfigurationNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.ApiConfigurationDeleted, (msg) => {
    store.dispatch(apiConfigurationDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ApiConfigurationNotDeleted, (msg) => {
    store.dispatch(apiConfigurationNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.ApiConfigurationsDeleted, (msg) => {
    store.dispatch(apiConfigurationsDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ApiConfigurationsNotDeleted, (msg) => {
    store.dispatch(apiConfigurationsNotDeleted({ history, msg }));
  });
};

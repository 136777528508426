/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

// Models
import { ApiConfiguration } from '../../models/ApiConfiguration';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface ApiConfigurationsState {
  updating: boolean;
  error: boolean;
  updatingEntityId?: string;
}

const initialState: ApiConfigurationsState = {
  updating: false,
  error: false,
  updatingEntityId: undefined,
};

// Name
const storeName = '@API_CONFIGURATIONS';

// Redux Actions|Reducers
const apiConfigurationsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createApiConfiguration: (state, action: PayloadAction<ApiConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    apiConfigurationCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    apiConfigurationNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateApiConfiguration: (state, action: PayloadAction<ApiConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    apiConfigurationUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    apiConfigurationNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteApiConfiguration: (state, action: PayloadAction<ApiConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    apiConfigurationDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    apiConfigurationNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteApiConfigurations: (state, action: PayloadAction<Array<ApiConfiguration>>) => {
      state.updating = true;
      state.error = false;
    },
    apiConfigurationsDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    apiConfigurationsNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Test
    testApiConfiguration: (state, action: PayloadAction<{ id: string }>) => {
      state.updatingEntityId = action.payload.id;
      state.error = false;
    },
    testApiConfigurations: (state, action: PayloadAction) => {
      state.updatingEntityId = uniqueId();
      state.error = false;
    },
    apiConfigurationTested: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingEntityId = undefined;
      state.error = false;
    },
    apiConfigurationNotTested: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingEntityId = undefined;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createApiConfiguration,
  apiConfigurationCreated,
  apiConfigurationNotCreated,
  // Update
  updateApiConfiguration,
  apiConfigurationUpdated,
  apiConfigurationNotUpdated,
  // Delete
  deleteApiConfiguration,
  apiConfigurationDeleted,
  apiConfigurationNotDeleted,
  // Delete All
  deleteApiConfigurations,
  apiConfigurationsDeleted,
  apiConfigurationsNotDeleted,
  // Test
  testApiConfiguration,
  testApiConfigurations,
  apiConfigurationTested,
  apiConfigurationNotTested,
} = apiConfigurationsSlice.actions;

// Export Reducer
export default apiConfigurationsSlice.reducer;

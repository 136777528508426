/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { ServiceConfiguration } from '../../../models/ServiceConfiguration';
import { SignalRAction } from '../../../models/SignalRAction';

// State
interface ServiceConfigurationTenantsState {
  updating: boolean;
  error: boolean;
}

const initialState: ServiceConfigurationTenantsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@ACCESS_PROFILE_TENANTS';

// Redux Actions|Reducers
const serviceConfigurationTenantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignServiceConfigurationTenants: (
      state,
      action: PayloadAction<{ serviceConfiguration: ServiceConfiguration; tenantIds: Array<string> }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    serviceConfigurationTenantsAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    serviceConfigurationTenantsNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignServiceConfigurationTenants,
  serviceConfigurationTenantsAssigned,
  serviceConfigurationTenantsNotAssigned,
} = serviceConfigurationTenantsSlice.actions;

// Export Reducer
export default serviceConfigurationTenantsSlice.reducer;

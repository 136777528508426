import React from 'react';
import { Avatar } from 'antd';

import { IdentityUser } from '../../models/IdentityUser';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { DetailHeader } from '../App/AppLayout/DetailHeader';

interface ProfileHeaderProps {
  identityUser: IdentityUser | null;
  pending: boolean;
}

export const ProfileHeader = ({ identityUser, pending }: ProfileHeaderProps) => {
  return (
    <DetailHeader>
      <div className="gx-profile-banner-top">
        <div className="gx-profile-banner-top-left">
          <div className="gx-profile-banner-avatar">
            <Avatar
              className="gx-size-90"
              alt="..."
              src="https://as2.ftcdn.net/jpg/02/60/78/83/500_F_260788352_x5sSHM4DGvpjHj9wz8sFltzAPktQwJCj.jpg"
            />
          </div>
          <div className="gx-profile-banner-avatar-info">
            <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
              {pending ? <ButtonSkeleton width={240} height={16} /> : identityUser?.UserName}
            </h2>
            <div className="gx-mb-0 gx-fs-lg">
              {pending ? <ButtonSkeleton width={160} height={16} /> : identityUser?.Email}
            </div>
          </div>
        </div>
      </div>
    </DetailHeader>
  );
};

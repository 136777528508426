import React, { useCallback, useEffect } from 'react';
import { Avatar, Button, Col, Row, Select } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Translated } from '../UI/Core';
import { DetailHeader } from '../App/AppLayout/DetailHeader';
import { useData } from '../../hooks/App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { useAppDispatch } from '../../hooks/App/useRedux';
import { AzureTenant } from '../../models/AzureTenant';
import { updateAzureTenant } from '../../store/AzureTenants/AzureTenants.redux';

const StyledSpan = styled.span`
  margin-left: 5px !important;
`;

interface ProfileHeaderProps {
  azureTenantData: AzureTenant | undefined;
  pending: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteConfirm: (azureTenant: AzureTenant | undefined) => void;
}

export const ProfileHeader = ({ azureTenantData, pending, setOpen, showDeleteConfirm }: ProfileHeaderProps) => {
  const { data: tenants, fetch: fetchTenants } = useData(ApiEndpoints.tenants.identity, null);
  const dispatch = useAppDispatch();

  // Effects
  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);

  const onEnviromentChange = useCallback(
    (value: string) => {
      const azureTenantToUpdate = azureTenantData;

      if (azureTenantToUpdate !== null && azureTenantToUpdate !== undefined) {
        azureTenantToUpdate.TenantId = value;
        dispatch(updateAzureTenant(azureTenantToUpdate));
      }
    },
    [azureTenantData, dispatch]
  );

  return (
    <DetailHeader>
      <div className="gx-profile-banner-top">
        <div className="gx-profile-banner-top-left">
          <div className="gx-profile-banner-avatar">
            <Avatar
              className="gx-size-90"
              alt="..."
              src="https://as2.ftcdn.net/jpg/02/60/78/83/500_F_260788352_x5sSHM4DGvpjHj9wz8sFltzAPktQwJCj.jpg"
            />
          </div>
          <div className="gx-profile-banner-avatar-info">
            <Row>
              <Col>
                <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                  {pending ? <ButtonSkeleton width={240} height={16} /> : azureTenantData?.Name}
                </h2>
              </Col>
            </Row>
            <Row>
              {pending ? (
                <ButtonSkeleton width={240} height={24} />
              ) : (
                <Col>
                  <Select
                    style={{ width: '200px' }}
                    onChange={onEnviromentChange}
                    value={azureTenantData?.TenantId}
                    defaultValue={azureTenantData?.TenantId}
                  >
                    {tenants?.map((tenant) => (
                      <Select.Option key={tenant.Id} value={tenant.Id}>
                        {tenant.Name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <div className="gx-profile-banner-top-right">
          <Button ghost type="primary" icon={<EditOutlined />} key="Edit" onClick={() => setOpen(true)}>
            <StyledSpan>
              <Translated id="app.edit" defaultMessage="Edit" />
            </StyledSpan>
          </Button>
          <Button
            ghost
            danger
            icon={<DeleteOutlined />}
            key="Delete"
            onClick={() => showDeleteConfirm(azureTenantData)}
          >
            <StyledSpan>
              <Translated id="app.delete" defaultMessage="Delete" />
            </StyledSpan>
          </Button>
        </div>
      </div>
    </DetailHeader>
  );
};

import React from 'react';
import { Breadcrumb, Row } from 'antd';
import styled from 'styled-components';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

// Hooks
import { Container, Flex } from '../Base';

// Styled
const BreadcrumbLabel = styled.span`
  margin-left: 4px;
`;
const StickyBreadcrumb = styled(Flex)`
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
  border-bottom: solid 1px #e8e8e8;
`;

// Models
export interface BreadcrumbItem {
  link?: string;
  label?: string | JSX.Element;
  icon?: JSX.Element;
}

export interface BreadcrumbMenuItem extends BreadcrumbItem {
  key: string | number;
}

// Helpers
export const getBreadcrumbMenuItems = (items: Array<BreadcrumbItem>) =>
  items.map((item, index) => ({ key: index, ...item } as BreadcrumbMenuItem));

// Props
export interface BreadcrumbMenuProps {
  breadcrumbs?: Array<BreadcrumbMenuItem>;
}

// Component
export const BreadcrumbMenu: React.FC<BreadcrumbMenuProps> = ({ breadcrumbs }) => {
  // Render
  return (
    <StickyBreadcrumb alignItems="center" height={40}>
      <Container>
        <Row className="gx-ml-3 gx-ml-lg-4">
          <Breadcrumb separator="›">
            {/* Default */}
            <Breadcrumb.Item>
              <Link to="/">
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>

            {/* Dynamic */}
            {breadcrumbs?.map((item) => {
              const { key, label, link, icon } = item;

              const breadcrumbContent = (
                <>
                  {!!icon && icon}
                  {label && <BreadcrumbLabel>{label}</BreadcrumbLabel>}
                </>
              );

              return (
                <Breadcrumb.Item key={key}>
                  {link ? <Link to={link}>{breadcrumbContent}</Link> : breadcrumbContent}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </Row>
      </Container>
    </StickyBreadcrumb>
  );
};

BreadcrumbMenu.defaultProps = {
  breadcrumbs: [],
};

// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import { businessClientCreated, businessClientNotCreated } from './BusinessClients.redux';

// Events
const SignalREvents = {
  BusinessClientCreated: `BusinessClientCreatedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  BusinessClientNotCreated: `BusinessClientNotCreatedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.BusinessClientCreated, (msg) => {
    store.dispatch(businessClientCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.BusinessClientNotCreated, (msg) => {
    store.dispatch(businessClientNotCreated({ history, msg }));
  });
};

import React, { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';
import { SyncOutlined } from '@ant-design/icons';

// Components
import { Translated } from '../../../components/UI/Core';
import { IconButton } from '../../../components/UI/Button/IconButton';

// Models
import { IdentifierTypeResponse } from '../../../models/IdentifierType';

// Hooks
import { usePACSIdentifierTypesRedux } from './usePACSIdentifierTypesRedux';

// Props
interface PACSIdentifierTypesProps {
  identifierTypeResponse: IdentifierTypeResponse | null;
  fetchIdentifierType: (params: any) => Promise<void>;
  updatingIdentifierType: boolean;
}

export const usePACSIdentifierTypes = ({
  identifierTypeResponse,
  fetchIdentifierType,
  updatingIdentifierType,
}: PACSIdentifierTypesProps) => {
  // Redux
  const { PACSIdentifierTypesManagementForm, updating, error, showSyncConfirm } = usePACSIdentifierTypesRedux({
    identifierTypeResponse,
  });
  const prevUpdating = usePrevious(updating);

  // Sync PACSIdentifierTypes Button
  const SyncButton = useMemo(
    () => (
      <IconButton
        title={<Translated id="identifierTypes.pacsIdentifierTypes.syncButton" />}
        onClick={() => showSyncConfirm()}
        Icon={SyncOutlined}
      />
    ),
    [showSyncConfirm]
  );

  // Component State
  const loading = !identifierTypeResponse;

  // Effects
  useEffect(() => {
    // Fetch after updating
    if (identifierTypeResponse && prevUpdating === true && updating === false && !error) {
      fetchIdentifierType({ id: identifierTypeResponse.IdentifierType.Id });
    }
  }, [prevUpdating, updating, error, fetchIdentifierType, identifierTypeResponse]);

  // Return Hook
  return useMemo(
    () => ({
      pacsIdentifierTypes: identifierTypeResponse?.AvailablePacsIdentifierTypes,
      PACSIdentifierTypesManagementForm,
      loading,
      updating: updating || updatingIdentifierType,
      SyncButton,
    }),
    [identifierTypeResponse, PACSIdentifierTypesManagementForm, loading, updating, updatingIdentifierType, SyncButton]
  );
};

import React from 'react';
import { ApiOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button } from 'antd';

// Models
import { ApiConfiguration } from '../../models/ApiConfiguration';

// Data
import { ApiConfigurationTypes } from '../../models/enums/ApiConfigurationTypes';

// Redux
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { testApiConfiguration } from '../../store/ApiConfigurations/ApiConfigurations.redux';

// Components
import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Spinner } from '../UI/Spinner/Spinner';
import { ServiceStatusTag } from '../UI/Tag/ServiceStatusTag';

// Styled
const StyledApiOutlined = styled(ApiOutlined)`
  font-size: 24px;
`;

// Props
interface ContactCardProps {
  apiConfiguration: ApiConfiguration | null;
  pending: boolean;
}

// Component
export const ContactCard = ({ apiConfiguration, pending }: ContactCardProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { updatingEntityId } = useAppSelector(({ apiConfigurations }) => apiConfigurations);

  return (
    <Widget title={<Translated id="app.apiConfiguration" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updatingEntityId}>
        {/* Name */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <i className="icon icon-avatar gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="apiConfigurations.apiConfigurationType" />
            </span>
            <div className="gx-mb-0">
              <span>
                {pending ? (
                  <ButtonSkeleton width={160} height={16} />
                ) : (
                  ApiConfigurationTypes.find((type) => type.Value === apiConfiguration?.ApiConfigurationType)?.Name
                )}
              </span>
            </div>
          </div>
        </div>

        {/* Status */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <StyledApiOutlined className="gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="apiConfigurations.status" />
            </span>
            <div className="gx-mb-0">
              <span>
                {pending || !apiConfiguration ? (
                  <ButtonSkeleton width={120} height={10} />
                ) : (
                  <>
                    <ServiceStatusTag service={apiConfiguration} />
                    <Button
                      type="primary"
                      ghost
                      style={{ marginBottom: 0 }}
                      size="small"
                      onClick={() => apiConfiguration && dispatch(testApiConfiguration({ id: apiConfiguration.Id }))}
                    >
                      <Translated id="apiConfigurations.status.check" />
                    </Button>
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      </Spinner>
    </Widget>
  );
};

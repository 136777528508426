import React, { useCallback, useMemo } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row as TableRow } from 'react-table';

// Types
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { CalendarEventConfiguration } from '../../models/CalendarEventConfiguration';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import {
  createCalendarEventConfiguration,
  deleteCalendarEventConfiguration,
  deleteCalendarEventConfigurations,
  updateCalendarEventConfiguration,
} from '../../store/CalendarEventConfigurations/CalendarEventConfigurations.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { CalendarEventConfigurationProtocolTypes } from '../../models/enums/CalendarEventConfigurationProtocol';

const { Option } = Select;
const { confirm } = Modal;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface CalendarEventConfigurationsReduxProps {
  isEditing?: boolean;
  initialValues?: Store;
}

// Hooks
export const useCalendarEventConfigurationsRedux = <T extends object>({
  isEditing,
  initialValues,
}: CalendarEventConfigurationsReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ calendarEventConfigurations }) => calendarEventConfigurations?.updating ?? false);
  const error = useAppSelector(({ calendarEventConfigurations }) => calendarEventConfigurations?.error ?? false);

  // Submit Handling
  const onSubmit = useCallback(
    (calendarEventConfiguration: CalendarEventConfiguration) => {
      if (isEditing) {
        const calendarEventConfigurationToUpdate = initialValues as CalendarEventConfiguration;
        calendarEventConfigurationToUpdate.Name = calendarEventConfiguration.Name;
        calendarEventConfigurationToUpdate.Protocol = calendarEventConfiguration.Protocol;

        dispatch(updateCalendarEventConfiguration(calendarEventConfigurationToUpdate));
      } else {
        dispatch(createCalendarEventConfiguration(calendarEventConfiguration));
      }
    },
    [dispatch, isEditing, initialValues]
  );

  // Components
  const CreateCalendarEventConfigurationForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as CalendarEventConfiguration)}
        initialValues={initialValues}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="calendarEventConfigurations.name" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'calendarEventConfigurations.form.warnings.name' }),
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="Protocol"
                label={<Translated id="calendarEventConfigurations.protocol" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'calendarEventConfigurations.form.warnings.protocol' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="calendarEventConfigurations.protocol" />}>
                  {CalendarEventConfigurationProtocolTypes.map((type) => (
                    <Option key={type.Value} value={type.Value}>
                      {type.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, initialValues, intl]
  );
  // Form options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'CalendarEventConfigurations',
        Form: CreateCalendarEventConfigurationForm,
        labels: {
          createButton: <Translated id="calendarEventConfigurations.form.create" />,
          drawerForm: (
            <Translated
              id={isEditing ? 'calendarEventConfigurations.form.edit' : 'calendarEventConfigurations.form.create'}
            />
          ),
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateCalendarEventConfigurationForm, isEditing]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (calendarEventConfiguration: CalendarEventConfiguration | undefined) => {
      if (calendarEventConfiguration) {
        confirm({
          title: intl.formatMessage({
            id: 'calendarEventConfigurations.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'calendarEventConfigurations.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteCalendarEventConfiguration(calendarEventConfiguration)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const calendarEventConfigurations = selectedFlatRows.map((d) => d.original) as CalendarEventConfiguration[];
      if (calendarEventConfigurations) {
        confirm({
          title: intl.formatMessage({
            id: 'calendarEventConfigurations.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'calendarEventConfigurations.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteCalendarEventConfigurations(calendarEventConfigurations)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
      showDeleteAllConfirm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm]
  );
};

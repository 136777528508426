import styled from 'styled-components';

export const Container = styled.div`
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;

  @media screen and (min-width: 768px) {
    width: 720px;
  }
  @media screen and (min-width: 992px) {
    width: 920px;
  }
  @media screen and (min-width: 1200px) {
    width: 1100px;
  }
  @media screen and (min-width: 1367px) {
    width: 1200px;
  }
  @media screen and (min-width: 1600px) {
    width: 1400px;
  }
`;

import React from 'react';
import { CellProps, HeaderProps } from 'react-table';
import { TableExpandableCell } from './TableExpandableCell';
import { TableExpandableHeader } from './TableExpandableHeader';

// Constants
export const EXPANSION_COLUMN = 'expansion';

// Render
export const getExpansionColumn = <D extends object, V = any>() => ({
  id: EXPANSION_COLUMN,
  width: 40,
  Header: (props: HeaderProps<D>) => <TableExpandableHeader {...props} />,
  Cell: (props: CellProps<D, V[]>) => <TableExpandableCell {...props} />,
});

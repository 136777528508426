import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useParams } from 'react-router';
import { useKiosksDetail } from '../../hooks/Kiosks/useKiosksDetail';

// Components
import { Container } from '../../components/UI/Base';
import { ProfileHeader } from '../../components/Kiosks';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';
import { KiosksCustomBranding } from '../../components/Kiosks/KiosksCustomBranding';
import { ImagePreviewCard } from '../../components/Kiosks/ImagePreviewCard';

export const KiosksDetailPage = () => {
  const { id } = useParams();
  const {
    getBreadcrumbMenuProps,
    getProfileHeaderProps,
    getFormDrawerProps,
    getKiosksCustomBrandingProps,
    getKioskDataProps,
  } = useKiosksDetail({ id });

  return (
    <>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <KiosksCustomBranding {...getKioskDataProps()} {...getKiosksCustomBrandingProps()} />
          </Col>
          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <ImagePreviewCard {...getKioskDataProps()} {...getKiosksCustomBrandingProps()} />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </>
  );
};

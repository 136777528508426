/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { CalendarEventConfiguration } from '../../models/CalendarEventConfiguration';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface CalendarEventConfigurationState {
  updating: boolean;
  updatingDefault: boolean;
  error: boolean;
}

const initialState: CalendarEventConfigurationState = {
  updating: false,
  updatingDefault: false,
  error: false,
};

// Name
const storeName = '@CALENDAR_EVENT_CONFIGURATION';

// Redux Actions|Reducers
const CalendarEventConfigurationSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createCalendarEventConfiguration: (state, action: PayloadAction<CalendarEventConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    calendarEventConfigurationCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    calendarEventConfigurationNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateCalendarEventConfiguration: (state, action: PayloadAction<CalendarEventConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    calendarEventConfigurationUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    calendarEventConfigurationNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteCalendarEventConfiguration: (state, action: PayloadAction<CalendarEventConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    calendarEventConfigurationDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    calendarEventConfigurationNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteCalendarEventConfigurations: (state, action: PayloadAction<Array<CalendarEventConfiguration>>) => {
      state.updating = true;
      state.error = false;
    },
    calendarEventConfigurationsDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    calendarEventConfigurationsNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Fetch
    fetchCalendarEvents: (state, action: PayloadAction<CalendarEventConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    calendarEventsFetched: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    calendarEventsNotFetched: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createCalendarEventConfiguration,
  calendarEventConfigurationCreated,
  calendarEventConfigurationNotCreated,
  // Update
  updateCalendarEventConfiguration,
  calendarEventConfigurationUpdated,
  calendarEventConfigurationNotUpdated,
  // Delete
  deleteCalendarEventConfiguration,
  calendarEventConfigurationDeleted,
  calendarEventConfigurationNotDeleted,
  // Delete All
  deleteCalendarEventConfigurations,
  calendarEventConfigurationsDeleted,
  calendarEventConfigurationsNotDeleted,
  // Fetch
  fetchCalendarEvents,
  calendarEventsFetched,
  calendarEventsNotFetched,
} = CalendarEventConfigurationSlice.actions;

// Export Reducer
export default CalendarEventConfigurationSlice.reducer;

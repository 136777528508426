// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  actionCreated,
  actionDeleted,
  actionNotCreated,
  actionNotDeleted,
  actionNotUpdated,
  actionUpdated,
} from './Actions.redux';
import { OperationType } from '../../models/SignalRAction';

// Events
const SignalREvents = {
  ActionOperation: 'ActionOperationIntegrationEvent',
};

// Exceptions
const SignalRExceptions = {
  ActionOperationFailed: 'ActionOperationFailedIntegrationException',
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // CRUD Operations
  hubConnection.on(SignalREvents.ActionOperation, (msg) => {
    const op = msg.operation as OperationType;
    switch (op) {
      case OperationType.Create:
        store.dispatch(actionCreated({ history, msg }));
        break;
      case OperationType.Update:
        store.dispatch(actionUpdated({ history, msg }));
        break;
      case OperationType.Delete:
        store.dispatch(actionDeleted({ history, msg }));
        break;
      default:
        break;
    }
  });
  hubConnection.on(SignalRExceptions.ActionOperationFailed, (msg) => {
    const op = msg.operation as OperationType;
    switch (op) {
      case OperationType.Create:
        store.dispatch(actionNotCreated({ history, msg }));
        break;
      case OperationType.Update:
        store.dispatch(actionNotUpdated({ history, msg }));
        break;
      case OperationType.Delete:
        store.dispatch(actionNotDeleted({ history, msg }));
        break;
      default:
        break;
    }
  });
};

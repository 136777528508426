// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  accessProfileCreated,
  accessProfileDeleted,
  accessProfileNotCreated,
  accessProfileNotDeleted,
  accessProfileNotUpdated,
  accessProfilesDeleted,
  accessProfileSettingsNotUpdated,
  accessProfileSettingsUpdated,
  accessProfilesNotDeleted,
  accessProfileUpdated,
} from './AccessProfiles.redux';

// Events
const SignalREvents = {
  AccessProfileCreated: `AccessProfileCreatedIntegrationEvent`,
  AccessProfileUpdated: `AccessProfileUpdatedIntegrationEvent`,
  AccessProfileDeleted: `AccessProfileDeletedIntegrationEvent`,
  AccessProfilesDeleted: `AccessProfilesDeletedIntegrationEvent`,
  AccessProfileSettingsUpdated: `AccessProfileSettingsUpdatedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  AccessProfileNotCreated: `AccessProfileNotCreatedIntegrationException`,
  AccessProfileNotUpdated: `AccessProfileNotUpdatedIntegrationException`,
  AccessProfileNotDeleted: `AccessProfileNotDeletedIntegrationException`,
  AccessProfilesNotDeleted: `AccessProfilesNotDeletedIntegrationException`,
  AccessProfileSettingsNotUpdated: `AccessProfileSettingsNotUpdatedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.AccessProfileCreated, (msg) => {
    store.dispatch(accessProfileCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AccessProfileNotCreated, (msg) => {
    store.dispatch(accessProfileNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.AccessProfileUpdated, (msg) => {
    store.dispatch(accessProfileUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AccessProfileNotUpdated, (msg) => {
    store.dispatch(accessProfileNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.AccessProfileDeleted, (msg) => {
    store.dispatch(accessProfileDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AccessProfileNotDeleted, (msg) => {
    store.dispatch(accessProfileNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.AccessProfilesDeleted, (msg) => {
    store.dispatch(accessProfilesDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AccessProfilesNotDeleted, (msg) => {
    store.dispatch(accessProfilesNotDeleted({ history, msg }));
  });

  // Update Settings
  hubConnection.on(SignalREvents.AccessProfileSettingsUpdated, (msg) => {
    store.dispatch(accessProfileSettingsUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AccessProfileSettingsNotUpdated, (msg) => {
    store.dispatch(accessProfileSettingsNotUpdated({ history, msg }));
  });
};

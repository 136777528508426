import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import styled from 'styled-components';
import { Action } from '../../../models/Action';
import { Tenant } from '../../../models/Tenant';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { assignActionTenants } from '../../../store/Actions/Actions.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';

const { confirm } = Modal;

// Props
interface ActionTenantsFormProps {
  actionTenantsResponse: Action | null;
  actionTenants: Array<Tenant> | null;
}

interface AssignConfirmProps {
  tenants: Array<Tenant>;
  action: Action;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useActionTenantsRedux = ({ actionTenantsResponse, actionTenants }: ActionTenantsFormProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ actions: actionTenantsState }) => actionTenantsState?.updating ?? false);
  const error = useAppSelector(({ actions: actionTenantsState }) => actionTenantsState?.error ?? false);

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ tenants, action }: AssignConfirmProps) => {
      if (tenants && action) {
        confirm({
          title: intl.formatMessage({
            id: 'actions.Tenants.multiSelect.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'actions.Tenants.multiSelect.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignActionTenants({ action, tenants })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // State
  const [tenantIds, setTenantIds] = useState<Array<string>>([]);

  // Sets the Keys when the response is updated
  // Fixes problem with initial values after API call
  useEffect(() => {
    setTenantIds(
      actionTenants
        ?.filter((tenant) =>
          actionTenantsResponse?.Tenants?.some(
            (actionTenant) =>
              actionTenant.CompanyId === tenant.CompanyId &&
              actionTenant.LocationId === tenant.LocationId &&
              actionTenant.BusinessClientId === tenant.BusinessClientId
          )
        )
        .map((tenant) => tenant.Id) ?? []
    );
  }, [actionTenants, actionTenantsResponse]);

  // Components
  const ActionTenantsManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      actionTenants?.map<TransferItem>((actionTenant) => ({
        key: actionTenant.Id,
        title: actionTenant.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <ItemTransfer dataSource={dataSource} targetKeys={tenantIds} setTargetKeys={setTenantIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            actionTenantsResponse &&
            actionTenants &&
            showAssignConfirm({
              tenants: actionTenants.filter((t) => tenantIds.some((id) => id === t.Id)),
              action: actionTenantsResponse,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [actionTenantsResponse, actionTenants, showAssignConfirm, tenantIds, setTenantIds]);

  return useMemo(
    () => ({
      ActionTenantsManagementForm,
      updating,
      error,
    }),
    [ActionTenantsManagementForm, updating, error]
  );
};

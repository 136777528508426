import { Button } from 'antd';
import React from 'react';
import { IdentityUser } from '../../models/IdentityUser';
import { Translated } from '../UI/Core';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { TranslatedTag } from '../UI/Tag/TranslatedTag';
import { Widget } from '../UI/Widget/Widget';

interface ContactCardProps {
  identityUser: IdentityUser | null;
  showResendInvitationConfirm: (identityUser: IdentityUser) => void;
  pending: boolean;
}

export const ContactCard = ({ identityUser, pending, showResendInvitationConfirm }: ContactCardProps) => (
  <Widget title={<Translated id="app.identityUser" />} styleName="gx-card-profile-sm">
    {/* Email */}
    <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
      <div className="gx-mr-3">
        <i className="icon icon-avatar gx-fs-xxl gx-text-grey" />
      </div>
      <div className="gx-media-body">
        <span className="gx-mb-0 gx-text-grey gx-fs-sm">
          <Translated id="identityUsers.email" />
        </span>
        <div className="gx-mb-0">
          <span>{pending ? <ButtonSkeleton width={120} height={10} /> : identityUser?.Email}</span>
        </div>
      </div>
    </div>

    {/* Confirmed */}
    <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
      <div className="gx-mr-3">
        <i className="icon icon-email gx-fs-xxl gx-text-grey" />
      </div>
      <div className="gx-media-body">
        <span className="gx-mb-0 gx-text-grey gx-fs-sm">
          <Translated id="identityUsers.confirmed" />
        </span>
        <div className="gx-mb-0">
          <span>
            {pending ? (
              <ButtonSkeleton width={120} height={10} />
            ) : identityUser?.Confirmed ? (
              <TranslatedTag color="green" translationId="app.yes" />
            ) : (
              <>
                <TranslatedTag color="red" translationId="app.no" />
                <Button
                  type="primary"
                  ghost
                  style={{ marginBottom: 0 }}
                  size="small"
                  onClick={() => identityUser && showResendInvitationConfirm(identityUser)}
                >
                  <Translated id="identityUsers.resendConfirmation" />
                </Button>
              </>
            )}
          </span>
        </div>
      </div>
    </div>

    {/* 2FA */}
    <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
      <div className="gx-mr-3">
        <i className="icon icon-lock-screen gx-fs-xxl gx-text-grey" />
      </div>
      <div className="gx-media-body">
        <span className="gx-mb-0 gx-text-grey gx-fs-sm">
          <Translated id="identityUsers.2fa" />
        </span>
        <div className="gx-mb-0">
          <span>
            {pending ? (
              <ButtonSkeleton width={120} height={10} />
            ) : identityUser?.TwoFactorEnabled ? (
              <TranslatedTag color="green" translationId="app.yes" />
            ) : (
              <TranslatedTag color="red" translationId="app.no" />
            )}
          </span>
        </div>
      </div>
    </div>
  </Widget>
);

import React, { useCallback, useMemo } from 'react';

// Hooks
import { useApiConfigurationsRedux } from './useApiConfigurationsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { apiConfigurationTableColumns } from '../../data/Table/ApiConfigurationTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

export const useApiConfigurationsList = <T extends object>() => {
  // Data
  const {
    data: apiConfigurations,
    pageCount,
    totalCount,
    fetch,
    pending,
  } = useData(ApiEndpoints.apiConfigurations.list, null);
  const data = useMemo(() => apiConfigurations, [apiConfigurations]);

  // Redux
  const { formOptions, updating, error, showDeleteAllConfirm } = useApiConfigurationsRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.apiConfigurations.list }), []);
  const getApiConfigurationsDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.ApiConfigurations.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-chart-radar" style={{ marginRight: 8 }} />
          <Translated id="appMenu.apiConfigurations" />
        </>
      ),
      data: data ?? [],
      columns: getTableColumns(apiConfigurationTableColumns, LocalStorageKeys.ApiConfigurations.table.hiddenColumns),
      fetchData: fetch,
    }),
    [fetch, data]
  );
  const getApiConfigurationsTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getApiConfigurationsCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getApiConfigurationsDataProps,
      getApiConfigurationsTableProps,
      getApiConfigurationsCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getApiConfigurationsDataProps,
      getApiConfigurationsTableProps,
      getFormDrawerProps,
      getApiConfigurationsCrudProps,
    ]
  );
};

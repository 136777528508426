import React from 'react';

import { TableColumn } from '../../types/Table';
import { Company } from '../../models/Company';
import { Translated } from '../../components/UI/Core';

export const companyTableColumns: Array<TableColumn<Company>> = [
  {
    Header: <Translated id="companies.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="companies.name" />,
    id: 'Name',
    accessor: 'Name',
  },
];

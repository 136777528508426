import { Space } from 'antd';
import React from 'react';
import { BillingStatsContent } from '../../../models/Dashboard/Content/BillingStatsContent';
import { Translated } from '../../UI/Core';
import { ButtonSkeleton } from '../../UI/Skeleton/ButtonSkeleton';

import { LineIndicator } from '../ExtraImports/LineIndicator';

interface BillingStatsProps {
  content: Array<BillingStatsContent> | undefined;
}

export const BillingStats = ({ content }: BillingStatsProps) => (
  <div className="ant-col gx-audi-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-5">
    <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
      <h6 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4">
        <Translated id="dashboard.welcomeCard.audience" />
      </h6>
      <ul className="gx-line-indicator">
        {content?.map((item) => (
          <li key={item.id}>
            <LineIndicator {...item} />
          </li>
        )) ?? (
          <Space direction="vertical" size={6}>
            <ButtonSkeleton width={140} height={14} />
            <ButtonSkeleton width={40} height={8} />
            <ButtonSkeleton width={110} height={14} />
            <ButtonSkeleton width={40} height={8} />
            <ButtonSkeleton width={150} height={14} />
            <ButtonSkeleton width={40} height={8} />
          </Space>
        )}
      </ul>
    </div>
  </div>
);

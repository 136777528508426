import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import styled from 'styled-components';
import { AccessProfile, AccessProfileResponse } from '../../../models/AccessProfile';
import { Tenant } from '../../../models/Tenant';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { assignAccessProfileTenants } from '../../../store/AccessProfiles/AccessProfileTenants/AccessProfileTenants.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';

const { confirm } = Modal;

// Props
interface AccessProfileTenantsReduxProps {
  accessProfileTenantsResponse: AccessProfileResponse | null;
  accessProfileTenants: Array<Tenant> | null;
}

interface AssignConfirmProps {
  tenants: Array<Tenant>;
  accessProfile: AccessProfile;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useAccessProfileTenantsRedux = ({
  accessProfileTenantsResponse,
  accessProfileTenants,
}: AccessProfileTenantsReduxProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(
    ({ accessProfileTenants: accessProfileTenantsState }) => accessProfileTenantsState?.updating ?? false
  );
  const error = useAppSelector(
    ({ accessProfileTenants: accessProfileTenantsState }) => accessProfileTenantsState?.error ?? false
  );

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ tenants, accessProfile }: AssignConfirmProps) => {
      if (tenants && accessProfile) {
        confirm({
          title: intl.formatMessage({
            id: 'accessProfiles.Tenants.multiSelect.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'accessProfiles.Tenants.multiSelect.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignAccessProfileTenants({ tenants, accessProfile })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // State
  const [tenantIds, setTenantIds] = useState<Array<string>>([]);

  // Sets the Keys when the response is updated
  // Fixes problem with initial values after API call
  useEffect(() => {
    setTenantIds(
      accessProfileTenants
        ?.filter((tenant) =>
          accessProfileTenantsResponse?.AccessProfile?.Tenants?.some(
            (accessProfileTenant) =>
              accessProfileTenant.CompanyId === tenant.CompanyId &&
              accessProfileTenant.LocationId === tenant.LocationId &&
              accessProfileTenant.BusinessClientId === tenant.BusinessClientId
          )
        )
        .map((tenant) => tenant.Id) ?? []
    );
  }, [accessProfileTenants, accessProfileTenantsResponse]);

  // Components
  const AccessProfileTenantsManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      accessProfileTenants?.map<TransferItem>((accessProfileTenant) => ({
        key: accessProfileTenant.Id,
        title: accessProfileTenant.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <ItemTransfer dataSource={dataSource} targetKeys={tenantIds} setTargetKeys={setTenantIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            accessProfileTenantsResponse &&
            accessProfileTenants &&
            showAssignConfirm({
              tenants: accessProfileTenants.filter((t) => tenantIds.some((id) => id === t.Id)),
              accessProfile: accessProfileTenantsResponse.AccessProfile,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [accessProfileTenantsResponse, accessProfileTenants, showAssignConfirm, tenantIds, setTenantIds]);

  return useMemo(
    () => ({
      AccessProfileTenantsManagementForm,
      updating,
      error,
    }),
    [AccessProfileTenantsManagementForm, updating, error]
  );
};

import React, { useCallback, useMemo } from 'react';

// Hooks
import { useIdentifierTypesRedux } from './useIdentifierTypesRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { identifierTypeTableColumns } from '../../data/Table/IdentifierTypeTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

export const useIdentifierTypeList = <T extends object>() => {
  // Data
  const {
    data: identifierTypes,
    pageCount,
    totalCount,
    fetch,
    pending,
  } = useData(ApiEndpoints.identifierTypes.list, null);
  const data = useMemo(() => identifierTypes, [identifierTypes]);

  // Redux
  const { formOptions, updating, error, showDeleteAllConfirm } = useIdentifierTypesRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.identifierTypes.list }), []);
  const getIdentifierTypesDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.IdentifierTypes.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-product-list" style={{ marginRight: 8 }} />
          <Translated id="appMenu.identifierTypes" />
        </>
      ),
      data: data ?? [],
      columns: getTableColumns(identifierTypeTableColumns, LocalStorageKeys.IdentifierTypes.table.hiddenColumns),
      fetchData: fetch,
    }),
    [fetch, data]
  );
  const getIdentifierTypesTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getIdentifierTypesCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getIdentifierTypesDataProps,
      getIdentifierTypesTableProps,
      getIdentifierTypesCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getIdentifierTypesDataProps,
      getIdentifierTypesTableProps,
      getFormDrawerProps,
      getIdentifierTypesCrudProps,
    ]
  );
};

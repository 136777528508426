import React, { useCallback, useMemo } from 'react';
import { Divider, Form, Input, Select, Space, Switch } from 'antd';
import FormItemLabel from 'antd/lib/form/FormItemLabel';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import {
  SaltoSpaceAuthentication,
  SaltoSpaceAuthenticationTypes,
  SaltoSpaceFormSettings,
  SaltoSpaceSettingsFormValues,
} from '../../../../models/PACSSettingFormValues';
import { ServiceConfigurationResponse } from '../../../../models/ServiceConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';

// Components
const { Item } = Form;
const { Option } = Select;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0px;
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface SaltoSpaceSettingsProps {
  serviceConfigurationResponse: ServiceConfigurationResponse | null;
}

// Hook
export const useSaltoSpaceSettings = ({
  // updating,
  serviceConfigurationResponse,
}: SaltoSpaceSettingsProps) => {
  // Intl
  const intl = useIntl();

  // Form Values
  const initialValues: SaltoSpaceSettingsFormValues = useMemo(
    () => ({
      Id: serviceConfigurationResponse?.ServiceConfiguration.Id,
      Name: serviceConfigurationResponse?.ServiceConfiguration.Name,
      AccessControlSystemType: serviceConfigurationResponse?.ServiceConfiguration.AccessControlSystemType,
      ManageEmployees: serviceConfigurationResponse?.ServiceConfiguration.ManageEmployees,
      SettingsData: JSON.parse(
        serviceConfigurationResponse?.SettingsData?.SettingsData ?? '{}'
      ) as SaltoSpaceFormSettings,
    }),
    [serviceConfigurationResponse]
  );

  // Components
  const SaltoSpaceSettingsForm = useCallback(
    () => (
      <>
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="ManageEmployees" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="AccessControlSystemType" hidden>
          <Input />
        </Item>

        {/* Form Values */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'Host']}
            label={<Translated id="pacsSetting.form.host" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.host' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem
            name={['SettingsData', 'Port']}
            label={<Translated id="pacsSetting.form.port" />}
            rules={[
              () => ({
                validator(_, value) {
                  const num = Number.parseInt(value, 10);
                  if (!Number.isNaN(num)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(intl.formatMessage({ id: 'pacsSetting.form.warnings.port' })));
                },
              }),
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem
            name={['SettingsData', 'AuthenticationType']}
            label={<Translated id="pacsSetting.form.authType" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.authType' }),
              },
            ]}
          >
            <Select placeholder={<Translated id="serviceConfigurations.accessControlSystemType" />}>
              {SaltoSpaceAuthenticationTypes.map((type) => (
                <Option key={type.Value} value={type.Value}>
                  {type.Name}
                </Option>
              ))}
            </Select>
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Item
          noStyle
          shouldUpdate={(prevValues: any, currentValues: any) =>
            prevValues.SettingsData.AuthenticationType !== currentValues.SettingsData.AuthenticationType
          }
        >
          {({ getFieldValue }) =>
            getFieldValue(['SettingsData', 'AuthenticationType']) === SaltoSpaceAuthentication.HttpBasic ? (
              <StyledSpace align="start">
                <NoSidePaddingFormItem
                  name={['SettingsData', 'Username']}
                  label={<Translated id="pacsSetting.form.username" />}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'pacsSetting.form.warnings.username' }),
                    },
                  ]}
                >
                  <Input />
                </NoSidePaddingFormItem>
              </StyledSpace>
            ) : null
          }
        </Item>

        <Item
          noStyle
          shouldUpdate={(prevValues: any, currentValues: any) =>
            prevValues.SettingsData.AuthenticationType !== currentValues.SettingsData.AuthenticationType
          }
        >
          {({ getFieldValue }) =>
            getFieldValue(['SettingsData', 'AuthenticationType']) !== SaltoSpaceAuthentication.Stp ? (
              <>
                <StyledSpace align="start">
                  <NoSidePaddingFormItem
                    name={['SettingsData', 'Password']}
                    label={<Translated id="pacsSetting.form.password" />}
                  >
                    <Input.Password />
                  </NoSidePaddingFormItem>

                  <NoSidePaddingFormItem
                    name={['SettingsData', 'ConfirmPassword']}
                    label={<Translated id="pacsSetting.form.confirmPassword" />}
                    dependencies={['SettingsData', 'Password']}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value || getFieldValue(['SettingsData', 'Password']) === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(intl.formatMessage({ id: 'pacsSetting.form.warnings.confirmPassword' }))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </NoSidePaddingFormItem>
                </StyledSpace>
                <Space>
                  <FormItemLabel label={<Translated id="pacsSetting.form.useHttps" />} prefixCls="cls" />
                  <NoMarginBottomFormItem name={['SettingsData', 'UseHttps']} valuePropName="checked">
                    <Switch
                      checkedChildren={<Translated id="pacsSetting.form.active" />}
                      unCheckedChildren={<Translated id="pacsSetting.form.disabled" />}
                    />
                  </NoMarginBottomFormItem>
                </Space>

                <Divider />

                <Space>
                  <FormItemLabel label={<Translated id="pacsSetting.form.ignoreSslErrors" />} prefixCls="cls" />
                  <NoMarginBottomFormItem name={['SettingsData', 'IgnoreSslErrors']} valuePropName="checked">
                    <Switch
                      checkedChildren={<Translated id="pacsSetting.form.active" />}
                      unCheckedChildren={<Translated id="pacsSetting.form.disabled" />}
                    />
                  </NoMarginBottomFormItem>
                </Space>
              </>
            ) : null
          }
        </Item>
      </>
    ),
    [intl]
  );

  return useMemo(
    () => ({
      SaltoSpaceSettingsForm,
      initialValues,
    }),
    [SaltoSpaceSettingsForm, initialValues]
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IdentifierType } from '../../../models/IdentifierType';
import { SignalRAction } from '../../../models/SignalRAction';

// State
interface PACSIdentifierTypesState {
  updating: boolean;
  error: boolean;
}

const initialState: PACSIdentifierTypesState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@PACS_IDENTIFIER_TYPES';

// Redux Actions|Reducers
const pacsIdentifierTypesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignPACSIdentifierTypes: (
      state,
      action: PayloadAction<{ identifierType: IdentifierType; pacsIdentifierTypeIds: Array<string> }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    pacsIdentifierTypesAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    pacsIdentifierTypesNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Sync
    syncPACSIdentifierTypes: (state) => {
      state.updating = true;
      state.error = false;
    },
    pacsIdentifierTypesSynced: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    pacsIdentifierTypesNotSynced: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignPACSIdentifierTypes,
  pacsIdentifierTypesAssigned,
  pacsIdentifierTypesNotAssigned,
  // Sync
  syncPACSIdentifierTypes,
  pacsIdentifierTypesSynced,
  pacsIdentifierTypesNotSynced,
} = pacsIdentifierTypesSlice.actions;

// Export Reducer
export default pacsIdentifierTypesSlice.reducer;

import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

// Utils
import { getEnvironmentStatus } from '../../../constants/Utils/StatusFunctions';

// Models
import { EnvironmentCardContent } from '../../../models/Dashboard/Content/EnvironmentCardContent';
import { Flex } from '../../UI/Base';

// Components
import { EnvironmentCollapse } from '../../UI/Collapse/EnvironmentCollapse';
import { Widget } from '../../UI/Widget/Widget';

export const EnvironmentCard = ({ title, services }: EnvironmentCardContent) => {
  // Hooks
  const intl = useIntl();

  // Utils
  const { statusInfo, problemCount } = getEnvironmentStatus(services);
  const { Color, Icon, NameId } = statusInfo;

  return (
    <Widget styleName="gx-card-widget gx-card-full gx-p-3">
      <Flex alignItems="center" className="gx-media gx-mb-3 gx-mt-1">
        <div className="gx-mr-2 gx-mr-xxl-3">
          <Icon style={{ display: 'flex' }} className={`gx-text-${Color} gx-fs-xxl`} />
        </div>
        <div className="gx-media-body" style={{ wordBreak: 'break-word' }}>
          <Link to="/Tenants" className={`gx-fs-xl gx-text-${Color} gx-font-weight-semi-bold`}>
            {title}
          </Link>
        </div>
      </Flex>

      <EnvironmentCollapse
        header={intl.formatMessage({ id: NameId }, { count: problemCount })}
        content={services}
        showArrow
      />
    </Widget>
  );
};

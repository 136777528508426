import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { IdentifierType } from '../../../models/IdentifierType';
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  assignPACSIdentifierTypes,
  pacsIdentifierTypesAssigned,
  pacsIdentifierTypesNotAssigned,
  pacsIdentifierTypesNotSynced,
  pacsIdentifierTypesSynced,
  syncPACSIdentifierTypes,
} from './PACSIdentifierTypes.redux';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignPACSIdentifierTypesSaga() {
  yield takeEvery(assignPACSIdentifierTypes.type, assignPACSIdentifierTypesRequest);
}

function* pacsIdentifierTypesAssignedSaga() {
  yield takeLatest(pacsIdentifierTypesAssigned.type, assignPACSIdentifierTypesResponse);
}

function* pacsIdentifierTypesNotAssignedSaga() {
  yield takeLatest(pacsIdentifierTypesNotAssigned.type, assignPACSIdentifierTypesError);
}

// Request
function* assignPACSIdentifierTypesRequest(
  action: PayloadAction<{ identifierType: IdentifierType; pacsIdentifierTypeIds: Array<string> }>
) {
  try {
    const {
      payload: { identifierType, pacsIdentifierTypeIds },
    } = action;
    yield apiService.execute({
      url: `IdentifierTypes/${identifierType.Id}/PACS`,
      method: ApiRequestType.PUT,
      data: {
        identifierTypeId: identifierType.Id,
        pacsIdentifierTypeIds,
      },
    });
  } catch ({ message }) {
    yield put({ type: pacsIdentifierTypesNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignPACSIdentifierTypesResponse() {
  notificationService.showSuccess('identifierTypes.pacsIdentifierTypes.notifications.assign');
}

// Error
function assignPACSIdentifierTypesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'identifierTypes.pacsIdentifierTypes.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* SYNC ***********************

// Worker Sagas
function* syncPACSIdentifierTypesSaga() {
  yield takeEvery(syncPACSIdentifierTypes.type, syncPACSIdentifierTypesRequest);
}

function* pacsIdentifierTypesSyncedSaga() {
  yield takeLatest(pacsIdentifierTypesSynced.type, syncPACSIdentifierTypesResponse);
}

function* pacsIdentifierTypesNotSyncedSaga() {
  yield takeLatest(pacsIdentifierTypesNotSynced.type, syncPACSIdentifierTypesError);
}

// Request
function* syncPACSIdentifierTypesRequest() {
  try {
    yield apiService.execute({
      url: `IdentifierTypes/PACS/Sync`,
      method: ApiRequestType.POST,
      data: {},
    });
  } catch ({ message }) {
    yield put({ type: pacsIdentifierTypesNotSynced.type, payload: { msg: { message } } });
  }
}

// Response
function syncPACSIdentifierTypesResponse() {
  notificationService.showSuccess('identifierTypes.pacsIdentifierTypes.notifications.synced');
}

// Error
function syncPACSIdentifierTypesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'identifierTypes.pacsIdentifierTypes.notifications.syncFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignPACSIdentifierTypesSaga(),
    pacsIdentifierTypesAssignedSaga(),
    pacsIdentifierTypesNotAssignedSaga(),
    // Sync
    syncPACSIdentifierTypesSaga(),
    pacsIdentifierTypesSyncedSaga(),
    pacsIdentifierTypesNotSyncedSaga(),
  ]);
}

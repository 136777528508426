import React from 'react';
import { Skeleton } from 'antd';

interface ButtonSkeletonProps {
  size: number;
}

export const AvatarSkeleton = ({ size }: ButtonSkeletonProps) => (
  <Skeleton.Avatar active style={{ width: `${size}px`, height: `${size}px`, lineHeight: `${size}px` }} />
);

import { Button, Divider, Form, Input, InputNumber } from 'antd';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';

import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Action, ActionFormValues } from '../../models/Action';
import { updateAction } from '../../store/Actions/Actions.redux';
import { ActionType } from '../../models/enums/ActionType';

const { Item } = Form;

interface ContactCardProps {
  action: Action | null;
  pending: boolean;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

export const ActionSettings = ({ action, pending }: ContactCardProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { updatingSettings } = useAppSelector(({ actions }) => actions);

  // Form Values
  const initialValues: ActionFormValues = useMemo(
    () => ({
      Id: action?.Id,
      Name: action?.Name,
      DisplayLabel: action?.DisplayLabel,
      ServiceConfigurationId: action?.ServiceConfigurationId,
      Type: action?.Type,
      Tenants: [],
      Settings: action?.Settings,
    }),
    [action]
  );

  const SettingsForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values: Action) => dispatch(updateAction(values))}
      >
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="Type" hidden>
          <Input />
        </Item>
        <Item name="ServiceConfigurationId" hidden>
          <Input />
        </Item>
        <Item name="DisplayLabel" hidden>
          <Input />
        </Item>

        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          {action?.Type === ActionType.Encode ? (
            <>
              <Form.Item
                name={['Settings', 'Encoder']}
                style={{ marginRight: 10 }}
                label={<Translated id="actionsSetting.encoder" />}
              >
                <Input placeholder="Encoder" />
              </Form.Item>
              <Form.Item name={['Settings', 'Timeout']} label={<Translated id="actionsSetting.timeout" />}>
                <InputNumber placeholder="Timeout" />
              </Form.Item>
            </>
          ) : (
            <Form.Item name={['Settings', 'Door']} label={<Translated id="actionsSetting.door" />}>
              <Input placeholder="Door" />
            </Form.Item>
          )}
        </div>

        <Divider />
        <StyledButton type="primary" htmlType="submit">
          <Translated id="form.confirmButton" />
        </StyledButton>
      </Form>
    ),
    [initialValues, dispatch, action]
  );

  return (
    <Widget title={<Translated id="app.action" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updatingSettings || pending}>
        <SettingsForm />
      </Spinner>
    </Widget>
  );
};

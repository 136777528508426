import { CheckCircleFilled, ExclamationCircleFilled, StopFilled, WarningFilled } from '@ant-design/icons';
import React from 'react';

export enum EnvironmentStatus {
  Errors = -3,
  Error = -2,
  AllError = -1,
  NotFound = 0,
  Operational = 1,
  Warning = 2,
  Warnings = 3,
}

export interface EnvironmentStatusType {
  NameId: string;
  Color: string;
  Icon: React.ForwardRefExoticComponent<any>;
  EnvironmentStatus: EnvironmentStatus;
}

export const EnvironmentStatusInfo: EnvironmentStatusType[] = [
  {
    NameId: 'dashboard.textCardGroup.systemsNonOperational',
    Color: 'error',
    Icon: StopFilled,
    EnvironmentStatus: EnvironmentStatus.AllError,
  },
  {
    NameId: 'dashboard.textCardGroup.systemNeedsAttention',
    Color: 'error',
    Icon: WarningFilled,
    EnvironmentStatus: EnvironmentStatus.Error,
  },
  {
    NameId: 'dashboard.textCardGroup.systemsNeedAttention',
    Color: 'error',
    Icon: WarningFilled,
    EnvironmentStatus: EnvironmentStatus.Errors,
  },
  {
    NameId: 'dashboard.textCardGroup.noAccessControlSystems',
    Color: 'info',
    Icon: ExclamationCircleFilled,
    EnvironmentStatus: EnvironmentStatus.NotFound,
  },
  {
    NameId: 'dashboard.textCardGroup.systemsOperational',
    Color: 'success',
    Icon: CheckCircleFilled,
    EnvironmentStatus: EnvironmentStatus.Operational,
  },
  {
    NameId: 'dashboard.textCardGroup.systemNeedsAttention',
    Color: 'warning',
    Icon: ExclamationCircleFilled,
    EnvironmentStatus: EnvironmentStatus.Warning,
  },
  {
    NameId: 'dashboard.textCardGroup.systemsNeedAttention',
    Color: 'warning',
    Icon: ExclamationCircleFilled,
    EnvironmentStatus: EnvironmentStatus.Warnings,
  },
];

import React, { useCallback, useMemo } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row as TableRow } from 'react-table';

// Types
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { ApiConfiguration } from '../../models/ApiConfiguration';
import { ApiConfigurationTypes } from '../../models/enums/ApiConfigurationTypes';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import {
  createApiConfiguration,
  deleteApiConfiguration,
  deleteApiConfigurations,
  updateApiConfiguration,
} from '../../store/ApiConfigurations/ApiConfigurations.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';

const { confirm } = Modal;
const { Option } = Select;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface ApiConfigurationsReduxProps {
  isEditing?: boolean;
  initialValues?: Store;
}

// Hooks
export const useApiConfigurationsRedux = <T extends object>({
  isEditing,
  initialValues,
}: ApiConfigurationsReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ apiConfigurations }) => apiConfigurations?.updating ?? false);
  const error = useAppSelector(({ apiConfigurations }) => apiConfigurations?.error ?? false);

  // Submit Handling
  const onSubmit = useCallback(
    (apiConfiguration: ApiConfiguration) => {
      if (isEditing) {
        dispatch(updateApiConfiguration(apiConfiguration));
      } else {
        dispatch(createApiConfiguration(apiConfiguration));
      }
    },
    [dispatch, isEditing]
  );

  // Components
  const CreateApiConfigurationForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as ApiConfiguration)}
        initialValues={initialValues}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="apiConfigurations.name" />}
                rules={[
                  { required: true, message: intl.formatMessage({ id: 'apiConfigurations.form.warnings.name' }) },
                ]}
              >
                <Input placeholder="Service Configuration" />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="ApiConfigurationType"
                label={<Translated id="apiConfigurations.apiConfigurationType" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'apiConfigurations.form.warnings.apiConfigurationType' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="apiConfigurations.apiConfigurationType" />}>
                  {ApiConfigurationTypes.map((type) => (
                    <Option key={type.Value} value={type.Value}>
                      {type.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, initialValues, intl]
  );
  // Form options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'ApiConfigurations',
        Form: CreateApiConfigurationForm,
        labels: {
          createButton: <Translated id="apiConfigurations.form.create" />,
          drawerForm: <Translated id={isEditing ? 'apiConfigurations.form.edit' : 'apiConfigurations.form.create'} />,
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateApiConfigurationForm, isEditing]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (apiConfiguration: ApiConfiguration | null) => {
      if (apiConfiguration) {
        confirm({
          title: intl.formatMessage({
            id: 'apiConfigurations.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'apiConfigurations.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteApiConfiguration(apiConfiguration)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const apiConfigurations = selectedFlatRows.map((d) => d.original) as ApiConfiguration[];
      if (apiConfigurations) {
        confirm({
          title: intl.formatMessage({
            id: 'apiConfigurations.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'apiConfigurations.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteApiConfigurations(apiConfigurations)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
      showDeleteAllConfirm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm]
  );
};

// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  companiesDeleted,
  companiesNotDeleted,
  companyCreated,
  companyDeleted,
  companyNotCreated,
  companyNotDeleted,
  companyNotUpdated,
  companyUpdated,
} from './Companies.redux';

// Events
const SignalREvents = {
  CompanyCreated: `CompanyCreatedIntegrationEvent`,
  CompanyUpdated: `CompanyUpdatedIntegrationEvent`,
  CompanyDeleted: `CompanyDeletedIntegrationEvent`,
  CompaniesDeleted: `CompaniesDeletedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  CompanyNotCreated: `CompanyNotCreatedIntegrationException`,
  CompanyNotUpdated: `CompanyNotUpdatedIntegrationException`,
  CompanyNotDeleted: `CompanyNotDeletedIntegrationException`,
  CompaniesNotDeleted: `CompaniesNotDeletedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.CompanyCreated, (msg) => {
    store.dispatch(companyCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.CompanyNotCreated, (msg) => {
    store.dispatch(companyNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.CompanyUpdated, (msg) => {
    store.dispatch(companyUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.CompanyNotUpdated, (msg) => {
    store.dispatch(companyNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.CompanyDeleted, (msg) => {
    store.dispatch(companyDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.CompanyNotDeleted, (msg) => {
    store.dispatch(companyNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.CompaniesDeleted, (msg) => {
    store.dispatch(companiesDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.CompaniesNotDeleted, (msg) => {
    store.dispatch(companiesNotDeleted({ history, msg }));
  });
};

import React from 'react';
import { Tag, Tooltip } from 'antd';
import { useIntl } from 'react-intl';

// Utils
import styled from 'styled-components';
import { getLocalDateTimeString } from '../../../constants/Utils/DateTime';

// Models
import { ServiceConfiguration } from '../../../models/ServiceConfiguration';

// Components
import { Translated } from '../Core';
import { EnvironmentService } from '../../../models/Dashboard/ApiResponses/EnvironmentStatsResponse';
import { getServiceStatus } from '../../../constants/Utils/StatusFunctions';
import { ApiConfiguration } from '../../../models/ApiConfiguration';

// Styled
const StyledTag = styled(Tag)`
  margin-bottom: 0;
`;

// Props
interface ServiceStatusTagProps {
  service: ServiceConfiguration | ApiConfiguration | EnvironmentService;
}

// Component
export const ServiceStatusTag = ({ service }: ServiceStatusTagProps) => {
  // Hooks
  const intl = useIntl();

  // Render Tag based on Status and LastChecked
  const { Color, NameId } = getServiceStatus(service);

  return (
    <Tooltip
      placement="bottomLeft"
      title={
        service.LastChecked &&
        intl.formatMessage(
          { id: 'serviceConfigurations.status.lastChecked' },
          { date: getLocalDateTimeString(service.LastChecked) }
        )
      }
    >
      <StyledTag color={Color}>
        <Translated id={NameId} />
      </StyledTag>
    </Tooltip>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, FormInstance, Input, Modal, Row, Select } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row as TableRow } from 'react-table';

// Types
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { ScheduleConfiguration } from '../../models/ScheduleConfiguration';
import { ScheduleConfigurationTypes } from '../../models/enums/ScheduleConfigurationTypes';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import {
  createScheduleConfiguration,
  deleteScheduleConfiguration,
  deleteScheduleConfigurations,
  updateScheduleConfiguration,
} from '../../store/ScheduleConfigurations/ScheduleConfigurations.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { useData } from '../App/useData';
import { Tenant } from '../../models/Tenant';

const { confirm } = Modal;
const { Option } = Select;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface ScheduleConfigurationsReduxProps {
  isEditing?: boolean;
  initialValues?: Store;
}

// Hooks
export const useScheduleConfigurationsRedux = <T extends object>({
  isEditing,
  initialValues,
}: ScheduleConfigurationsReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Form State
  const [formValues, setFormValues] = useState<Store>();

  // Data
  const { data: tenants, fetch: fetchTenants } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ scheduleConfigurations }) => scheduleConfigurations?.updating ?? false);
  const error = useAppSelector(({ scheduleConfigurations }) => scheduleConfigurations?.error ?? false);

  // Submit Handling
  const onSubmit = useCallback(
    (scheduleConfiguration: ScheduleConfiguration) => {
      if (isEditing) {
        dispatch(updateScheduleConfiguration(scheduleConfiguration));
      } else {
        dispatch(createScheduleConfiguration(scheduleConfiguration));
      }
    },
    [dispatch, isEditing]
  );

  const onChangeTenant = useCallback(
    (tenantId: string, form: FormInstance<T>) => {
      const selectedTenant = tenants?.find((tenant) => tenant.Id === tenantId);

      // Update Hidden Form Values
      form.setFieldValue(['Tenant', 'CompanyId'], selectedTenant?.CompanyId);
      form.setFieldValue(['Tenant', 'LocationId'], selectedTenant?.LocationId);
      form.setFieldValue(['Tenant', 'BusinessClientId'], selectedTenant?.BusinessClientId);
    },
    [tenants]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchTenants();
  }, [fetchTenants]);

  useEffect(() => {
    // Fetch on initializing
    if (initialValues === undefined || initialValues === null) {
      return;
    }
    if (tenants === null) {
      return;
    }
    const tenant: Tenant = initialValues.Tenant;

    const newTenant = tenants.find(
      (x) =>
        x.BusinessClientId === tenant.BusinessClientId &&
        x.CompanyId === tenant.CompanyId &&
        x.LocationId === tenant.LocationId
    );

    if (newTenant) {
      setFormValues({
        ...initialValues,
        TenantId: newTenant.Id,
      });
    }
  }, [tenants, initialValues]);

  // Components
  const CreateScheduleConfigurationForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as ScheduleConfiguration)}
        initialValues={formValues}
        onValuesChange={({ TenantId }: Store) => {
          // Api Client Data OnChange
          if (TenantId) onChangeTenant(TenantId, form);
        }}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="Interval" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="CustomConfiguration" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="Enabled" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="scheduleConfigurations.name" />}
                rules={[
                  { required: true, message: intl.formatMessage({ id: 'scheduleConfigurations.form.warnings.name' }) },
                ]}
              >
                <Input placeholder="Schedule Configuration" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="ScheduleConfigurationType"
                label={<Translated id="scheduleConfigurations.scheduleConfigurationType" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'scheduleConfigurations.form.warnings.scheduleConfigurationType',
                    }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="scheduleConfigurations.scheduleConfigurationTypes" />}>
                  {ScheduleConfigurationTypes.map((type) => (
                    <Option key={type.Value} value={type.Value}>
                      {type.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="TenantId"
                label={<Translated id="clients.clientTenant" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'clients.form.warnings.tenant' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="clients.clientTenant" />}>
                  {tenants?.map((tenant) => (
                    <Option key={tenant.Id} value={tenant.Id}>
                      {tenant.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={['Tenant', 'CompanyId']} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={['Tenant', 'LocationId']} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={['Tenant', 'BusinessClientId']} hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, formValues, intl, tenants, onChangeTenant]
  );
  // Form options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'ScheduleConfigurations',
        Form: CreateScheduleConfigurationForm,
        labels: {
          createButton: <Translated id="scheduleConfigurations.form.create" />,
          drawerForm: (
            <Translated id={isEditing ? 'scheduleConfigurations.form.edit' : 'scheduleConfigurations.form.create'} />
          ),
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateScheduleConfigurationForm, isEditing]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (scheduleConfiguration: ScheduleConfiguration | null) => {
      if (scheduleConfiguration) {
        confirm({
          title: intl.formatMessage({
            id: 'scheduleConfigurations.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'scheduleConfigurations.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteScheduleConfiguration(scheduleConfiguration)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const ScheduleConfigurations = selectedFlatRows.map((d) => d.original) as ScheduleConfiguration[];
      if (ScheduleConfigurations) {
        confirm({
          title: intl.formatMessage({
            id: 'scheduleConfigurations.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'scheduleConfigurations.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteScheduleConfigurations(ScheduleConfigurations)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
      showDeleteAllConfirm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm]
  );
};

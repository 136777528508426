// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  calendarEventConfigurationCreated,
  calendarEventConfigurationDeleted,
  calendarEventConfigurationNotCreated,
  calendarEventConfigurationNotDeleted,
  calendarEventConfigurationNotUpdated,
  calendarEventConfigurationsDeleted,
  calendarEventConfigurationsNotDeleted,
  calendarEventConfigurationUpdated,
  calendarEventsFetched,
  calendarEventsNotFetched,
} from './CalendarEventConfigurations.redux';

// Events
const SignalREvents = {
  CalendarEventConfigurationCreated: `CalendarEventConfigurationCreatedIntegrationEvent`,
  CalendarEventConfigurationUpdated: `CalendarEventConfigurationUpdatedIntegrationEvent`,
  CalendarEventConfigurationDeleted: `CalendarEventConfigurationDeletedIntegrationEvent`,
  CalendarEventConfigurationsDeleted: `CalendarEventConfigurationsDeletedIntegrationEvent`,
  CalendarEventsFetched: `CalendarEventsFetchedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  CalendarEventConfigurationNotCreated: `CalendarEventConfigurationNotCreatedIntegrationException`,
  CalendarEventConfigurationNotUpdated: `CalendarEventConfigurationNotUpdatedIntegrationException`,
  CalendarEventConfigurationNotDeleted: `CalendarEventConfigurationNotDeletedIntegrationException`,
  CalendarEventConfigurationsNotDeleted: `CalendarEventConfigurationsNotDeletedIntegrationException`,
  CalendarEventsNotFetched: `CalendarEventsNotFetchedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.CalendarEventConfigurationCreated, (msg) => {
    store.dispatch(calendarEventConfigurationCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.CalendarEventConfigurationNotCreated, (msg) => {
    store.dispatch(calendarEventConfigurationNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.CalendarEventConfigurationUpdated, (msg) => {
    store.dispatch(calendarEventConfigurationUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.CalendarEventConfigurationNotUpdated, (msg) => {
    store.dispatch(calendarEventConfigurationNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.CalendarEventConfigurationDeleted, (msg) => {
    store.dispatch(calendarEventConfigurationDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.CalendarEventConfigurationNotDeleted, (msg) => {
    store.dispatch(calendarEventConfigurationNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.CalendarEventConfigurationsDeleted, (msg) => {
    store.dispatch(calendarEventConfigurationsDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.CalendarEventConfigurationsNotDeleted, (msg) => {
    store.dispatch(calendarEventConfigurationsNotDeleted({ history, msg }));
  });

  // Fetch
  hubConnection.on(SignalREvents.CalendarEventsFetched, (msg) => {
    store.dispatch(calendarEventsFetched({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.CalendarEventsNotFetched, (msg) => {
    store.dispatch(calendarEventsNotFetched({ history, msg }));
  });
};

import { History } from 'history';

export enum OperationType {
  Other = 0,
  Create = 1,
  Update = 2,
  Delete = 3,
}

export type CrudOperationMessage = {
  affectedEntities: Array<string>;
  operation: OperationType;
  message: string | undefined;
};

export type SignalRAction = {
  history: History;
  msg: any;
};

export type SignalRCrudAction = {
  history: History;
  msg: CrudOperationMessage;
};

import styled, { css } from 'styled-components';
import { styleCss } from '../../../constants/Utils/StyledComponents';
import { overflowEllipsis } from './OverflowEllipsis';
import { Block, BlockProps } from './Block';

export const text = css`
  ${styleCss('color')};
  ${styleCss('fontFamily')};
  ${styleCss('fontSize')};
  ${styleCss('fontWeight')};
  ${styleCss('fontStyle')};
  ${styleCss('lineHeight')};
  ${styleCss('textAlign')};
  ${styleCss('textTransform')};
  ${styleCss('textDecoration')};
  ${styleCss('textOverflow')};
`;

export interface TextProps extends BlockProps {
  color?: string;
  fontSize?: string | number;
  fontFamily?: string;
  fontWeight?: string;
  fontStyle?: 'normal' | 'italic';
  lineHeight?: string;
  textAlign?: 'left' | 'right' | 'justify' | 'center' | 'start' | 'end';
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'initial' | 'inherit' | 'none';
  textDecoration?: 'underline';
  textOverflow?: 'ellipsis' | 'clip' | string;

  inline?: boolean;
  ellipsis?: boolean;
  noWrap?: boolean;
}

export const Text = styled(Block.withComponent('span'))<TextProps>`
  ${text};
  ${(props) =>
    props.inline &&
    css`
      display: inline;
    `};
  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `};
  ${(props) => props.ellipsis && overflowEllipsis};
`;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { useAzureTenantsRedux } from './useAzureTenantsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { AzureTenant } from '../../models/AzureTenant';

// Props
interface AzureTenantDetailProps {
  id?: string;
}

export const useAzureTenantDetail = <T extends object>({ id }: AzureTenantDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Data
  const { data: azureTenantResponse, fetch, pending } = useData(ApiEndpoints.azureTenants.detail, null);
  const prevPending = usePrevious(pending);

  // Component State
  const [azureTenantData, setAzureTenantData] = useState<AzureTenant>();

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useAzureTenantsRedux<T>({
    isEditing: true,
    initialValues: azureTenantResponse as Store,
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.azureTenants.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      azureTenantData,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [azureTenantData, pending, getFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      azureTenant: azureTenantResponse,
      pending,
    }),
    [azureTenantResponse, pending]
  );
  const getSettingsProps = useCallback(
    () => ({
      azureTenant: azureTenantData,
      fetchAzureTenant: fetch,
      updatingAzureTenant: pending,
    }),
    [azureTenantData, fetch, pending]
  );
  const getAzureGroupsProps = useCallback(
    () => ({
      azureTenantResponse: azureTenantData,
      fetchAzureTenant: fetch,
      updatingAzureTenant: pending,
    }),
    [azureTenantData, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && !pending) {
      if (!azureTenantResponse) {
        notificationService.showError('azureTenants.notFound');
        navigate('/AzureTenants');
      }
      // Update Component State based on API response
      else {
        setAzureTenantData(azureTenantResponse);
      }
    }
  }, [prevPending, pending, azureTenantResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (prevUpdating === true && !updating && !error) {
      fetch({ id });
    }
  }, [prevUpdating, updating, error, fetch, id]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getSettingsProps,
      getAzureGroupsProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getSettingsProps,
      getAzureGroupsProps,
    ]
  );
};

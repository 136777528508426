import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { DeleteKioskFileRequest, Kiosk } from '../../models/Kiosk';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  createKiosk,
  deleteKiosk,
  deleteKioskFile,
  deleteKiosks,
  kioskActionsNotUpdated,
  kioskActionsUpdated,
  kioskCreated,
  kioskDeleted,
  kioskFileDeleted,
  kioskFileNotDeleted,
  kioskFileNotUpdated,
  kioskFileUpdated,
  kioskNotCreated,
  kioskNotDeleted,
  kioskNotUpdated,
  kiosksDeleted,
  kiosksNotDeleted,
  kioskUpdated,
  updateKiosk,
  updateKioskActions,
  updateKioskFile,
} from './Kiosks.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createKioskSaga() {
  yield takeEvery(createKiosk.type, createKioskRequest);
}

function* kioskCreatedSaga() {
  yield takeLatest(kioskCreated.type, createKioskResponse);
}

function* kioskNotCreatedSaga() {
  yield takeLatest(kioskNotCreated.type, createKioskError);
}

// Request
function* createKioskRequest(action: PayloadAction<Kiosk>) {
  try {
    const { payload: kiosk } = action;
    yield apiService.execute({
      url: 'KioskConfigurations',
      method: ApiRequestType.POST,
      data: kiosk,
    });
  } catch ({ message }) {
    yield put({ type: kioskNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createKioskResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('kiosks.notifications.create');
  action.payload.history.push(`/Kiosks/${action.payload.msg.entityId}`);
}

// Error
function createKioskError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('kiosks.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateKioskSaga() {
  yield takeEvery(updateKiosk.type, updateKioskRequest);
}

function* kioskUpdatedSaga() {
  yield takeLatest(kioskUpdated.type, updateKioskResponse);
}

function* kioskNotUpdatedSaga() {
  yield takeLatest(kioskNotUpdated.type, updateKioskError);
}

// Request
function* updateKioskRequest(action: PayloadAction<Kiosk>) {
  try {
    const { payload: kiosk } = action;
    yield apiService.execute({
      url: `KioskConfigurations/${kiosk.Id}`,
      method: ApiRequestType.PUT,
      data: kiosk,
    });
  } catch ({ message }) {
    yield put({ type: kioskNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateKioskResponse() {
  notificationService.showSuccess('kiosks.notifications.update');
}

// Error
function updateKioskError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('kiosks.notifications.updateFailed', action?.payload?.msg.message);
}

function* updateKioskFileSaga() {
  yield takeEvery(updateKioskFile.type, updateKioskFileRequest);
}

function* kioskFileUpdatedSaga() {
  yield takeLatest(kioskFileUpdated.type, updateKioskFileResponse);
}

function* kioskFileNotUpdatedSaga() {
  yield takeLatest(kioskFileNotUpdated.type, updateKioskFileError);
}

// Request
function* updateKioskFileRequest(action: PayloadAction<Kiosk>) {
  try {
    const formData = new FormData();
    const { payload: kiosk } = action;
    formData.append('FormFile', kiosk.Media[0].FormFile);
    formData.append('MediaType', kiosk.Media[0].MediaType.toString());
    formData.append('Language', kiosk.Media[0].Language.toString());

    yield apiService.execute({
      url: `KioskConfigurations/${kiosk.Id}/files`,
      method: ApiRequestType.POST,
      data: formData,
    });
  } catch ({ message }) {
    yield put({ type: kioskFileNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateKioskFileResponse() {
  notificationService.showSuccess('kiosks.notifications.update.file');
}

// Error
function updateKioskFileError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('kiosks.notifications.updateFailed.file', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteKioskSaga() {
  yield takeEvery(deleteKiosk.type, deleteKioskRequest);
}

function* kioskDeletedSaga() {
  yield takeLatest(kioskDeleted.type, deleteKioskResponse);
}

function* kioskNotDeletedSaga() {
  yield takeLatest(kioskNotDeleted.type, deleteKioskError);
}

// Request
function* deleteKioskRequest(action: PayloadAction<Kiosk>) {
  try {
    const { payload: kiosk } = action;
    yield apiService.execute({
      url: `KioskConfigurations/${kiosk.Id}`,
      method: ApiRequestType.DELETE,
      data: kiosk,
    });
  } catch ({ message }) {
    yield put({ type: kioskNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteKioskResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('kiosks.notifications.delete');
  action.payload.history.push(`/Kiosks`);
}

// Error
function deleteKioskError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('kiosks.notifications.deleteFailed', action?.payload?.msg.message);
}

function* deleteKioskFileSaga() {
  yield takeEvery(deleteKioskFile.type, deleteKioskFileRequest);
}

function* kioskFileDeletedSaga() {
  yield takeLatest(kioskFileDeleted.type, deleteKioskFileResponse);
}

function* kioskFileNotDeletedSaga() {
  yield takeLatest(kioskFileNotDeleted.type, deleteKioskFileError);
}

// Request
function* deleteKioskFileRequest(action: PayloadAction<DeleteKioskFileRequest>) {
  try {
    const { payload: kiosk } = action;
    yield apiService.execute({
      url: `KioskConfigurations/${kiosk.Id}/file`,
      method: ApiRequestType.DELETE,
      data: kiosk,
    });
  } catch ({ message }) {
    yield put({ type: kioskFileNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteKioskFileResponse() {
  notificationService.showSuccess('kiosks.notifications.delete.file');
}

// Error
function deleteKioskFileError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('kiosks.notifications.deleteFailed.file', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteKiosksSaga() {
  yield takeEvery(deleteKiosks.type, deleteKiosksRequest);
}

function* kiosksDeletedSaga() {
  yield takeLatest(kiosksDeleted.type, deleteKiosksResponse);
}

function* kiosksNotDeletedSaga() {
  yield takeLatest(kiosksNotDeleted.type, deleteKiosksError);
}

// Request
function* deleteKiosksRequest(action: PayloadAction<Array<Kiosk>>) {
  try {
    const { payload: kiosks } = action;
    yield apiService.execute({
      url: `KioskConfigurations`,
      method: ApiRequestType.DELETE,
      data: { ids: kiosks.map((kiosk) => kiosk.Id) },
    });
  } catch ({ message }) {
    yield put({ type: kiosksNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteKiosksResponse() {
  notificationService.showSuccess('kiosks.notifications.deleteAll');
}

// Error
function deleteKiosksError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('kiosks.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// Updated kisok Actions

function* updateKioskActionsSaga() {
  yield takeEvery(updateKioskActions.type, UpdateKioskActionRequest);
}

function* kioskActionsUpdatedSaga() {
  yield takeLatest(kioskActionsUpdated.type, updateKioskActionsResponse);
}

function* kioskActionsNotUpdatedSaga() {
  yield takeLatest(kioskActionsNotUpdated.type, updateKioskActionsError);
}

// Request
function* UpdateKioskActionRequest(action: PayloadAction<Kiosk>) {
  try {
    const { payload: kiosk } = action;
    yield apiService.execute({
      url: `KioskConfigurations/${kiosk.Id}/Actions`,
      method: ApiRequestType.PUT,
      data: kiosk,
    });
  } catch ({ message }) {
    yield put({ type: kioskActionsNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateKioskActionsResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('kiosks.actions.notifications.update');
  action.payload.history.push(`/Kiosks/${action.payload.msg.entityId}`);
}

// Error
function updateKioskActionsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('kiosks.actions.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createKioskSaga(),
    kioskCreatedSaga(),
    kioskNotCreatedSaga(),
    // Update
    updateKioskSaga(),
    kioskUpdatedSaga(),
    kioskNotUpdatedSaga(),
    updateKioskFileSaga(),
    kioskFileUpdatedSaga(),
    kioskFileNotUpdatedSaga(),
    // Delete
    deleteKioskSaga(),
    kioskDeletedSaga(),
    kioskNotDeletedSaga(),
    deleteKioskFileSaga(),
    kioskFileDeletedSaga(),
    kioskFileNotDeletedSaga(),
    // Delete All
    deleteKiosksSaga(),
    kiosksDeletedSaga(),
    kiosksNotDeletedSaga(),
    // Kiosk Action
    updateKioskActionsSaga(),
    kioskActionsUpdatedSaga(),
    kioskActionsNotUpdatedSaga(),
  ]);
}

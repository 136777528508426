import React, { useCallback, useEffect, useMemo } from 'react';

// Hooks
import { useTenantsRedux } from './useTenantsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { getTenantTableColumns } from '../../data/Table/TenantTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Hook
export const useTenantsList = <T extends object>() => {
  // Data
  const { data: tenants, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.tenants.list, null);
  const {
    data: companies,
    fetch: fetchCompanies,
    pending: pendingCompanies,
  } = useData(ApiEndpoints.companies.list, null);
  const {
    data: locations,
    fetch: fetchLocations,
    pending: pendingLocations,
  } = useData(ApiEndpoints.locations.list, null);

  // Redux
  const {
    formOptions,
    updating,
    error,
    showDeleteAllConfirm,
    showEditForm: showTenantEditForm,
  } = useTenantsRedux<T>({ companies, locations });

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({
    formOptions,
    updating,
    error,
    onAdd: () => showTenantEditForm(),
  });

  // Callbacks
  const showEditForm = useCallback(
    ({ values }: any) => {
      showTenantEditForm(values);
      getFormDrawerProps().setOpen(true);
    },
    [getFormDrawerProps, showTenantEditForm]
  );

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.tenants.list }), []);
  const getTenantsDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.Tenants.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-crm" style={{ marginRight: 8 }} />
          <Translated id="appMenu.tenants" />
        </>
      ),
      data: tenants ?? [],
      columns: getTableColumns(
        getTenantTableColumns({ companies, locations }),
        LocalStorageKeys.Tenants.table.hiddenColumns
      ),
      fetchData: fetch,
    }),
    [fetch, tenants, companies, locations]
  );
  const getTenantsTableProps = useCallback(
    () => ({
      pending: pending || pendingCompanies || pendingLocations,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, pendingCompanies, pendingLocations, updating, pageCount, totalCount]
  );
  const getTenantsCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
      showEditForm,
      onRowClick: (id: string) => showEditForm({ values: tenants?.find((l) => l.Id === id) }),
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm, showEditForm, tenants]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchCompanies();
    fetchLocations();
  }, [fetchCompanies, fetchLocations]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getTenantsDataProps,
      getTenantsTableProps,
      getTenantsCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getTenantsDataProps,
      getTenantsTableProps,
      getFormDrawerProps,
      getTenantsCrudProps,
    ]
  );
};

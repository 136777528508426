// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  apiClientCreated,
  apiClientNotCreated,
  apiClientNotUpdated,
  apiClientsDeleted,
  apiClientsNotDeleted,
  apiClientUpdated,
  validationFailed,
} from './ApiClients.redux';

// Events
const SignalREvents = {
  ApiClientCreated: `ApiClientCreatedIntegrationEvent`,
  ApiClientUpdated: `ApiClientUpdatedIntegrationEvent`,
  ApiClientsDeleted: `ApiClientsDeletedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  ValidationFailed: 'ValidationFailedIntegrationException',
  ApiClientNotCreated: `ApiClientNotCreatedIntegrationException`,
  ApiClientNotUpdated: `ApiClientNotUpdatedIntegrationException`,
  ApiClientsNotDeleted: `ApiClientsNotDeletedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.ApiClientCreated, (msg) => {
    store.dispatch(apiClientCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ApiClientNotCreated, (msg) => {
    store.dispatch(apiClientNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.ApiClientUpdated, (msg) => {
    store.dispatch(apiClientUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ApiClientNotUpdated, (msg) => {
    store.dispatch(apiClientNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.ApiClientsDeleted, (msg) => {
    store.dispatch(apiClientsDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ApiClientsNotDeleted, (msg) => {
    store.dispatch(apiClientsNotDeleted({ history, msg }));
  });

  // Validation
  hubConnection.on(SignalRExceptions.ValidationFailed, () => {
    store.dispatch(validationFailed());
  });
};

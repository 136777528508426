import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { ScheduleConfigurationResponse } from '../../../models/ScheduleConfiguration';

// Hooks
import { useScheduleSettingsRedux } from './useScheduleSettingsRedux';

// Props
interface ScheduleSettingProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
  fetchScheduleConfiguration: (params: any) => Promise<void>;
  updatingScheduleConfiguration: boolean;
}

export const useScheduleSettings = ({
  scheduleConfigurationResponse,
  fetchScheduleConfiguration,
  updatingScheduleConfiguration,
}: ScheduleSettingProps) => {
  // Redux
  const { ScheduleSettingManagementForm, updating, error } = useScheduleSettingsRedux({
    scheduleConfigurationResponse,
  });
  const prevUpdating = usePrevious(updating);

  // Component Effects
  useEffect(() => {
    // Fetch after updating
    if (scheduleConfigurationResponse && prevUpdating === true && updating === false && !error) {
      fetchScheduleConfiguration({ id: scheduleConfigurationResponse?.Id });
    }
  }, [prevUpdating, updating, error, fetchScheduleConfiguration, scheduleConfigurationResponse]);

  // Return Hook
  return useMemo(
    () => ({
      ScheduleSettingManagementForm,
      loading: !scheduleConfigurationResponse,
      updating: updating || updatingScheduleConfiguration,
    }),
    [ScheduleSettingManagementForm, scheduleConfigurationResponse, updating, updatingScheduleConfiguration]
  );
};

import React, { useCallback, useMemo } from 'react';
import { Divider, Form, Input, Space } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import { SaltoKsFormSettings, SaltoKsSettingsFormValues } from '../../../../models/ApiConfigurationSettingFormValues';
import { ApiConfigurationResponse } from '../../../../models/ApiConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';

// Components
const { Item } = Form;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface SaltoKsSettingsProps {
  apiConfigurationResponse: ApiConfigurationResponse | null;
}

// Hook
export const useSaltoKsSettings = ({
  // updating,
  apiConfigurationResponse,
}: SaltoKsSettingsProps) => {
  // Intl
  const intl = useIntl();

  // Form Values
  const initialValues: SaltoKsSettingsFormValues = useMemo(
    () => ({
      Id: apiConfigurationResponse?.ApiConfiguration.Id,
      Name: apiConfigurationResponse?.ApiConfiguration.Name,
      ApiConfiguration: apiConfigurationResponse?.ApiConfiguration.ApiConfigurationType,
      SettingsData: JSON.parse(apiConfigurationResponse?.SettingsData ?? '{}') as SaltoKsFormSettings,
    }),
    [apiConfigurationResponse]
  );

  // Components
  const SaltoKsSettingsForm = useCallback(
    () => (
      <>
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="AccessControlSystemType" hidden>
          <Input />
        </Item>

        {/* Form Values */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'ConnectUrl']}
            label={<Translated id="pacsSetting.form.connectUrl" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.connectUrl' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
          <NoSidePaddingFormItem
            name={['SettingsData', 'IdentityUrl']}
            label={<Translated id="pacsSetting.form.identityUrl" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.identityUrl' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Divider />

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'ClientId']}
            label={<Translated id="pacsSetting.form.clientId" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.clientId' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem name={['SettingsData', 'Secret']} label={<Translated id="pacsSetting.form.secret" />}>
            <Input.Password />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Divider />

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'CollectionId']}
            label={<Translated id="pacsSetting.form.collectionId" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.collectionId' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>
      </>
    ),
    [intl]
  );

  return useMemo(
    () => ({
      SaltoKsSettingsForm,
      initialValues,
    }),
    [SaltoKsSettingsForm, initialValues]
  );
};

import React, { useCallback, useMemo } from 'react';
import { Divider, Form, Input, Select, Space, Switch } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import FormItemLabel from 'antd/es/form/FormItemLabel';
import {
  SaltoKsFormSettings,
  SaltoKsRole,
  SaltoKsSettingsFormValues,
  SaltoKsSite,
} from '../../../../models/PACSSettingFormValues';
import { ServiceConfigurationResponse } from '../../../../models/ServiceConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';

// Components
const { Item } = Form;
const { Option } = Select;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0px;
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface SaltoKsSettingsProps {
  serviceConfigurationResponse: ServiceConfigurationResponse | null;
}

// Hook
export const useSaltoKsSettings = ({
  // updating,
  serviceConfigurationResponse,
}: SaltoKsSettingsProps) => {
  // Intl
  const intl = useIntl();

  // Form Values
  const initialValues: SaltoKsSettingsFormValues = useMemo(
    () => ({
      Id: serviceConfigurationResponse?.ServiceConfiguration.Id,
      Name: serviceConfigurationResponse?.ServiceConfiguration.Name,
      AccessControlSystemType: serviceConfigurationResponse?.ServiceConfiguration.AccessControlSystemType,
      ManageEmployees: serviceConfigurationResponse?.ServiceConfiguration.ManageEmployees,
      SettingsData: JSON.parse(serviceConfigurationResponse?.SettingsData?.SettingsData ?? '{}') as SaltoKsFormSettings,
    }),
    [serviceConfigurationResponse]
  );
  const availableSites: Array<SaltoKsSite> = useMemo(() => {
    const settings = JSON.parse(
      serviceConfigurationResponse?.SettingsData?.SettingsData ?? '{}'
    ) as SaltoKsFormSettings;

    return JSON.parse(settings.AvailableSitesJson ?? '[]');
  }, [serviceConfigurationResponse]);

  const availableRoles: SaltoKsRole[] = useMemo(() => {
    const settings = JSON.parse(
      serviceConfigurationResponse?.SettingsData?.SettingsData ?? '{}'
    ) as SaltoKsFormSettings;

    return availableSites.find((x) => x.Id === settings.SiteId)?.Roles ?? [];
  }, [serviceConfigurationResponse, availableSites]);

  // Components
  const SaltoKsSettingsForm = useCallback(
    () => (
      <>
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="ManageEmployees" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="AccessControlSystemType" hidden>
          <Input />
        </Item>

        {/* Form Values */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'ConnectUrl']}
            label={<Translated id="pacsSetting.form.connectUrl" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.connectUrl' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
          <NoSidePaddingFormItem
            name={['SettingsData', 'IdentityUrl']}
            label={<Translated id="pacsSetting.form.identityUrl" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.identityUrl' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Divider />

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'ClientId']}
            label={<Translated id="pacsSetting.form.clientId" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.clientId' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem name={['SettingsData', 'Secret']} label={<Translated id="pacsSetting.form.secret" />}>
            <Input.Password />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Divider />

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'Username']}
            label={<Translated id="pacsSetting.form.username" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.username' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'Password']}
            label={<Translated id="pacsSetting.form.password" />}
          >
            <Input.Password />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem
            name={['SettingsData', 'ConfirmPassword']}
            label={<Translated id="pacsSetting.form.confirmPassword" />}
            dependencies={['SettingsData', 'Password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue(['SettingsData', 'Password']) === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(intl.formatMessage({ id: 'pacsSetting.form.warnings.confirmPassword' }))
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Divider />

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'SiteId']}
            label={<Translated id="pacsSetting.form.site" />}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select placeholder={<Translated id="pacsSetting.form.site" />}>
              {availableSites.map((site) => (
                <Option key={site.Id} value={site.Id}>
                  {site.Name}
                </Option>
              ))}
            </Select>
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem
            name={['SettingsData', 'RoleId']}
            label={<Translated id="pacsSetting.form.role" />}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select placeholder={<Translated id="pacsSetting.form.role" />}>
              {availableRoles.map((role) => (
                <Option key={role.Id} value={role.Id}>
                  {role.Name}
                </Option>
              ))}
            </Select>
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Space>
          <FormItemLabel label={<Translated id="pacsSetting.form.writeEmail" />} prefixCls="cls" />
          <NoMarginBottomFormItem name={['SettingsData', 'WriteEmail']} valuePropName="checked">
            <Switch
              checkedChildren={<Translated id="pacsSetting.form.active" />}
              unCheckedChildren={<Translated id="pacsSetting.form.disabled" />}
            />
          </NoMarginBottomFormItem>
        </Space>
      </>
    ),
    [intl, availableSites, availableRoles]
  );

  return useMemo(
    () => ({
      SaltoKsSettingsForm,
      initialValues,
    }),
    [SaltoKsSettingsForm, initialValues]
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { ApiConfiguration } from '../../../models/ApiConfiguration';
import { SignalRAction } from '../../../models/SignalRAction';

// State
interface ApiConfigurationTenantsState {
  updating: boolean;
  error: boolean;
}

const initialState: ApiConfigurationTenantsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@API_CONFIGURATION_TENANTS';

// Redux Actions|Reducers
const apiConfigurationTenantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignApiConfigurationTenants: (
      state,
      action: PayloadAction<{ apiConfiguration: ApiConfiguration; tenantIds: Array<string> }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    apiConfigurationTenantsAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    apiConfigurationTenantsNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignApiConfigurationTenants,
  apiConfigurationTenantsAssigned,
  apiConfigurationTenantsNotAssigned,
} = apiConfigurationTenantsSlice.actions;

// Export Reducer
export default apiConfigurationTenantsSlice.reducer;

// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  kioskCreated,
  kioskDeleted,
  kioskFileDeleted,
  kioskFileNotDeleted,
  kioskFileNotUpdated,
  kioskFileUpdated,
  kioskNotCreated,
  kioskNotDeleted,
  kioskNotUpdated,
  kiosksDeleted,
  kiosksNotDeleted,
  kioskUpdated,
} from './Kiosks.redux';

// Events
const SignalREvents = {
  KioskCreated: `KioskConfigurationCreatedIntegrationEvent`,
  KioskUpdated: `KioskConfigurationUpdatedIntegrationEvent`,
  KioskDeleted: `KioskConfigurationDeletedIntegrationEvent`,
  KiosksDeleted: `KioskConfigurationsDeletedIntegrationEvent`,
  KioskFileUpdated: `KioskConfigurationFileUpdatedIntegrationEvent`,
  KioskFileDeleted: `KioskConfigurationFileDeletedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  KioskNotCreated: `KioskConfigurationNotCreatedIntegrationException`,
  KioskNotUpdated: `KioskConfigurationNotUpdatedIntegrationException`,
  KioskNotDeleted: `KioskConfigurationNotDeletedIntegrationException`,
  KiosksNotDeleted: `KioskConfigurationsNotDeletedIntegrationException`,
  KioskFileNotUpdated: `KioskConfigurationFileNotUpdatedIntegrationException`,
  KioskFileNotDeleted: `KioskConfigurationFileNotDeletedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.KioskCreated, (msg) => {
    store.dispatch(kioskCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.KioskNotCreated, (msg) => {
    store.dispatch(kioskNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.KioskUpdated, (msg) => {
    store.dispatch(kioskUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.KioskNotUpdated, (msg) => {
    store.dispatch(kioskNotUpdated({ history, msg }));
  });
  hubConnection.on(SignalREvents.KioskFileUpdated, (msg) => {
    store.dispatch(kioskFileUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.KioskFileNotUpdated, (msg) => {
    store.dispatch(kioskFileNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.KioskDeleted, (msg) => {
    store.dispatch(kioskDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.KioskNotDeleted, (msg) => {
    store.dispatch(kioskNotDeleted({ history, msg }));
  });
  hubConnection.on(SignalREvents.KioskFileDeleted, (msg) => {
    store.dispatch(kioskFileDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.KioskFileNotDeleted, (msg) => {
    store.dispatch(kioskFileNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.KiosksDeleted, (msg) => {
    store.dispatch(kiosksDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.KiosksNotDeleted, (msg) => {
    store.dispatch(kiosksNotDeleted({ history, msg }));
  });
};

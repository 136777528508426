import React, { useCallback, useMemo } from 'react';

// Hooks
import { useScheduleConfigurationsRedux } from './useScheduleConfigurationsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { ScheduleConfigurationTableColumns } from '../../data/Table/ScheduleConfigurationTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

export const useScheduleConfigurationsList = <T extends object>() => {
  // Data
  const {
    data: ScheduleConfigurations,
    pageCount,
    totalCount,
    fetch,
    pending,
  } = useData(ApiEndpoints.scheduleConfigurations.list, null);
  const data = useMemo(() => ScheduleConfigurations, [ScheduleConfigurations]);

  // Redux
  const { formOptions, updating, error, showDeleteAllConfirm } = useScheduleConfigurationsRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.scheduleConfigurations.list }), []);
  const getScheduleConfigurationsDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.ScheduleConfigurations.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-schedule" style={{ marginRight: 8 }} />
          <Translated id="appMenu.scheduleConfigurations" />
        </>
      ),
      data: data ?? [],
      columns: getTableColumns(
        ScheduleConfigurationTableColumns,
        LocalStorageKeys.ScheduleConfigurations.table.hiddenColumns
      ),
      fetchData: fetch,
    }),
    [fetch, data]
  );
  const getScheduleConfigurationsTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getScheduleConfigurationsCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getScheduleConfigurationsDataProps,
      getScheduleConfigurationsTableProps,
      getScheduleConfigurationsCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getScheduleConfigurationsDataProps,
      getScheduleConfigurationsTableProps,
      getFormDrawerProps,
      getScheduleConfigurationsCrudProps,
    ]
  );
};

import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { ServiceConfigurationResponse } from '../../../models/ServiceConfiguration';

// Hooks
import { useServiceConfigurationTenantsRedux } from './useServiceConfigurationTenantsRedux';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface ServiceConfigurationTenantsProps {
  serviceConfigurationResponse: ServiceConfigurationResponse | null;
  fetchServiceConfiguration: (params: any) => Promise<void>;
  updatingServiceConfiguration: boolean;
}

export const useServiceConfigurationTenants = ({
  serviceConfigurationResponse: serviceConfigurationTenantsResponse,
  fetchServiceConfiguration,
  updatingServiceConfiguration: updatingServiceConfigurationTenant,
}: ServiceConfigurationTenantsProps) => {
  // Data
  const {
    data: serviceConfigurationTenants,
    fetch: fetchServiceConfigurationTenants,
    pending,
  } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const { ServiceConfigurationTenantsManagementForm, updating, error } = useServiceConfigurationTenantsRedux({
    serviceConfigurationTenantsResponse,
    serviceConfigurationTenants,
  });
  const prevUpdating = usePrevious(updating);

  // Component State
  const loading = pending && !serviceConfigurationTenantsResponse;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchServiceConfigurationTenants();
  }, [fetchServiceConfigurationTenants]);

  useEffect(() => {
    // Fetch after updating
    if (serviceConfigurationTenantsResponse && prevUpdating === true && updating === false && !error) {
      fetchServiceConfiguration({ id: serviceConfigurationTenantsResponse.ServiceConfiguration.Id });
      fetchServiceConfigurationTenants();
    }
  }, [
    prevUpdating,
    updating,
    error,
    fetchServiceConfiguration,
    fetchServiceConfigurationTenants,
    serviceConfigurationTenantsResponse,
  ]);

  // Return Hook
  return useMemo(
    () => ({
      serviceConfigurationTenants,
      ServiceConfigurationTenantsManagementForm,
      loading,
      updating: updating || updatingServiceConfigurationTenant,
    }),
    [
      serviceConfigurationTenants,
      ServiceConfigurationTenantsManagementForm,
      loading,
      updating,
      updatingServiceConfigurationTenant,
    ]
  );
};

import styled, { css } from 'styled-components';
import { Text, TextProps } from './Text';

export const LinkText = styled(Text)<TextProps & { onClick?: () => void }>`
  ${(props) =>
    props.onClick &&
    css`
      color: #548aff;
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    `};
`;

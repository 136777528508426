import React, { useCallback, useMemo } from 'react';

// Hooks
import { useCompaniesRedux } from './useCompaniesRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { companyTableColumns } from '../../data/Table/CompanyTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Hook
export const useCompaniesList = <T extends object>() => {
  // Data
  const { data: companies, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.companies.list, null);

  // Redux
  const {
    formOptions,
    updating,
    error,
    showDeleteAllConfirm,
    showEditForm: showCompanyEditForm,
  } = useCompaniesRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({
    formOptions,
    updating,
    error,
    onAdd: () => showCompanyEditForm(),
  });

  // Callbacks
  const showEditForm = useCallback(
    ({ values }: any) => {
      showCompanyEditForm(values);
      getFormDrawerProps().setOpen(true);
    },
    [getFormDrawerProps, showCompanyEditForm]
  );

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.companies.list }), []);
  const getCompaniesDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.Companies.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-company" style={{ marginRight: 8 }} />
          <Translated id="appMenu.companies" />
        </>
      ),
      data: companies ?? [],
      columns: getTableColumns(companyTableColumns, LocalStorageKeys.Companies.table.hiddenColumns),
      fetchData: fetch,
    }),
    [fetch, companies]
  );
  const getCompaniesTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getCompaniesCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
      showEditForm,
      onRowClick: (id: string) => showEditForm({ values: companies?.find((l) => l.Id === id) }),
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm, showEditForm, companies]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getCompaniesDataProps,
      getCompaniesTableProps,
      getCompaniesCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getCompaniesDataProps,
      getCompaniesTableProps,
      getFormDrawerProps,
      getCompaniesCrudProps,
    ]
  );
};

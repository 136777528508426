/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { BusinessClientFormValues } from '../../models/BusinessClient';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface BusinessClientsState {
  updating: boolean;
  error: boolean;
}

const initialState: BusinessClientsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@BUSINESS_CLIENTS';

// Redux Actions|Reducers
const businessClientsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createBusinessClient: (state, action: PayloadAction<BusinessClientFormValues>) => {
      state.updating = true;
      state.error = false;
    },
    businessClientCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    businessClientNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createBusinessClient,
  businessClientCreated,
  businessClientNotCreated,
} = businessClientsSlice.actions;

// Export Reducer
export default businessClientsSlice.reducer;

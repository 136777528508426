import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import styled from 'styled-components';
import { ApiConfiguration, ApiConfigurationResponse } from '../../../models/ApiConfiguration';
import { Tenant } from '../../../models/Tenant';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { assignApiConfigurationTenants } from '../../../store/ApiConfigurations/ApiConfigurationTenants/ApiConfigurationTenants.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';

const { confirm } = Modal;

// Props
interface ApiConfigurationTenantsFormProps {
  apiConfigurationTenantsResponse: ApiConfigurationResponse | null;
  apiConfigurationTenants: Array<Tenant> | null;
}

interface AssignConfirmProps {
  tenantIds: Array<string>;
  apiConfiguration: ApiConfiguration;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useApiConfigurationTenantsRedux = ({
  apiConfigurationTenantsResponse,
  apiConfigurationTenants,
}: ApiConfigurationTenantsFormProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(
    ({ apiConfigurationTenants: apiConfigurationTenantsState }) => apiConfigurationTenantsState?.updating ?? false
  );
  const error = useAppSelector(
    ({ apiConfigurationTenants: apiConfigurationTenantsState }) => apiConfigurationTenantsState?.error ?? false
  );

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ tenantIds, apiConfiguration }: AssignConfirmProps) => {
      if (tenantIds && apiConfiguration) {
        confirm({
          title: intl.formatMessage({
            id: 'apiConfigurations.Tenants.multiSelect.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'apiConfigurations.Tenants.multiSelect.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignApiConfigurationTenants({ tenantIds, apiConfiguration })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // State
  const [tenantIds, setTenantIds] = useState<Array<string>>([]);

  // Sets the Keys when the response is updated
  // Fixes problem with initial values after API call
  useEffect(() => {
    setTenantIds(
      apiConfigurationTenants
        ?.filter((tenant) =>
          apiConfigurationTenantsResponse?.ApiConfiguration.Tenants?.some(
            (apiConfigurationTenant) => apiConfigurationTenant.Id === tenant.Id
          )
        )
        .map((tenant) => tenant.Id) ?? []
    );
  }, [apiConfigurationTenants, apiConfigurationTenantsResponse]);

  // Components
  const ApiConfigurationTenantsManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      apiConfigurationTenants?.map<TransferItem>((apiConfigurationTenant) => ({
        key: apiConfigurationTenant.Id,
        title: apiConfigurationTenant.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <ItemTransfer dataSource={dataSource} targetKeys={tenantIds} setTargetKeys={setTenantIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            apiConfigurationTenantsResponse &&
            showAssignConfirm({
              tenantIds,
              apiConfiguration: apiConfigurationTenantsResponse.ApiConfiguration,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [apiConfigurationTenantsResponse, apiConfigurationTenants, showAssignConfirm, tenantIds, setTenantIds]);

  return useMemo(
    () => ({
      ApiConfigurationTenantsManagementForm,
      updating,
      error,
    }),
    [ApiConfigurationTenantsManagementForm, updating, error]
  );
};

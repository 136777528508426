import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { useServiceConfigurationsRedux } from './useServiceConfigurationsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { useAppSelector } from '../App/useRedux';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Props
interface ServiceConfigurationDetailProps {
  id?: string;
}

export const useServiceConfigurationsDetail = <T extends object>({ id }: ServiceConfigurationDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Redux
  const { updatingEntityId } = useAppSelector(({ serviceConfigurations }) => serviceConfigurations);
  const prevUpdatingEntityId = usePrevious(updatingEntityId);

  // Data
  const {
    data: serviceConfigurationResponse,
    fetch,
    pending,
  } = useData(ApiEndpoints.serviceConfigurations.detail, null);
  const prevPending = usePrevious(pending);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useServiceConfigurationsRedux<T>({
    isEditing: true,
    initialValues: serviceConfigurationResponse?.ServiceConfiguration as Store,
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.serviceConfigurations.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      serviceConfiguration: serviceConfigurationResponse?.ServiceConfiguration ?? null,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [serviceConfigurationResponse, pending, getFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      serviceConfiguration: serviceConfigurationResponse?.ServiceConfiguration ?? null,
      pending,
    }),
    [serviceConfigurationResponse, pending]
  );
  const getServiceConfigurationTenantsProps = useCallback(
    () => ({
      serviceConfigurationResponse,
      fetchServiceConfiguration: fetch,
      updatingServiceConfiguration: pending,
    }),
    [serviceConfigurationResponse, fetch, pending]
  );
  const getPACSSettingsProps = useCallback(
    () => ({
      serviceConfigurationResponse,
      fetchServiceConfiguration: fetch,
      updatingServiceConfiguration: pending,
    }),
    [serviceConfigurationResponse, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && pending === false && !serviceConfigurationResponse) {
      notificationService.showError('serviceConfigurations.notFound');
      navigate('/ServiceConfigurations');
    }
  }, [prevPending, pending, serviceConfigurationResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (prevUpdating === true && updating === false && !error) {
      fetch({ id });
    }
  }, [prevUpdating, updating, error, fetch, id]);

  useEffect(() => {
    // Fetch after updating a service configurations status
    if (prevUpdatingEntityId != null && updatingEntityId == null && !error) {
      fetch({ id });
    }
  }, [id, prevUpdatingEntityId, updatingEntityId, error, fetch]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getServiceConfigurationTenantsProps,
      getServiceConfigurationProps: getPACSSettingsProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getServiceConfigurationTenantsProps,
      getPACSSettingsProps,
    ]
  );
};

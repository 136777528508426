import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useApiConfigurationsList } from '../../hooks/ApiConfigurations/useApiConfigurationsList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const ApiConfigurationsPage = () => {
  const {
    updating,
    getBreadcrumbMenuProps,
    getApiConfigurationsDataProps,
    getApiConfigurationsTableProps,
    getApiConfigurationsCrudProps,
    getFormDrawerProps,
  } = useApiConfigurationsList();
  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        <Row>
          <Col span={24}>
            <Table
              {...getApiConfigurationsDataProps()}
              {...getApiConfigurationsTableProps()}
              {...getApiConfigurationsCrudProps()}
            />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};

import moment, { Moment, unitOfTime } from 'moment';

export type MomentInput = Moment | Date | string | number | undefined;

export const dateTimeISOFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateTimeStringFormat = 'DD MMM YYYY HH:mm';
export const minDate = moment('0001-01-01T00:00:00', dateTimeISOFormat);

export const getLocalDateTime = (dateTime: MomentInput) => moment.utc(dateTime, dateTimeISOFormat).local();

export const getLocalDateTimeString = (dateTime: MomentInput) =>
  getLocalDateTime(dateTime).format(dateTimeStringFormat);

export const getCurrentUtcDate = () => new Date(moment.utc().format());

export const getValidLocalDateTime = (dateTime?: MomentInput | null) => {
  if (dateTime == null) return null;

  const localDateTime = moment.utc(dateTime, dateTimeISOFormat).local();
  if (isMinDate(localDateTime)) return null;

  return localDateTime;
};

export const isMinDate = (dateTime: Moment) => dateTime.isSame(minDate, 'date');

export const isInThePast = (dateTime: MomentInput, amount = 1, unit: unitOfTime.DurationConstructor = 'd') => {
  const yesterday = moment().subtract(amount, unit);
  return getLocalDateTime(dateTime).isBefore(yesterday);
};

import { useMemo } from 'react';
import { isArray, isEmpty } from 'lodash';

// Models
import { EnvironmentStatsResponse } from '../../models/Dashboard/ApiResponses/EnvironmentStatsResponse';
import { ActiveFilters } from '../../models/Filters';

// Hooks
import { useAppSelector } from '../App/useRedux';
import { ServiceStatus } from '../../models/enums/ServiceStatus';
import { isInThePast } from '../../constants/Utils/DateTime';

// Props
interface DashboardFiltersProps {
  content: Array<EnvironmentStatsResponse> | undefined;
}

// Hook
export const useDashboardFilters = ({ content }: DashboardFiltersProps) => {
  // Redux
  const { status, services, companies, locations } = useAppSelector(({ filters }) => filters?.environments);

  // Filters
  const statusFilter = useMemo(() => (isArray(status) ? status : status == null ? [] : [status]), [status]);
  const servicesFilter = useMemo(() => (isArray(services) ? services : services == null ? [] : [services]), [services]);
  const companiesFilter = useMemo(
    () => (isArray(companies) ? companies : companies == null ? [] : [companies]),
    [companies]
  );
  const locationsFilter = useMemo(
    () => (isArray(locations) ? locations : locations == null ? [] : [locations]),
    [locations]
  );

  // Validation
  const isFiltering =
    !isEmpty(statusFilter) || !isEmpty(servicesFilter) || !isEmpty(companiesFilter) || !isEmpty(locationsFilter);

  // Filter Logic
  let filterCount = 0;
  const applyFilters = () => {
    let result: Array<EnvironmentStatsResponse> = content || [];

    // Status
    if (!isEmpty(statusFilter)) {
      result = result.filter((i) =>
        i.Services.some((srv) =>
          statusFilter.some((f) => (isInThePast(srv.LastChecked) ? f === ServiceStatus.NotChecked : f === srv.Status))
        )
      );
      filterCount += statusFilter.length;
    }
    // Services
    if (!isEmpty(servicesFilter)) {
      result = result.filter((i) => i.Services.some((srv) => servicesFilter.some((f) => f === srv.Id)));
      filterCount += servicesFilter.length;
    }
    // Companies
    if (!isEmpty(companiesFilter)) {
      result = result.filter((i) => companiesFilter.some((f) => f === i.CompanyId));
      filterCount += companiesFilter.length;
    }
    // Locations
    if (!isEmpty(locationsFilter)) {
      result = result.filter((i) => locationsFilter.some((f) => f === i.LocationId));
      filterCount += locationsFilter.length;
    }

    return result;
  };
  const filteredContent = isFiltering ? applyFilters() : content;

  // Filters Response
  const activeFilters = useMemo<ActiveFilters>(
    () => ({
      statusFilter,
      servicesFilter,
      companiesFilter,
      locationsFilter,
    }),
    [statusFilter, servicesFilter, companiesFilter, locationsFilter]
  );

  return useMemo(
    () => ({
      activeFilters,
      filteredContent,
      isFiltering,
      filterCount,
    }),
    [activeFilters, filteredContent, isFiltering, filterCount]
  );
};

import { Button } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { StyledTooltip } from '../Tooltip/StyledTooltip';

interface IconButtonProps {
  title: ReactNode;
  onClick: React.MouseEventHandler<HTMLElement> | undefined;
  Icon: React.ForwardRefExoticComponent<React.RefAttributes<HTMLSpanElement>>;
}

// Styled
const StyledSpan = styled.span`
  width: 14px;
  height: 14px;
  line-height: 16px;
  display: flex;
`;
const StyledButton = styled(Button)`
  padding: 5px 10px;
  margin-bottom: 0;
  margin-right: 24px;
  display: flex;
  align-items: center;

  :focus,
  :active,
  :active:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &[disabled] {
    margin-right: 15px;
  }
`;

export const IconButton = ({ title, onClick, Icon }: IconButtonProps) => (
  <StyledTooltip title={title} placement="bottomRight">
    <StyledButton onClick={onClick}>
      <StyledSpan>
        <Icon />
      </StyledSpan>
    </StyledButton>
  </StyledTooltip>
);

export enum ApiConfigurationEnum {
  SaltoSpace = 1,
  SaltoKs = 2,
}

export interface ApiConfigurationType {
  Name: string;
  Value: number;
  ApiConfiguration: ApiConfigurationEnum;
}

export const ApiConfigurationTypes: ApiConfigurationType[] = [
  {
    Name: 'SALTO Space | SHIP',
    Value: 1,
    ApiConfiguration: ApiConfigurationEnum.SaltoSpace,
  },
  {
    Name: 'SALTO KS | Core API',
    Value: 2,
    ApiConfiguration: ApiConfigurationEnum.SaltoKs,
  },
];

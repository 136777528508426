import React, { useCallback, useMemo } from 'react';

// Hooks
import { useIdentityUsersRedux } from './useIdentityUsersRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { identityUserTableColumns } from '../../data/Table/IdentityUserTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { useIdentityUserTableFilter } from './useIdentityUserTableFilter';

// Hook
export const useIdentityUserList = <T extends object>() => {
  // Data
  const { data: identityUsers, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.identityUsers.list, null);

  // Redux
  const { formOptions, updating, error } = useIdentityUsersRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Table Filter
  const { FilterSelect, customTableFilters } = useIdentityUserTableFilter();

  // Fetch + Append Filter Data
  const fetchData = useCallback((args: any) => fetch({ ...args, ...customTableFilters }), [fetch, customTableFilters]);

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.identityUsers.list }), []);
  const getIdentityUsersDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.IdentityUsers.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-auth-screen" style={{ marginRight: 8 }} />
          <Translated id="appMenu.identityUsers" />
        </>
      ),
      data: identityUsers ?? [],
      columns: getTableColumns(identityUserTableColumns, LocalStorageKeys.IdentityUsers.table.hiddenColumns),
      fetchData,
    }),
    [fetchData, identityUsers]
  );
  const getIdentityUsersTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getIdentityUsersCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      saveHiddenTableColumns,
      hideDeleteButton: true,
      hideSelectableRows: true,
      FilterSelect,
    }),
    [AddButton, FilterSelect, getFormDrawerProps]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getIdentityUsersDataProps,
      getIdentityUsersTableProps,
      getIdentityUsersCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getIdentityUsersDataProps,
      getIdentityUsersTableProps,
      getFormDrawerProps,
      getIdentityUsersCrudProps,
    ]
  );
};

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { AccessProfile } from '../../../models/AccessProfile';
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  accessProfileTenantsAssigned,
  accessProfileTenantsNotAssigned,
  assignAccessProfileTenants,
} from './AccessProfileTenants.redux';
import { Tenant } from '../../../models/Tenant';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignAccessProfileTenantsSaga() {
  yield takeEvery(assignAccessProfileTenants.type, assignAccessProfileTenantsRequest);
}

function* accessProfileTenantsAssignedSaga() {
  yield takeLatest(accessProfileTenantsAssigned.type, assignAccessProfileTenantsResponse);
}

function* accessProfileTenantsNotAssignedSaga() {
  yield takeLatest(accessProfileTenantsNotAssigned.type, assignAccessProfileTenantsError);
}

// Request
function* assignAccessProfileTenantsRequest(
  action: PayloadAction<{ accessProfile: AccessProfile; tenants: Array<Tenant> }>
) {
  try {
    const {
      payload: { accessProfile, tenants },
    } = action;
    yield apiService.execute({
      url: `AccessProfiles/${accessProfile.Id}/Tenants`,
      method: ApiRequestType.PUT,
      data: {
        AccessProfileId: accessProfile.Id,
        Tenants: tenants,
      },
    });
  } catch ({ message }) {
    yield put({ type: accessProfileTenantsNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignAccessProfileTenantsResponse() {
  notificationService.showSuccess('accessProfiles.Tenants.notifications.assign');
}

// Error
function assignAccessProfileTenantsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'accessProfiles.Tenants.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************

// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignAccessProfileTenantsSaga(),
    accessProfileTenantsAssignedSaga(),
    accessProfileTenantsNotAssignedSaga(),
  ]);
}

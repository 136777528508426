import React from 'react';
import { Col, Empty } from 'antd';

import { useIntl } from 'react-intl';
import { Widget } from '../Widget/Widget';
import { Flex } from '../Base';

interface EnvironmentsEmptyProps {
  isFiltering: boolean;
}

export const EnvironmentsEmpty = ({ isFiltering }: EnvironmentsEmptyProps) => {
  const intl = useIntl();

  return (
    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      <Widget styleName="gx-card-widget gx-card-full gx-p-3">
        <Flex alignItems="center" justifyContent="center">
          <Empty
            description={intl.formatMessage(
              { id: 'dashboard.textCardGroup.filtersNotFound' },
              {
                filterText: isFiltering
                  ? intl.formatMessage({ id: 'dashboard.textCardGroup.filtersNotFoundText' })
                  : '',
              }
            )}
          />
        </Flex>
      </Widget>
    </Col>
  );
};

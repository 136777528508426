import React, { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router';

import { ErrorPage } from '../../../pages/App/ErrorPage';

export const ErrorContainer = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const [showError, setShowError] = React.useState(false);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onReset={() => {
        setShowError(false);
        navigate('/');
      }}
      resetKeys={[showError]}
    >
      {children}
    </ErrorBoundary>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { Location } from '../../models/Location';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface LocationsState {
  updating: boolean;
  error: boolean;
}

const initialState: LocationsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@LOCATIONS';

// Redux Actions|Reducers
const locationsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createLocation: (state, action: PayloadAction<Location>) => {
      state.updating = true;
      state.error = false;
    },
    locationCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    locationNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateLocation: (state, action: PayloadAction<Location>) => {
      state.updating = true;
      state.error = false;
    },
    locationUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    locationNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteLocation: (state, action: PayloadAction<Location>) => {
      state.updating = true;
      state.error = false;
    },
    locationDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    locationNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteLocations: (state, action: PayloadAction<Array<Location>>) => {
      state.updating = true;
      state.error = false;
    },
    locationsDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    locationsNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createLocation,
  locationCreated,
  locationNotCreated,
  // Update
  updateLocation,
  locationUpdated,
  locationNotUpdated,
  // Delete
  deleteLocation,
  locationDeleted,
  locationNotDeleted,
  // Delete All
  deleteLocations,
  locationsDeleted,
  locationsNotDeleted,
} = locationsSlice.actions;

// Export Reducer
export default locationsSlice.reducer;

import React, { PropsWithChildren } from 'react';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import styled from 'styled-components';

interface WidgetProps {
  className?: string;
  styleName?: string;
  height?: number;
}

const StyledCard = styled(Card)`
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 10px;

  & .ant-card-head-title {
    padding: 18px 0;
  }

  &.ant-card-head-title-with-tabs .ant-card-head-title {
    padding: 0;
  }
`;

export const Widget = ({
  title,
  children,
  cover,
  extra,
  actions,
  className,
  styleName,
  ...rest
}: PropsWithChildren<CardProps & WidgetProps>) => {
  return (
    <StyledCard
      title={title}
      actions={actions}
      cover={cover}
      extra={extra}
      className={`${styleName} ${className}`}
      {...rest}
    >
      {children}
    </StyledCard>
  );
};

Widget.defaultProps = {
  className: undefined,
  styleName: undefined,
  height: undefined,
};

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { IdentityUser } from '../../../models/IdentityUser';
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  assignIdentityUserTenants,
  identityUserTenantsAssigned,
  identityUserTenantsNotAssigned,
} from './IdentityUserTenants.redux';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignIdentityUserTenantsSaga() {
  yield takeEvery(assignIdentityUserTenants.type, assignIdentityUserTenantsRequest);
}

function* identityUserTenantsAssignedSaga() {
  yield takeLatest(identityUserTenantsAssigned.type, assignIdentityUserTenantsResponse);
}

function* identityUserTenantsNotAssignedSaga() {
  yield takeLatest(identityUserTenantsNotAssigned.type, assignIdentityUserTenantsError);
}

// Request
function* assignIdentityUserTenantsRequest(
  action: PayloadAction<{ identityUser: IdentityUser; tenantIds: Array<string> }>
) {
  try {
    const {
      payload: { identityUser, tenantIds },
    } = action;
    yield apiService.execute({
      url: `IdentityUsers/${identityUser.Id}/Tenants`,
      method: ApiRequestType.PUT,
      data: {
        IdentityUserId: identityUser.Id,
        IdentityName: identityUser.Email,
        TenantIds: tenantIds,
      },
    });
  } catch ({ message }) {
    yield put({ type: identityUserTenantsNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignIdentityUserTenantsResponse() {
  notificationService.showSuccess('identityUsers.Tenants.notifications.assign');
}

// Error
function assignIdentityUserTenantsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'identityUsers.Tenants.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignIdentityUserTenantsSaga(),
    identityUserTenantsAssignedSaga(),
    identityUserTenantsNotAssignedSaga(),
  ]);
}

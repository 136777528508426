import * as React from 'react';
import styled from 'styled-components';

const AspectRatioWrapper = styled.div<{ ratio: number }>`
  position: relative;
  width: 100%;
  padding-bottom: ${(p) => p.ratio * 100}%;
`;

const AspectRatioChild = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

interface Props {
  ratio: number;
}

export const AspectRatio = ({ ratio, children }: React.PropsWithChildren<Props>) => (
  <AspectRatioWrapper ratio={ratio}>
    <AspectRatioChild>{children}</AspectRatioChild>
  </AspectRatioWrapper>
);

import React, { useCallback, useMemo } from 'react';

// Hooks
import { useKiosksRedux } from './useKiosksRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { kioskTableColumns } from '../../data/Table/KioskTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

export const useKiosksList = <T extends object>() => {
  // Data
  const { data: kiosks, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.kiosks.list, null);
  const data = useMemo(() => kiosks, [kiosks]);

  // Redux
  const { formOptions, updating, error, showDeleteAllConfirm } = useKiosksRedux<T>({});

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.kiosks.list }), []);
  const getKiosksDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.Kiosks.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-apps" style={{ marginRight: 8 }} />
          <Translated id="appMenu.kiosks" />
        </>
      ),
      data: data ?? [],
      columns: getTableColumns(kioskTableColumns, LocalStorageKeys.Kiosks.table.hiddenColumns),
      fetchData: fetch,
    }),
    [fetch, data]
  );
  const getKiosksTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getKiosksCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getKiosksDataProps,
      getKiosksTableProps,
      getKiosksCrudProps,
      getFormDrawerProps,
    }),
    [updating, getBreadcrumbMenuProps, getKiosksDataProps, getKiosksTableProps, getFormDrawerProps, getKiosksCrudProps]
  );
};

import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { usePrevious } from 'react-use';

// Hooks
import { useActionsRedux } from './useActionsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { useAppSelector } from '../App/useRedux';

// Props
interface ActionDetailProps {
  id?: string;
}

// Hook
export const useActionDetail = <T extends object>({ id }: ActionDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Redux State
  const { updatingSettings } = useAppSelector(({ actions }) => actions);
  const prevUpdatingSettings = usePrevious(updatingSettings);

  // Data
  const { data: actionResponse, fetch, pending } = useData(ApiEndpoints.Actions.detail, null);
  const prevPending = usePrevious(pending);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useActionsRedux<T>({
    isEditing: true,
    initialValues: actionResponse ?? {},
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.Actions.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      action: actionResponse ?? null,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [actionResponse, pending, getFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      action: actionResponse ?? null,
      pending,
    }),
    [actionResponse, pending]
  );
  const getActionTenantsProps = useCallback(
    () => ({
      actionResponse,
      fetchAction: fetch,
      updatingAction: pending,
    }),
    [actionResponse, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && pending === false && !actionResponse) {
      notificationService.showError('Action.notFound');
      navigate('/Actions');
    }
  }, [prevPending, pending, actionResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (
      ((prevUpdating === true && updating === false) ||
        (prevUpdatingSettings === true && updatingSettings === false)) &&
      !error
    ) {
      fetch({ id });
    }
  }, [prevUpdating, updating, prevUpdatingSettings, updatingSettings, error, fetch, id]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getActionTenantsProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getActionTenantsProps,
    ]
  );
};

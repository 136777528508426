import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

// Data
import { useAppDispatch } from '../App/useRedux';
import { IntroText, Kiosk, KioskAction, KioskActionText, KioskMediaType } from '../../models/Kiosk';
import { deleteKioskFile, updateKiosk, updateKioskFile } from '../../store/Kiosks/Kiosks.redux';

// Props
interface KioskDetailProps {
  allowUpdates: boolean;
  kioskData: Kiosk | undefined;
  selectedLanguage: string;
  setKioskData: Dispatch<SetStateAction<Kiosk | undefined>>;
  enableSecurityRegulations: boolean;
  enableGuestInstructions: boolean;
  enableNda: boolean;
}

// Hook
export const useKiosksCustomBranding = ({
  allowUpdates,
  kioskData,
  selectedLanguage,
  setKioskData,
  enableSecurityRegulations,
  enableGuestInstructions,
  enableNda,
}: KioskDetailProps) => {
  // Redux
  const dispatch = useAppDispatch();

  // Component State
  const currentText: IntroText = useMemo(() => {
    const text = kioskData?.Texts.find((x) => x.Language === selectedLanguage);
    return {
      CompletionTitle: text?.CompletionTitle ?? '',
      CompletionSubtitle: text?.CompletionSubtitle ?? '',
      IntroSubtext: text?.IntroSubtext ?? '',
      IntroSubtitle: text?.IntroSubtitle ?? '',
      IntroTitle: text?.IntroTitle ?? '',
      Language: text?.Language ?? (!allowUpdates ? '' : selectedLanguage),
    };
  }, [kioskData, allowUpdates, selectedLanguage]);

  const setKioskText = useCallback(
    (text: IntroText) => {
      if (kioskData === undefined || !allowUpdates) {
        return;
      }
      if (text.Language === '' || text.Language !== selectedLanguage) {
        return;
      }
      const texts = kioskData?.Texts ?? [];
      const newTexts = texts.filter((x) => x.Language !== text.Language);

      newTexts.push(text);
      setKioskData({ ...kioskData, Texts: newTexts });
    },
    [setKioskData, selectedLanguage, allowUpdates, kioskData]
  );

  const addAction = useCallback(
    (action: KioskAction) => {
      if (kioskData === undefined || !allowUpdates) {
        return;
      }
      const actions = kioskData?.Actions ?? [];
      const order = actions.length;

      actions.push({
        ...action,
        Order: order,
      });
      setKioskData({ ...kioskData, Actions: actions });
    },
    [setKioskData, allowUpdates, kioskData]
  );

  const updateAction = useCallback(
    (action: KioskAction) => {
      if (kioskData === undefined || !allowUpdates) {
        return;
      }
      const actions = kioskData?.Actions ?? [];
      if (!actions[action.Order]) {
        return;
      }
      actions[action.Order] = {
        ...action,
        Texts: actions[action.Order].Texts,
      };
      setKioskData({ ...kioskData, Actions: actions });
    },
    [setKioskData, allowUpdates, kioskData]
  );

  const setActionOrder = useCallback(
    (oldOrder: number, newOrder: number) => {
      if (kioskData === undefined || !allowUpdates) {
        return;
      }
      const actions = kioskData?.Actions ?? [];
      if (!actions[oldOrder] || !actions[newOrder]) {
        return;
      }
      actions[oldOrder].Order = newOrder;
      actions[newOrder].Order = oldOrder;
      actions.sort((a, b) => a.Order - b.Order);
      setKioskData({ ...kioskData, Actions: actions });
    },
    [setKioskData, allowUpdates, kioskData]
  );

  const setActionText = useCallback(
    (item: number, text: KioskActionText) => {
      if (kioskData === undefined || !allowUpdates) {
        return;
      }
      if (text.Language === '' || text.Language !== selectedLanguage) {
        return;
      }
      const actions = kioskData?.Actions ?? [];
      if (!actions[item]) {
        return;
      }

      const action = actions[item];
      action.Texts = action.Texts.filter((x) => x.Language !== text.Language);
      action.Texts.push(text);

      actions[item] = {
        ...action,
      };
      setKioskData({ ...kioskData, Actions: actions });
    },
    [setKioskData, allowUpdates, kioskData, selectedLanguage]
  );

  // Callbacks
  const handleUpload = useCallback(
    (kioskMediaType: KioskMediaType, file?: File) => {
      if (kioskData && file) {
        setKioskData({ ...kioskData });
        dispatch(
          updateKioskFile({
            ...kioskData,
            Media: [{ MediaType: kioskMediaType, FormFile: file, Url: '', MimeType: '', Language: selectedLanguage }],
          })
        );
      }
    },
    [kioskData, setKioskData, dispatch, selectedLanguage]
  );

  const handleDelete = useCallback(
    (kioskMediaType: KioskMediaType) => {
      if (kioskData) {
        setKioskData({ ...kioskData });
        dispatch(
          deleteKioskFile({
            ...kioskData,
            Language: selectedLanguage,
            MediaType: kioskMediaType,
          })
        );
      }
    },
    [kioskData, setKioskData, dispatch, selectedLanguage]
  );

  // Submit Handling
  const onSubmit = useCallback(() => {
    if (kioskData) {
      dispatch(updateKiosk(kioskData));
    }
  }, [dispatch, kioskData]);

  const onSecurityRegulationSave = useCallback(() => {
    if (kioskData) {
      dispatch(updateKiosk({ ...kioskData, EnableSecurityRegulations: enableSecurityRegulations }));
    }
  }, [dispatch, kioskData, enableSecurityRegulations]);

  const onGuestInstructionsSave = useCallback(() => {
    if (kioskData) {
      dispatch(updateKiosk({ ...kioskData, EnableGuestInstructions: enableGuestInstructions }));
    }
  }, [dispatch, kioskData, enableGuestInstructions]);

  const onGuestNdaSave = useCallback(() => {
    if (kioskData) {
      dispatch(updateKiosk({ ...kioskData, EnableNda: enableNda }));
    }
  }, [dispatch, kioskData, enableNda]);

  // Return Hook
  return useMemo(
    () => ({
      currentText,
      handleUpload,
      handleDelete,
      setKioskText,
      onSubmit,
      onSecurityRegulationSave,
      onGuestInstructionsSave,
      addAction,
      updateAction,
      setActionOrder,
      setActionText,
      onGuestNdaSave,
    }),
    [
      currentText,
      handleUpload,
      handleDelete,
      setKioskText,
      onSubmit,
      onSecurityRegulationSave,
      onGuestInstructionsSave,
      addAction,
      updateAction,
      setActionOrder,
      setActionText,
      onGuestNdaSave,
    ]
  );
};

import React, { useEffect, useRef } from 'react';

import { Scrollbars } from 'react-custom-scrollbars-2';

// Types
import { TableProps } from '../../../types/Table';

// Hooks
import { useTableProps } from '../../../hooks/Table/useTableProps';

// Components
import { Flex } from '../Base';
import { TableScrollbars, TableWrapper } from './elements/TableComponents';
import { TableCard } from './elements/TableCard';
import { TableTitle } from './elements/TableTitle';
import { TableActions } from './elements/TableActions';
import { TableHead } from './elements/TableHead';
import { TableBody } from './elements/TableBody';

// Render
export const Table = <T extends object>(tableProps: TableProps<T>) => {
  const { height } = tableProps;
  const { getTitleProps, getTableProps, getHeadProps, getBodyProps, getActionsProps } = useTableProps<T>(tableProps);

  const scrollbarRef = useRef<Scrollbars | null>(null);
  const { PageChanger } = getActionsProps();

  useEffect(() => {
    scrollbarRef?.current?.scrollToTop();
  }, [PageChanger]);

  return (
    <TableCard
      title={<TableTitle {...getTitleProps()} />}
      actions={[<TableActions {...getActionsProps()} key="actions" />]}
      height={height}
    >
      <TableScrollbars ref={scrollbarRef}>
        <Flex direction="column">
          <TableWrapper {...getTableProps()}>
            <TableHead {...getHeadProps()} />
            <TableBody {...getBodyProps()} />
          </TableWrapper>
        </Flex>
      </TableScrollbars>
    </TableCard>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { Tenant } from '../../models/Tenant';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface TenantsState {
  updating: boolean;
  error: boolean;
}

const initialState: TenantsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@TENANTS';

// Redux Actions|Reducers
const tenantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createTenant: (state, action: PayloadAction<Tenant>) => {
      state.updating = true;
      state.error = false;
    },
    tenantCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    tenantNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateTenant: (state, action: PayloadAction<Tenant>) => {
      state.updating = true;
      state.error = false;
    },
    tenantUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    tenantNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteTenant: (state, action: PayloadAction<Tenant>) => {
      state.updating = true;
      state.error = false;
    },
    tenantDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    tenantNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteTenants: (state, action: PayloadAction<Array<Tenant>>) => {
      state.updating = true;
      state.error = false;
    },
    tenantsDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    tenantsNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createTenant,
  tenantCreated,
  tenantNotCreated,
  // Update
  updateTenant,
  tenantUpdated,
  tenantNotUpdated,
  // Delete
  deleteTenant,
  tenantDeleted,
  tenantNotDeleted,
  // Delete All
  deleteTenants,
  tenantsDeleted,
  tenantsNotDeleted,
} = tenantsSlice.actions;

// Export Reducer
export default tenantsSlice.reducer;

import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    bottom: 3em;
  }
  .Toastify__toast {
    font-family: 'Source Sans Pro';
    font-weight: 400;
  }
  .Toastify__toast-body {
    font-weight: 400;
  }
  .Toastify__progress-bar {
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { Translated } from '../UI/Core';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Widget } from '../UI/Widget/Widget';
import { AzureTenant } from '../../models/AzureTenant';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { Spinner } from '../UI/Spinner/Spinner';
import { importAzureTenant } from '../../store/AzureTenants/AzureTenants.redux';

// Styled
const StyledImportOutlined = styled(ImportOutlined)`
  font-size: 24px;
`;

interface ContactCardProps {
  azureTenant: AzureTenant | null;
  pending: boolean;
}

export const ContactCard = ({ azureTenant, pending }: ContactCardProps) => {
  const dispatch = useAppDispatch();
  const { updating } = useAppSelector(({ azureTenants }) => azureTenants);

  return (
    <Widget title={<Translated id="app.azureTenant" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updating}>
        {/* Name */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <i className="icon icon-avatar gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="azureTenants.name" />
            </span>
            <div className="gx-mb-0">
              <span>{pending ? <ButtonSkeleton width={120} height={10} /> : azureTenant?.Name}</span>
            </div>
          </div>
        </div>
        {/* Status */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <StyledImportOutlined className="gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="azureTenants.importUsers" />
            </span>
            <div className="gx-mb-0">
              <span>
                {pending || !azureTenant ? (
                  <ButtonSkeleton width={120} height={10} />
                ) : (
                  <Button
                    type="primary"
                    ghost
                    style={{ marginBottom: 0 }}
                    size="small"
                    onClick={() => azureTenant && dispatch(importAzureTenant(azureTenant))}
                  >
                    <Translated id="azureTenants.importUsers.button" />
                  </Button>
                )}
              </span>
            </div>
          </div>
        </div>
      </Spinner>
    </Widget>
  );
};

import styled, { css } from 'styled-components';
import { styleCss } from '../../../constants/Utils/StyledComponents';

export interface BlockProps {
  height?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
  margin?: string | number;
  marginTop?: string | number;
  marginBottom?: string | number;
  marginLeft?: string | number;
  marginRight?: string | number;
  marginX?: string | number;
  marginY?: string | number;
  padding?: string | number;
  paddingTop?: string | number;
  paddingBottom?: string | number;
  paddingLeft?: string | number;
  paddingRight?: string | number;
  paddingX?: string | number;
  paddingY?: string | number;
  bg?: string;
  border?: string;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRight?: string;
  borderRadius?: string | number;
  clickable?: boolean;
  overflow?: 'auto' | 'hidden' | 'scroll';
  flex?: string;
  grow?: string;
  shrink?: string;
  alignSelf?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  position?: 'relative' | 'absolute' | 'fixed';
  top?: string | number;
  left?: string | number;
  right?: string | number;
  bottom?: string | number;
}

export const Block = styled.div<BlockProps>`
  display: block;
  ${styleCss('height')};
  ${styleCss('minHeight')};
  ${styleCss('maxHeight')};
  ${styleCss('width')};
  ${styleCss('minWidth')};
  ${styleCss('maxWidth')};

  ${styleCss('margin')};
  ${styleCss('marginTop')};
  ${styleCss('marginBottom')};
  ${styleCss('marginLeft')};
  ${styleCss('marginRight')};
  ${styleCss('marginX', 'margin-left')};
  ${styleCss('marginX', 'margin-right')};
  ${styleCss('marginY', 'margin-top')};
  ${styleCss('marginY', 'margin-bottom')};

  ${styleCss('padding')};
  ${styleCss('paddingTop')};
  ${styleCss('paddingBottom')};
  ${styleCss('paddingLeft')};
  ${styleCss('paddingRight')};
  ${styleCss('paddingX', 'padding-left')};
  ${styleCss('paddingX', 'padding-right')};
  ${styleCss('paddingY', 'padding-top')};
  ${styleCss('paddingY', 'padding-bottom')};

  ${styleCss('bg', 'background')};
  ${styleCss('border')};
  ${styleCss('borderTop')};
  ${styleCss('borderBottom')};
  ${styleCss('borderLeft')};
  ${styleCss('borderRight')};
  ${styleCss('borderRadius')};

  ${styleCss('overflow')};

  ${styleCss('flex')};
  ${styleCss('grow', 'flex-grow')};
  ${styleCss('shrink', 'flex-shrink')};
  ${styleCss('alignSelf')};

  ${styleCss('position')};
  ${styleCss('top')};
  ${styleCss('left')};
  ${styleCss('right')};
  ${styleCss('bottom')};

  ${(props) =>
    (props.clickable || props.onClick) &&
    css`
      cursor: pointer;
    `};
`;

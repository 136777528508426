import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { Tenant } from '../../models/Tenant';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  createTenant,
  deleteTenant,
  deleteTenants,
  tenantCreated,
  tenantDeleted,
  tenantNotCreated,
  tenantNotDeleted,
  tenantNotUpdated,
  tenantsDeleted,
  tenantsNotDeleted,
  tenantUpdated,
  updateTenant,
} from './Tenants.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createTenantSaga() {
  yield takeEvery(createTenant.type, createTenantRequest);
}

function* tenantCreatedSaga() {
  yield takeLatest(tenantCreated.type, createTenantResponse);
}

function* tenantNotCreatedSaga() {
  yield takeLatest(tenantNotCreated.type, createTenantError);
}

// Request
function* createTenantRequest(action: PayloadAction<Tenant>) {
  try {
    const { payload: tenant } = action;
    yield apiService.execute({
      url: 'Tenants',
      method: ApiRequestType.POST,
      data: tenant,
    });
  } catch ({ message }) {
    yield put({ type: tenantNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createTenantResponse() {
  notificationService.showSuccess('tenants.notifications.create');
}

// Error
function createTenantError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('tenants.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateTenantSaga() {
  yield takeEvery(updateTenant.type, updateTenantRequest);
}

function* tenantUpdatedSaga() {
  yield takeLatest(tenantUpdated.type, updateTenantResponse);
}

function* tenantNotUpdatedSaga() {
  yield takeLatest(tenantNotUpdated.type, updateTenantError);
}

// Request
function* updateTenantRequest(action: PayloadAction<Tenant>) {
  try {
    const { payload: tenant } = action;
    yield apiService.execute({
      url: `Tenants/${tenant.Id}`,
      method: ApiRequestType.PUT,
      data: tenant,
    });
  } catch ({ message }) {
    yield put({ type: tenantNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateTenantResponse() {
  notificationService.showSuccess('tenants.notifications.update');
}

// Error
function updateTenantError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('tenants.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteTenantSaga() {
  yield takeEvery(deleteTenant.type, deleteTenantRequest);
}

function* tenantDeletedSaga() {
  yield takeLatest(tenantDeleted.type, deleteTenantResponse);
}

function* tenantNotDeletedSaga() {
  yield takeLatest(tenantNotDeleted.type, deleteTenantError);
}

// Request
function* deleteTenantRequest(action: PayloadAction<Tenant>) {
  try {
    const { payload: tenant } = action;
    yield apiService.execute({
      url: `Tenants/${tenant.Id}`,
      method: ApiRequestType.DELETE,
      data: tenant,
    });
  } catch ({ message }) {
    yield put({ type: tenantNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteTenantResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('tenants.notifications.delete');
  action.payload.history.push(`/Tenants`);
}

// Error
function deleteTenantError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('tenants.notifications.deleteFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteTenantsSaga() {
  yield takeEvery(deleteTenants.type, deleteTenantsRequest);
}

function* tenantsDeletedSaga() {
  yield takeLatest(tenantsDeleted.type, deleteTenantsResponse);
}

function* tenantsNotDeletedSaga() {
  yield takeLatest(tenantsNotDeleted.type, deleteTenantsError);
}

// Request
function* deleteTenantsRequest(action: PayloadAction<Array<Tenant>>) {
  try {
    const { payload: tenants } = action;
    yield apiService.execute({
      url: `Tenants`,
      method: ApiRequestType.DELETE,
      data: { ids: tenants.map((tenant) => tenant.Id) },
    });
  } catch ({ message }) {
    yield put({ type: tenantsNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteTenantsResponse() {
  notificationService.showSuccess('tenants.notifications.deleteAll');
}

// Error
function deleteTenantsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('tenants.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createTenantSaga(),
    tenantCreatedSaga(),
    tenantNotCreatedSaga(),
    // Update
    updateTenantSaga(),
    tenantUpdatedSaga(),
    tenantNotUpdatedSaga(),
    // Delete
    deleteTenantSaga(),
    tenantDeletedSaga(),
    tenantNotDeletedSaga(),
    // Delete All
    deleteTenantsSaga(),
    tenantsDeletedSaga(),
    tenantsNotDeletedSaga(),
  ]);
}

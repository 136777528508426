import { CheckCircleFilled, ExclamationCircleFilled, StopFilled } from '@ant-design/icons';

export enum ApiClientStatus {
  NotHeardFrom = 0,
  Operational = 1,
  GeneralError = 2,
  TargetOffline = 3,
  ConfigurationError = 4,
}

export interface ApiClientStatusType {
  NameId: string;
  Color: string;
  Icon: React.ForwardRefExoticComponent<any>;
  Status: ApiClientStatus;
}

export const ApiClientStatusInfo: ApiClientStatusType[] = [
  {
    NameId: 'apiClientStatus.status.notHeardFrom',
    Color: 'warning',
    Icon: ExclamationCircleFilled,
    Status: ApiClientStatus.NotHeardFrom,
  },
  {
    NameId: 'apiClientStatus.status.operational',
    Color: 'success',
    Icon: CheckCircleFilled,
    Status: ApiClientStatus.Operational,
  },
  {
    NameId: 'apiClientStatus.status.generalError',
    Color: 'error',
    Icon: StopFilled,
    Status: ApiClientStatus.GeneralError,
  },
  {
    NameId: 'apiClientStatus.status.targetOffline',
    Color: 'error',
    Icon: StopFilled,
    Status: ApiClientStatus.TargetOffline,
  },
  {
    NameId: 'apiClientStatus.status.configurationError',
    Color: 'warning',
    Icon: ExclamationCircleFilled,
    Status: ApiClientStatus.ConfigurationError,
  },
];

import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

interface HtmlProps {
  html: string;
  styleClass: string;
}

export const HTMLSanitize = ({ html, styleClass }: HtmlProps) => {
  // Sanitize the unsafe HTML using DOMPurify
  const safeHTML = useMemo(() => {
    return DOMPurify.sanitize(html);
  }, [html]);

  return (
    <div>
      {/* Render the sanitized HTML */}
      <div className={styleClass}>{parse(safeHTML)}</div>
    </div>
  );
};

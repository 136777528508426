import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { Company } from '../../models/Company';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  companiesDeleted,
  companiesNotDeleted,
  companyCreated,
  companyDeleted,
  companyNotCreated,
  companyNotDeleted,
  companyNotUpdated,
  companyUpdated,
  createCompany,
  deleteCompanies,
  deleteCompany,
  updateCompany,
} from './Companies.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createCompanySaga() {
  yield takeEvery(createCompany.type, createCompanyRequest);
}

function* companyCreatedSaga() {
  yield takeLatest(companyCreated.type, createCompanyResponse);
}

function* companyNotCreatedSaga() {
  yield takeLatest(companyNotCreated.type, createCompanyError);
}

// Request
function* createCompanyRequest(action: PayloadAction<Company>) {
  try {
    const { payload: company } = action;
    yield apiService.execute({
      url: 'Companies',
      method: ApiRequestType.POST,
      data: company,
    });
  } catch ({ message }) {
    yield put({ type: companyNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createCompanyResponse() {
  notificationService.showSuccess('companies.notifications.create');
}

// Error
function createCompanyError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('companies.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateCompanySaga() {
  yield takeEvery(updateCompany.type, updateCompanyRequest);
}

function* companyUpdatedSaga() {
  yield takeLatest(companyUpdated.type, updateCompanyResponse);
}

function* companyNotUpdatedSaga() {
  yield takeLatest(companyNotUpdated.type, updateCompanyError);
}

// Request
function* updateCompanyRequest(action: PayloadAction<Company>) {
  try {
    const { payload: company } = action;
    yield apiService.execute({
      url: `Companies/${company.Id}`,
      method: ApiRequestType.PUT,
      data: company,
    });
  } catch ({ message }) {
    yield put({ type: companyNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateCompanyResponse() {
  notificationService.showSuccess('companies.notifications.update');
}

// Error
function updateCompanyError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('companies.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteCompanySaga() {
  yield takeEvery(deleteCompany.type, deleteCompanyRequest);
}

function* companyDeletedSaga() {
  yield takeLatest(companyDeleted.type, deleteCompanyResponse);
}

function* companyNotDeletedSaga() {
  yield takeLatest(companyNotDeleted.type, deleteCompanyError);
}

// Request
function* deleteCompanyRequest(action: PayloadAction<Company>) {
  try {
    const { payload: company } = action;
    yield apiService.execute({
      url: `Companies/${company.Id}`,
      method: ApiRequestType.DELETE,
      data: company,
    });
  } catch ({ message }) {
    yield put({ type: companyNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteCompanyResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('companies.notifications.delete');
  action.payload.history.push(`/Companies`);
}

// Error
function deleteCompanyError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('companies.notifications.deleteFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteCompaniesSaga() {
  yield takeEvery(deleteCompanies.type, deleteCompaniesRequest);
}

function* companiesDeletedSaga() {
  yield takeLatest(companiesDeleted.type, deleteCompaniesResponse);
}

function* companiesNotDeletedSaga() {
  yield takeLatest(companiesNotDeleted.type, deleteCompaniesError);
}

// Request
function* deleteCompaniesRequest(action: PayloadAction<Array<Company>>) {
  try {
    const { payload: companies } = action;
    yield apiService.execute({
      url: `Companies`,
      method: ApiRequestType.DELETE,
      data: { ids: companies.map((company) => company.Id) },
    });
  } catch ({ message }) {
    yield put({ type: companiesNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteCompaniesResponse() {
  notificationService.showSuccess('companies.notifications.deleteAll');
}

// Error
function deleteCompaniesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('companies.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createCompanySaga(),
    companyCreatedSaga(),
    companyNotCreatedSaga(),
    // Update
    updateCompanySaga(),
    companyUpdatedSaga(),
    companyNotUpdatedSaga(),
    // Delete
    deleteCompanySaga(),
    companyDeletedSaga(),
    companyNotDeletedSaga(),
    // Delete All
    deleteCompaniesSaga(),
    companiesDeletedSaga(),
    companiesNotDeletedSaga(),
  ]);
}

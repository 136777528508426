// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  identifierTypeCreated,
  identifierTypeDeleted,
  identifierTypeNotCreated,
  identifierTypeNotDeleted,
  identifierTypeNotUpdated,
  identifierTypesDeleted,
  identifierTypeSettingsNotUpdated,
  identifierTypeSettingsUpdated,
  identifierTypesNotDeleted,
  identifierTypeUpdated,
} from './IdentifierTypes.redux';

// Events
const SignalREvents = {
  IdentifierTypeCreated: `IdentifierTypeCreatedIntegrationEvent`,
  IdentifierTypeUpdated: `IdentifierTypeUpdatedIntegrationEvent`,
  IdentifierTypeDeleted: `IdentifierTypeDeletedIntegrationEvent`,
  IdentifierTypesDeleted: `IdentifierTypesDeletedIntegrationEvent`,
  IdentifierTypeSettingsUpdated: `IdentifierTypeSettingsUpdatedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  IdentifierTypeNotCreated: `IdentifierTypeNotCreatedIntegrationException`,
  IdentifierTypeNotUpdated: `IdentifierTypeNotUpdatedIntegrationException`,
  IdentifierTypeNotDeleted: `IdentifierTypeNotDeletedIntegrationException`,
  IdentifierTypesNotDeleted: `IdentifierTypesNotDeletedIntegrationException`,
  IdentifierTypeSettingsNotUpdated: `IdentifierTypeSettingsNotUpdatedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.IdentifierTypeCreated, (msg) => {
    store.dispatch(identifierTypeCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.IdentifierTypeNotCreated, (msg) => {
    store.dispatch(identifierTypeNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.IdentifierTypeUpdated, (msg) => {
    store.dispatch(identifierTypeUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.IdentifierTypeNotUpdated, (msg) => {
    store.dispatch(identifierTypeNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.IdentifierTypeDeleted, (msg) => {
    store.dispatch(identifierTypeDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.IdentifierTypeNotDeleted, (msg) => {
    store.dispatch(identifierTypeNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.IdentifierTypesDeleted, (msg) => {
    store.dispatch(identifierTypesDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.IdentifierTypesNotDeleted, (msg) => {
    store.dispatch(identifierTypesNotDeleted({ history, msg }));
  });

  // Update Settings
  hubConnection.on(SignalREvents.IdentifierTypeSettingsUpdated, (msg) => {
    store.dispatch(identifierTypeSettingsUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.IdentifierTypeSettingsNotUpdated, (msg) => {
    store.dispatch(identifierTypeSettingsNotUpdated({ history, msg }));
  });
};

import React, { useCallback, useMemo } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row as TableRow } from 'react-table';

// Types
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { AccessProfile } from '../../models/AccessProfile';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import {
  createAccessProfile,
  deleteAccessProfile,
  deleteAccessProfiles,
  updateAccessProfile,
} from '../../store/AccessProfiles/AccessProfiles.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';

const { confirm } = Modal;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface AccessProfilesReduxProps {
  isEditing?: boolean;
  initialValues?: Store | undefined;
}

// Hook
export const useAccessProfilesRedux = <T extends object>({
  isEditing,
  initialValues,
}: AccessProfilesReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ accessProfiles }) => accessProfiles?.updating ?? false);
  const error = useAppSelector(({ accessProfiles }) => accessProfiles?.error ?? false);

  // Submit Handling
  const onSubmit = useCallback(
    (accessProfile: AccessProfile) => {
      if (isEditing) {
        dispatch(updateAccessProfile(accessProfile));
      } else {
        dispatch(createAccessProfile(accessProfile));
      }
    },
    [dispatch, isEditing]
  );

  // Form
  const CreateAccessProfileForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as AccessProfile)}
        initialValues={initialValues}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="accessProfiles.name" />}
                rules={[{ required: true, message: intl.formatMessage({ id: 'accessProfiles.form.warnings.name' }) }]}
              >
                <Input placeholder="Access Profile" />
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, initialValues, intl]
  );
  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'AccessProfiles',
        Form: CreateAccessProfileForm,
        labels: {
          createButton: <Translated id="accessProfiles.form.create" />,
          drawerForm: <Translated id={isEditing ? 'accessProfiles.form.edit' : 'accessProfiles.form.create'} />,
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateAccessProfileForm, isEditing]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (accessProfile: AccessProfile | null) => {
      if (accessProfile) {
        confirm({
          title: intl.formatMessage({
            id: 'accessProfiles.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'accessProfiles.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteAccessProfile(accessProfile)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const accessProfiles = selectedFlatRows.map((d) => d.original) as AccessProfile[];
      if (accessProfiles) {
        confirm({
          title: intl.formatMessage({
            id: 'accessProfiles.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'accessProfiles.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteAccessProfiles(accessProfiles)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
      showDeleteAllConfirm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm]
  );
};

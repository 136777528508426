import React from 'react';

import { TableColumn } from '../../types/Table';
import { Tenant } from '../../models/Tenant';
import { Translated } from '../../components/UI/Core';
import { Company, Location } from '../../models';

interface TenantsTableColumnParams {
  companies: Company[] | null;
  locations: Location[] | null;
}

type GetTenantsTableColumnProps = (params: TenantsTableColumnParams) => Array<TableColumn<Tenant>>;

export const getTenantTableColumns: GetTenantsTableColumnProps = ({ companies, locations }) => [
  {
    Header: <Translated id="tenants.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="tenants.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="tenants.company" />,
    id: 'CompanyId',
    accessor: (row) => companies?.find((company) => company.Id === row.CompanyId)?.Name ?? row.Id,
    disableFilters: true,
  },
  {
    Header: <Translated id="tenants.location" />,
    id: 'LocationId',
    accessor: (row) => locations?.find((location) => location.Id === row.LocationId)?.Name ?? row.Id,
    disableFilters: true,
  },
];

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import React from 'react';
import '.';

interface EditorValues {
  value: string;
  onChange: (event: any) => void;
}

// Styled

// Component
export const Editor = ({ value, onChange }: EditorValues) => {
  const Font = Quill.import('formats/font');
  Font.whitelist = [
    'SourceSansPro',
    'Arial',
    'calibri',
    'courier',
    'Georgia',
    'lucida',
    'Tahoma',
    'Times',
    'trebuchet',
    'verdana',
    'Montserrat',
  ];
  Quill.register(Font, true);

  const Size = Quill.import('attributors/style/size');
  Size.whitelist = ['16px', '20px', '38px', '58px'];
  Quill.register(Size, true);

  const quillModules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: Font.whitelist }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: Size.whitelist }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['link', 'image', 'video'],
    ],
  };

  return <ReactQuill className="ql-editor" onChange={onChange} value={value} theme="snow" modules={quillModules} />;
};

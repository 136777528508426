import * as React from 'react';
import { ColumnInstance } from 'react-table';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

// Types
import { Flex } from '../../../Base';

// Props
interface SortIconProps<T extends object> {
  column: ColumnInstance<T>;
}

// Render
export const SortIcon = <T extends object>({ column }: SortIconProps<T>) => {
  const isSortedDesc = column.isSorted && column.isSortedDesc;
  const isSortedAsc = column.isSorted && !column.isSortedDesc;
  return column.canSort ? (
    <Flex width={24} direction="column">
      <CaretUpOutlined style={{ fontSize: 10, color: isSortedAsc ? '#08c' : '#bfbfbf' }} />
      <CaretDownOutlined style={{ fontSize: 10, color: isSortedDesc ? '#08c' : '#bfbfbf' }} />
    </Flex>
  ) : null;
};

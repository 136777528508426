import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useParams } from 'react-router';
import { useCalendarEventConfigurationDetail } from '../../hooks/CalendarEventConfigurations/useCalendarEventConfigurationDetail';

// Components
import { Container } from '../../components/UI/Base';
import { ContactCard, ProfileHeader, Settings } from '../../components/CalendarEventConfigurations';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const CalendarEventConfigurationDetailPage = () => {
  const { id } = useParams();
  const { getBreadcrumbMenuProps, getProfileHeaderProps, getFormDrawerProps, getSettingsProps, getContactCardProps } =
    useCalendarEventConfigurationDetail({ id });

  return (
    <>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <Settings {...getSettingsProps()} />
          </Col>
          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <ContactCard {...getContactCardProps()} />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </>
  );
};

import React from 'react';

import { TableColumn } from '../../types/Table';
import { IdentifierType } from '../../models/IdentifierType';
import { Translated } from '../../components/UI/Core';
import { TranslatedTag } from '../../components/UI/Tag/TranslatedTag';

export const identifierTypeTableColumns: Array<TableColumn<IdentifierType>> = [
  {
    Header: <Translated id="identifierTypes.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="identifierTypes.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="identifierTypes.isDefaultForCheckInTable" />,
    id: 'DefaultForCheckIn',
    accessor: (row) =>
      row.DefaultForCheckIn ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
  {
    Header: <Translated id="identifierTypes.shownInSelfRegistration" />,
    id: 'ShownInSelfRegistration',
    accessor: (row) =>
      row.ShownInSelfRegistration ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
  {
    Header: <Translated id="identifierTypes.requiredInSelfRegistration" />,
    id: 'RequiredInSelfRegistration',
    isVisible: false,
    accessor: (row) =>
      row.RequiredInSelfRegistration ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
  {
    Header: <Translated id="identifierTypes.allowedToChangeInInvitation" />,
    id: 'AllowedToChangeInInvitation',
    accessor: (row) =>
      row.AllowedToChangeInInvitation ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
  {
    Header: <Translated id="identifierTypes.requiredInInvitation" />,
    id: 'RequiredInInvitation',
    isVisible: false,
    accessor: (row) =>
      row.RequiredInInvitation ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
  {
    Header: <Translated id="identifierTypes.showInParkingReservation" />,
    id: 'ShowInParkingReservation',
    accessor: (row) =>
      row.ShowInParkingReservation ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
  {
    Header: <Translated id="identifierTypes.requiredInParkingReservation" />,
    id: 'RequiredInParkingReservation',
    accessor: (row) =>
      row.RequiredInParkingReservation ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
];

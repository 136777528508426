import React from 'react';
import { Badge, Button, Collapse, Row, Tooltip } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ReloadOutlined } from '@ant-design/icons';

// Components
import { Flex } from '../../UI/Base';
import { SelectFilter } from '../../UI/Select/SelectFilter';
import { SystemStatusFilter } from '../../UI/Select/SystemStatusFilter';

// Models
import { EnvironmentFiltersResponse } from '../../../models/Dashboard/ApiResponses/EnvironmentFiltersResponse';
import { FiltersSkeleton } from '../../UI/Skeleton/FiltersSkeleton';
import {
  clearDashboardFilters,
  setCompanies,
  setLocations,
  setServices,
  setStatus,
} from '../../../store/Filters/Filters.redux';
import { useAppDispatch, useAppSelector } from '../../../hooks/App/useRedux';
import { ActiveFilters } from '../../../models/Filters';
import { Translated } from '../../UI/Core';
import { EnvironmentStatsResponse } from '../../../models/Dashboard/ApiResponses/EnvironmentStatsResponse';
import { getFilterStatus } from '../../../constants/Utils/StatusFunctions';
import { testServiceConfigurations } from '../../../store/ServiceConfigurations/ServiceConfigurations.redux';
import { AppColors } from '../../../constants/Utils/Colors';

// Styled
const StyledPanel = styled(Collapse.Panel)`
  &.ant-collapse-item {
    & .ant-collapse-header.ant-collapse-header {
      align-items: center;
    }
  }
`;
const StyledBadge = styled(Badge)`
  margin-bottom: 0;
  margin-right: 8px;
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
  margin-left: 8px;
`;

// Props
interface EnvironmentFiltersProps {
  filters: EnvironmentFiltersResponse | undefined;
  content: Array<EnvironmentStatsResponse> | undefined;
  activeFilters: ActiveFilters;
  filterCount: number;
  loading: boolean;
}

// Component
export const EnvironmentFilters = ({
  filters,
  activeFilters,
  content,
  loading,
  filterCount,
}: EnvironmentFiltersProps) => {
  // Hooks
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const { updatingEntityId } = useAppSelector(({ serviceConfigurations }) => serviceConfigurations);
  const updating = !!updatingEntityId;

  // Available and Available Filters
  const { Services, Companies, Locations } = filters || {};
  const { servicesFilter, statusFilter, companiesFilter, locationsFilter } = activeFilters;

  // Variables
  const hasFilters = filterCount > 0;

  const statusInfo = getFilterStatus(filterCount, content);
  const {
    statusInfo: { Icon, Color, NameId },
    problemCount,
  } = statusInfo || {};

  // Rendering
  const renderCollapseHeader = () => (
    <Flex alignItems="center">
      <div className="gx-mr-xl-3 gx-mr-2">
        <Icon style={{ display: 'flex' }} className={`gx-text-${Color} gx-fs-xxxl`} />
      </div>
      <h2 className="gx-entry-title" style={{ marginBottom: 'unset' }}>
        <Flex alignItems="center">
          {intl.formatMessage(
            { id: NameId },
            {
              filterText: intl.formatMessage({ id: 'dashboard.textCardGroup.filtersNotFoundText' }),
              count: problemCount,
            }
          )}
          <Tooltip key={NameId} title={<Translated id="dashboard.filters.check" />}>
            <StyledButton
              loading={updating}
              disabled={updating}
              type="primary"
              ghost
              shape="circle"
              style={{ marginBottom: 0 }}
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(testServiceConfigurations());
              }}
            >
              {!updating && <ReloadOutlined />}
            </StyledButton>
          </Tooltip>
        </Flex>
      </h2>
    </Flex>
  );

  const renderHeaderFilters = () => (
    <Flex alignItems="center">
      {hasFilters && (
        <Button
          type="primary"
          ghost
          style={{ marginBottom: 0 }}
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            dispatch(clearDashboardFilters());
          }}
        >
          <Translated id="dashboard.filters.clear" />
        </Button>
      )}
      <StyledBadge style={{ backgroundColor: AppColors.gold }} count={filterCount} showZero />
      <Translated id="dashboard.filters.label" />
    </Flex>
  );

  return (
    <div className="ant-col gx-visit-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-14">
      {loading ? (
        <FiltersSkeleton />
      ) : (
        <Collapse ghost activeKey="1">
          <StyledPanel
            key="1"
            showArrow={false}
            collapsible="icon"
            header={renderCollapseHeader()}
            extra={renderHeaderFilters()}
          >
            <Row>
              <SelectFilter
                filters={Services}
                labelId="dashboard.audience.accessControlSystems"
                defaultValue={servicesFilter}
                handleChange={(value) => dispatch(setServices(value))}
              />
              <SystemStatusFilter
                labelId="dashboard.welcomeCard.item5"
                defaultValue={statusFilter}
                handleChange={(value) => dispatch(setStatus(value))}
              />
              <SelectFilter
                filters={Companies}
                labelId="dashboard.welcomeCard.item3"
                defaultValue={companiesFilter}
                handleChange={(value) => dispatch(setCompanies(value))}
              />
              <SelectFilter
                filters={Locations}
                labelId="dashboard.welcomeCard.item4"
                defaultValue={locationsFilter}
                handleChange={(value) => dispatch(setLocations(value))}
              />
            </Row>
          </StyledPanel>
        </Collapse>
      )}
    </div>
  );
};

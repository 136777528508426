import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import styled from 'styled-components';
import { IdentityUser, IdentityUserResponse } from '../../../models/IdentityUser';
import { IdentityRoleResponse } from '../../../models/IdentityRole';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { assignIdentityUserRoles } from '../../../store/IdentityUsers/IdentityUserRoles/IdentityUserRoles.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';
import { IdentityRoles } from '../../../models/enums/IdentityRoles';

const { confirm } = Modal;

// Props
interface IdentityUserRolesReduxProps {
  identityUserResponse: IdentityUserResponse | null;
  identityUserRoles: Array<IdentityRoleResponse> | null;
}

interface AssignConfirmProps {
  roleIds: Array<string>;
  identityUser: IdentityUser;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useIdentityUserRolesRedux = ({ identityUserResponse, identityUserRoles }: IdentityUserRolesReduxProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(
    ({ identityUserRoles: identityUserRolesState }) => identityUserRolesState?.updating ?? false
  );
  const error = useAppSelector(
    ({ identityUserRoles: identityUserRolesState }) => identityUserRolesState?.error ?? false
  );

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ roleIds, identityUser }: AssignConfirmProps) => {
      if (roleIds && identityUser) {
        confirm({
          title: intl.formatMessage({
            id: 'identityUsers.Roles.multiSelect.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'identityUsers.Roles.multiSelect.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignIdentityUserRoles({ roleIds, identityUser })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // State
  const [roleIds, setRoleIds] = useState<Array<string>>([]);

  // Sets the Keys when the response is updated
  // Fixes problem with initial values after API call
  useEffect(() => {
    setRoleIds(
      identityUserRoles
        ?.filter((role) =>
          identityUserResponse?.Identity.Roles?.some((identityUserRole) => identityUserRole === role.Name)
        )
        .map((role) => role.Id) ?? []
    );
  }, [identityUserRoles, identityUserResponse]);

  // Components
  const IdentityUserRolesManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      identityUserRoles?.map<TransferItem>((identityUserRole) => ({
        key: identityUserRole.Id,
        title: intl.formatMessage({ id: IdentityRoles.find((r) => r.IdentityRole === identityUserRole.Name)?.NameId }),
        description: '',
      })) ?? [];

    return (
      <>
        <ItemTransfer dataSource={dataSource} targetKeys={roleIds} setTargetKeys={setRoleIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            identityUserResponse && showAssignConfirm({ roleIds, identityUser: identityUserResponse.Identity })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [intl, identityUserResponse, identityUserRoles, showAssignConfirm, roleIds, setRoleIds]);

  return useMemo(
    () => ({
      IdentityUserRolesManagementForm,
      updating,
      error,
    }),
    [IdentityUserRolesManagementForm, updating, error]
  );
};

import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useActionList } from '../../hooks/Actions/useActionList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const ActionsPage = () => {
  const {
    updating,
    getBreadcrumbMenuProps,
    getActionsDataProps,
    getActionsTableProps,
    getActionsCrudProps,
    getFormDrawerProps,
  } = useActionList();

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        <Row>
          <Col span={24}>
            <Table {...getActionsDataProps()} {...getActionsTableProps()} {...getActionsCrudProps()} />
          </Col>
        </Row>
      </Container>
      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectFilterType } from '../../models/Filters';

// State
interface FiltersState {
  environments: {
    status: SelectFilterType;
    services: SelectFilterType;
    companies: SelectFilterType;
    locations: SelectFilterType;
  };
}

const initialState: FiltersState = {
  environments: {
    status: [],
    services: [],
    companies: [],
    locations: [],
  },
};

// Name
const storeName = '@FILTERS';

// Redux Actions|Reducers
const filtersSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    clearDashboardFilters: (state) => {
      state.environments = initialState.environments;
    },
    setStatus: (state, action: PayloadAction<SelectFilterType>) => {
      state.environments.status = action.payload;
    },
    setServices: (state, action: PayloadAction<SelectFilterType>) => {
      state.environments.services = action.payload;
    },
    setCompanies: (state, action: PayloadAction<SelectFilterType>) => {
      state.environments.companies = action.payload;
    },
    setLocations: (state, action: PayloadAction<SelectFilterType>) => {
      state.environments.locations = action.payload;
    },
  },
});

// Export Actions
export const { clearDashboardFilters, setStatus, setServices, setCompanies, setLocations } = filtersSlice.actions;

// Export Reducer
export default filtersSlice.reducer;

import React from 'react';

// Redux
import { useAppDispatch } from '../../../hooks/App/useRedux';
import { switchLanguage } from '../../../store/AppSettings/AppSettings.redux';

// Helpers
import { appLanguages } from '../../../constants/AppLanguages/AppLanguages';
import { CustomScrollbars } from '../../UI/Core';

// Props
interface LanguageMenuProps {
  onClick: () => void;
}

export const LanguageMenu = ({ onClick }: LanguageMenuProps) => {
  const dispatch = useAppDispatch();

  return (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {appLanguages.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={() => {
              onClick();
              dispatch(switchLanguage(language));
            }}
            role="presentation"
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );
};

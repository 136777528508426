import { SagaMiddleware } from 'redux-saga';

// App Settings
import AppSettingsSagas from './AppSettings/AppSettings.sagas';
// Access Profiles
import AccessProfilesSagas from './AccessProfiles/AccessProfiles.sagas';
import PACSAccessProfilesSagas from './AccessProfiles/PACSAccessProfiles/PACSAccessProfiles.sagas';
import AccessProfileTenantsSagas from './AccessProfiles/AccessProfileTenants/AccessProfileTenants.sagas';
// Identifier Types
import IdentifierTypesSagas from './IdentifierTypes/IdentifierTypes.sagas';
import PACSIdentifierTypesSagas from './IdentifierTypes/PACSIdentifierTypes/PACSIdentifierTypes.sagas';
import IdentifierTypeTenantsSagas from './IdentifierTypes/IdentifierTypeTenants/IdentifierTypeTenants.sagas';
// Schedule Configurations
import ScheduleConfigurationsSagas from './ScheduleConfigurations/ScheduleConfigurations.sagas';
// Service Configurations
import ServiceConfigurationsSagas from './ServiceConfigurations/ServiceConfigurations.sagas';
import PACSSettingsSagas from './ServiceConfigurations/PACSSettings/PACSSettings.sagas';
import ServiceConfigurationTenantsSagas from './ServiceConfigurations/ServiceConfigurationTenants/ServiceConfigurationTenants.sagas';
// Api Configurations
import ApiConfigurationsSagas from './ApiConfigurations/ApiConfigurations.sagas';
import ApiConfigurationTenantsSagas from './ApiConfigurations/ApiConfigurationTenants/ApiConfigurationTenants.sagas';
// Kiosks
import KiosksSagas from './Kiosks/Kiosks.sagas';
// Identity Users
import IdentityUsersSagas from './IdentityUsers/IdentityUsers.sagas';
import IdentityUserTenantsSagas from './IdentityUsers/IdentityUserTenants/IdentityUserTenants.sagas';
import IdentityUserRolesSagas from './IdentityUsers/IdentityUserRoles/IdentityUserRoles.sagas';
// Companies
import CompaniesSagas from './Companies/Companies.sagas';
// Locations
import LocationsSagas from './Locations/Locations.sagas';
// Tenants
import TenantsSagas from './Tenants/Tenants.sagas';
// Clients
import ApiClientsSagas from './ApiClients/ApiClients.sagas';
// AzureTenant
import AzureTenantsSagas from './AzureTenants/AzureTenants.sagas';
import AzureGroupsSagas from './AzureTenants/AzureGroups/AzureGroups.sagas';
// Calendar Event Configuration
import CalendarEventConfigurationsSagas from './CalendarEventConfigurations/CalendarEventConfigurations.sagas';
// Actions
import ActionSagas from './Actions/Actions.sagas';
// Business Clients
import BusinessClientsSagas from './BusinessClients/BusinessClients.sagas';

// Sagas Runner
export const runSagas = (sagaMiddleware: SagaMiddleware<object>) => {
  // App Settings
  sagaMiddleware.run(AppSettingsSagas);
  // Access Profiles
  sagaMiddleware.run(AccessProfilesSagas);
  sagaMiddleware.run(PACSAccessProfilesSagas);
  sagaMiddleware.run(AccessProfileTenantsSagas);
  // Identifier Types
  sagaMiddleware.run(IdentifierTypesSagas);
  sagaMiddleware.run(PACSIdentifierTypesSagas);
  sagaMiddleware.run(IdentifierTypeTenantsSagas);
  // Schedule Configurations
  sagaMiddleware.run(ScheduleConfigurationsSagas);
  // Service Configurations
  sagaMiddleware.run(ServiceConfigurationsSagas);
  sagaMiddleware.run(PACSSettingsSagas);
  sagaMiddleware.run(ServiceConfigurationTenantsSagas);
  // Api Configurations
  sagaMiddleware.run(ApiConfigurationsSagas);
  sagaMiddleware.run(ApiConfigurationTenantsSagas);
  // Kiosk Configurations
  sagaMiddleware.run(KiosksSagas);
  // Identity Users
  sagaMiddleware.run(IdentityUsersSagas);
  sagaMiddleware.run(IdentityUserTenantsSagas);
  sagaMiddleware.run(IdentityUserRolesSagas);
  // Companies
  sagaMiddleware.run(CompaniesSagas);
  // Locations
  sagaMiddleware.run(LocationsSagas);
  // Tenants
  sagaMiddleware.run(TenantsSagas);
  // API ClientsSagas
  sagaMiddleware.run(ApiClientsSagas);
  // AzureTenant
  sagaMiddleware.run(AzureTenantsSagas);
  sagaMiddleware.run(AzureGroupsSagas);
  // Calendar Event Configuration
  sagaMiddleware.run(CalendarEventConfigurationsSagas);
  // Actions
  sagaMiddleware.run(ActionSagas);
  // Business Clients
  sagaMiddleware.run(BusinessClientsSagas);
};

import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { CloudSyncOutlined } from '@ant-design/icons';
import { Translated } from '../UI/Core';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Widget } from '../UI/Widget/Widget';
import { CalendarEventConfiguration } from '../../models/CalendarEventConfiguration';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { Spinner } from '../UI/Spinner/Spinner';
import { CalendarEventConfigurationProtocolTypes } from '../../models/enums/CalendarEventConfigurationProtocol';
import { fetchCalendarEvents } from '../../store/CalendarEventConfigurations/CalendarEventConfigurations.redux';

// Styled
const StyledCloudSyncOutlined = styled(CloudSyncOutlined)`
  font-size: 24px;
`;

interface ContactCardProps {
  calendarEventConfiguration: CalendarEventConfiguration | null;
  pending: boolean;
}

export const ContactCard = ({ calendarEventConfiguration, pending }: ContactCardProps) => {
  const dispatch = useAppDispatch();
  const { updating } = useAppSelector(({ calendarEventConfigurations }) => calendarEventConfigurations);

  return (
    <Widget title={<Translated id="app.calendarEventConfiguration" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updating}>
        {/* Protocol */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <i className="icon icon-avatar gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="calendarEventConfigurations.protocol" />
            </span>
            <div className="gx-mb-0">
              <span>
                {pending ? (
                  <ButtonSkeleton width={120} height={10} />
                ) : (
                  CalendarEventConfigurationProtocolTypes.find(
                    (type) => type.Value === calendarEventConfiguration?.Protocol
                  )?.Name
                )}
              </span>
            </div>
          </div>
        </div>
        {/* Status */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <StyledCloudSyncOutlined className="gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="calendarEventConfigurations.fetchCalendarEvents" />
            </span>
            <div className="gx-mb-0">
              <span>
                {pending || !calendarEventConfiguration ? (
                  <ButtonSkeleton width={120} height={10} />
                ) : (
                  <Button
                    type="primary"
                    ghost
                    style={{ marginBottom: 0 }}
                    size="small"
                    onClick={() =>
                      calendarEventConfiguration && dispatch(fetchCalendarEvents(calendarEventConfiguration))
                    }
                  >
                    <Translated id="calendarEventConfigurations.fetchCalendarEvents.button" />
                  </Button>
                )}
              </span>
            </div>
          </div>
        </div>
      </Spinner>
    </Widget>
  );
};

import React from 'react';

import { TableColumn } from '../../types/Table';
import { Translated } from '../../components/UI/Core';
import { BusinessClient } from '../../models/BusinessClient';

export const businessClientTableColumns: Array<TableColumn<BusinessClient>> = [
  {
    Header: <Translated id="businessClients.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="businessClients.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="businessClients.description" />,
    id: 'Description',
    accessor: 'Description',
  },
];

// History
export type ApiActivityHistoryContent = {
  id: string;
  period: string;
  activities: Array<ApiActivity>;
};
export type ApiActivity = {
  id: string;
  author: string;
  action: string;
  object: string;
  data: string;
};
export const apiActivityHistory: Array<ApiActivityHistoryContent> = [
  {
    id: 'XX1',
    period: 'Today',
    activities: [
      {
        id: 'YY1',
        author: 'Damian',
        action: 'Updated',
        object: 'Access Profile',
        data: `{ AccessProfileId: '001', Name: 'VIP Access', DateTime: '08/06/2022 21:12:21' }`,
      },
      {
        id: 'YY2',
        author: 'Andres',
        action: 'Created',
        object: 'Identifier Type',
        data: `{ IdentifierTypeId: '001', Name: 'Pass', DateTime: '08/06/2022 14:40:58' }`,
      },
    ],
  },
  {
    id: 'XX2',
    period: 'Last week',
    activities: [
      {
        id: 'YY21',
        author: 'Damian',
        action: 'Updated',
        object: 'Access Profile',
        data: `{ AccessProfileId: '001', Name: 'VIP Access', DateTime: '08/06/2022 21:12:21' }`,
      },
      {
        id: 'YY22',
        author: 'Andres',
        action: 'Created',
        object: 'Identifier Type',
        data: `{ IdentifierTypeId: '001', Name: 'Pass', DateTime: '08/06/2022 14:40:58' }`,
      },
    ],
  },
];

// Chart Metrics
export type apiMetricsData = {
  dataKey: string;
  name: string;
  thisPeriod: number;
  lastPeriod: number;
};
export const apiMetrics: Array<apiMetricsData> = [
  { dataKey: 'thisPeriod', name: '1', thisPeriod: 3, lastPeriod: 0 },
  { dataKey: 'lastPeriod', name: '2', thisPeriod: 3, lastPeriod: 1 },
  { dataKey: 'thisPeriod', name: '3', thisPeriod: 5, lastPeriod: 2 },
  { dataKey: 'lastPeriod', name: '4', thisPeriod: 10, lastPeriod: 0 },
  { dataKey: 'thisPeriod', name: '5', thisPeriod: 4, lastPeriod: 1 },
  { dataKey: 'lastPeriod', name: '6', thisPeriod: 16, lastPeriod: 3 },
  { dataKey: 'thisPeriod', name: '7', thisPeriod: 5, lastPeriod: 1 },
  { dataKey: 'lastPeriod', name: '8', thisPeriod: 11, lastPeriod: 5 },
  { dataKey: 'thisPeriod', name: '9', thisPeriod: 6, lastPeriod: 2 },
  { dataKey: 'lastPeriod', name: '10', thisPeriod: 11, lastPeriod: 3 },
  { dataKey: 'thisPeriod', name: '11', thisPeriod: 30, lastPeriod: 2 },
  { dataKey: 'lastPeriod', name: '12', thisPeriod: 10, lastPeriod: 1 },
  { dataKey: 'thisPeriod', name: '13', thisPeriod: 13, lastPeriod: 0 },
  { dataKey: 'lastPeriod', name: '14', thisPeriod: 4, lastPeriod: 2 },
  { dataKey: 'thisPeriod', name: '15', thisPeriod: 3, lastPeriod: 8 },
  { dataKey: 'lastPeriod', name: '16', thisPeriod: 1, lastPeriod: 0 },
  { dataKey: 'thisPeriod', name: '17', thisPeriod: 0, lastPeriod: 0 },
];
export const yearData = [
  { name: 'JAN', lastYear: 200, thisYear: 600 },
  { name: 'FEB', lastYear: 500, thisYear: 900 },
  { name: 'MAR', lastYear: 700, thisYear: 1200 },
  { name: 'JUN', lastYear: 500, thisYear: 900 },
  { name: 'AUG', lastYear: 200, thisYear: 800 },
  { name: 'SEP', lastYear: 400, thisYear: 400 },
  { name: 'OCT', lastYear: 400, thisYear: 500 },
  { name: 'NOV', lastYear: 400, thisYear: 1200 },
  { name: 'DEC', lastYear: 200, thisYear: 800 },
];

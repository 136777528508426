import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useAccessProfilesList } from '../../hooks/AccessProfiles/useAccessProfilesList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const AccessProfilesPage = () => {
  const {
    updating,
    getBreadcrumbMenuProps,
    getAccessProfilesDataProps,
    getAccessProfilesTableProps,
    getAccessProfilesCrudProps,
    getFormDrawerProps,
  } = useAccessProfilesList();

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        <Row>
          <Col span={24}>
            <Table
              {...getAccessProfilesDataProps()}
              {...getAccessProfilesTableProps()}
              {...getAccessProfilesCrudProps()}
            />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};

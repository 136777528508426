/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PACSSettingsFormValues } from '../../../models/PACSSettingFormValues';

// Models
import { SignalRAction } from '../../../models/SignalRAction';

// State
interface PACSServiceConfigurationsState {
  updating: boolean;
  error: boolean;
}

const initialState: PACSServiceConfigurationsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@PACS_SETTINGS';

// Redux Actions|Reducers
const pacsServiceConfigurationsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignPACSServiceConfiguration: (state, action: PayloadAction<PACSSettingsFormValues>) => {
      state.updating = true;
      state.error = false;
    },
    pacsServiceConfigurationAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    pacsServiceConfigurationNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    // Advanced Settings
    updatePACSAdvancedSettings: (state, action: PayloadAction<PACSSettingsFormValues>) => {
      state.updating = true;
      state.error = false;
    },
    pacsAdvancedSettingsUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    pacsAdvancedSettingsNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignPACSServiceConfiguration,
  pacsServiceConfigurationAssigned,
  pacsServiceConfigurationNotAssigned,
  updatePACSAdvancedSettings,
  pacsAdvancedSettingsUpdated,
  pacsAdvancedSettingsNotUpdated,
} = pacsServiceConfigurationsSlice.actions;

// Export Reducer
export default pacsServiceConfigurationsSlice.reducer;

import React from 'react';
import { Route } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Login } from '../components/UI/ApiAuthorization/Login';
import { Logout } from '../components/UI/ApiAuthorization/Logout';

import { ApplicationPaths, LoginActions, LogoutActions } from '../constants/Settings/AuthSettings';

export const getAuthorizationRoutes = () => (
  <Route path={ApplicationPaths.AuthorizationPrefix} key={uuid()}>
    <Route path={ApplicationPaths.Login} element={<Login action={LoginActions.Login} />} />
    <Route path={ApplicationPaths.LoginFailed} element={<Login action={LoginActions.LoginFailed} />} />
    <Route path={ApplicationPaths.LoginCallback} element={<Login action={LoginActions.LoginCallback} />} />
    <Route path={ApplicationPaths.Profile} element={<Login action={LoginActions.Profile} />} />
    <Route path={ApplicationPaths.Register} element={<Login action={LoginActions.Register} />} />
    <Route path={ApplicationPaths.LogOut} element={<Logout action={LogoutActions.Logout} />} />
    <Route path={ApplicationPaths.LogOutCallback} element={<Logout action={LogoutActions.LogoutCallback} />} />
  </Route>
);

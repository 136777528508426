import React from 'react';
import styled from 'styled-components';

// Styled
const Icon = ({ ...rest }) => <i {...rest} />;
export const DashboardIcon = styled(Icon)`
  display: inline-block;
  color: inherit;
  font-style: normal;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
`;

import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { useIdentityUsersRedux } from './useIdentityUsersRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Props
interface IdentityUserDetailProps {
  id?: string;
}

// Hook
export const useIdentityUserDetail = <T extends object>({ id }: IdentityUserDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Data
  const { data: identityUserResponse, fetch, pending } = useData(ApiEndpoints.identityUsers.detail, null);
  const prevPending = usePrevious(pending);

  // Redux
  const { formOptions, updating, error, showResendInvitationConfirm } = useIdentityUsersRedux<T>({
    isEditing: true,
    initialValues: identityUserResponse?.Identity as Store,
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.identityUsers.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      identityUser: identityUserResponse?.Identity ?? null,
      pending,
      setOpen: getFormDrawerProps().setOpen,
    }),
    [identityUserResponse, pending, getFormDrawerProps]
  );
  const getContactCardProps = useCallback(
    () => ({
      identityUser: identityUserResponse?.Identity ?? null,
      pending,
      showResendInvitationConfirm,
    }),
    [identityUserResponse, pending, showResendInvitationConfirm]
  );
  const getIdentityUsersProps = useCallback(
    () => ({
      identityUserResponse,
      fetchIdentityUser: fetch,
      updatingIdentityUser: pending,
    }),
    [identityUserResponse, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && pending === false && !identityUserResponse) {
      notificationService.showError('identityUsers.notFound');
      navigate('/IdentityUsers');
    }
  }, [prevPending, pending, identityUserResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (prevUpdating === true && updating === false && !error) {
      fetch({ id });
    }
  }, [prevUpdating, updating, error, fetch, id]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getIdentityUsersProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getIdentityUsersProps,
    ]
  );
};

import React from 'react';
import styled from 'styled-components';
import { Col, Skeleton } from 'antd';

// Components
import { Translated } from '../UI/Core';
import { Editor } from './Editor';

// Styled
const StyledContainer = styled('div')`
  margin-bottom: 8px;
`;

// Props
interface KioskMessageEditorProps {
  pending: boolean;
  labelId: string;
  valueText: string;
  onChange: (event: any) => void;
}

// Component
export const KioskMessageEditor = ({ pending, labelId, valueText, onChange }: KioskMessageEditorProps) => {
  return (
    <Col span={24}>
      <StyledContainer className="column-layout" style={{ marginBottom: '20px' }}>
        <h3 style={{ color: '#57b0d0' }}>
          <Translated id={labelId} />
        </h3>
        {pending ? <Skeleton /> : <Editor value={valueText} onChange={onChange} />}
      </StyledContainer>
    </Col>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Collapse, Divider, Input, Popconfirm, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { KioskAction, KioskActionText, KioskIconOptions } from '../../models/Kiosk';
import { Action } from '../../models/Action';
import { Translated } from '../UI/Core';

const { Panel } = Collapse;
const { Option } = Select;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    background-color: #f5f5f5;
  }

  .ant-collapse-item-active .ant-collapse-header {
    background-color: #57b0d0;
    color: white;
  }
`;

interface ExpandableListItemProps {
  action: KioskAction;
  onActionChange: (updatedAction: KioskAction) => void;
  actionData: Action[] | null;
  onDeleteAction: (order: number) => void;
  selectedLanguage: string;
  selectedFormLanguage: string;
}

export const ExpandableListItem: React.FC<ExpandableListItemProps> = ({
  action,
  onActionChange,
  actionData,
  onDeleteAction,
  selectedLanguage,
  selectedFormLanguage,
}) => {
  const [allowSkip, setAllowSkip] = useState(action.AllowSkip);
  const [icon, setIcon] = useState(action.Icon);
  const [actionId, setActionId] = useState(action.ActionId);
  const [text, setText] = useState<KioskActionText>(
    action?.Texts.find((x) => x.Language === selectedFormLanguage) ?? {
      Language: selectedLanguage,
      Title: '',
      Subtext: '',
      ButtonText: '',
      LoadingText: '',
      CompletionText: '',
    }
  );

  // Effects
  useEffect(() => {
    if (selectedLanguage === selectedFormLanguage) return;
    const ret = action.Texts.find((x) => x.Language === selectedFormLanguage);
    if (!ret) {
      setText({
        Language: selectedFormLanguage,
        Title: '',
        Subtext: '',
        ButtonText: '',
        LoadingText: '',
        CompletionText: '',
      });
      return;
    }
    setText(ret);
  }, [selectedFormLanguage, selectedLanguage, action.Texts]);

  // Update AllowSkip
  const handleAllowSkipChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const newAllowSkip = e.target.checked;
      setAllowSkip(newAllowSkip);
      onActionChange({ ...action, AllowSkip: newAllowSkip, Texts: [text], ActionId: actionId, Icon: icon });
    },
    [action, actionId, icon, onActionChange, text]
  );

  // Update Text Fields
  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, field: keyof KioskActionText) => {
      const newText = { ...text, [field]: e.target.value };
      const newTexts = action.Texts.filter((x) => x.Language !== selectedLanguage);
      newTexts.push(newText);
      setText(newText);
      onActionChange({ ...action, Texts: newTexts, ActionId: actionId, Icon: icon, AllowSkip: allowSkip });
    },
    [text, onActionChange, action, actionId, icon, allowSkip, selectedLanguage]
  );

  // Update Icon
  const handleIconChange = useCallback(
    (value: number) => {
      setIcon(value);
      onActionChange({ ...action, Icon: value, Texts: [text], ActionId: actionId, AllowSkip: allowSkip });
    },
    [action, actionId, allowSkip, onActionChange, text]
  );

  // Update ActionId
  const handleActionChange = useCallback(
    (value: string) => {
      setActionId(value);
      onActionChange({ ...action, ActionId: value, Icon: icon, Texts: [text], AllowSkip: allowSkip });
    },
    [action, allowSkip, icon, onActionChange, text]
  );

  const handleDelete = useCallback(() => {
    onDeleteAction(action.Order);
  }, [action.Order, onDeleteAction]);

  return (
    <>
      <StyledCollapse key={action.Order}>
        <Panel
          header={
            <>
              {action?.Texts[0]?.Title || `Action ${action.Order + 1}`}
              <Popconfirm
                title="Are you sure you want to delete this action?"
                onConfirm={(e: any) => {
                  e.stopPropagation(); // Prevent Collapse panel expansion
                  handleDelete();
                }}
                okText="Yes"
                cancelText="No"
                onCancel={(e: any) => {
                  e.stopPropagation(); // Prevent Collapse panel expansion
                }}
              >
                <Button
                  type="link"
                  danger
                  style={{
                    float: 'right',
                    backgroundColor: '#f9e6e6',
                    color: 'red',
                    borderRadius: '4px',
                    border: 'none',
                    transition: 'background-color 0.3s, color 0.3s',
                    margin: '-8px',
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation(); // Prevent Collapse panel expansion
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          }
          key={action.Order}
        >
          <div>
            <Checkbox checked={allowSkip} onChange={handleAllowSkipChange}>
              Allow To Skip
            </Checkbox>
          </div>
          <div style={{ marginTop: '10px' }}>
            <div>
              <Translated id="kiosk.action.text.icon" />
            </div>
            <Select
              style={{ width: '100%' }}
              onChange={handleIconChange}
              value={icon}
              placeholder={<Translated id="kiosk.action.text.icon.placeholder" />}
            >
              {KioskIconOptions.map((icons) => (
                <Option key={icons.value} value={icons.value}>
                  {icons.label}
                </Option>
              ))}
            </Select>
          </div>

          <div style={{ marginTop: '10px' }}>
            <div>
              <Translated id="kiosk.action.text.action" />
            </div>
            <Select
              style={{ width: '100%' }}
              value={actionId}
              onChange={handleActionChange}
              placeholder={<Translated id="kiosk.action.text.action.placeholder" />}
            >
              {actionData?.map((a) => (
                <Option key={a.Id} value={a.Id}>
                  {a.DisplayLabel}
                </Option>
              ))}
            </Select>
          </div>

          <div style={{ marginTop: '10px' }}>
            <div>
              <Translated id="kiosk.action.text.title" />
            </div>
            <Input placeholder="Enter Title" value={text.Title} onChange={(e) => handleTextChange(e, 'Title')} />
          </div>

          <div style={{ marginTop: '10px' }}>
            <div>
              <Translated id="kiosk.action.text.subtext" />
            </div>
            <Input placeholder="Enter Subtext" value={text.Subtext} onChange={(e) => handleTextChange(e, 'Subtext')} />
          </div>

          <div style={{ marginTop: '10px' }}>
            <div>
              <Translated id="kiosk.action.text.buttonText" />
            </div>
            <Input
              placeholder="Enter Button Text"
              value={text.ButtonText}
              onChange={(e) => handleTextChange(e, 'ButtonText')}
            />
          </div>

          <div style={{ marginTop: '10px' }}>
            <div>
              <Translated id="kiosk.action.text.loadingText" />
            </div>
            <Input
              placeholder="Enter Loading Text"
              value={text.LoadingText}
              onChange={(e) => handleTextChange(e, 'LoadingText')}
            />
          </div>

          <div style={{ marginTop: '10px' }}>
            <div>
              <Translated id="kiosk.action.text.completionText" />
            </div>
            <Input
              placeholder="Enter Completion Text"
              value={text.CompletionText}
              onChange={(e) => handleTextChange(e, 'CompletionText')}
            />
          </div>
        </Panel>
      </StyledCollapse>
      <Divider />
    </>
  );
};

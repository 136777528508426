import React from 'react';
import { Col, Empty, Row, Skeleton, Tabs } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

// Models
import { ServiceConfigurationResponse } from '../../models/ServiceConfiguration';

// Hooks
import { useServiceConfigurationTenants } from '../../hooks/ServiceConfigurations/ServiceConfigurationTenants/useServiceConfigurationTenants';

// Components
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Translated } from '../UI/Core';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }

  & .ant-tabs-nav {
    height: 58px;
  }

  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
`;

// Props
interface ServiceConfigurationTenantsProps {
  serviceConfigurationResponse: ServiceConfigurationResponse | null;
  fetchServiceConfiguration: (params: any) => Promise<void>;
  updatingServiceConfiguration: boolean;
}

// Component
export const ServiceConfigurationTenants = ({
  serviceConfigurationResponse,
  fetchServiceConfiguration,
  updatingServiceConfiguration,
}: ServiceConfigurationTenantsProps) => {
  // Identifiers Hook
  const { loading, updating, serviceConfigurationTenants, ServiceConfigurationTenantsManagementForm } =
    useServiceConfigurationTenants({
      serviceConfigurationResponse,
      fetchServiceConfiguration,
      updatingServiceConfiguration,
    });

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderEmpty = () => [
    {
      key: '0',
      label: <Translated id="serviceConfigurations.Tenants.tab" />,
      children: (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="serviceConfigurations.Tenants.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = () => [
    {
      key: '0',
      label: <Translated id="serviceConfigurations.Tenants.tab" />,
      children: (
        <Spinner spinning={updating}>
          <ServiceConfigurationTenantsManagementForm />
        </Spinner>
      ),
    },
  ];

  const renderItems = () => {
    if (loading) return renderLoading();
    if (!serviceConfigurationTenants || isEmpty(serviceConfigurationTenants)) return renderEmpty();
    return renderForm();
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderItems()} />
    </StyledWidget>
  );
};

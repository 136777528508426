import React, { useCallback, useMemo } from 'react';
import { Button, Divider, Form, Modal } from 'antd';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import { ApiConfigurationSettingsFormValues } from '../../../models/ApiConfigurationSettingFormValues';
import { ApiConfiguration, ApiConfigurationResponse } from '../../../models/ApiConfiguration';
import { ApiConfigurationEnum } from '../../../models/enums/ApiConfigurationTypes';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';

// Actions
import { updateApiConfiguration } from '../../../store/ApiConfigurations/ApiConfigurations.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { useSaltoSpaceSettings } from './SaltoSpace/useSaltoSpaceSettings';
import { useSaltoKsSettings } from './SaltoKs/useSaltoKsSettings';

const { Item } = Form;
const { confirm } = Modal;

// Styled
const NoBottomMarginFormItem = styled(Item)`
  margin-bottom: 0;
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Props
interface SettingsReduxProps {
  apiConfigurationResponse: ApiConfigurationResponse | null;
}

interface FormSettingsProps {
  SettingsForm: () => React.JSX.Element;
  initialValues?: Store;
}

interface AssignConfirmProps {
  pacsSettingsFormValues: ApiConfigurationSettingsFormValues;
}

// Hook
export const useSettingsRedux = ({ apiConfigurationResponse }: SettingsReduxProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ pacsSettings }) => pacsSettings?.updating ?? false);
  const error = useAppSelector(({ pacsSettings }) => pacsSettings?.error ?? false);

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ pacsSettingsFormValues }: AssignConfirmProps) => {
      if (pacsSettingsFormValues) {
        confirm({
          title: intl.formatMessage({
            id: 'apiConfigurations.pacsSettings.confirm.sync',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'apiConfigurations.pacsSettings.confirm.syncSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => {
            if (apiConfigurationResponse?.ApiConfiguration !== undefined) {
              const configuration: ApiConfiguration = {
                ...apiConfigurationResponse?.ApiConfiguration,
              };
              configuration.SettingsData = pacsSettingsFormValues.SettingsData;
              return dispatch(updateApiConfiguration(configuration));
            }
            return undefined;
          },
          onCancel: () => null,
        });
      }
    },
    [dispatch, apiConfigurationResponse, intl]
  );

  // Hook Forms
  const { SaltoSpaceSettingsForm, initialValues: saltoSpaceInitialValues } = useSaltoSpaceSettings({
    apiConfigurationResponse,
  });

  const { SaltoKsSettingsForm, initialValues: saltoKsInitialValues } = useSaltoKsSettings({
    apiConfigurationResponse,
  });

  let settings: FormSettingsProps;
  switch (apiConfigurationResponse?.ApiConfiguration.ApiConfigurationType) {
    case ApiConfigurationEnum.SaltoSpace:
      settings = {
        SettingsForm: SaltoSpaceSettingsForm,
        initialValues: saltoSpaceInitialValues,
      };
      break;
    case ApiConfigurationEnum.SaltoKs:
      settings = {
        SettingsForm: SaltoKsSettingsForm,
        initialValues: saltoKsInitialValues,
      };
      break;
    default:
      settings = {
        SettingsForm: () => <div />,
        initialValues: {},
      };
      break;
  }
  const { initialValues, SettingsForm } = settings;

  // Components
  const SettingManagementForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values: ApiConfigurationSettingsFormValues) =>
          apiConfigurationResponse &&
          showAssignConfirm({
            pacsSettingsFormValues: {
              ...values,
              SettingsData: JSON.stringify(values.SettingsData),
            },
          })
        }
      >
        <SettingsForm />

        <Divider />

        {/* Action Buttons */}
        <NoBottomMarginFormItem>
          <StyledButton type="primary" htmlType="submit">
            <Translated id="form.confirmButton" />
          </StyledButton>
        </NoBottomMarginFormItem>
      </Form>
    ),
    [apiConfigurationResponse, showAssignConfirm, initialValues, SettingsForm]
  );

  return useMemo(
    () => ({
      SettingManagementForm,
      updating,
      error,
    }),
    [SettingManagementForm, updating, error]
  );
};

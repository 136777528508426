import React, { ChangeEvent } from 'react';
import { TableToggleCommonProps, UseRowSelectInstanceProps, UseRowSelectRowProps } from 'react-table';
import { Checkbox } from 'antd';

// Constants
export const SELECTION_COLUMN = 'selection';

// Components
const TableCheckbox = ({ checked, indeterminate, onChange, ...rest }: TableToggleCommonProps) => (
  <Checkbox
    checked={checked}
    indeterminate={indeterminate}
    onClick={(event) => {
      event.stopPropagation();
    }}
    onChange={(e) => onChange && onChange(e as unknown as ChangeEvent)}
    {...rest}
  />
);

// Render
export const getSelectionColumn = <T extends object>() => ({
  id: SELECTION_COLUMN,
  Header: ({ getToggleAllPageRowsSelectedProps }: React.PropsWithChildren<UseRowSelectInstanceProps<T>>) => (
    <TableCheckbox {...getToggleAllPageRowsSelectedProps()} />
  ),
  Cell: ({ row }: { row: UseRowSelectRowProps<T> }) => <TableCheckbox {...row.getToggleRowSelectedProps()} />,
});

import React from 'react';

import { TableColumn } from '../../types/Table';
import { Translated } from '../../components/UI/Core';
import { AzureTenant } from '../../models/AzureTenant';
import { TranslatedTag } from '../../components/UI/Tag/TranslatedTag';

export const azureTenantTableColumns: Array<TableColumn<AzureTenant>> = [
  {
    Header: <Translated id="azureTenants.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="azureTenants.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="azureTenants.azureTenantId" />,
    id: 'AzureTenantId',
    accessor: 'AzureTenantId',
  },
  {
    Header: <Translated id="azureTenants.table.useSso" />,
    accessor: (row) =>
      row.UseSso ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    id: 'UseSso',
  },
];

import React from 'react';
import { Avatar, Menu, MenuProps, Popover } from 'antd';
import styled from 'styled-components';

// Hooks
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/App/useAuth';

// Constants
import { isMobile } from '../../../constants/Settings/ThemeSettings';
import { BasicIcon } from '../../UI/Icon/BasicIcon';

// Components
import { Flex, Text } from '../../UI/Base';
import { Translated } from '../../UI/Core';
import { logoutUrl, profileUrl } from '../../../constants/Utils/Urls';
import { useAppSelector } from '../../../hooks/App/useRedux';

// Styled
const StyledMenu = styled(Menu)`
  & .ant-menu-submenu {
    & > .ant-menu.ant-menu-sub {
      .ant-menu-item {
        padding-left: 40px !important;
      }
    }
  }
`;

export const UserMenu = () => {
  // Redux
  const { width } = useAppSelector(({ appSettings }) => appSettings);

  // Auth Hook
  const {
    authState: { user },
  } = useAuth();

  const navigate = useNavigate();

  const items: MenuProps['items'] = [
    {
      key: 'Profile',
      label: <Translated id="appMenu.account" />,
      icon: <BasicIcon className="icon icon-avatar" style={{ marginRight: 8 }} />,
      onClick: () => navigate(profileUrl),
    },

    {
      key: 'Logout',
      label: <Translated id="appMenu.logout" />,
      icon: <BasicIcon className="icon icon-signin" style={{ marginRight: 8 }} />,
      onClick: () => navigate(logoutUrl),
    },
  ];

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <StyledMenu mode="inline" defaultSelectedKeys={[]} items={items} />
    </ul>
  );

  return (
    <li className="gx-user-nav">
      <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Flex alignItems="center">
          {!isMobile(width) && (
            <Text marginRight={8} fontSize={16} color="white">
              {user?.profile?.name}
            </Text>
          )}
          <Avatar
            src="https://as2.ftcdn.net/jpg/02/60/78/83/500_F_260788352_x5sSHM4DGvpjHj9wz8sFltzAPktQwJCj.jpg"
            className="gx-avatar gx-size-30 gx-pointer"
            alt=""
          />
        </Flex>
      </Popover>
    </li>
  );
};

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { ApiConfiguration } from '../../../models/ApiConfiguration';
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  apiConfigurationTenantsAssigned,
  apiConfigurationTenantsNotAssigned,
  assignApiConfigurationTenants,
} from './ApiConfigurationTenants.redux';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignApiConfigurationTenantsSaga() {
  yield takeEvery(assignApiConfigurationTenants.type, assignApiConfigurationTenantsRequest);
}

function* apiConfigurationTenantsAssignedSaga() {
  yield takeLatest(apiConfigurationTenantsAssigned.type, assignApiConfigurationTenantsResponse);
}

function* apiConfigurationTenantsNotAssignedSaga() {
  yield takeLatest(apiConfigurationTenantsNotAssigned.type, assignApiConfigurationTenantsError);
}

// Request
function* assignApiConfigurationTenantsRequest(
  action: PayloadAction<{ apiConfiguration: ApiConfiguration; tenantIds: Array<string> }>
) {
  try {
    const {
      payload: { apiConfiguration, tenantIds },
    } = action;
    yield apiService.execute({
      url: `ApiConfigurations/${apiConfiguration.Id}/Tenants`,
      method: ApiRequestType.PUT,
      data: {
        ApiConfigurationId: apiConfiguration.Id,
        TenantIds: tenantIds,
      },
    });
  } catch ({ message }) {
    yield put({ type: apiConfigurationTenantsNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignApiConfigurationTenantsResponse() {
  notificationService.showSuccess('apiConfigurations.Tenants.notifications.assign');
}

// Error
function assignApiConfigurationTenantsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'apiConfigurations.Tenants.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************

// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignApiConfigurationTenantsSaga(),
    apiConfigurationTenantsAssignedSaga(),
    apiConfigurationTenantsNotAssignedSaga(),
  ]);
}

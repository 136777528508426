import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { text, TextProps } from './Text';

export const TextLink = styled(Link)<LinkProps & TextProps>`
  display: block;
  color: inherit;
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
  ${text};
`;

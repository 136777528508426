/* eslint-disable react/jsx-key */

import React from 'react';
import styled from 'styled-components';
import { Empty } from 'antd';

// Props
import { isEmpty } from 'lodash';
import { TableBodyProps } from '../../../../hooks/Table/useTableProps';

// Components
import { Text } from '../../Base';
import { TableRow } from './TableRow';
import { TableData, TableDataProps } from './TableData';
import { TableSkeleton } from './TableSkeleton';

// Styled
const TableBodyWrapper = styled.tbody``;

// Render
export const TableBody = <T extends object>({
  rows,
  pending,
  columnCount,
  showAllColumns,
  onRowClick,
  getTableBodyProps,
  prepareRow,
}: React.PropsWithChildren<TableBodyProps<T>>) => {
  const renderContent = () => {
    if (pending) {
      // Loading
      return (
        <TableRow pending={pending}>
          <TableData className="no-border">
            <TableSkeleton columnCount={columnCount} />
          </TableData>
        </TableRow>
      );
    }

    if (isEmpty(rows)) {
      // Empty Result
      return (
        <TableRow noHover>
          <TableData className="no-border">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </TableData>
        </TableRow>
      );
    }

    return rows.map((row) => {
      prepareRow(row);
      const cells = showAllColumns ? row.allCells : row.cells;
      return (
        <TableRow {...onRowClick(row.values.Id)} {...row.getRowProps()}>
          {cells.map((cell) => (
            <TableData<React.FC<TableDataProps<T>>> {...cell.getCellProps()} column={cell.column}>
              <Text ellipsis noWrap>
                {cell.render('Cell')}
              </Text>
            </TableData>
          ))}
        </TableRow>
      );
    });
  };

  return <TableBodyWrapper {...getTableBodyProps()}>{renderContent()}</TableBodyWrapper>;
};

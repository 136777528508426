import React from 'react';

import Aux from '../../../constants/Utils/Auxiliary';
import { BillingStatsContent } from '../../../models/Dashboard/Content/BillingStatsContent';

const MAX_BILLING_VALUE = 15;

export const LineIndicator = ({ title, width, value, color }: BillingStatsContent) => {
  const widthValue = Number.parseInt(width, 10);
  const overLimit = widthValue > MAX_BILLING_VALUE;
  const textStyle = {
    fontSize: '0.95em',
    color: overLimit ? 'red' : 'unset',
    fontWeight: overLimit ? 'bold' : 'unset',
  };

  return (
    <Aux>
      <p style={textStyle}>{title}</p>
      <div className="gx-line-indi-info gx-mt-1">
        <div
          className={`gx-line-indi gx-bg-${overLimit ? 'red' : color}`}
          style={{
            maxWidth: `calc(100% - 50px)`,
            width: `${100 * (widthValue / MAX_BILLING_VALUE)}%`,
          }}
        />

        <span
          className="gx-line-indi-count gx-ml-2 ml-auto"
          style={textStyle}
        >{`${value} / ${MAX_BILLING_VALUE}`}</span>
      </div>
    </Aux>
  );
};

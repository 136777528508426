import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { SignalRAction, SignalRCrudAction } from '../../models/SignalRAction';

// Redux
import {
  actionCreated,
  actionDeleted,
  actionNotCreated,
  actionNotDeleted,
  actionNotUpdated,
  actionUpdated,
  assignActionTenants,
  createAction,
  deleteAction,
  deleteActions,
  updateAction,
} from './Actions.redux';
import { Action } from '../../models/Action';
import { Tenant } from '../../models/Tenant';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createActionSaga() {
  yield takeEvery(createAction.type, createActionRequest);
}

function* actionCreatedSaga() {
  yield takeLatest(actionCreated.type, createActionResponse);
}

function* actionNotCreatedSaga() {
  yield takeLatest(actionNotCreated.type, createActionError);
}

// Request
function* createActionRequest(action: PayloadAction<Action>) {
  try {
    const { payload: ActionData } = action;
    yield apiService.execute({
      url: 'Actions',
      method: ApiRequestType.POST,
      data: ActionData,
    });
  } catch ({ message }) {
    yield put({ type: actionNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createActionResponse(action: PayloadAction<SignalRCrudAction>) {
  notificationService.showSuccess('actions.notifications.create');
  action.payload.history.push(`/Actions/${action.payload.msg.affectedEntities[0]}`);
}

// Error
function createActionError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('actions.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateActionsaga() {
  yield takeEvery(updateAction.type, updateActionRequest);
}

function* actionUpdatedSaga() {
  yield takeLatest(actionUpdated.type, updateActionResponse);
}

function* actionNotUpdatedSaga() {
  yield takeLatest(actionNotUpdated.type, updateActionError);
}

// Request
function* updateActionRequest(action: PayloadAction<Action>) {
  try {
    const { payload: ActionData } = action;
    yield apiService.execute({
      url: `Actions/${ActionData.Id}`,
      method: ApiRequestType.PUT,
      data: ActionData,
    });
  } catch ({ message }) {
    yield put({ type: actionNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateActionResponse() {
  notificationService.showSuccess('actions.notifications.update');
}

// Error
function updateActionError(action: PayloadAction<SignalRCrudAction>) {
  notificationService.showErrorWithContent('actions.notifications.updateFailed', action?.payload?.msg.message ?? '');
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteActionsaga() {
  yield takeEvery(deleteAction.type, deleteActionRequest);
}

function* actionDeletedSaga() {
  yield takeLatest(actionDeleted.type, deleteActionResponse);
}

function* actionNotDeletedSaga() {
  yield takeLatest(actionNotDeleted.type, deleteActionError);
}

// Request
function* deleteActionRequest(action: PayloadAction<Action>) {
  try {
    const { payload: ActionData } = action;
    yield apiService.execute({
      url: `Actions/${ActionData.Id}`,
      method: ApiRequestType.DELETE,
      data: ActionData,
    });
  } catch ({ message }) {
    yield put({ type: actionNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteActionResponse(action: PayloadAction<SignalRCrudAction>) {
  if (action.payload.msg.affectedEntities.length > 1) {
    notificationService.showSuccess('actions.notifications.deleteAll');
  } else {
    notificationService.showSuccess('actions.notifications.delete');
    action.payload.history.push(`/Actions`);
  }
}

// Error
function deleteActionError(action: PayloadAction<SignalRAction>) {
  if (action.payload.msg.affectedEntities.length > 1) {
    notificationService.showErrorWithContent('actions.notifications.deleteAllFailed', action?.payload?.msg.message);
  } else {
    notificationService.showErrorWithContent('actions.notifications.deleteFailed', action?.payload?.msg.message);
  }
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteActionsSaga() {
  yield takeEvery(deleteActions.type, deleteActionsRequest);
}

// Request
function* deleteActionsRequest(action: PayloadAction<Array<Action>>) {
  try {
    const { payload: ActionData } = action;
    yield apiService.execute({
      url: `Actions`,
      method: ApiRequestType.DELETE,
      data: { ids: ActionData.map((actionData) => actionData.Id) },
    });
  } catch ({ message }) {
    yield put({ type: actionNotDeleted.type, payload: { msg: { message } } });
  }
}

// Assign tenants
function* assignActionTenantsSaga() {
  yield takeEvery(assignActionTenants.type, assignActionTenantsRequest);
}

function* assignActionTenantsRequest(actionsData: PayloadAction<{ action: Action; tenants: Array<Tenant> }>) {
  try {
    const {
      payload: { action, tenants },
    } = actionsData;
    yield apiService.execute({
      url: `Actions/${action.Id}/Tenants`,
      method: ApiRequestType.PUT,
      data: {
        ActionId: action.Id,
        Tenants: tenants,
      },
    });
  } catch ({ message }) {
    yield put({ type: actionNotUpdated.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* EXPORT SAGAS ***************
export default function* sagas() {
  yield all([
    // Create
    createActionSaga(),
    actionCreatedSaga(),
    actionNotCreatedSaga(),
    // Update
    updateActionsaga(),
    actionUpdatedSaga(),
    actionNotUpdatedSaga(),
    // Delete
    deleteActionsaga(),
    actionDeletedSaga(),
    actionNotDeletedSaga(),
    // Delete All
    deleteActionsSaga(),
    // Assign action tenants
    assignActionTenantsSaga(),
  ]);
}

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { AccessProfile } from '../../models/AccessProfile';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  accessProfileCreated,
  accessProfileDeleted,
  accessProfileNotCreated,
  accessProfileNotDeleted,
  accessProfileNotUpdated,
  accessProfilesDeleted,
  accessProfileSettingsNotUpdated,
  accessProfileSettingsUpdated,
  accessProfilesNotDeleted,
  accessProfileUpdated,
  createAccessProfile,
  deleteAccessProfile,
  deleteAccessProfiles,
  updateAccessProfile,
  updateAccessProfileSettings,
} from './AccessProfiles.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createAccessProfileSaga() {
  yield takeEvery(createAccessProfile.type, createAccessProfileRequest);
}

function* accessProfileCreatedSaga() {
  yield takeLatest(accessProfileCreated.type, createAccessProfileResponse);
}

function* accessProfileNotCreatedSaga() {
  yield takeLatest(accessProfileNotCreated.type, createAccessProfileError);
}

// Request
function* createAccessProfileRequest(action: PayloadAction<AccessProfile>) {
  try {
    const { payload: accessProfile } = action;
    yield apiService.execute({
      url: 'AccessProfiles',
      method: ApiRequestType.POST,
      data: accessProfile,
    });
  } catch ({ message }) {
    yield put({ type: accessProfileNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createAccessProfileResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('accessProfiles.notifications.create');
  action.payload.history.push(`/AccessProfiles/${action.payload.msg.entityId}`);
}

// Error
function createAccessProfileError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('accessProfiles.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateAccessProfileSaga() {
  yield takeEvery(updateAccessProfile.type, updateAccessProfileRequest);
}

function* accessProfileUpdatedSaga() {
  yield takeLatest(accessProfileUpdated.type, updateAccessProfileResponse);
}

function* accessProfileNotUpdatedSaga() {
  yield takeLatest(accessProfileNotUpdated.type, updateAccessProfileError);
}

// Request
function* updateAccessProfileRequest(action: PayloadAction<AccessProfile>) {
  try {
    const { payload: accessProfile } = action;
    yield apiService.execute({
      url: `AccessProfiles/${accessProfile.Id}`,
      method: ApiRequestType.PUT,
      data: accessProfile,
    });
  } catch ({ message }) {
    yield put({ type: accessProfileNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateAccessProfileResponse() {
  notificationService.showSuccess('accessProfiles.notifications.update');
}

// Error
function updateAccessProfileError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('accessProfiles.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteAccessProfileSaga() {
  yield takeEvery(deleteAccessProfile.type, deleteAccessProfileRequest);
}

function* accessProfileDeletedSaga() {
  yield takeLatest(accessProfileDeleted.type, deleteAccessProfileResponse);
}

function* accessProfileNotDeletedSaga() {
  yield takeLatest(accessProfileNotDeleted.type, deleteAccessProfileError);
}

// Request
function* deleteAccessProfileRequest(action: PayloadAction<AccessProfile>) {
  try {
    const { payload: accessProfile } = action;
    yield apiService.execute({
      url: `AccessProfiles/${accessProfile.Id}`,
      method: ApiRequestType.DELETE,
      data: accessProfile,
    });
  } catch ({ message }) {
    yield put({ type: accessProfileNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteAccessProfileResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('accessProfiles.notifications.delete');
  action.payload.history.push(`/AccessProfiles`);
}

// Error
function deleteAccessProfileError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('accessProfiles.notifications.deleteFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteAccessProfilesSaga() {
  yield takeEvery(deleteAccessProfiles.type, deleteAccessProfilesRequest);
}

function* accessProfilesDeletedSaga() {
  yield takeLatest(accessProfilesDeleted.type, deleteAccessProfilesResponse);
}

function* accessProfilesNotDeletedSaga() {
  yield takeLatest(accessProfilesNotDeleted.type, deleteAccessProfilesError);
}

// Request
function* deleteAccessProfilesRequest(action: PayloadAction<Array<AccessProfile>>) {
  try {
    const { payload: accessProfiles } = action;
    yield apiService.execute({
      url: `AccessProfiles`,
      method: ApiRequestType.DELETE,
      data: { ids: accessProfiles.map((accessProfile) => accessProfile.Id) },
    });
  } catch ({ message }) {
    yield put({ type: accessProfilesNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteAccessProfilesResponse() {
  notificationService.showSuccess('accessProfiles.notifications.deleteAll');
}

// Error
function deleteAccessProfilesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'accessProfiles.notifications.deleteAllFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* UPDATE SETTINGS *********************

// Worker Sagas
function* updateAccessProfileSettingsSaga() {
  yield takeEvery(updateAccessProfileSettings.type, updateAccessProfileSettingsRequest);
}

function* accessProfileSettingsUpdatedSaga() {
  yield takeLatest(accessProfileSettingsUpdated.type, updateAccessProfileSettingsResponse);
}

function* accessProfileSettingsNotUpdatedSaga() {
  yield takeLatest(accessProfileSettingsNotUpdated.type, updateAccessProfileSettingsError);
}

// Request
function* updateAccessProfileSettingsRequest(action: PayloadAction<AccessProfile>) {
  try {
    const { payload: accessProfile } = action;
    yield apiService.execute({
      url: `AccessProfiles/${accessProfile.Id}/Settings`,
      method: ApiRequestType.PUT,
      data: {
        Settings: accessProfile,
      },
    });
  } catch ({ message }) {
    yield put({ type: accessProfileSettingsNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateAccessProfileSettingsResponse() {
  notificationService.showSuccess('accessProfiles.notifications.update');
}

// Error
function updateAccessProfileSettingsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('accessProfiles.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createAccessProfileSaga(),
    accessProfileCreatedSaga(),
    accessProfileNotCreatedSaga(),
    // Update
    updateAccessProfileSaga(),
    accessProfileUpdatedSaga(),
    accessProfileNotUpdatedSaga(),
    // Delete
    deleteAccessProfileSaga(),
    accessProfileDeletedSaga(),
    accessProfileNotDeletedSaga(),
    // Delete All
    deleteAccessProfilesSaga(),
    accessProfilesDeletedSaga(),
    accessProfilesNotDeletedSaga(),
    // Update Settings
    updateAccessProfileSettingsSaga(),
    accessProfileSettingsUpdatedSaga(),
    accessProfileSettingsNotUpdatedSaga(),
  ]);
}

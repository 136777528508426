import React from 'react';
import { Button, Collapse, List, Tooltip } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Models
import { EnvironmentService } from '../../../models/Dashboard/ApiResponses/EnvironmentStatsResponse';

// Components
import { Translated } from '../Core';
import { testServiceConfiguration } from '../../../store/ServiceConfigurations/ServiceConfigurations.redux';
import { useAppDispatch, useAppSelector } from '../../../hooks/App/useRedux';
import { Flex } from '../Base';
import { ServiceStatusTag } from '../Tag/ServiceStatusTag';
import { getServiceStatus } from '../../../constants/Utils/StatusFunctions';

// Components
const { Panel } = Collapse;

// Styled
const StyledFlex = styled(Flex)`
  margin-right: 16px;
`;
const StyledPanel = styled(Panel)`
  & .ant-collapse-content .ant-list-empty-text .ant-empty {
    margin: 8px 0 0px;
  }
`;

// Props
interface EnvironmentCollapseProps {
  header: React.ReactNode;
  content: Array<EnvironmentService>;
  showArrow?: boolean;
}

// Component
export const EnvironmentCollapse = ({ header, content, showArrow }: EnvironmentCollapseProps) => {
  const dispatch = useAppDispatch();
  const { updatingEntityId } = useAppSelector(({ serviceConfigurations }) => serviceConfigurations);

  // Rendering
  const renderTestButton = (item: EnvironmentService) => {
    const loading = updatingEntityId === item.Id;

    return (
      <Tooltip key={item.Id} title={<Translated id="serviceConfigurations.status.check" />}>
        <Button
          loading={loading}
          disabled={!!updatingEntityId}
          type="primary"
          ghost
          shape="circle"
          style={{ marginBottom: 0 }}
          size="small"
          onClick={() => item && dispatch(testServiceConfiguration({ id: item.Id }))}
        >
          {!loading && <ApiOutlined />}
        </Button>
      </Tooltip>
    );
  };

  const renderAvatar = (item: EnvironmentService) => {
    const { Color, Icon } = getServiceStatus(item);
    return <Icon className={`gx-text-${Color} gx-fs-xl`} />;
  };

  const renderTitle = (item: EnvironmentService) => (
    <Link to={`/ServiceConfigurations/${item.Id}`} style={{ fontWeight: 600 }}>
      {item.Name}
    </Link>
  );

  const renderItem = (item: EnvironmentService) => (
    <List.Item actions={[renderTestButton(item)]}>
      <List.Item.Meta
        avatar={renderAvatar(item)}
        title={renderTitle(item)}
        description={<ServiceStatusTag service={item} />}
      />
    </List.Item>
  );

  return (
    <Collapse ghost expandIconPosition="end">
      <StyledPanel
        key="1"
        header={header}
        extra={<StyledFlex alignItems="center">{content.length}</StyledFlex>}
        showArrow={showArrow}
        className="environment-collapse-panel"
      >
        <List itemLayout="horizontal" dataSource={content} renderItem={renderItem} />
      </StyledPanel>
    </Collapse>
  );
};

EnvironmentCollapse.defaultProps = {
  showArrow: false,
};

/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// State
interface AccountState {
  userRoles: string[];
}

const initialState: AccountState = {
  userRoles: [],
};

// Name
const storeName = '@ACCOUNT';

// Redux Actions|Reducers
const accountSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setUserRoles: (state, action: PayloadAction<string[]>) => {
      state.userRoles = action.payload;
    },
  },
});

// Export Actions
export const { setUserRoles } = accountSlice.actions;

// Export Reducer
export default accountSlice.reducer;

import React, { useCallback, useMemo } from 'react';

// Hooks
import { useAzureTenantsRedux } from './useAzureTenantsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { azureTenantTableColumns } from '../../data/Table/AzureTenantTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

export const useAzureTenantsList = <T extends object>() => {
  // Data
  const { data: azureTenants, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.azureTenants.list, null);
  const data = useMemo(() => azureTenants, [azureTenants]);

  // Redux
  const { formOptions, updating, error, showDeleteAllConfirm } = useAzureTenantsRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.azureTenants.list }), []);
  const getAzureTenantsDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.AzureTenants.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-auth-screen" style={{ marginRight: 8 }} />
          <Translated id="appMenu.azureTenants" />
        </>
      ),
      data: data ?? [],
      columns: getTableColumns(azureTenantTableColumns, LocalStorageKeys.AzureTenants.table.hiddenColumns),
      fetchData: fetch,
    }),
    [fetch, data]
  );
  const getAzureTenantsTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getAzureTenantsCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getAzureTenantsDataProps,
      getAzureTenantsTableProps,
      getAzureTenantsCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getAzureTenantsDataProps,
      getAzureTenantsTableProps,
      getFormDrawerProps,
      getAzureTenantsCrudProps,
    ]
  );
};

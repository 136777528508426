import React from 'react';
import { Col, Select, Tag } from 'antd';
import { useIntl } from 'react-intl';
import { DefaultOptionType } from 'antd/lib/select';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { orderBy } from 'lodash';

import { Translated } from '../Core';
import { ServiceStatusInfo } from '../../../models/enums/ServiceStatus';
import { SelectFilterType } from '../../../models/Filters';

const tagRender = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const color = ServiceStatusInfo.find((x) => x.ServiceStatus === value)?.Color;

  return (
    <Tag
      color={color}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};

interface SystemStatusFilterProps {
  labelId: string;
  placeholderId?: string;
  defaultValue: SelectFilterType;
  handleChange?: ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
}

export const SystemStatusFilter = ({ labelId, placeholderId, defaultValue, handleChange }: SystemStatusFilterProps) => {
  const intl = useIntl();
  return (
    <Col xl={12} lg={24} md={12} sm={12} xs={24} id={`select-${labelId}`}>
      <h4 className="gx-timeline-info-day gx-mb-2">
        <Translated id={labelId} />
      </h4>
      <Select
        mode="multiple"
        showArrow
        allowClear
        style={{ width: '100%' }}
        className="gx-mb-2"
        optionFilterProp="label"
        getPopupContainer={() => document.getElementById(`select-${labelId}`) || document.body}
        placeholder={<Translated id={placeholderId} />}
        tagRender={tagRender}
        options={orderBy(ServiceStatusInfo, (c) => c.ServiceStatus, 'desc').map((c) => ({
          value: c.ServiceStatus,
          label: intl.formatMessage({ id: c.NameId }),
        }))}
        value={defaultValue}
        defaultValue={defaultValue}
        onChange={handleChange}
      />
    </Col>
  );
};

SystemStatusFilter.defaultProps = {
  placeholderId: 'app.ui.select.placeholder.multiple',
  handleChange: undefined,
};

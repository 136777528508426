import React, { useCallback, useMemo } from 'react';

// Hooks
import { useLocationsRedux } from './useLocationsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { locationTableColumns } from '../../data/Table/LocationTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Hook
export const useLocationsList = <T extends object>() => {
  // Data
  const { data: locations, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.locations.list, null);

  // Redux
  const {
    formOptions,
    updating,
    error,
    showDeleteAllConfirm,
    showEditForm: showLocationEditForm,
  } = useLocationsRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({
    formOptions,
    updating,
    error,
    onAdd: () => showLocationEditForm(),
  });

  // Callbacks
  const showEditForm = useCallback(
    ({ values }: any) => {
      showLocationEditForm(values);
      getFormDrawerProps().setOpen(true);
    },
    [getFormDrawerProps, showLocationEditForm]
  );

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.locations.list }), []);
  const getLocationsDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.Locations.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-location" style={{ marginRight: 8 }} />
          <Translated id="appMenu.locations" />
        </>
      ),
      data: locations ?? [],
      columns: getTableColumns(locationTableColumns, LocalStorageKeys.Locations.table.hiddenColumns),
      fetchData: fetch,
    }),
    [fetch, locations]
  );
  const getLocationsTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getLocationsCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
      showEditForm,
      onRowClick: (id: string) => showEditForm({ values: locations?.find((l) => l.Id === id) }),
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm, showEditForm, locations]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getLocationsDataProps,
      getLocationsTableProps,
      getLocationsCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getLocationsDataProps,
      getLocationsTableProps,
      getFormDrawerProps,
      getLocationsCrudProps,
    ]
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { Company } from '../../models/Company';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface CompaniesState {
  updating: boolean;
  error: boolean;
}

const initialState: CompaniesState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@COMPANIES';

// Redux Actions|Reducers
const companiesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createCompany: (state, action: PayloadAction<Company>) => {
      state.updating = true;
      state.error = false;
    },
    companyCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    companyNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateCompany: (state, action: PayloadAction<Company>) => {
      state.updating = true;
      state.error = false;
    },
    companyUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    companyNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteCompany: (state, action: PayloadAction<Company>) => {
      state.updating = true;
      state.error = false;
    },
    companyDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    companyNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteCompanies: (state, action: PayloadAction<Array<Company>>) => {
      state.updating = true;
      state.error = false;
    },
    companiesDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    companiesNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createCompany,
  companyCreated,
  companyNotCreated,
  // Update
  updateCompany,
  companyUpdated,
  companyNotUpdated,
  // Delete
  deleteCompany,
  companyDeleted,
  companyNotDeleted,
  // Delete All
  deleteCompanies,
  companiesDeleted,
  companiesNotDeleted,
} = companiesSlice.actions;

// Export Reducer
export default companiesSlice.reducer;

import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Props
interface MailtoProps {
  mailto: string;
  subject: string;
  label: string;
}

// Component
export const Mailto = ({ mailto, subject, label }: MailtoProps) => {
  return (
    <StyledButton
      size="small"
      onClick={(e) => {
        e.preventDefault();
        window.open(`mailto:${mailto}?subject=${subject}`);
      }}
    >
      {label}
    </StyledButton>
  );
};

import React from 'react';
import { Space } from 'antd';
import { Translated } from '../../UI/Core';
import { ManagementStatsContent } from '../../../models/Dashboard/Content/ManagementStatsContent';
import { ButtonSkeleton } from '../../UI/Skeleton/ButtonSkeleton';

interface ManagementStatsProps {
  content: Array<ManagementStatsContent> | undefined;
}

export const ManagementStats = ({ content }: ManagementStatsProps) => (
  <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-5">
    <div className="gx-wel-ema gx-pt-xl-2">
      <h1 className="gx-mb-3">
        <Translated id="dashboard.welcomeCard.welcome" />
      </h1>
      <p className="gx-fs-sm gx-text-uppercase">
        <Translated id="dashboard.welcomeCard.notificationMessage" />
      </p>
      <ul className="gx-list-group">
        {content?.map((item) => (
          <li key={item.id}>
            {item.icon}
            <span>
              {item.value} {item.label}
            </span>
          </li>
        )) ?? (
          <Space direction="vertical">
            <ButtonSkeleton width={160} height={14} />
            <ButtonSkeleton width={140} height={14} />
            <ButtonSkeleton width={170} height={14} />
            <ButtonSkeleton width={140} height={14} />
          </Space>
        )}
      </ul>
    </div>
  </div>
);

import React from 'react';
import { Col, Space } from 'antd';
import { Widget } from '../Widget/Widget';
import { ButtonSkeleton } from './ButtonSkeleton';
import { AvatarSkeleton } from './AvatarSkeleton';

interface EnvironmentsSkeletonProps {
  count: number;
}

export const EnvironmentsSkeleton = ({ count }: EnvironmentsSkeletonProps) => (
  <>
    {[...Array(count).keys()].map((i) => (
      <Col key={i} xl={12} lg={24} md={12} sm={12} xs={24}>
        <Widget styleName="gx-card-widget gx-card-full gx-p-3">
          <div className="gx-media gx-align-items-center gx-flex-nowrap">
            <div className="gx-mr-2 gx-mr-xxl-3">
              <AvatarSkeleton size={38} />
            </div>
            <div className="gx-media-body">
              <Space direction="vertical" size={12}>
                <ButtonSkeleton width={160} height={24} />
                <ButtonSkeleton width={40} height={12} />
              </Space>
            </div>
          </div>
        </Widget>
      </Col>
    ))}
  </>
);

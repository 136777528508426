import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { IdcardOutlined, SyncOutlined } from '@ant-design/icons';

// Models
import styled from 'styled-components';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';
import { AzureTenant } from '../../../models/AzureTenant';
import { assignAzureGroups, syncAzureGroups } from '../../../store/AzureTenants/AzureGroups/AzureGroups.redux';
import { IconButton } from '../../../components/UI/Button/IconButton';
import { AzureGroup } from '../../../models/AzureGroup';

const { confirm } = Modal;

// Props
interface AzureGroupsFormProps {
  azureTenantResponse: AzureTenant | undefined;
}

interface AssignConfirmProps {
  azureTenant: AzureTenant;
  azureGroups: Array<AzureGroup>;
}

interface SyncConfirmProps {
  azureTenant: AzureTenant;
}

interface SyncButtonProps {
  azureTenant: AzureTenant | undefined;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useAzureGroupsRedux = ({ azureTenantResponse }: AzureGroupsFormProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ azureGroups: azureGroupsState }) => azureGroupsState?.updating ?? false);
  const error = useAppSelector(({ azureGroups: azureGroupsState }) => azureGroupsState?.error ?? false);

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ azureTenant, azureGroups }: AssignConfirmProps) => {
      if (azureTenant && azureGroups) {
        confirm({
          title: intl.formatMessage({
            id: 'azureTenants.azureGroups.multiSelect.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'azureTenants.azureGroups.multiSelect.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignAzureGroups({ azureTenant, azureGroups })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showSyncConfirm = useCallback(
    ({ azureTenant }: SyncConfirmProps) => {
      confirm({
        title: intl.formatMessage({
          id: 'azureTenants.azureGroups.confirm.sync',
        }),
        icon: <IdcardOutlined />,
        content: intl.formatMessage({
          id: 'azureTenants.azureGroups.confirm.syncSub',
        }),
        okText: intl.formatMessage({
          id: 'app.yes',
          defaultMessage: 'Yes',
        }),
        cancelText: intl.formatMessage({
          id: 'app.no',
          defaultMessage: 'No',
        }),
        okType: 'primary',
        onOk: () => dispatch(syncAzureGroups({ azureTenant })),
        onCancel: () => null,
      });
    },
    [intl, dispatch]
  );

  // Sync PACSIdentifierTypes Button
  const SyncButton = useCallback(
    ({ azureTenant }: SyncButtonProps) => {
      if (!azureTenant) return null;
      return (
        <IconButton
          title={<Translated id="azureTenants.azureGroups.syncButton" />}
          onClick={() => showSyncConfirm({ azureTenant })}
          Icon={SyncOutlined}
        />
      );
    },
    [showSyncConfirm]
  );

  // State
  const [groupIds, setGroupIds] = useState<Array<string>>([]);

  // Sets the Keys when the response is updated
  // Fixes problem with initial values after API call
  useEffect(() => {
    setGroupIds(azureTenantResponse?.Groups?.filter((x) => x.IsAssigned).map((x) => x.Id) ?? []);
  }, [azureTenantResponse]);

  // Components
  const AzureGroupsManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      azureTenantResponse?.Groups?.map<TransferItem>((azureGroup) => ({
        key: azureGroup.Id,
        title: azureGroup.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <ItemTransfer dataSource={dataSource} targetKeys={groupIds} setTargetKeys={setGroupIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            azureTenantResponse &&
            showAssignConfirm({
              azureTenant: azureTenantResponse,
              azureGroups: azureTenantResponse.Groups.filter((x) => groupIds.some((id) => id === x.Id)),
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [groupIds, azureTenantResponse, showAssignConfirm]);

  return useMemo(
    () => ({
      AzureGroupsManagementForm,
      updating,
      error,
      SyncButton,
    }),
    [AzureGroupsManagementForm, updating, error, SyncButton]
  );
};

// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import {
  pacsIdentifierTypesAssigned,
  pacsIdentifierTypesNotAssigned,
  pacsIdentifierTypesNotSynced,
  pacsIdentifierTypesSynced,
} from './PACSIdentifierTypes.redux';

// Events
const SignalREvents = {
  PACSIdentifierTypesAssigned: `PACSIdentifierTypesAssignedIntegrationEvent`,
  PACSIdentifierTypesSynced: `PACSIdentifierTypesSyncedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  PACSIdentifierTypesNotAssigned: `PACSIdentifierTypesNotAssignedIntegrationException`,
  PACSIdentifierTypesNotSynced: `PACSIdentifierTypesNotSyncedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.PACSIdentifierTypesAssigned, (msg) => {
    store.dispatch(pacsIdentifierTypesAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PACSIdentifierTypesNotAssigned, (msg) => {
    store.dispatch(pacsIdentifierTypesNotAssigned({ history, msg }));
  });

  // Sync
  hubConnection.on(SignalREvents.PACSIdentifierTypesSynced, (msg) => {
    store.dispatch(pacsIdentifierTypesSynced({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PACSIdentifierTypesNotSynced, (msg) => {
    store.dispatch(pacsIdentifierTypesNotSynced({ history, msg }));
  });
};

import React from 'react';
import { Col, Row, Skeleton, Tabs } from 'antd';
import styled from 'styled-components';

// Models
import { CalendarEventConfiguration } from '../../models/CalendarEventConfiguration';

// Hooks
import { useSettings } from '../../hooks/CalendarEventConfigurations/Settings/useSettings';

// Components
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Translated } from '../UI/Core';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }
  & .ant-tabs-nav {
    height: 58px;
  }
  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
`;

// Props
interface CalendarEventConfigurationSettingsProps {
  calendarEventConfiguration: CalendarEventConfiguration | undefined;
  fetchCalendarEventConfiguration: (params: any) => Promise<void>;
  updatingCalendarEventConfiguration: boolean;
}

// Component
export const Settings = ({
  calendarEventConfiguration,
  fetchCalendarEventConfiguration,
  updatingCalendarEventConfiguration,
}: CalendarEventConfigurationSettingsProps) => {
  // Service Configurations Hook
  const { loading, updating, SettingsForm } = useSettings({
    calendarEventConfiguration,
    fetchCalendarEventConfiguration,
    updatingCalendarEventConfiguration,
  });

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = () => [
    {
      key: '0',
      label: <Translated id="calendarEventConfigurations.settings" />,
      children: (
        <Spinner spinning={updating}>
          <SettingsForm />
        </Spinner>
      ),
    },
  ];

  const renderItems = () => {
    if (loading) return renderLoading();
    return renderForm();
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderItems()} />
    </StyledWidget>
  );
};

import React, { useCallback, useMemo } from 'react';

// Hooks
import { useCalendarEventConfigurationsRedux } from './useCalendarEventConfigurationsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { calendarEventConfigurationTableColumns } from '../../data/Table/CalendarEventConfigurationTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

export const useCalendarEventConfigurationsList = <T extends object>() => {
  // Data
  const {
    data: calendarEventConfigurations,
    pageCount,
    totalCount,
    fetch,
    pending,
  } = useData(ApiEndpoints.calendarEventConfigurations.list, null);
  const data = useMemo(() => calendarEventConfigurations, [calendarEventConfigurations]);

  // Redux
  const { formOptions, updating, error, showDeleteAllConfirm } = useCalendarEventConfigurationsRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(
    () => ({ breadcrumbs: appBreadcrumbs.calendarEventConfigurations.list }),
    []
  );
  const getCalendarEventConfigurationsDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.CalendarEventConfigurations.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-email" style={{ marginRight: 8 }} />
          <Translated id="appMenu.calendarEventConfigurations" />
        </>
      ),
      data: data ?? [],
      columns: getTableColumns(
        calendarEventConfigurationTableColumns,
        LocalStorageKeys.CalendarEventConfigurations.table.hiddenColumns
      ),
      fetchData: fetch,
    }),
    [fetch, data]
  );
  const getCalendarEventConfigurationsTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getCalendarEventConfigurationsCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getCalendarEventConfigurationsDataProps,
      getCalendarEventConfigurationsTableProps,
      getCalendarEventConfigurationsCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getCalendarEventConfigurationsDataProps,
      getCalendarEventConfigurationsTableProps,
      getFormDrawerProps,
      getCalendarEventConfigurationsCrudProps,
    ]
  );
};

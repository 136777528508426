// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import {
  pacsAdvancedSettingsNotUpdated,
  pacsAdvancedSettingsUpdated,
  pacsServiceConfigurationAssigned,
  pacsServiceConfigurationNotAssigned,
} from './PACSSettings.redux';

// Events
const SignalREvents = {
  PACSServiceConfigurationAssigned: `PACSServiceConfigurationAssignedIntegrationEvent`,
  PACSAdvancedSettingsUpdated: `PACSAdvancedSettingsUpdatedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  PACSServiceConfigurationNotAssigned: `PACSServiceConfigurationNotAssignedIntegrationException`,
  PACSAdvancedSettingsNotUpdated: `PACSAdvancedSettingsNotUpdatedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.PACSServiceConfigurationAssigned, (msg) => {
    store.dispatch(pacsServiceConfigurationAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PACSServiceConfigurationNotAssigned, (msg) => {
    store.dispatch(pacsServiceConfigurationNotAssigned({ history, msg }));
  });
  // Advanced Settings
  hubConnection.on(SignalREvents.PACSAdvancedSettingsUpdated, (msg) => {
    store.dispatch(pacsAdvancedSettingsUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PACSAdvancedSettingsNotUpdated, (msg) => {
    store.dispatch(pacsAdvancedSettingsNotUpdated({ history, msg }));
  });
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IdentityUser } from '../../models/IdentityUser';
import { SignalRAction } from '../../models/SignalRAction';
import { IdentityUserUnsafe } from '../../models/IdentityUserUnsafe';

// State
interface IdentityUsersState {
  updating: boolean;
  error: boolean;
  tableCustomFilters: CustomFilters;
  unsafeTableCustomFilters: CustomFilters;
}

interface CustomFilters {
  roles: string[];
  businessClientId?: string;
  tenantId?: string;
}

const initialState: IdentityUsersState = {
  updating: false,
  error: false,
  tableCustomFilters: { roles: [] },
  unsafeTableCustomFilters: { roles: [] },
};

// Name
const storeName = '@IDENTITY_USERS';

// Redux Actions|Reducers
const identityUsersSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createIdentityUser: (state, action: PayloadAction<IdentityUser>) => {
      state.updating = true;
      state.error = false;
    },
    identityUserCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identityUserNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Activation
    sendInvitationMail: (state, action: PayloadAction<IdentityUser>) => {
      state.updating = true;
      state.error = false;
    },
    invitationMailSent: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    invitationMailNotSent: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Table custom filters
    updateCustomTableFilters: (state, action: PayloadAction<CustomFilters>) => {
      state.tableCustomFilters = action.payload;
    },
    updateUnsafeCustomTableFilters: (state, action: PayloadAction<CustomFilters>) => {
      state.unsafeTableCustomFilters = action.payload;
    },

    // UNSAFE
    updateIdentityUserUnsafe: (state, action: PayloadAction<IdentityUserUnsafe>) => {
      state.updating = true;
      state.error = false;
    },
    identityUserUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identityUserNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createIdentityUser,
  identityUserCreated,
  identityUserNotCreated,
  // Activation
  sendInvitationMail,
  invitationMailSent,
  invitationMailNotSent,
  // Table custom filters
  updateCustomTableFilters,
  updateUnsafeCustomTableFilters,
  // UNSAFE
  updateIdentityUserUnsafe,
  identityUserUpdated,
  identityUserNotUpdated,
} = identityUsersSlice.actions;

// Export Reducer
export default identityUsersSlice.reducer;

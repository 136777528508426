import { CheckCircleFilled, ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';
import React from 'react';

export enum FilterStatus {
  Error = -1,
  NotFound = 0,
  Operational = 1,
  Warning = 2,
  Warnings = 3,
}

export interface FilterStatusType {
  NameId: string;
  Color: string;
  Icon: React.ForwardRefExoticComponent<any>;
  FilterStatus: FilterStatus;
}

export const FilterStatusInfo: FilterStatusType[] = [
  {
    NameId: 'dashboard.textCardGroup.filtersNotFound',
    Color: 'info',
    Icon: ExclamationCircleFilled,
    FilterStatus: FilterStatus.NotFound,
  },
  {
    NameId: 'dashboard.textCardGroup.environmentsOperational',
    Color: 'success',
    Icon: CheckCircleFilled,
    FilterStatus: FilterStatus.Operational,
  },
  {
    NameId: 'dashboard.textCardGroup.environmentNeedsAttention',
    Color: 'warning',
    Icon: WarningFilled,
    FilterStatus: FilterStatus.Warning,
  },
  {
    NameId: 'dashboard.textCardGroup.environmentsNeedAttention',
    Color: 'warning',
    Icon: WarningFilled,
    FilterStatus: FilterStatus.Warnings,
  },
];

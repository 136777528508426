import React, { useEffect, useState } from 'react';
import { Card, Col, Skeleton } from 'antd';
import styled from 'styled-components';
import { FileOutlined } from '@ant-design/icons';
import { Translated } from '../UI/Core';
import { Kiosk, KioskMediaType } from '../../models/Kiosk';
import { Spinner } from '../UI/Spinner/Spinner';
import { HTMLSanitize } from './HTMLSanitize';

// Assets
import BlueKiosk from '../../assets/videos/BlueKiosk.mp4';

// Styled
const TextsContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

// Props
interface KioskFileProps {
  pending: boolean;
  updating: boolean;
  kioskData: Kiosk | undefined;
  selectedLanguage: string;
  tabId: number;
}

// Component
export const ImagePreviewCard = ({ pending, updating, kioskData, selectedLanguage, tabId }: KioskFileProps) => {
  const kioskTexts =
    kioskData?.Texts.find((texts) => texts.Language === selectedLanguage) ??
    kioskData?.Texts.find((texts) => texts.Language === kioskData?.DefaultLanguage);

  const [kioskMedia, setKioskMedia] = useState(kioskData?.Media.find((x) => x.MediaType === tabId));

  useEffect(() => {
    setKioskMedia(
      kioskData?.Media.find((x) => x.MediaType === tabId && x.Language === selectedLanguage) ??
        kioskData?.Media.find((x) => x.MediaType === tabId && x.Language === kioskData?.DefaultLanguage)
    );
  }, [tabId, kioskData, selectedLanguage]);

  if (tabId === 4) {
    return null;
  }

  return (
    <Card title={<Translated id="kiosks.image.preview" />} bodyStyle={{ padding: '12px' }}>
      <Spinner spinning={updating}>
        {pending ? (
          <SkeletonContainer>
            <Skeleton.Image active />
          </SkeletonContainer>
        ) : (
          <div
            style={{
              height: 360,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            {kioskMedia ? (
              kioskMedia.MimeType.startsWith('image') ? (
                <img
                  src={kioskMedia.Url}
                  style={{
                    maxWidth: '100%',
                    display: 'block',
                    margin: 'auto',
                  }}
                  alt="preview"
                />
              ) : kioskMedia.MimeType.startsWith('video') ? (
                <video
                  key={kioskMedia.MediaType}
                  muted
                  autoPlay
                  loop
                  playsInline
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                >
                  <source src={kioskMedia.Url} type="video/mp4" />
                </video>
              ) : (
                <embed style={{ height: '100%', width: '100%' }} src={kioskMedia.Url} type="application/pdf" />
              )
            ) : tabId === KioskMediaType.Banner ? (
              <video muted autoPlay loop playsInline style={{ objectFit: 'cover', height: '100%', width: '100%' }}>
                {tabId === KioskMediaType.Banner && <source src={BlueKiosk} type="video/mp4" />}
              </video>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1>No file found</h1>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: '#57b0d0',
                  }}
                >
                  <FileOutlined style={{ fontSize: '100px' }} />
                </div>
              </div>
            )}
            {tabId === KioskMediaType.Banner && (
              <div
                style={{
                  position: 'absolute',
                  height: '42%',
                  width: '100%',
                  left: '0',
                  bottom: '0',
                  backgroundImage: 'linear-gradient(transparent, white)',
                }}
              />
            )}
          </div>
        )}
        {!pending && tabId === KioskMediaType.Banner && (
          <TextsContainer>
            <Col>
              <HTMLSanitize html={kioskTexts?.IntroTitle ?? ''} styleClass="ql-editor" />
            </Col>
            <Col>
              <HTMLSanitize html={kioskTexts?.IntroSubtitle ?? ''} styleClass="ql-editor" />
            </Col>
            <Col>
              <HTMLSanitize html={kioskTexts?.IntroSubtext ?? ''} styleClass="ql-editor" />
            </Col>
          </TextsContainer>
        )}
      </Spinner>
    </Card>
  );
};

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { BusinessClientFormValues } from '../../models/BusinessClient';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import { businessClientCreated, businessClientNotCreated, createBusinessClient } from './BusinessClients.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createBusinessClientSaga() {
  yield takeEvery(createBusinessClient.type, createBusinessClientRequest);
}

function* businessClientCreatedSaga() {
  yield takeLatest(businessClientCreated.type, createBusinessClientResponse);
}

function* businessClientNotCreatedSaga() {
  yield takeLatest(businessClientNotCreated.type, createBusinessClientError);
}

// Request
function* createBusinessClientRequest(action: PayloadAction<BusinessClientFormValues>) {
  try {
    const { payload: businessClient } = action;
    yield apiService.execute({
      url: 'BusinessClients',
      method: ApiRequestType.POST,
      data: businessClient,
    });
  } catch ({ message }) {
    yield put({ type: businessClientNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createBusinessClientResponse() {
  notificationService.showSuccess('businessClients.notifications.create');
}

// Error
function createBusinessClientError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('businessClients.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createBusinessClientSaga(),
    businessClientCreatedSaga(),
    businessClientNotCreatedSaga(),
  ]);
}

import React, { useCallback, useMemo } from 'react';
import { Row } from 'react-table';
import { Button } from 'antd';
import styled from 'styled-components';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

// Components
import { StyledTooltip } from '../../components/UI/Tooltip/StyledTooltip';
import { Translated } from '../../components/UI/Core';

// Props
interface SelectedRowsProps<T extends object> {
  selectedFlatRows: Row<T>[];
  showDeleteAllConfirm: (selectedFlatRows: Row<T>[]) => void;
  showEditForm: (selectedFlatRows: Row<T>) => void;
}

// Styled
const StyledSpan = styled.span`
  width: 14px;
  height: 14px;
  line-height: 16px;
  display: flex;
`;
const StyledButton = styled(Button)`
  padding: 5px 10px;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  :focus,
  :active,
  :active:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &[disabled] {
    margin-right: 15px;
  }
`;

// Hook
export const useSelectedRows = <T extends object>({
  selectedFlatRows,
  showDeleteAllConfirm,
  showEditForm,
}: SelectedRowsProps<T>) => {
  // Components
  const DeleteButton = useCallback(
    () =>
      showDeleteAllConfirm ? (
        <StyledTooltip title={<Translated id="table.deleteButton" defaultMessage="Delete" />} placement="bottomRight">
          <StyledButton
            danger
            disabled={selectedFlatRows.length === 0}
            onClick={() => showDeleteAllConfirm(selectedFlatRows)}
          >
            <StyledSpan>
              <DeleteOutlined />
            </StyledSpan>
          </StyledButton>
        </StyledTooltip>
      ) : null,
    [selectedFlatRows, showDeleteAllConfirm]
  );

  const EditButton = useCallback(
    () =>
      showEditForm ? (
        <StyledTooltip title={<Translated id="table.editButton" defaultMessage="Edit" />} placement="bottomRight">
          <StyledButton disabled={selectedFlatRows.length !== 1} onClick={() => showEditForm(selectedFlatRows[0])}>
            <StyledSpan>
              <EditOutlined />
            </StyledSpan>
          </StyledButton>
        </StyledTooltip>
      ) : null,
    [selectedFlatRows, showEditForm]
  );

  return useMemo(
    () => ({
      DeleteButton,
      EditButton,
    }),
    [DeleteButton, EditButton]
  );
};

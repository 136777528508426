// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import { identifierTypeTenantsAssigned, identifierTypeTenantsNotAssigned } from './IdentifierTypeTenants.redux';

// Events
const SignalREvents = {
  IdentifierTypeTenantsAssigned: `IdentifierTypeTenantsAssignedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  IdentifierTypeTenantsNotAssigned: `IdentifierTypeTenantsNotAssignedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.IdentifierTypeTenantsAssigned, (msg) => {
    store.dispatch(identifierTypeTenantsAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.IdentifierTypeTenantsNotAssigned, (msg) => {
    store.dispatch(identifierTypeTenantsNotAssigned({ history, msg }));
  });
};

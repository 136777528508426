import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import { AccessProfile, AccessProfileResponse } from '../../../models/AccessProfile';

// Utils
import { getTreeCheckedChildrenIds, getTreeChildren } from '../../../constants/Utils/UIFunctions';
import { getPACSAccessProfileTreeItems } from '../../../constants/Utils/TreeFunctions';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import {
  assignPACSAccessProfiles,
  syncPACSAccessProfiles,
} from '../../../store/AccessProfiles/PACSAccessProfiles/PACSAccessProfiles.redux';

// Components
import { TreeForm } from '../../../components/UI/Tree/TreeForm';

const { confirm } = Modal;

// Props
interface AccessProfilesReduxProps {
  accessProfileResponse: AccessProfileResponse | null;
}

interface AssignConfirmProps {
  pacsAccessProfileIds: Array<string>;
  accessProfile: AccessProfile;
}

// Hook
export const usePACSAccessProfilesRedux = ({ accessProfileResponse }: AccessProfilesReduxProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(
    ({ pacsAccessProfiles: pacsAccessProfilesState }) => pacsAccessProfilesState?.updating ?? false
  );
  const error = useAppSelector(
    ({ pacsAccessProfiles: pacsAccessProfilesState }) => pacsAccessProfilesState?.error ?? false
  );

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ pacsAccessProfileIds, accessProfile }: AssignConfirmProps) => {
      if (pacsAccessProfileIds && accessProfile) {
        confirm({
          title: intl.formatMessage({
            id: 'accessProfiles.checkableTree.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'accessProfiles.checkableTree.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignPACSAccessProfiles({ accessProfile, pacsAccessProfileIds })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showSyncConfirm = useCallback(() => {
    confirm({
      title: intl.formatMessage({
        id: 'accessProfiles.pacsAccessProfiles.confirm.sync',
      }),
      icon: <IdcardOutlined />,
      content: intl.formatMessage({
        id: 'accessProfiles.pacsAccessProfiles.confirm.syncSub',
      }),
      okText: intl.formatMessage({
        id: 'app.yes',
        defaultMessage: 'Yes',
      }),
      cancelText: intl.formatMessage({
        id: 'app.no',
        defaultMessage: 'No',
      }),
      okType: 'primary',
      onOk: () => dispatch(syncPACSAccessProfiles()),
      onCancel: () => null,
    });
  }, [intl, dispatch]);

  // Tree Items
  const treeItems = useMemo(
    () => getPACSAccessProfileTreeItems(accessProfileResponse?.AvailablePacsAccessProfiles ?? []),
    [accessProfileResponse]
  );

  // Get the Keys (Id) from all PACS of an Access Profile
  const initialKeys = useMemo(
    () =>
      getTreeChildren(treeItems)
        ?.filter((item) => accessProfileResponse?.PacsAccessProfiles?.some((ap) => ap.Id === item.id))
        .map((item) => item.key) ?? [],
    [accessProfileResponse, treeItems]
  );

  // Form
  const PACSAccessProfilesManagementForm = useCallback(
    () => (
      <TreeForm
        dataSource={treeItems}
        initialKeys={initialKeys}
        onSave={(ids: Array<string>) =>
          accessProfileResponse &&
          accessProfileResponse.AccessProfile &&
          showAssignConfirm({
            pacsAccessProfileIds: getTreeCheckedChildrenIds(treeItems, ids),
            accessProfile: accessProfileResponse.AccessProfile,
          })
        }
      />
    ),
    [treeItems, initialKeys, accessProfileResponse, showAssignConfirm]
  );

  return useMemo(
    () => ({
      PACSAccessProfilesManagementForm,
      updating,
      error,
      showSyncConfirm,
    }),
    [PACSAccessProfilesManagementForm, updating, error, showSyncConfirm]
  );
};

import React from 'react';
import { Col, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { Translated } from '../Core';
import { EnvironmentFilter } from '../../../models/Dashboard/ApiResponses/EnvironmentFiltersResponse';
import { SelectFilterType } from '../../../models/Filters';

const { Option } = Select;

interface SelectFilterProps {
  labelId: string;
  placeholderId?: string;
  filters: Array<EnvironmentFilter> | undefined;
  defaultValue: SelectFilterType;
  handleChange?: ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
}

export const SelectFilter = ({ labelId, placeholderId, filters, defaultValue, handleChange }: SelectFilterProps) => {
  return (
    <Col xl={12} lg={24} md={12} sm={12} xs={24} id={`select-${labelId}`}>
      <h4 className="gx-timeline-info-day gx-mb-2">
        <Translated id={labelId} />
      </h4>
      <Select
        mode="multiple"
        showArrow
        allowClear
        style={{ width: '100%' }}
        className="gx-mb-2"
        optionFilterProp="label"
        getPopupContainer={() => document.getElementById(`select-${labelId}`) || document.body}
        placeholder={<Translated id={placeholderId} />}
        defaultValue={defaultValue}
        value={defaultValue}
        onChange={handleChange}
      >
        {filters?.map((x) => (
          <Option key={x.Value} value={x.Value} label={x.Label}>
            {x.Label}
          </Option>
        ))}
      </Select>
    </Col>
  );
};

SelectFilter.defaultProps = {
  placeholderId: 'app.ui.select.placeholder.multiple',
  handleChange: undefined,
};

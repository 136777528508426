import styled, { css } from 'styled-components';
import { styleCss } from '../../../constants/Utils/StyledComponents';
import { Block, BlockProps } from './Block';

export interface FlexProps extends BlockProps {
  inline?: boolean;
  column?: boolean;
  reverse?: boolean;
  flexWrap?: boolean;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
}

export const Flex = styled(Block)<FlexProps>`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: ${(props) => `${props.column ? 'column' : 'row'}${props.reverse ? '-reverse' : ''}`};
  ${(props) =>
    props.flexWrap &&
    css`
      flex-wrap: wrap;
    `};

  ${styleCss('direction', 'flex-direction')};
  ${styleCss('justifyContent')};
  ${styleCss('alignContent')};
  ${styleCss('alignItems')};
`;

// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  pacsAccessProfilesImported,
  pacsAccessProfilesNotImported,
  pacsEmployeesImported,
  pacsEmployeesNotImported,
  pacsUserAttached,
  pacsUserNotAttached,
  serviceConfigurationCreated,
  serviceConfigurationDeleted,
  serviceConfigurationNotCreated,
  serviceConfigurationNotDeleted,
  serviceConfigurationNotTested,
  serviceConfigurationNotUpdated,
  serviceConfigurationsDeleted,
  serviceConfigurationsNotDeleted,
  serviceConfigurationTested,
  serviceConfigurationUpdated,
} from './ServiceConfigurations.redux';

// Events
const SignalREvents = {
  ServiceConfigurationCreated: `ServiceConfigurationCreatedIntegrationEvent`,
  PACSServiceConfigurationAssigned: `PACSServiceConfigurationAssignedIntegrationEvent`,
  ServiceConfigurationDeleted: `ServiceConfigurationDeletedIntegrationEvent`,
  ServiceConfigurationsDeleted: `ServiceConfigurationsDeletedIntegrationEvent`,
  ServiceConfigurationStatusSet: `ServiceConfigurationStatusSetIntegrationEvent`,
  PACSEmployeesFetched: 'PACSEmployeesFetchedIntegrationEvent',
  PACSUserAttached: 'PACSUserAttachedIntegrationEvent',
  PACSAccessProfilesImported: 'AccessProfilesImportedIntegrationEvent',
};

// Exceptions
const SignalRExceptions = {
  ServiceConfigurationNotCreated: `ServiceConfigurationNotCreatedIntegrationException`,
  ServiceConfigurationNotUpdated: `ServiceConfigurationNotUpdatedIntegrationException`,
  ServiceConfigurationNotDeleted: `ServiceConfigurationNotDeletedIntegrationException`,
  ServiceConfigurationsNotDeleted: `ServiceConfigurationsNotDeletedIntegrationException`,
  ServiceConfigurationStatusNotSet: `ServiceConfigurationStatusNotSetIntegrationException`,
  PACSServiceConfigurationStatusNotChecked: `PACSServiceConfigurationStatusNotCheckedIntegrationException`,
  PACSEmployeesNotFetched: `PACSEmployeesNotFetchedIntegrationException`,
  PACSUserNotAttached: 'PACSUserNotAttachedIntegrationException',
  PACSAccessProfilesNotImported: 'PACSAccessProfilesNotFetchedIntegrationException',
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Test
  hubConnection.on(SignalREvents.ServiceConfigurationStatusSet, (msg) => {
    store.dispatch(serviceConfigurationTested({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ServiceConfigurationStatusNotSet, (msg) => {
    store.dispatch(serviceConfigurationNotTested({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PACSServiceConfigurationStatusNotChecked, (msg) => {
    store.dispatch(serviceConfigurationNotTested({ history, msg }));
  });

  // Create
  hubConnection.on(SignalREvents.ServiceConfigurationCreated, (msg) => {
    store.dispatch(serviceConfigurationCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ServiceConfigurationNotCreated, (msg) => {
    store.dispatch(serviceConfigurationNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.PACSServiceConfigurationAssigned, (msg) => {
    store.dispatch(serviceConfigurationUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ServiceConfigurationNotUpdated, (msg) => {
    store.dispatch(serviceConfigurationNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.ServiceConfigurationDeleted, (msg) => {
    store.dispatch(serviceConfigurationDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ServiceConfigurationNotDeleted, (msg) => {
    store.dispatch(serviceConfigurationNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.ServiceConfigurationsDeleted, (msg) => {
    store.dispatch(serviceConfigurationsDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ServiceConfigurationsNotDeleted, (msg) => {
    store.dispatch(serviceConfigurationsNotDeleted({ history, msg }));
  });

  // Import Pacs Users
  hubConnection.on(SignalREvents.PACSEmployeesFetched, (msg) => {
    store.dispatch(pacsEmployeesImported({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PACSEmployeesNotFetched, (msg) => {
    store.dispatch(pacsEmployeesNotImported({ history, msg }));
  });
  hubConnection.on(SignalREvents.PACSUserAttached, (msg) => {
    store.dispatch(pacsUserAttached({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PACSUserNotAttached, (msg) => {
    store.dispatch(pacsUserNotAttached({ history, msg }));
  });

  // Import Access Profiles
  hubConnection.on(SignalREvents.PACSAccessProfilesImported, (msg) => {
    store.dispatch(pacsAccessProfilesImported({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PACSAccessProfilesNotImported, (msg) => {
    store.dispatch(pacsAccessProfilesNotImported({ history, msg }));
  });
};

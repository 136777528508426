import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { useKiosksRedux } from './useKiosksRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { useAppSelector } from '../App/useRedux';
import { Kiosk } from '../../models/Kiosk';

// Props
interface KioskDetailProps {
  id?: string;
}

// Hook
export const useKiosksDetail = <T extends object>({ id }: KioskDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Redux State
  const { updatingDefault } = useAppSelector(({ kiosks }) => kiosks);
  const { locale } = useAppSelector(({ appSettings }) => appSettings);
  const prevUpdatingDefault = usePrevious(updatingDefault);

  // Data
  const { data: kioskResponse, fetch, pending } = useData(ApiEndpoints.kiosks.detail, null);
  const prevPending = usePrevious(pending);

  // Component State
  const [selectedLanguage, setSelectedLanguage] = useState(locale?.locale ?? '');
  const [kioskData, setKioskData] = useState<Kiosk>();
  const [tabId, setTabId] = useState(0);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useKiosksRedux<T>({
    isEditing: true,
    initialValues: kioskResponse as Store,
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.kiosks.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      pending,
      kioskData,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [pending, kioskData, getFormDrawerProps, showDeleteConfirm]
  );

  const onTabChange = useCallback(
    (event: string) => {
      setTabId(parseInt(event, 10));
    },
    [setTabId]
  );

  const getKiosksCustomBrandingProps = useCallback(
    () => ({
      setSelectedLanguage,
      setKioskData,
      setTabId,
      onTabChange,
    }),
    [setSelectedLanguage, setKioskData, setTabId, onTabChange]
  );
  const getKioskDataProps = useCallback(
    () => ({
      pending,
      updating,
      kioskData,
      selectedLanguage,
      tabId,
    }),
    [pending, updating, kioskData, selectedLanguage, tabId]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    if (prevPending === true && pending === false) {
      // Navigates to the index if not found
      if (!kioskResponse) {
        notificationService.showError('kiosks.notFound');
        navigate('/Kiosks');
      }
      // Update Component State based on API response
      else {
        setKioskData(kioskResponse);
      }
    }
  }, [prevPending, pending, kioskResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (
      ((prevUpdating === true && !updating) || (prevUpdatingDefault === true && updatingDefault === false)) &&
      !error
    ) {
      fetch({ id });
    }
  }, [prevUpdating, updating, prevUpdatingDefault, updatingDefault, error, fetch, id]);

  return useMemo(
    () => ({
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getFormDrawerProps,
      getKioskDataProps,
      getKiosksCustomBrandingProps,
    }),
    [getBreadcrumbMenuProps, getProfileHeaderProps, getFormDrawerProps, getKioskDataProps, getKiosksCustomBrandingProps]
  );
};

import React from 'react';
import { Col, Empty, Row, Skeleton, Tabs } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

// Models
// Hooks
// Components
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Translated } from '../UI/Core';
import { useAzureGroups } from '../../hooks/AzureTenants/AzureGroups/useAzureGroups';
import { AzureTenant } from '../../models/AzureTenant';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }

  & .ant-tabs-nav {
    height: 58px;
  }

  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
`;

// Props
interface IdentifierTypeTenantsProps {
  azureTenantResponse: AzureTenant | undefined;
  fetchAzureTenant: (params: any) => Promise<void>;
  updatingAzureTenant: boolean;
}

// Component
export const AzureGroups = ({
  azureTenantResponse,
  fetchAzureTenant,
  updatingAzureTenant,
}: IdentifierTypeTenantsProps) => {
  // Identifiers Hook
  const { loading, updating, AzureGroupsManagementForm, SyncButton } = useAzureGroups({
    azureTenantResponse,
    fetchAzureTenant,
    updatingAzureTenant,
  });

  if (!azureTenantResponse?.FilterByGroups) return null;

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderEmpty = () => [
    {
      key: '0',
      label: <Translated id="azureTenants.azureGroups.tab" />,
      children: (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="azureTenants.azureGroups.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = () => [
    {
      key: '0',
      label: <Translated id="azureTenants.azureGroups.tab" />,
      children: (
        <Spinner spinning={updating}>
          <AzureGroupsManagementForm />
        </Spinner>
      ),
    },
  ];

  const renderItems = () => {
    if (loading) return renderLoading();
    if (!azureTenantResponse || !azureTenantResponse.Groups || isEmpty(azureTenantResponse.Groups))
      return renderEmpty();
    return renderForm();
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs
        tabBarExtraContent={{ right: SyncButton({ azureTenant: azureTenantResponse }) }}
        items={renderItems()}
      />
    </StyledWidget>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import styled from 'styled-components';
import { IdentifierType, IdentifierTypeResponse } from '../../../models/IdentifierType';
import { Tenant } from '../../../models/Tenant';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { assignIdentifierTypeTenants } from '../../../store/IdentifierTypes/IdentifierTypeTenants/IdentifierTypeTenants.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';

const { confirm } = Modal;

// Props
interface IdentifierTypeTenantsFormProps {
  identifierTypeTenantsResponse: IdentifierTypeResponse | null;
  identifierTypeTenants: Array<Tenant> | null;
}

interface AssignConfirmProps {
  tenants: Array<Tenant>;
  identifierType: IdentifierType;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useIdentifierTypeTenantsRedux = ({
  identifierTypeTenantsResponse,
  identifierTypeTenants,
}: IdentifierTypeTenantsFormProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(
    ({ identifierTypeTenants: identifierTypeTenantsState }) => identifierTypeTenantsState?.updating ?? false
  );
  const error = useAppSelector(
    ({ identifierTypeTenants: identifierTypeTenantsState }) => identifierTypeTenantsState?.error ?? false
  );

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ tenants, identifierType }: AssignConfirmProps) => {
      if (tenants && identifierType) {
        confirm({
          title: intl.formatMessage({
            id: 'identifierTypes.Tenants.multiSelect.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'identifierTypes.Tenants.multiSelect.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignIdentifierTypeTenants({ tenants, identifierType })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // State
  const [tenantIds, setTenantIds] = useState<Array<string>>([]);

  // Sets the Keys when the response is updated
  // Fixes problem with initial values after API call
  useEffect(() => {
    setTenantIds(
      identifierTypeTenants
        ?.filter((tenant) =>
          identifierTypeTenantsResponse?.IdentifierType.Tenants?.some(
            (identifierTypeTenant) =>
              identifierTypeTenant.CompanyId === tenant.CompanyId &&
              identifierTypeTenant.LocationId === tenant.LocationId &&
              identifierTypeTenant.BusinessClientId === tenant.BusinessClientId
          )
        )
        .map((tenant) => tenant.Id) ?? []
    );
  }, [identifierTypeTenants, identifierTypeTenantsResponse]);

  // Components
  const IdentifierTypeTenantsManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      identifierTypeTenants?.map<TransferItem>((identifierTypeTenant) => ({
        key: identifierTypeTenant.Id,
        title: identifierTypeTenant.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <ItemTransfer dataSource={dataSource} targetKeys={tenantIds} setTargetKeys={setTenantIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            identifierTypeTenantsResponse &&
            identifierTypeTenants &&
            showAssignConfirm({
              tenants: identifierTypeTenants.filter((t) => tenantIds.some((id) => id === t.Id)),
              identifierType: identifierTypeTenantsResponse.IdentifierType,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [identifierTypeTenantsResponse, identifierTypeTenants, showAssignConfirm, tenantIds, setTenantIds]);

  return useMemo(
    () => ({
      IdentifierTypeTenantsManagementForm,
      updating,
      error,
    }),
    [IdentifierTypeTenantsManagementForm, updating, error]
  );
};

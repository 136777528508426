import React from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import styled from 'styled-components';

// Models
import { ScheduleConfiguration } from '../../models/ScheduleConfiguration';

// Redux
import { useAppSelector } from '../../hooks/App/useRedux';

// Components
import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Spinner } from '../UI/Spinner/Spinner';
import { ScheduleConfigurationTypes } from '../../models/enums/ScheduleConfigurationTypes';
import { getLocalDateTimeString } from '../../constants/Utils/DateTime';

// Styled
const StyledCalendarOutlined = styled(CalendarOutlined)`
  font-size: 24px;
`;

// Props
interface ContactCardProps {
  scheduleConfigurationData: ScheduleConfiguration | null;
  pending: boolean;
}

// Component
export const ContactCard = ({ scheduleConfigurationData, pending }: ContactCardProps) => {
  // Hooks
  const { updatingEntityId } = useAppSelector(({ scheduleConfigurations }) => scheduleConfigurations);

  return (
    <Widget title={<Translated id="app.scheduleConfiguration" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updatingEntityId}>
        {/* Name */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <i className="icon icon-avatar gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="scheduleConfigurations.scheduleConfigurationType" />
            </span>
            <div className="gx-mb-0">
              <span>
                {pending ? (
                  <ButtonSkeleton width={160} height={16} />
                ) : (
                  ScheduleConfigurationTypes.find(
                    (type) => type.Value === scheduleConfigurationData?.ScheduleConfigurationType
                  )?.Name
                )}
              </span>
            </div>
          </div>
        </div>

        {/* Status */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <StyledCalendarOutlined className="gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="scheduleConfigurations.lastRun" />
            </span>
            <div className="gx-mb-0">
              <span>{getLocalDateTimeString(scheduleConfigurationData?.LastRun)}</span>
            </div>
          </div>
        </div>
      </Spinner>
    </Widget>
  );
};

import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useTenantsList } from '../../hooks/Tenants/useTenantsList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const TenantsPage = () => {
  const {
    updating,
    getBreadcrumbMenuProps,
    getTenantsDataProps,
    getTenantsTableProps,
    getTenantsCrudProps,
    getFormDrawerProps,
  } = useTenantsList();

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        <Row>
          <Col span={24}>
            <Table {...getTenantsDataProps()} {...getTenantsTableProps()} {...getTenantsCrudProps()} />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};

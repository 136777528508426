/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { ScheduleConfiguration } from '../../models/ScheduleConfiguration';
import { SignalRAction } from '../../models/SignalRAction';
import { ScheduleSettingsFormValues } from '../../models/ScheduleSettingFormValues';

// State
interface ScheduleConfigurationsState {
  updating: boolean;
  error: boolean;
  updatingEntityId?: string;
}

const initialState: ScheduleConfigurationsState = {
  updating: false,
  error: false,
  updatingEntityId: undefined,
};

// Name
const storeName = '@SCHEDULE_CONFIGURATIONS';

// Redux Actions|Reducers
const scheduleConfigurationsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createScheduleConfiguration: (state, action: PayloadAction<ScheduleConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    scheduleConfigurationCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    scheduleConfigurationNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateScheduleConfiguration: (state, action: PayloadAction<ScheduleSettingsFormValues>) => {
      state.updating = true;
      state.error = false;
    },
    scheduleConfigurationUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    scheduleConfigurationNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteScheduleConfiguration: (state, action: PayloadAction<ScheduleConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    scheduleConfigurationDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    scheduleConfigurationNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteScheduleConfigurations: (state, action: PayloadAction<Array<ScheduleConfiguration>>) => {
      state.updating = true;
      state.error = false;
    },
    scheduleConfigurationsDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    scheduleConfigurationsNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createScheduleConfiguration,
  scheduleConfigurationCreated,
  scheduleConfigurationNotCreated,
  // Update
  updateScheduleConfiguration,
  scheduleConfigurationUpdated,
  scheduleConfigurationNotUpdated,
  // Delete
  deleteScheduleConfiguration,
  scheduleConfigurationDeleted,
  scheduleConfigurationNotDeleted,
  // Delete All
  deleteScheduleConfigurations,
  scheduleConfigurationsDeleted,
  scheduleConfigurationsNotDeleted,
} = scheduleConfigurationsSlice.actions;

// Export Reducer
export default scheduleConfigurationsSlice.reducer;

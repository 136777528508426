import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { IdType } from 'react-table';
import { TreeItem } from '../../components/UI/Tree/TreeForm';
import { TableColumn } from '../../types/Table';
import { getFromLocalStorage, saveToLocalStorage } from './LocalStorage';

/* **** CheckableTree **** */

// Gets all leaves from tree
type GetTreeChildrenFunc = (items: Array<TreeItem>) => Array<TreeItem>;
export const getTreeChildren: GetTreeChildrenFunc = (items) =>
  items.reduce<Array<TreeItem>>((array, current) => {
    if (current.children && current.children.length) {
      return [...array, ...getTreeChildren(current.children)];
    }
    return [...array, current];
  }, []);

// Gets all leaves from tree filtered by 'keys'
type GetTreeCheckedChildrenIdsFunc = (items: Array<TreeItem>, keys: Array<string>) => Array<string>;
export const getTreeCheckedChildrenIds: GetTreeCheckedChildrenIdsFunc = (items, keys) =>
  items.reduce<Array<string>>((array, current) => {
    if (current.children && current.children.length) {
      return [...array, ...getTreeCheckedChildrenIds(current.children, keys)];
    }
    if (keys.includes(current.key)) {
      return [...array, current.id];
    }
    return array;
  }, []);

/* **** Table **** */

// Gets all table columns and checks local storage for saved hidden columns
type GetTableColumnsFunc = <T extends object>(tableColumns: TableColumn<T>[], key: string) => TableColumn<T>[];
export const getTableColumns: GetTableColumnsFunc = (tableColumns, key) => {
  const savedHiddenColumns = getFromLocalStorage<[]>(key);
  return savedHiddenColumns
    ? tableColumns.map((c) => ({
        ...c,
        isVisible: !savedHiddenColumns.some((col) => col === c.id),
      }))
    : tableColumns;
};

type SaveHiddenTableColumnsFunc = <T extends object>(hiddenColumns: IdType<T>[], key: string) => void;
export const saveHiddenTableColumns: SaveHiddenTableColumnsFunc = (hiddenColumns, key) =>
  saveToLocalStorage(key, hiddenColumns);

/* **** Menu **** */
type findOpenKeyFunc = (key: string, array: Array<ItemType>) => string;
export const findOpenKey: findOpenKeyFunc = (key, array) =>
  array
    .find((o) => {
      if (o?.key === key) return o;
      return findOpenKey(key, o && 'children' in o ? o.children || [] : []);
    })
    ?.key?.toString() ?? '';

/* **** Input Auto-Width **** */
export const getInputWidth = (value: string | number, padding = 16) => {
  const length = value ? value.toString().length : 1;
  return length * 10 + padding;
};

import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import { IdentifierType, IdentifierTypeResponse } from '../../../models/IdentifierType';

// Utils
import { getTreeCheckedChildrenIds, getTreeChildren } from '../../../constants/Utils/UIFunctions';
import { getPACSIdentifierTypeTreeItems } from '../../../constants/Utils/TreeFunctions';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import {
  assignPACSIdentifierTypes,
  syncPACSIdentifierTypes,
} from '../../../store/IdentifierTypes/PACSIdentifierTypes/PACSIdentifierTypes.redux';

// Components
import { TreeForm } from '../../../components/UI/Tree/TreeForm';

const { confirm } = Modal;

// Props
interface PACSIdentifierTypesReduxProps {
  identifierTypeResponse: IdentifierTypeResponse | null;
}

interface AssignConfirmProps {
  pacsIdentifierTypeIds: Array<string>;
  identifierType: IdentifierType;
}

// Hook
export const usePACSIdentifierTypesRedux = ({ identifierTypeResponse }: PACSIdentifierTypesReduxProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(
    ({ pacsIdentifierTypes: pacsIdentifierTypesState }) => pacsIdentifierTypesState?.updating ?? false
  );
  const error = useAppSelector(
    ({ pacsIdentifierTypes: pacsIdentifierTypesState }) => pacsIdentifierTypesState?.error ?? false
  );

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ pacsIdentifierTypeIds, identifierType }: AssignConfirmProps) => {
      if (pacsIdentifierTypeIds && identifierType) {
        confirm({
          title: intl.formatMessage({
            id: 'identifierTypes.checkableTree.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'identifierTypes.checkableTree.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignPACSIdentifierTypes({ identifierType, pacsIdentifierTypeIds })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showSyncConfirm = useCallback(() => {
    confirm({
      title: intl.formatMessage({
        id: 'identifierTypes.pacsIdentifierTypes.confirm.sync',
      }),
      icon: <IdcardOutlined />,
      content: intl.formatMessage({
        id: 'identifierTypes.pacsIdentifierTypes.confirm.syncSub',
      }),
      okText: intl.formatMessage({
        id: 'app.yes',
        defaultMessage: 'Yes',
      }),
      cancelText: intl.formatMessage({
        id: 'app.no',
        defaultMessage: 'No',
      }),
      okType: 'primary',
      onOk: () => dispatch(syncPACSIdentifierTypes()),
      onCancel: () => null,
    });
  }, [intl, dispatch]);

  // Tree Items
  const treeItems = useMemo(
    () => getPACSIdentifierTypeTreeItems(identifierTypeResponse?.AvailablePacsIdentifierTypes ?? []),
    [identifierTypeResponse]
  );

  // Get the Keys (Id) from all PACS of an Identifier Type
  const initialKeys = useMemo(
    () =>
      getTreeChildren(treeItems)
        ?.filter((item) => identifierTypeResponse?.PacsIdentifierTypes?.some((ap) => ap.Id === item.id))
        .map((item) => item.key) ?? [],
    [treeItems, identifierTypeResponse]
  );

  // Form
  const PACSIdentifierTypesManagementForm = useCallback(
    () => (
      <TreeForm
        dataSource={treeItems}
        initialKeys={initialKeys}
        onSave={(ids: Array<string>) =>
          identifierTypeResponse &&
          identifierTypeResponse.IdentifierType &&
          showAssignConfirm({
            pacsIdentifierTypeIds: getTreeCheckedChildrenIds(treeItems, ids),
            identifierType: identifierTypeResponse.IdentifierType,
          })
        }
      />
    ),
    [treeItems, initialKeys, identifierTypeResponse, showAssignConfirm]
  );

  return useMemo(
    () => ({
      PACSIdentifierTypesManagementForm,
      updating,
      error,
      showSyncConfirm,
    }),
    [PACSIdentifierTypesManagementForm, updating, error, showSyncConfirm]
  );
};

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { IdentityUser } from '../../../models/IdentityUser';
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  assignIdentityUserRoles,
  identityUserRolesAssigned,
  identityUserRolesNotAssigned,
} from './IdentityUserRoles.redux';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignIdentityUserRolesSaga() {
  yield takeEvery(assignIdentityUserRoles.type, assignIdentityUserRolesRequest);
}

function* identityUserRolesAssignedSaga() {
  yield takeLatest(identityUserRolesAssigned.type, assignIdentityUserRolesResponse);
}

function* identityUserRolesNotAssignedSaga() {
  yield takeLatest(identityUserRolesNotAssigned.type, assignIdentityUserRolesError);
}

// Request
function* assignIdentityUserRolesRequest(
  action: PayloadAction<{ identityUser: IdentityUser; roleIds: Array<string> }>
) {
  try {
    const {
      payload: { identityUser, roleIds },
    } = action;
    yield apiService.execute({
      url: `IdentityUsers/${identityUser.Id}/Roles`,
      method: ApiRequestType.PUT,
      data: {
        IdentityUserId: identityUser.Id,
        RoleIds: roleIds,
      },
    });
  } catch ({ message }) {
    yield put({ type: identityUserRolesNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignIdentityUserRolesResponse() {
  notificationService.showSuccess('identityUsers.Roles.notifications.assign');
}

// Error
function assignIdentityUserRolesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'identityUsers.Roles.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignIdentityUserRolesSaga(),
    identityUserRolesAssignedSaga(),
    identityUserRolesNotAssignedSaga(),
  ]);
}

import React from 'react';
import { Col, Row, Skeleton, Tabs } from 'antd';
import styled from 'styled-components';

// Models
import { ScheduleConfigurationResponse } from '../../models/ScheduleConfiguration';

// Components
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Translated } from '../UI/Core';
import { useScheduleSettings } from '../../hooks/ScheduleConfigurations/SettingsForm/useScheduleSettings';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }
  & .ant-tabs-nav {
    height: 58px;
  }
  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
`;

// Props
interface ServiceSettingsProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
  fetchScheduleConfiguration: (params: any) => Promise<void>;
  updatingScheduleConfiguration: boolean;
}

// Component
export const ScheduleSettings = ({
  scheduleConfigurationResponse,
  fetchScheduleConfiguration,
  updatingScheduleConfiguration,
}: ServiceSettingsProps) => {
  // Redux
  const { ScheduleSettingManagementForm, updating } = useScheduleSettings({
    scheduleConfigurationResponse,
    fetchScheduleConfiguration,
    updatingScheduleConfiguration,
  });

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = () => [
    {
      key: '0',
      label: <Translated id="scheduleConfigurations.tab" />,
      children: (
        <Spinner spinning={updating}>
          <ScheduleSettingManagementForm />
        </Spinner>
      ),
    },
  ];

  const renderItems = () => {
    if (!scheduleConfigurationResponse) return renderLoading();
    return renderForm();
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderItems()} />
    </StyledWidget>
  );
};

import React from 'react';
import { Tag, Tooltip } from 'antd';
import { useIntl } from 'react-intl';

// Utils
import styled from 'styled-components';
import moment from 'moment';
import { getLocalDateTimeString } from '../../../constants/Utils/DateTime';

// Models
import { ApiClient } from '../../../models/ApiClient';
import { ApiClientStatus } from '../../../models/enums/ApiClientStatus';

// Components
import { Translated } from '../Core';
import { getApiClientStatus } from '../../../constants/Utils/StatusFunctions';

// Styled
const StyledTag = styled(Tag)`
  margin-bottom: 0;
`;

// Props
interface ApiClientStatusTagProps {
  apiClient: ApiClient;
}

// Component
export const ApiClientStatusTag = ({ apiClient }: ApiClientStatusTagProps) => {
  // Hooks
  const intl = useIntl();

  // Render Tag based on Status and LastChecked
  const { Color, NameId } = getApiClientStatus(apiClient);

  return apiClient.ApiClientStatus === ApiClientStatus.NotHeardFrom ? (
    <div />
  ) : (
    <Tooltip
      placement="bottomLeft"
      title={
        apiClient.LastHeardFrom &&
        intl.formatMessage(
          { id: 'serviceConfigurations.status.lastChecked' },
          { date: getLocalDateTimeString(moment(apiClient.LastHeardFrom)) }
        )
      }
    >
      <StyledTag color={Color}>
        <Translated id={NameId} />
      </StyledTag>
    </Tooltip>
  );
};

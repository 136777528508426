import React from 'react';
import { Col, Empty, Row, Skeleton, Tabs } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

// Models
import { Action } from '../../models/Action';
// Hooks
import { useActionTenants } from '../../hooks/Actions/ActionTenants/useActionTenants';
// Components
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Translated } from '../UI/Core';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }

  & .ant-tabs-nav {
    height: 58px;
  }

  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
`;

// Props
interface ActionTenantsProps {
  actionResponse: Action | null;
  fetchAction: (params: any) => Promise<void>;
  updatingAction: boolean;
}

// Component
export const ActionTenants = ({ actionResponse, fetchAction, updatingAction }: ActionTenantsProps) => {
  // Identifiers Hook
  const { loading, updating, actionTenants, ActionTenantsManagementForm } = useActionTenants({
    actionResponse,
    fetchAction,
    updatingAction,
  });

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderEmpty = () => [
    {
      key: '0',
      label: <Translated id="action.Tenants.tab" />,
      children: (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="action.Tenants.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = () => [
    {
      key: '0',
      label: <Translated id="action.Tenants.tab" />,
      children: (
        <Spinner spinning={updating}>
          <ActionTenantsManagementForm />
        </Spinner>
      ),
    },
  ];

  const renderItems = () => {
    if (loading) return renderLoading();
    if (!actionTenants || isEmpty(actionTenants)) return renderEmpty();
    return renderForm();
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderItems()} />
    </StyledWidget>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Assets
import wideLogo from '../../../assets/images/bf_logo.png';
import iconLogo from '../../../assets/images/bf_logo_icon.png';

const StyledLogoImg = styled.img`
  height: 30px;
  margin-left: 6px;
`;

export const AppLogo = () => (
  <>
    <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
      <StyledLogoImg alt="" src={iconLogo} />
    </Link>
    <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
      <StyledLogoImg alt="" src={wideLogo} />
    </Link>
  </>
);

export enum CalendarEventConfigurationProtocolEnum {
  Custom = 0,
  Outlook = 1,
  Gmail = 2,
}

export interface CalendarEventConfigurationProtocolType {
  Name: string;
  Value: number;
  ApiConfiguration: CalendarEventConfigurationProtocolEnum;
}

export const CalendarEventConfigurationProtocolTypes: CalendarEventConfigurationProtocolType[] = [
  {
    Name: 'Custom',
    Value: 0,
    ApiConfiguration: CalendarEventConfigurationProtocolEnum.Custom,
  },
  {
    Name: 'Outlook',
    Value: 1,
    ApiConfiguration: CalendarEventConfigurationProtocolEnum.Outlook,
  },
  {
    Name: 'Gmail',
    Value: 2,
    ApiConfiguration: CalendarEventConfigurationProtocolEnum.Gmail,
  },
];

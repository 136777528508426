import React, { PropsWithChildren } from 'react';
import Tooltip, { TooltipPlacement } from 'antd/lib/tooltip';
import styled from 'styled-components';

const StyledSpan = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;

interface CustomTooltipProps {
  title: React.ReactNode;
  placement: TooltipPlacement;
}

export const StyledTooltip = ({ children, title, placement }: PropsWithChildren<CustomTooltipProps>) => {
  return (
    <Tooltip title={<StyledSpan>{title}</StyledSpan>} placement={placement} mouseEnterDelay={0.3}>
      {children}
    </Tooltip>
  );
};

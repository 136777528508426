import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { Action } from '../../../models/Action';

// Hooks
import { useActionTenantsRedux } from './useActionTenantsRedux';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface ActionTenantsProps {
  actionResponse: Action | null;
  fetchAction: (params: any) => Promise<void>;
  updatingAction: boolean;
}

export const useActionTenants = ({
  actionResponse: actionTenantsResponse,
  fetchAction,
  updatingAction: updatingActionTenant,
}: ActionTenantsProps) => {
  // Data
  const { data: actionTenants, fetch: fetchActionTenants, pending } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const { ActionTenantsManagementForm, updating, error } = useActionTenantsRedux({
    actionTenantsResponse,
    actionTenants,
  });
  const prevUpdating = usePrevious(updating);

  // Component State
  const loading = pending && !actionTenantsResponse;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchActionTenants();
  }, [fetchActionTenants]);

  useEffect(() => {
    // Fetch after updating
    if (actionTenantsResponse && prevUpdating === true && updating === false && !error) {
      fetchAction({ id: actionTenantsResponse.Id });
      fetchActionTenants();
    }
  }, [prevUpdating, updating, error, fetchAction, fetchActionTenants, actionTenantsResponse]);

  // Return Hook
  return useMemo(
    () => ({
      actionTenants,
      ActionTenantsManagementForm,
      loading,
      updating: updating || updatingActionTenant,
    }),
    [actionTenants, ActionTenantsManagementForm, loading, updating, updatingActionTenant]
  );
};

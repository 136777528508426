// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  scheduleConfigurationCreated,
  scheduleConfigurationDeleted,
  scheduleConfigurationNotCreated,
  scheduleConfigurationNotDeleted,
  scheduleConfigurationNotUpdated,
  scheduleConfigurationsDeleted,
  scheduleConfigurationsNotDeleted,
  scheduleConfigurationUpdated,
} from './ScheduleConfigurations.redux';

// Events
const SignalREvents = {
  ScheduleConfigurationCreated: `ScheduleConfigurationCreatedIntegrationEvent`,
  ScheduleConfigurationUpdated: `ScheduleConfigurationUpdatedIntegrationEvent`,
  ScheduleConfigurationDeleted: `ScheduleConfigurationDeletedIntegrationEvent`,
  ScheduleConfigurationsDeleted: `ScheduleConfigurationsDeletedIntegrationEvent`,
  ScheduleConfigurationStatusSet: `ScheduleConfigurationStatusSetIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  ScheduleConfigurationNotCreated: `ScheduleConfigurationNotCreatedIntegrationException`,
  ScheduleConfigurationNotUpdated: `ScheduleConfigurationNotUpdatedIntegrationException`,
  ScheduleConfigurationNotDeleted: `ScheduleConfigurationNotDeletedIntegrationException`,
  ScheduleConfigurationsNotDeleted: `ScheduleConfigurationsNotDeletedIntegrationException`,
  ScheduleConfigurationStatusNotSet: `ScheduleConfigurationStatusNotSetIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.ScheduleConfigurationCreated, (msg) => {
    store.dispatch(scheduleConfigurationCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ScheduleConfigurationNotCreated, (msg) => {
    store.dispatch(scheduleConfigurationNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.ScheduleConfigurationUpdated, (msg) => {
    store.dispatch(scheduleConfigurationUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ScheduleConfigurationNotUpdated, (msg) => {
    store.dispatch(scheduleConfigurationNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.ScheduleConfigurationDeleted, (msg) => {
    store.dispatch(scheduleConfigurationDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ScheduleConfigurationNotDeleted, (msg) => {
    store.dispatch(scheduleConfigurationNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.ScheduleConfigurationsDeleted, (msg) => {
    store.dispatch(scheduleConfigurationsDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ScheduleConfigurationsNotDeleted, (msg) => {
    store.dispatch(scheduleConfigurationsNotDeleted({ history, msg }));
  });
};

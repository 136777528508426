import React from 'react';

import { Typography } from 'antd';
import { TableColumn } from '../../types/Table';
import { ApiClient } from '../../models/ApiClient';
import { Translated } from '../../components/UI/Core';
import { ApiConfigurationTypes } from '../../models/enums/ApiConfigurationTypes';
import { ApiClientStatusTag } from '../../components/UI/Tag/ApiClientStatusTag';

export const apiClientTableColumns: Array<TableColumn<ApiClient>> = [
  {
    Header: <Translated id="clients.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="clients.clientName" />,
    id: 'ClientName',
    accessor: 'ClientName',
  },
  {
    Header: <Translated id="clients.apiConfigurationType" />,
    id: 'ApiConfigurationType',
    accessor: (row) => ApiConfigurationTypes.find((type) => type.Value === row.ApiConfigurationType)?.Name,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="clients.clientId" />,
    id: 'ClientId',
    accessor: (row) => <Typography.Text copyable>{row.ClientId}</Typography.Text>,
    disableFilters: true,
  },
  {
    Header: <Translated id="apiConfigurations.status" />,
    id: 'Status',
    accessor: (row) => <ApiClientStatusTag apiClient={row} />,
    disableSortBy: true,
    disableFilters: true,
  },
];

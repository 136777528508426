import React, { useCallback, useMemo } from 'react';
import { UsePaginationInstanceProps } from 'react-table';
import { Button, Input, InputNumber, Select } from 'antd';
import styled from 'styled-components';

// Components
import { Flex, Text } from '../../components/UI/Base';
import { Translated } from '../../components/UI/Core';
import { PaginationIcon } from '../../components/UI/Table/elements/Icons/PaginationIcon';

// Utils
import { getInputWidth } from '../../constants/Utils/UIFunctions';

// Props
interface PaginationProps<T extends object> extends UsePaginationInstanceProps<T> {
  totalCount: number;
  state: {
    pageIndex: number;
    pageSize: number;
  };
}

// Components
const { Option } = Select;

// Styled
const StyledButton = styled(Button)`
  padding: 0px 4px;
  margin-bottom: 0;

  :focus,
  :active,
  :active:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;
const StyledButtonLeft = styled(StyledButton)`
  margin-right: 0 !important;
  border-right: none;
  border-radius: 4px 0 0 4px;
`;
const StyledButtonRight = styled(StyledButton)`
  border-left: none;
  border-radius: 0 4px 4px 0;
`;
const StyledInputNumber = styled(InputNumber)`
  border-radius: 0 4px 4px 0;
  margin-right: 0;
`;
const StyledInput = styled(Input)`
  text-align: center;
  border-radius: 4px 0 0 4px;
  margin-right: 0;
`;
const StyledInputTotal = styled(StyledInput)`
  border-radius: 4px;
`;

// Hook
export const usePagination = <T extends object>({
  totalCount,
  canPreviousPage,
  canNextPage,
  pageOptions,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  // Instance State
  state: { pageIndex, pageSize },
}: PaginationProps<T>) => {
  // Components
  const PageChanger = useCallback(
    () => (
      <Flex>
        <StyledButtonLeft size="small" color="primary" onClick={() => previousPage()} disabled={!canPreviousPage}>
          <PaginationIcon left />
        </StyledButtonLeft>
        <StyledInputNumber
          controls={false}
          size="small"
          min={1}
          max={pageOptions.length || 1}
          defaultValue={pageIndex + 1}
          style={{ width: getInputWidth(pageIndex + 1) }}
          onChange={(value) => {
            const page = value ? Number(value) - 1 : 0;
            gotoPage(page);
          }}
        />
        <Flex margin="0 4px 0 4px" alignItems="center">
          <Text>
            <Translated id="app.of" />
          </Text>
        </Flex>
        <StyledInput
          style={{ width: getInputWidth(pageOptions.length) }}
          size="small"
          disabled
          defaultValue={pageOptions.length || 1}
        />
        <StyledButtonRight size="small" color="primary" onClick={() => nextPage()} disabled={!canNextPage}>
          <PaginationIcon right />
        </StyledButtonRight>
      </Flex>
    ),
    [pageIndex, canPreviousPage, canNextPage, pageOptions, gotoPage, nextPage, previousPage]
  );

  const PageSizeChanger = useCallback(
    () => (
      <Flex>
        <Select
          size="small"
          defaultValue={pageSize}
          onChange={(value) => {
            setPageSize(Number(value));
          }}
        >
          {[20, 50, 100, 500].map((size) => (
            <Option key={size} value={size}>
              {size}
            </Option>
          ))}
        </Select>
      </Flex>
    ),
    [pageSize, setPageSize]
  );

  const PageItemsCount = useCallback(() => {
    const start = Math.min(pageIndex * pageSize + 1, totalCount);
    const end = Math.min((pageIndex + 1) * pageSize, totalCount);
    const count = `${start} - ${end}`;
    return (
      <Flex>
        <StyledInputTotal
          style={{ width: getInputWidth(count, -8) }}
          size="small"
          disabled
          defaultValue={count ?? '-'}
        />
        <Flex margin="0 4px 0 4px" alignItems="center">
          <Text>
            <Translated id="app.of" />
          </Text>
        </Flex>
        <StyledInputTotal
          style={{ width: getInputWidth(totalCount) }}
          size="small"
          disabled
          defaultValue={totalCount ?? '-'}
        />
      </Flex>
    );
  }, [pageIndex, pageSize, totalCount]);

  return useMemo(
    () => ({
      PageChanger,
      PageSizeChanger,
      PageItemsCount,
    }),
    [PageChanger, PageSizeChanger, PageItemsCount]
  );
};

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { IdentifierType } from '../../../models/IdentifierType';
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  assignIdentifierTypeTenants,
  identifierTypeTenantsAssigned,
  identifierTypeTenantsNotAssigned,
} from './IdentifierTypeTenants.redux';
import { Tenant } from '../../../models/Tenant';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignIdentifierTypeTenantsSaga() {
  yield takeEvery(assignIdentifierTypeTenants.type, assignIdentifierTypeTenantsRequest);
}

function* identifierTypeTenantsAssignedSaga() {
  yield takeLatest(identifierTypeTenantsAssigned.type, assignIdentifierTypeTenantsResponse);
}

function* identifierTypeTenantsNotAssignedSaga() {
  yield takeLatest(identifierTypeTenantsNotAssigned.type, assignIdentifierTypeTenantsError);
}

// Request
function* assignIdentifierTypeTenantsRequest(
  action: PayloadAction<{ identifierType: IdentifierType; tenants: Array<Tenant> }>
) {
  try {
    const {
      payload: { identifierType, tenants },
    } = action;
    yield apiService.execute({
      url: `IdentifierTypes/${identifierType.Id}/Tenants`,
      method: ApiRequestType.PUT,
      data: {
        IdentifierTypeId: identifierType.Id,
        Tenants: tenants,
      },
    });
  } catch ({ message }) {
    yield put({ type: identifierTypeTenantsNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignIdentifierTypeTenantsResponse() {
  notificationService.showSuccess('identifierTypes.Tenants.notifications.assign');
}

// Error
function assignIdentifierTypeTenantsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'identifierTypes.Tenants.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************

// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignIdentifierTypeTenantsSaga(),
    identifierTypeTenantsAssignedSaga(),
    identifierTypeTenantsNotAssignedSaga(),
  ]);
}

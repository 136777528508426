import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { ServiceConfiguration } from '../../../models/ServiceConfiguration';
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  assignServiceConfigurationTenants,
  serviceConfigurationTenantsAssigned,
  serviceConfigurationTenantsNotAssigned,
} from './ServiceConfigurationTenants.redux';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignServiceConfigurationTenantsSaga() {
  yield takeEvery(assignServiceConfigurationTenants.type, assignServiceConfigurationTenantsRequest);
}

function* serviceConfigurationTenantsAssignedSaga() {
  yield takeLatest(serviceConfigurationTenantsAssigned.type, assignServiceConfigurationTenantsResponse);
}

function* serviceConfigurationTenantsNotAssignedSaga() {
  yield takeLatest(serviceConfigurationTenantsNotAssigned.type, assignServiceConfigurationTenantsError);
}

// Request
function* assignServiceConfigurationTenantsRequest(
  action: PayloadAction<{ serviceConfiguration: ServiceConfiguration; tenantIds: Array<string> }>
) {
  try {
    const {
      payload: { serviceConfiguration, tenantIds },
    } = action;
    yield apiService.execute({
      url: `ServiceConfigurations/${serviceConfiguration.Id}/Tenants`,
      method: ApiRequestType.PUT,
      data: {
        ServiceConfigurationId: serviceConfiguration.Id,
        TenantIds: tenantIds,
      },
    });
  } catch ({ message }) {
    yield put({ type: serviceConfigurationTenantsNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignServiceConfigurationTenantsResponse() {
  notificationService.showSuccess('serviceConfigurations.Tenants.notifications.assign');
}

// Error
function assignServiceConfigurationTenantsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'serviceConfigurations.Tenants.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************

// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignServiceConfigurationTenantsSaga(),
    serviceConfigurationTenantsAssignedSaga(),
    serviceConfigurationTenantsNotAssignedSaga(),
  ]);
}

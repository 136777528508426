import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Hooks
import { useAzureGroupsRedux } from './useAzureGroupsRedux';

// Data
import { AzureTenant } from '../../../models/AzureTenant';

// Props
interface AzureGroupTenantsProps {
  azureTenantResponse: AzureTenant | undefined;
  fetchAzureTenant: (params: any) => Promise<void>;
  updatingAzureTenant: boolean;
}

export const useAzureGroups = ({
  azureTenantResponse,
  fetchAzureTenant,
  updatingAzureTenant,
}: AzureGroupTenantsProps) => {
  // Redux
  const { AzureGroupsManagementForm, updating, error, SyncButton } = useAzureGroupsRedux({
    azureTenantResponse,
  });
  const prevUpdating = usePrevious(updating);

  // Component State
  const loading = !azureTenantResponse;

  // Effects
  useEffect(() => {
    // Fetch after updating
    if (azureTenantResponse && prevUpdating === true && !updating && !error) {
      fetchAzureTenant({ id: azureTenantResponse.Id });
    }
  }, [prevUpdating, updating, error, azureTenantResponse, fetchAzureTenant]);

  // Return Hook
  return useMemo(
    () => ({
      AzureGroupsManagementForm,
      loading,
      updating: updating || updatingAzureTenant,
      SyncButton,
    }),
    [AzureGroupsManagementForm, SyncButton, loading, updating, updatingAzureTenant]
  );
};

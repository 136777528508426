import { useEffect, useMemo } from 'react';

// Hooks
import { useReduxLocation } from './useLocation';
import { useAppDispatch, useAppSelector } from './useRedux';

// Constants
import { AppLocale } from '../../constants/AppLanguages/AppLocale';
import { isInThePast } from '../../constants/Utils/DateTime';

// Redux Actions
import { testServiceConfigurations } from '../../store/ServiceConfigurations/ServiceConfigurations.redux';

export const useAppSettings = () => {
  // Redux Location
  useReduxLocation();

  // Redux
  const dispatch = useAppDispatch();
  const { lastChecked, locale } = useAppSelector(({ appSettings }) => appSettings ?? {});

  // App Locale
  const currentAppLocale = AppLocale[locale?.locale ?? 'en'];

  // Effects
  useEffect(() => {
    // Update Environments when LastChecked is older than 1 day
    if (!lastChecked || isInThePast(lastChecked)) {
      dispatch(testServiceConfigurations());
    }
  }, [lastChecked, dispatch]);

  return useMemo(
    () => ({
      currentAppLocale,
    }),
    [currentAppLocale]
  );
};

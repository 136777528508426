import { uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { usePrevious } from 'react-use';

// Data
import { useIntl } from 'react-intl';
import { apiActivityHistory, apiMetrics, yearData } from '../../components/Dashboard/ExtraImports/Data';

// Models
import { BillingStatsContent } from '../../models/Dashboard/Content/BillingStatsContent';
import { EnvironmentCardContent } from '../../models/Dashboard/Content/EnvironmentCardContent';
import { ManagementStatsContent } from '../../models/Dashboard/Content/ManagementStatsContent';
import { ActivityHistoryContent } from '../../models/ActivityHistoryContent';

// Hooks
import { useAppSelector } from '../App/useRedux';

// Components
import { Translated } from '../../components/UI/Core';
import { DashboardIcon } from '../../components/UI/Icon/DashboardIcon';
import { useData } from '../App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { DashboardDataTypes } from '../../models/enums/DashboardDataTypes';
import { useDashboardFilters } from './useDashboardFilters';

// Styled
const BoldSpan = styled.span`
  font-weight: bold;
`;
const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
  margin-top: 2px;
`;

// Hook
export const useDashboardData = () => {
  // Intl
  const translate = useIntl();

  // Redux
  const width = useAppSelector(({ appSettings }) => appSettings.width);
  const { updatingEntityId } = useAppSelector(({ serviceConfigurations }) => serviceConfigurations);
  const prevUpdatingEntityId = usePrevious(updatingEntityId);

  // Data
  const { data, fetch, pending } = useData(ApiEndpoints.dashboard.data, null);
  const { ManagementStats, BillingStats, EnvironmentStats, EnvironmentFilters } = data || {};

  // Filters
  const { activeFilters, filteredContent, isFiltering, filterCount } = useDashboardFilters({
    content: EnvironmentStats,
  });

  // Props
  const getManagementStatsProps = useCallback(
    () => ({
      content: ManagementStats?.map(
        (item) =>
          ({
            id: item.Id,
            value: item.Value,
            type: item.Type,
            icon:
              item.Type === DashboardDataTypes.ApplicationUsers ? (
                <DashboardIcon className="icon icon-auth-screen" />
              ) : item.Type === DashboardDataTypes.Environments ? (
                <DashboardIcon className="icon icon-crm" />
              ) : item.Type === DashboardDataTypes.Companies ? (
                <DashboardIcon className="icon icon-company" />
              ) : item.Type === DashboardDataTypes.Locations ? (
                <DashboardIcon className="icon icon-location" />
              ) : (
                <span />
              ),
            label:
              item.Type === DashboardDataTypes.ApplicationUsers ? (
                <Translated id="dashboard.welcomeCard.item1" />
              ) : item.Type === DashboardDataTypes.Environments ? (
                <Translated id="dashboard.welcomeCard.item2" />
              ) : item.Type === DashboardDataTypes.Companies ? (
                <Translated id="dashboard.welcomeCard.item3" />
              ) : item.Type === DashboardDataTypes.Locations ? (
                <Translated id="dashboard.welcomeCard.item4" />
              ) : (
                item.Type
              ),
          } as ManagementStatsContent)
      ),
    }),
    [ManagementStats]
  );

  const getBillingStatsProps = useCallback(
    () => ({
      content: BillingStats?.map(
        (apiAudience) =>
          ({
            id: apiAudience.Id,
            width: apiAudience.Value.toString(),
            title:
              apiAudience.Type === DashboardDataTypes.ServiceConfigurations ? (
                <Translated id="dashboard.audience.accessControlSystems" />
              ) : apiAudience.Type === DashboardDataTypes.AccessProfiles ? (
                <Translated id="dashboard.audience.accessProfiles" />
              ) : apiAudience.Type === DashboardDataTypes.IdentifierTypes ? (
                <Translated id="dashboard.audience.identifierTypes" />
              ) : (
                apiAudience.Type
              ),
            color:
              apiAudience.Type === DashboardDataTypes.ServiceConfigurations
                ? 'green'
                : apiAudience.Type === DashboardDataTypes.AccessProfiles
                ? 'blue'
                : apiAudience.Type === DashboardDataTypes.IdentifierTypes
                ? 'orange'
                : 'grey',
            value: apiAudience.Value,
          } as BillingStatsContent)
      ),
    }),
    [BillingStats]
  );

  const getEnvironmentStatsProps = useCallback(
    () => ({
      loading: pending && !filteredContent,
      isFiltering,
      content: filteredContent?.map(
        (apiCard) =>
          ({
            id: apiCard.Id,
            companyId: apiCard.CompanyId,
            locationId: apiCard.LocationId,
            title: apiCard.Name,
            services: apiCard.Services,
          } as EnvironmentCardContent)
      ),
    }),
    [pending, filteredContent, isFiltering]
  );

  const getSiteMetricsProps = useCallback(
    () => ({
      siteMetrics: apiMetrics.map((apiMetric) => ({
        dataKey: apiMetric.dataKey,
        name: apiMetric.name,
        thisPeriod: apiMetric.thisPeriod,
        lastPeriod: apiMetric.lastPeriod,
      })),
      dataKeys: uniqBy(
        apiMetrics.map((apiMetric) => ({
          dataKey: apiMetric.dataKey,
          type: 'monotone',
          fillOpacity: 0.5,
          stroke: apiMetric.dataKey === 'thisPeriod' ? '#038FDE' : '#FE9E15',
          fill: apiMetric.dataKey === 'thisPeriod' ? '#038FDE' : '#FE9E15',
        })),
        'dataKey'
      ),
    }),
    []
  );

  const getTotalRevenueCardProps = useCallback(
    () => ({
      yearData,
      width,
    }),
    [width]
  );

  const getActivityHistoryProps = useCallback(
    () => ({
      activityHistory: apiActivityHistory.map(
        (content) =>
          ({
            id: content.id,
            period:
              content.period === 'Today'
                ? translate.formatMessage({ id: 'dashboard.activity.history.periodToday' })
                : content.period === 'Last week'
                ? translate.formatMessage({ id: 'dashboard.activity.history.periodLastWeek' })
                : translate.formatMessage({ id: 'dashboard.activity.history.periodLastMonth' }),
            activities: content.activities.map((activity) => ({
              id: activity.id,
              header: [
                <StyledAvatar key={`${activity.id}-${1}`} icon={<UserOutlined />} />,
                <BoldSpan key={`${activity.id}-${2}`}>{activity.author}</BoldSpan>,
                <span key={`${activity.id}-${3}`}> </span>,
                activity.action === 'Created'
                  ? translate.formatMessage(
                      { id: 'dashboard.activity.history.action.create' },
                      { actionTranslation: <BoldSpan>{activity.object}</BoldSpan> }
                    )
                  : activity.action === 'Updated'
                  ? translate.formatMessage(
                      { id: 'dashboard.activity.history.action.update' },
                      { actionTranslation: <BoldSpan>{activity.object}</BoldSpan> }
                    )
                  : activity.action === 'Deleted'
                  ? translate.formatMessage(
                      { id: 'dashboard.activity.history.action.delete' },
                      { actionTranslation: <BoldSpan>{activity.object}</BoldSpan> }
                    )
                  : translate.formatMessage(
                      { id: 'dashboard.activity.history.action' },
                      { actionTranslation: <BoldSpan>{activity.object}</BoldSpan> }
                    ),
                <span key={`${activity.id}-${4}`}> </span>,
              ],
              content: activity.data,
            })),
          } as ActivityHistoryContent)
      ),
    }),
    [translate]
  );

  const getFiltersProps = useCallback(
    () => ({
      loading: pending || !filteredContent,
      content: filteredContent,
      filters: EnvironmentFilters,
      activeFilters,
      filterCount,
    }),
    [activeFilters, pending, filteredContent, filterCount, EnvironmentFilters]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch();
  }, [fetch]);

  useEffect(() => {
    // Fetch after updating a service configurations status
    if (prevUpdatingEntityId != null && updatingEntityId == null) {
      fetch();
    }
  }, [prevUpdatingEntityId, updatingEntityId, fetch]);

  return useMemo(
    () => ({
      getManagementStatsProps,
      getSiteMetricsProps,
      getTotalRevenueCardProps,
      getEnvironmentStatsProps,
      getBillingStatsProps,
      getActivityHistoryProps,
      getFiltersProps,
    }),
    [
      getManagementStatsProps,
      getSiteMetricsProps,
      getTotalRevenueCardProps,
      getEnvironmentStatsProps,
      getBillingStatsProps,
      getActivityHistoryProps,
      getFiltersProps,
    ]
  );
};

import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useParams } from 'react-router';
import { useActionDetail } from '../../hooks/Actions/useActionDetail';

// Components
import { Container } from '../../components/UI/Base';

import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';
import { ActionTenants, ContactCard, ProfileHeader } from '../../components/Actions';
import { ActionSettings } from '../../components/Actions/ActionSettings';

export const ActionsDetailPage = () => {
  const { id } = useParams();
  const {
    updating,
    getBreadcrumbMenuProps,
    getProfileHeaderProps,
    getContactCardProps,
    getFormDrawerProps,
    getActionTenantsProps,
  } = useActionDetail({
    id,
  });

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />
      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <ActionTenants {...getActionTenantsProps()} />
          </Col>

          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <ContactCard {...getContactCardProps()} />
          </Col>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <ActionSettings {...getContactCardProps()} />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};

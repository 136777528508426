// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import {
  serviceConfigurationTenantsAssigned,
  serviceConfigurationTenantsNotAssigned,
} from './ServiceConfigurationTenants.redux';

// Events
const SignalREvents = {
  ServiceConfigurationTenantsAssigned: `ServiceConfigurationTenantsAssignedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  ServiceConfigurationTenantsNotAssigned: `ServiceConfigurationTenantsNotAssignedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.ServiceConfigurationTenantsAssigned, (msg) => {
    store.dispatch(serviceConfigurationTenantsAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ServiceConfigurationTenantsNotAssigned, (msg) => {
    store.dispatch(serviceConfigurationTenantsNotAssigned({ history, msg }));
  });
};

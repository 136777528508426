import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { useIdentifierTypesRedux } from './useIdentifierTypesRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { useAppSelector } from '../App/useRedux';

// Props
interface IdentifierTypeDetailProps {
  id?: string;
}

// Hook
export const useIdentifierTypesDetail = <T extends object>({ id }: IdentifierTypeDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Redux State
  const { updatingSettings } = useAppSelector(({ identifierTypes }) => identifierTypes);
  const prevUpdatingSettings = usePrevious(updatingSettings);

  // Data
  const { data: identifierTypeResponse, fetch, pending } = useData(ApiEndpoints.identifierTypes.detail, null);
  const prevPending = usePrevious(pending);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useIdentifierTypesRedux<T>({
    isEditing: true,
    initialValues: identifierTypeResponse?.IdentifierType as Store,
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.identifierTypes.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      identifierType: identifierTypeResponse?.IdentifierType ?? null,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [identifierTypeResponse, pending, getFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      identifierType: identifierTypeResponse?.IdentifierType ?? null,
      pending,
    }),
    [identifierTypeResponse, pending]
  );
  const getIdentifierTypeTenantsProps = useCallback(
    () => ({
      identifierTypeResponse,
      fetchIdentifierType: fetch,
      updatingIdentifierType: pending,
    }),
    [identifierTypeResponse, fetch, pending]
  );
  const getPACSIdentifierTypesProps = useCallback(
    () => ({
      identifierTypeResponse,
      fetchIdentifierType: fetch,
      updatingIdentifierType: pending,
    }),
    [identifierTypeResponse, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && pending === false && !identifierTypeResponse) {
      notificationService.showError('identifierTypes.notFound');
      navigate('/IdentifierTypes');
    }
  }, [prevPending, pending, identifierTypeResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (
      ((prevUpdating === true && updating === false) ||
        (prevUpdatingSettings === true && updatingSettings === false)) &&
      !error
    ) {
      fetch({ id });
    }
  }, [prevUpdating, updating, prevUpdatingSettings, updatingSettings, error, fetch, id]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getIdentifierTypeTenantsProps,
      getPACSIdentifierTypesProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getIdentifierTypeTenantsProps,
      getPACSIdentifierTypesProps,
    ]
  );
};

import React from 'react';
import { Button, Drawer, Form as AntForm } from 'antd';

import styled from 'styled-components';
import { Translated } from '../Core';
import { useResetFormOnOpen } from '../../../hooks/Drawer/useResetFormOnOpen';
import { FormOptions } from '../../../types/Table';

export interface FormDrawerProps<T extends object> extends FormOptions<T> {
  updating: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledDrawer = styled(Drawer)`
  & .ant-drawer-content-wrapper {
    max-width: 100%;
    width: 736px !important;

    @media (min-width: 800px) {
      width: 378px !important;
    }
  }
`;

export const FormDrawer = <T extends object>({ Form, labels, updating, open, setOpen }: FormDrawerProps<T>) => {
  // Form
  const [form] = AntForm.useForm();
  useResetFormOnOpen({ form, open });

  // Button Handling
  const onClose = () => {
    setOpen(false);
  };
  const onConfirm = () => {
    form.submit();
  };

  return (
    <StyledDrawer
      title={labels.drawerForm ?? <Translated id="form.add" />}
      onClose={onClose}
      open={open}
      headerStyle={{ padding: '8px 0px 16px 0' }}
      bodyStyle={{ padding: '16px 0px 16px 0' }}
      footerStyle={{ padding: '16px 0px 0px 0' }}
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} style={{ marginBottom: 0, marginRight: 8 }}>
            <Translated id="form.cancelButton" />
          </Button>
          <Button loading={updating} onClick={onConfirm} style={{ marginBottom: 0 }} type="primary" htmlType="submit">
            {labels.submitButton ?? <Translated id="form.createButton" />}
          </Button>
        </div>
      }
    >
      <Form form={form} />
    </StyledDrawer>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { useCalendarEventConfigurationsRedux } from './useCalendarEventConfigurationsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { CalendarEventConfiguration } from '../../models/CalendarEventConfiguration';

// Props
interface CalendarEventConfigurationDetailProps {
  id?: string;
}

export const useCalendarEventConfigurationDetail = <T extends object>({
  id,
}: CalendarEventConfigurationDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Data
  const {
    data: calendarEventConfigurationResponse,
    fetch,
    pending,
  } = useData(ApiEndpoints.calendarEventConfigurations.detail, null);
  const prevPending = usePrevious(pending);

  // Component State
  const [calendarEventConfigurationData, setCalendarEventConfigurationData] = useState<CalendarEventConfiguration>();

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useCalendarEventConfigurationsRedux<T>({
    isEditing: true,
    initialValues: calendarEventConfigurationResponse as Store,
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(
    () => ({ breadcrumbs: appBreadcrumbs.calendarEventConfigurations.detail }),
    []
  );
  const getProfileHeaderProps = useCallback(
    () => ({
      calendarEventConfigurationData,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [calendarEventConfigurationData, pending, getFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      calendarEventConfiguration: calendarEventConfigurationResponse,
      pending,
    }),
    [calendarEventConfigurationResponse, pending]
  );
  const getSettingsProps = useCallback(
    () => ({
      calendarEventConfiguration: calendarEventConfigurationData,
      fetchCalendarEventConfiguration: fetch,
      updatingCalendarEventConfiguration: pending,
    }),
    [calendarEventConfigurationData, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && !pending) {
      if (!calendarEventConfigurationResponse) {
        notificationService.showError('calendarEventConfigurations.notFound');
        navigate('/CalendarEventConfigurations');
      }
      // Update Component State based on API response
      else {
        setCalendarEventConfigurationData(calendarEventConfigurationResponse);
      }
    }
  }, [prevPending, pending, calendarEventConfigurationResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (prevUpdating === true && !updating && !error) {
      fetch({ id });
    }
  }, [prevUpdating, updating, error, fetch, id]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getSettingsProps,
    }),
    [updating, getBreadcrumbMenuProps, getProfileHeaderProps, getContactCardProps, getFormDrawerProps, getSettingsProps]
  );
};

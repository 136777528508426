import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { ApiClient } from '../../models/ApiClient';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  apiClientCreated,
  apiClientNotCreated,
  apiClientNotUpdated,
  apiClientsDeleted,
  apiClientsNotDeleted,
  apiClientUpdated,
  createApiClient,
  deleteApiClients,
  updateApiClient,
} from './ApiClients.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createApiClientSaga() {
  yield takeEvery(createApiClient.type, createApiClientRequest);
}

function* apiClientCreatedSaga() {
  yield takeLatest(apiClientCreated.type, createApiClientResponse);
}

function* apiClientNotCreatedSaga() {
  yield takeLatest(apiClientNotCreated.type, createApiClientError);
}

// Request
function* createApiClientRequest(action: PayloadAction<ApiClient>) {
  try {
    const { payload: client } = action;
    yield apiService.execute({
      url: 'ApiClients',
      method: ApiRequestType.POST,
      data: client,
    });
  } catch ({ message }) {
    yield put({ type: apiClientNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createApiClientResponse() {
  notificationService.showSuccess('clients.notifications.create');
}

// Error
function createApiClientError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('clients.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateApiClientSaga() {
  yield takeEvery(updateApiClient.type, updateApiClientRequest);
}

function* apiClientUpdatedSaga() {
  yield takeLatest(apiClientUpdated.type, updateApiClientResponse);
}

function* apiClientNotUpdatedSaga() {
  yield takeLatest(apiClientNotUpdated.type, updateApiClientError);
}

// Request
function* updateApiClientRequest(action: PayloadAction<ApiClient>) {
  try {
    const { payload: client } = action;
    yield apiService.execute({
      url: `ApiClients/${client.Id}`,
      method: ApiRequestType.PUT,
      data: client,
    });
  } catch ({ message }) {
    yield put({ type: apiClientNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateApiClientResponse() {
  notificationService.showSuccess('clients.notifications.update');
}

// Error
function updateApiClientError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('clients.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteApiClientsSaga() {
  yield takeEvery(deleteApiClients.type, deleteApiClientsRequest);
}

function* apiClientsDeletedSaga() {
  yield takeLatest(apiClientsDeleted.type, deleteApiClientsResponse);
}

function* apiClientsNotDeletedSaga() {
  yield takeLatest(apiClientsNotDeleted.type, deleteApiClientsError);
}

// Request
function* deleteApiClientsRequest(action: PayloadAction<Array<ApiClient>>) {
  try {
    const { payload: clients } = action;
    yield apiService.execute({
      url: `ApiClients`,
      method: ApiRequestType.DELETE,
      data: { ids: clients.map((client) => client.Id) },
    });
  } catch ({ message }) {
    yield put({ type: apiClientsNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteApiClientsResponse() {
  notificationService.showSuccess('clients.notifications.deleteAll');
}

// Error
function deleteApiClientsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('clients.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createApiClientSaga(),
    apiClientCreatedSaga(),
    apiClientNotCreatedSaga(),

    // Update
    updateApiClientSaga(),
    apiClientUpdatedSaga(),
    apiClientNotUpdatedSaga(),

    // Delete all
    deleteApiClientsSaga(),
    apiClientsDeletedSaga(),
    apiClientsNotDeletedSaga(),
  ]);
}

import React from 'react';
import { Tag } from 'antd';

// Props
interface CustomTagProps {
  color?: string;
  text?: string | JSX.Element;
}

// Component
export const TextTag = ({ color, text }: CustomTagProps) => <Tag color={color}>{text}</Tag>;

// Default Props
TextTag.defaultProps = {
  color: '',
  text: '',
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { DeleteKioskFileRequest, Kiosk } from '../../models/Kiosk';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface KiosksState {
  updating: boolean;
  updatingDefault: boolean;
  error: boolean;
}

const initialState: KiosksState = {
  updating: false,
  updatingDefault: false,
  error: false,
};

// Name
const storeName = '@KIOSKS';

// Redux Actions|Reducers
const kiosksSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createKiosk: (state, action: PayloadAction<Kiosk>) => {
      state.updating = true;
      state.error = false;
    },
    kioskCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    kioskNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateKiosk: (state, action: PayloadAction<Kiosk>) => {
      state.updating = true;
      state.error = false;
    },
    kioskUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    kioskNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    updateKioskFile: (state, action: PayloadAction<Kiosk>) => {
      state.updating = true;
      state.error = false;
    },
    kioskFileUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    kioskFileNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    updateKioskActions: (state, action: PayloadAction<Kiosk>) => {
      state.updating = true;
      state.error = false;
    },
    kioskActionsUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    kioskActionsNotUpdated: (state, action: PayloadAction<Kiosk>) => {
      state.updating = true;
      state.error = false;
    },

    // Delete
    deleteKiosk: (state, action: PayloadAction<Kiosk>) => {
      state.updating = true;
      state.error = false;
    },
    kioskDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    kioskNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    deleteKioskFile: (state, action: PayloadAction<DeleteKioskFileRequest>) => {
      state.updating = true;
      state.error = false;
    },
    kioskFileDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    kioskFileNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteKiosks: (state, action: PayloadAction<Array<Kiosk>>) => {
      state.updating = true;
      state.error = false;
    },
    kiosksDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    kiosksNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createKiosk,
  kioskCreated,
  kioskNotCreated,
  // Update
  updateKiosk,
  kioskUpdated,
  kioskNotUpdated,
  updateKioskFile,
  kioskFileUpdated,
  kioskFileNotUpdated,
  // Delete
  deleteKiosk,
  kioskDeleted,
  kioskNotDeleted,
  deleteKioskFile,
  kioskFileDeleted,
  kioskFileNotDeleted,
  // Delete All
  deleteKiosks,
  kiosksDeleted,
  kiosksNotDeleted,
  // kiosk Actions
  updateKioskActions,
  kioskActionsUpdated,
  kioskActionsNotUpdated,
} = kiosksSlice.actions;

// Export Reducer
export default kiosksSlice.reducer;

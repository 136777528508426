import React from 'react';

import { TableColumn } from '../../types/Table';
import { Translated } from '../../components/UI/Core';
import { CalendarEventConfiguration } from '../../models/CalendarEventConfiguration';
import { CalendarEventConfigurationProtocolTypes } from '../../models/enums/CalendarEventConfigurationProtocol';

export const calendarEventConfigurationTableColumns: Array<TableColumn<CalendarEventConfiguration>> = [
  {
    Header: <Translated id="calendarEventConfigurations.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="calendarEventConfigurations.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="calendarEventConfigurations.protocol" />,
    id: 'Protocol',
    accessor: (row) => CalendarEventConfigurationProtocolTypes.find((type) => type.Value === row.Protocol)?.Name,
  },
];

import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { jwtDecode, JwtPayload } from 'jwt-decode';

// Settings
import { ApplicationName, ApplicationPaths, ClaimTypeRole } from '../../constants/Settings/AuthSettings';

// Props
export interface AuthState {
  isAuthenticated: boolean;
  subscriptionId?: number;
  user?: void | User | null;
  isInitialized: boolean;
  userRoles: string[];
}

export interface JwtPayloadWithRoles extends JwtPayload {
  [ClaimTypeRole]: string[];
}

interface CallbackProps {
  callback: CallbackFunc;
  subscriptionId: number;
}

interface AuthResponse {
  status: string;
  data?: any;
}

// Types
type CallbackFunc = (state: AuthState) => void;
type GetStateFunc = (subscriptionId?: number) => AuthState;
type ResponseFunc = (data?: any) => AuthResponse;

// Constants
export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail',
};

// Service
class AuthorizeService {
  // Service Subscribers
  private serviceCallbacks: Array<CallbackProps>;

  private serviceNextSubscriptionId: number;

  // Service State
  private userManager: UserManager | null;

  private serviceIsAuthenticated: boolean;

  private serviceUser?: void | User | null;

  private isInitialized: boolean;

  private userRoles: string[];

  // Promises
  private initPromise: Promise<void> | null;

  // Constructor
  constructor() {
    // Service Subscribers
    this.serviceCallbacks = [];
    this.serviceNextSubscriptionId = 0;

    // Service State
    this.userManager = null;
    this.serviceIsAuthenticated = false;
    this.serviceUser = undefined;
    this.isInitialized = false;
    this.userRoles = [];

    // Promises
    this.initPromise = null;

    // Init
    this.ensureInitialized();
  }

  // Singleton Instance
  static get instance() {
    return authService;
  }

  // Object Creators
  private static createArguments = () => {
    return { redirectMethod: 'replace' };
  };

  private static success: ResponseFunc = (data?: any) => {
    return { status: AuthenticationResultStatus.Success, data };
  };

  private static error: ResponseFunc = (data?: any) => {
    return { status: AuthenticationResultStatus.Fail, data };
  };

  private static redirect: ResponseFunc = () => {
    return { status: AuthenticationResultStatus.Redirect };
  };

  // External Methods
  async getAccessToken(refresh = false) {
    await this.ensureInitialized();
    try {
      let user = await this.userManager?.getUser();

      // Makes sure the token is not expired before using it, otherwise it's refreshed silently
      if (refresh || user?.expired) {
        user = await this.userManager?.signinSilent();
        this.updateState(user);
      }

      return user?.access_token ?? '';
    } catch (e: any) {
      if (Object.prototype.hasOwnProperty.call(e, 'error') && e.error === 'login_required') {
        this.updateState(null);
      }
      return '';
    }
  }

  async signIn(state: any) {
    await this.ensureInitialized();
    try {
      const silentUser = await this.userManager?.signinSilent();
      this.updateState(silentUser);
      return AuthorizeService.success(state);
    } catch (silentError) {
      try {
        await this.userManager?.signinRedirect();
        return AuthorizeService.redirect();
      } catch (redirectError) {
        return AuthorizeService.error(redirectError);
      }
    }
  }

  async completeSignIn(url: string) {
    await this.ensureInitialized();
    try {
      const user = await this.userManager?.signinCallback(url);
      this.updateState(user);
      return AuthorizeService.success(user?.state);
    } catch (error) {
      return AuthorizeService.error('Failed to login...');
    }
  }

  async signOut() {
    await this.ensureInitialized();
    try {
      await this.userManager?.signoutRedirect();
      return AuthorizeService.redirect();
    } catch (redirectSignOutError) {
      return AuthorizeService.error(redirectSignOutError);
    }
  }

  async completeSignOut(url: string) {
    await this.ensureInitialized();
    try {
      await this.userManager?.signoutCallback(url);
      this.updateState(undefined);
      return AuthorizeService.success();
    } catch (error) {
      return AuthorizeService.error(error);
    }
  }

  // Subscriber Methods
  subscribe(callback: CallbackFunc) {
    this.serviceCallbacks.push({ callback, subscriptionId: (this.serviceNextSubscriptionId += 1) });
    callback(this.getState(this.serviceNextSubscriptionId));
  }

  unsubscribe(subscriptionId: number) {
    const subscriptionIndex = this.serviceCallbacks
      .map((element, index) => (element.subscriptionId === subscriptionId ? { found: true, index } : { found: false }))
      .filter((element) => element.found === true);

    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    const { index } = subscriptionIndex[0];
    if (index) this.serviceCallbacks.splice(index, 1);
  }

  private notifySubscribers() {
    for (let i = 0; i < this.serviceCallbacks.length; i += 1) {
      const { callback, subscriptionId } = this.serviceCallbacks[i];
      callback(this.getState(subscriptionId));
    }
  }

  // State Methods
  private updateState(user?: void | User | null) {
    this.serviceIsAuthenticated = !!user;
    this.serviceUser = user;
    this.isInitialized = true;

    if (user) {
      const token = jwtDecode<JwtPayloadWithRoles>(user.access_token);
      if (typeof token[ClaimTypeRole] === 'string') {
        this.userRoles = [token[ClaimTypeRole]];
      } else {
        this.userRoles = token[ClaimTypeRole];
      }
    } else {
      this.userRoles = [];
    }

    this.notifySubscribers();
  }

  private getState: GetStateFunc = (subscriptionId) => ({
    subscriptionId,
    isAuthenticated: this.serviceIsAuthenticated,
    user: this.serviceUser,
    isInitialized: this.isInitialized,
    userRoles: this.userRoles,
  });

  // Singleton Initializer
  private async getInitPromise() {
    if (!this.initPromise) {
      this.initPromise = this.init();
    }
    return this.initPromise;
  }

  // Init
  private async ensureInitialized() {
    await this.getInitPromise();
  }

  private async init() {
    if (this.userManager) {
      return;
    }

    // Fetch Configuration
    const response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
    if (!response.ok) {
      throw new Error(`Could not load settings for '${ApplicationName}'`);
    }

    // Settings
    const settings = await response.json();
    settings.automaticSilentRenew = true;
    settings.includeIdTokenInSilentRenew = true;
    settings.loadUserInfo = true;
    settings.userStore = new WebStorageStateStore({
      prefix: ApplicationName,
    });

    // UserManager Initializer
    this.userManager = new UserManager(settings);
    const user = await this.userManager.getUser();
    this.updateState(user);

    // Events
    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager?.removeUser();
      this.updateState(undefined);
    });
  }
}

// Export new instance
export const authService = new AuthorizeService();

import { ApiConfigurationEnum } from './enums/ApiConfigurationTypes';

export interface ApiConfigurationSettingsFormValues {
  Id: string | undefined;
  Name: string | undefined;
  ApiConfiguration?: ApiConfigurationEnum;
  SettingsData: any;
}

export interface SaltoSpaceSettingsFormValues extends ApiConfigurationSettingsFormValues {
  SettingsData: SaltoSpaceFormSettings;
}

export interface SaltoSpaceFormSettings {
  Host: string | undefined;
  Port: number | undefined;
  UseHttps: boolean | undefined;
  AuthenticationType: number | undefined;
  Username: string | undefined;
  Password: string | undefined;
  ClientId: string | undefined;
  IgnoreSslErrors: boolean | undefined;
}

export enum SaltoSpaceAuthentication {
  Stp = 0,
  HttpBasic = 1,
  HttpWithKey = 2,
}

export interface SaltoSpaceAuthenticationType {
  Name: string;
  Value: number;
}

export const SaltoSpaceAuthenticationTypes: SaltoSpaceAuthenticationType[] = [
  {
    Name: 'STP',
    Value: 0,
  },
  {
    Name: 'HTTP Basic',
    Value: 1,
  },
  {
    Name: 'HTTP With key',
    Value: 2,
  },
];

export interface SaltoKsSettingsFormValues extends ApiConfigurationSettingsFormValues {
  SettingsData: SaltoKsFormSettings;
}

export interface SaltoKsFormSettings {
  ConnectUrl: string | undefined;
  IdentityUrl: string | undefined;
  ClientId: string | undefined;
  Secret: string | undefined;
  CollectionId: string | undefined;
}

export interface SaltoKsSite {
  Id: string | undefined;
  Name: string | undefined;
  Roles: Array<SaltoKsRole> | undefined;
}

export interface SaltoKsRole {
  Id: string | undefined;
  Name: string | undefined;
}

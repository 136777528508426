import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Input, Modal, Row, Select, Typography } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { useIntl } from 'react-intl';
import { usePrevious } from 'react-use';
import { Row as TableRow } from 'react-table';
import styled from 'styled-components';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { apiClientTableColumns } from '../../data/Table/ApiClientTableColumns';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Constants
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';

// Models
import { ApiConfiguration } from '../../models/ApiConfiguration';
import { ApiConfigurationEnum } from '../../models/enums/ApiConfigurationTypes';
import { ApiClient, ApiClientEditModel } from '../../models/ApiClient';
import { FormOptions, UseFormProps } from '../../types/Table';

// Redux
import {
  clearApiClientSecretValue,
  createApiClient,
  deleteApiClients,
  updateApiClient,
} from '../../store/ApiClients/ApiClients.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { Spinner } from '../../components/UI/Spinner/Spinner';

const { confirm } = Modal;
const { Option } = Select;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Hook
export const useApiClientList = <T extends object>() => {
  // Intl
  const intl = useIntl();

  // Data
  const { data: clients, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.apiClients.list, null);
  const { data: apiConfigurations, fetch: fetchApiConfigurations } = useData(ApiEndpoints.apiConfigurations.list, null);
  const { data: tenants, fetch: fetchTenants } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ apiClients }) => apiClients?.updating ?? false);
  const error = useAppSelector(({ apiClients }) => apiClients?.error ?? false);
  const secret = useAppSelector(({ apiClients }) => apiClients?.secret);
  const prevSecret = usePrevious(secret);

  // Form State
  const [formEditModel, setFormEditModel] = useState<ApiClientEditModel>();

  // Component State
  const [filteredApiConfigurations, setFilteredApiConfigurations] = useState<ApiConfiguration[] | undefined>();
  const [apiConfigurationType, setApiConfigurationType] = useState<ApiConfigurationEnum>();

  // Form Submit Handling
  const onSubmit = useCallback(
    (client: ApiClient) => {
      if (formEditModel) {
        dispatch(updateApiClient(client));
      } else {
        dispatch(createApiClient(client));
      }
    },
    [dispatch, formEditModel]
  );

  // Form OnChange Handling
  const onChangeTenant = useCallback(
    (tenantId: string, form: FormInstance<T>) => {
      const selectedTenant = tenants?.find((tenant) => tenant.Id === tenantId);

      // Update Hidden Form Values
      form.setFieldValue(['Tenant', 'CompanyId'], selectedTenant?.CompanyId);
      form.setFieldValue(['Tenant', 'LocationId'], selectedTenant?.LocationId);
      form.setFieldValue(['Tenant', 'BusinessClientId'], selectedTenant?.BusinessClientId);
      form.setFieldValue('ApiConfigurationId', undefined);
      form.setFieldValue('ApiConfigurationType', undefined);

      // Update Component State
      setFilteredApiConfigurations(
        apiConfigurations?.filter((sc) => sc.Tenants?.some((tenant) => tenant.Id === selectedTenant?.Id))
      );
      setApiConfigurationType(undefined);
    },
    [apiConfigurations, tenants]
  );

  const onChangeApiConfiguration = useCallback(
    (apiConfigurationId: string, form: FormInstance<T>) => {
      const apiConfiguration = filteredApiConfigurations?.find((config) => config.Id === apiConfigurationId);

      // Update Hidden Form Values
      form.setFieldValue('ApiConfigurationType', apiConfiguration?.ApiConfigurationType);

      // Update Component State
      setApiConfigurationType(apiConfiguration?.ApiConfigurationType);
    },
    [filteredApiConfigurations]
  );

  const onChangeSaltoKsLockId = useCallback((saltoKsLockId: string, form: FormInstance<T>) => {
    // Update Hidden Form Values
    form.setFieldValue('CustomData', JSON.stringify({ SaltoKsLockId: saltoKsLockId }));
  }, []);

  // Form Component
  const ApiClientForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as ApiClient)}
        initialValues={formEditModel}
        onValuesChange={({ TenantId, ApiConfigurationId, SaltoKsLockId }: Store) => {
          // Api Client Data OnChange
          if (TenantId) onChangeTenant(TenantId, form);
          if (ApiConfigurationId) onChangeApiConfiguration(ApiConfigurationId, form);
          // Custom Data onChange
          if (SaltoKsLockId) onChangeSaltoKsLockId(SaltoKsLockId, form);
        }}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="ClientName"
                label={<Translated id="clients.clientName" />}
                rules={[{ required: true, message: intl.formatMessage({ id: 'clients.form.warnings.name' }) }]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="TenantId"
                label={<Translated id="clients.clientTenant" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'clients.form.warnings.tenant' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="clients.clientTenant" />}>
                  {tenants?.map((tenant) => (
                    <Option key={tenant.Id} value={tenant.Id}>
                      {tenant.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={['Tenant', 'CompanyId']} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={['Tenant', 'LocationId']} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={['Tenant', 'BusinessClientId']} hidden>
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="ApiConfigurationId"
                label={<Translated id="apiClients.apiConfiguration" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'apiClients.form.warnings.apiConfiguration' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="apiClients.apiConfiguration" />}>
                  {filteredApiConfigurations?.map((apiConfiguration) => (
                    <Option key={apiConfiguration.Id} value={apiConfiguration.Id}>
                      {apiConfiguration.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="ApiConfigurationType" hidden>
                <Input />
              </Form.Item>
            </Col>
            {apiConfigurationType === ApiConfigurationEnum.SaltoKs && (
              <Col>
                <Form.Item
                  name="SaltoKsLockId"
                  label={<Translated id="apiClients.saltoKsLockId" />}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'apiClients.form.warnings.saltoKsLockId' }),
                    },
                  ]}
                >
                  <Input placeholder={intl.formatMessage({ id: 'apiClients.saltoKsLockId' })} />
                </Form.Item>
              </Col>
            )}
            <Form.Item name="CustomData" hidden>
              <Input />
            </Form.Item>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [
      apiConfigurationType,
      filteredApiConfigurations,
      formEditModel,
      intl,
      onChangeApiConfiguration,
      onChangeSaltoKsLockId,
      onChangeTenant,
      onSubmit,
      tenants,
      updating,
    ]
  );

  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'Clients',
        Form: ApiClientForm,
        labels: {
          createButton: <Translated id="clients.form.create" />,
          drawerForm: <Translated id={formEditModel ? 'clients.form.edit' : 'clients.form.create'} />,
          submitButton: <Translated id={formEditModel ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [ApiClientForm, formEditModel]
  );

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({
    formOptions,
    updating,
    error,
    onAdd: () => showClientEditForm(),
  });

  // Callbacks
  const showSecretModal = useCallback(
    (secretValue: string) => {
      Modal.info({
        title: <strong>Client secret</strong>,
        content: (
          <>
            <p>
              {intl.formatMessage({ id: 'clients.modal.secretTitle' })} <br />
              {intl.formatMessage({ id: 'clients.modal.secretSubtitle' })}
            </p>
            <br />
            <Typography.Title level={4} copyable code>
              {secretValue}
            </Typography.Title>
          </>
        ),
        okText: intl.formatMessage({
          id: 'app.ok',
          defaultMessage: 'Ok',
        }),
        width: 600,
        okType: 'primary',
        onOk: () => dispatch(clearApiClientSecretValue()),
      });
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const apiClients = selectedFlatRows.map((d) => d.original) as ApiClient[];
      if (apiClients) {
        confirm({
          title: intl.formatMessage({ id: 'clients.confirm.deleteAll' }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({ id: 'clients.confirm.deleteAllSub' }),
          okText: intl.formatMessage({ id: 'app.yes' }),
          cancelText: intl.formatMessage({ id: 'app.no' }),
          okType: 'danger',
          onOk: () => dispatch(deleteApiClients(apiClients)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showClientEditForm = useCallback(
    (values?: ApiClient) => {
      setFormEditModel(values as ApiClientEditModel);
    },
    [setFormEditModel]
  );

  // Callbacks
  const showEditForm = useCallback(
    ({ values }: { values?: ApiClient }) => {
      showClientEditForm(values);
      getFormDrawerProps().setOpen(true);
    },
    [getFormDrawerProps, showClientEditForm]
  );

  // Props
  const getBreadcrumbMenuProps = useCallback(
    () => ({
      breadcrumbs: appBreadcrumbs.clients.list,
    }),
    []
  );
  const getClientsDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.Clients.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-user" style={{ marginRight: 8 }} />
          <Translated id="appMenu.apiClients" />
        </>
      ),
      data: clients ?? [],
      columns: getTableColumns(apiClientTableColumns, LocalStorageKeys.Clients.table.hiddenColumns),
      fetchData: fetch,
    }),
    [clients, fetch]
  );

  const getClientsTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );

  const getClientsCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      saveHiddenTableColumns,
      showDeleteAllConfirm,
      showEditForm,
      onRowClick: (id: string) => showEditForm({ values: clients?.find((l) => l.Id === id) }),
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm, showEditForm, clients]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchTenants();
    fetchApiConfigurations();
  }, [fetchApiConfigurations, fetchTenants]);

  useEffect(() => {
    // Show secret modal when there's a secret to show
    if (secret && prevSecret !== secret) {
      showSecretModal(secret);
    }
  }, [secret, prevSecret, showSecretModal]);

  useEffect(() => {
    // Clear up values from form when closing the drawer
    if (!getFormDrawerProps().open) {
      setFilteredApiConfigurations(undefined);
      setApiConfigurationType(undefined);
    }
  }, [getFormDrawerProps]);

  useEffect(() => {
    // Load values on edit
    if (formEditModel?.Id) {
      const selectedTenant = tenants?.find(
        (x) =>
          x.CompanyId === formEditModel?.Tenant.CompanyId &&
          x.LocationId === formEditModel?.Tenant.LocationId &&
          x.BusinessClientId === formEditModel?.Tenant.BusinessClientId
      );

      if (selectedTenant && selectedTenant.Id !== formEditModel.TenantId) {
        // Set Custom Data
        let saltoKsCustomData: { SaltoKsLockId: string };
        if (formEditModel.ApiConfigurationType === ApiConfigurationEnum.SaltoKs) {
          saltoKsCustomData = JSON.parse(formEditModel.CustomData);
        }

        // Set the Form Edit Model
        setFormEditModel((prevFormEditModel: any) => ({
          ...prevFormEditModel,
          TenantId: selectedTenant.Id,
          Tenant: selectedTenant,
          // Custom Data
          ...saltoKsCustomData,
        }));

        // Update Component State
        setFilteredApiConfigurations(
          apiConfigurations?.filter((sc) => sc.Tenants?.some((tenant) => tenant.Id === selectedTenant.Id))
        );
        setApiConfigurationType(formEditModel.ApiConfigurationType);
      }
    } else {
      setFilteredApiConfigurations(undefined);
      setApiConfigurationType(undefined);
    }
  }, [formEditModel, tenants, apiConfigurations]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getClientsDataProps,
      getClientsTableProps,
      getClientsCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getClientsDataProps,
      getClientsTableProps,
      getFormDrawerProps,
      getClientsCrudProps,
    ]
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { Action } from '../../models/Action';
import { Tenant } from '../../models/Tenant';

// State
interface ActionsState {
  updating: boolean;
  updatingSettings: boolean;
  error: boolean;
}

const initialState: ActionsState = {
  updating: false,
  updatingSettings: false,
  error: false,
};

// Name
const storeName = '@IDENTIFIER_ACTIONS';

// Redux Actions|Reducers
const ActionsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createAction: (state, action: PayloadAction<Action>) => {
      state.updating = true;
      state.error = false;
    },
    actionCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    actionNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateAction: (state, action: PayloadAction<Action>) => {
      state.updating = true;
      state.error = false;
    },
    actionUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    actionNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteAction: (state, action: PayloadAction<Action>) => {
      state.updating = true;
      state.error = false;
    },
    actionDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    actionNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteActions: (state, action: PayloadAction<Array<Action>>) => {
      state.updating = true;
      state.error = false;
    },

    // Assign tenants
    assignActionTenants: (state, action: PayloadAction<{ action: Action; tenants: Array<Tenant> }>) => {
      state.updating = true;
      state.error = false;
    },
  },
});

// Export Actions
export const {
  // Create
  createAction,
  actionCreated,
  actionNotCreated,
  // Update
  updateAction,
  actionUpdated,
  actionNotUpdated,
  // Delete
  deleteAction,
  actionDeleted,
  actionNotDeleted,
  // Delete All
  deleteActions,
  // Assign tenants
  assignActionTenants,
} = ActionsSlice.actions;

// Export Reducer
export default ActionsSlice.reducer;

// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import { identityUserTenantsAssigned, identityUserTenantsNotAssigned } from './IdentityUserTenants.redux';

// Events
const SignalREvents = {
  IdentityTenantsAssigned: `IdentityTenantsAssignedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  IdentityTenantsNotAssigned: `IdentityTenantsNotAssignedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.IdentityTenantsAssigned, (msg) => {
    store.dispatch(identityUserTenantsAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.IdentityTenantsNotAssigned, (msg) => {
    store.dispatch(identityUserTenantsNotAssigned({ history, msg }));
  });
};

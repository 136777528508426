/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { AccessProfile } from '../../../models/AccessProfile';
import { SignalRAction } from '../../../models/SignalRAction';

// State
interface PACSAccessProfilesState {
  updating: boolean;
  error: boolean;
}

const initialState: PACSAccessProfilesState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@PACS_ACCESS_PROFILES';

// Redux Actions|Reducers
const pacsAccessProfilesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignPACSAccessProfiles: (
      state,
      action: PayloadAction<{ accessProfile: AccessProfile; pacsAccessProfileIds: Array<string> }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    pacsAccessProfilesAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    pacsAccessProfilesNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Sync
    syncPACSAccessProfiles: (state) => {
      state.updating = true;
      state.error = false;
    },
    pacsAccessProfilesSynced: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    pacsAccessProfilesNotSynced: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignPACSAccessProfiles,
  pacsAccessProfilesAssigned,
  pacsAccessProfilesNotAssigned,
  // Sync
  syncPACSAccessProfiles,
  pacsAccessProfilesSynced,
  pacsAccessProfilesNotSynced,
} = pacsAccessProfilesSlice.actions;

// Export Reducer
export default pacsAccessProfilesSlice.reducer;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import styled from 'styled-components';
import { IdentityUser, IdentityUserResponse } from '../../../models/IdentityUser';
import { Tenant } from '../../../models/Tenant';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { assignIdentityUserTenants } from '../../../store/IdentityUsers/IdentityUserTenants/IdentityUserTenants.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';

const { confirm } = Modal;

// Props
interface IdentityUserTenantsReduxProps {
  identityUserResponse: IdentityUserResponse | null;
  identityUserTenants: Array<Tenant> | null;
}

interface AssignConfirmProps {
  tenantIds: Array<string>;
  identityUser: IdentityUser;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useIdentityUserTenantsRedux = ({
  identityUserResponse,
  identityUserTenants,
}: IdentityUserTenantsReduxProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(
    ({ identityUserTenants: identityUserTenantsState }) => identityUserTenantsState?.updating ?? false
  );
  const error = useAppSelector(
    ({ identityUserTenants: identityUserTenantsState }) => identityUserTenantsState?.error ?? false
  );

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ tenantIds, identityUser }: AssignConfirmProps) => {
      if (tenantIds && identityUser) {
        confirm({
          title: intl.formatMessage({
            id: 'identityUsers.Tenants.multiSelect.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'identityUsers.Tenants.multiSelect.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignIdentityUserTenants({ tenantIds, identityUser })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // State
  const [tenantIds, setTenantIds] = useState<Array<string>>([]);

  // Sets the Keys when the response is updated
  // Fixes problem with initial values after API call
  useEffect(() => {
    setTenantIds(
      identityUserTenants
        ?.filter((tenant) =>
          identityUserResponse?.Tenants?.some((identityUserTenant) => identityUserTenant.Id === tenant.Id)
        )
        .map((tenant) => tenant.Id) ?? []
    );
  }, [identityUserTenants, identityUserResponse]);

  // Components
  const IdentityUserTenantsManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      identityUserTenants?.map<TransferItem>((identityUserTenant) => ({
        key: identityUserTenant.Id,
        title: identityUserTenant.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <ItemTransfer dataSource={dataSource} targetKeys={tenantIds} setTargetKeys={setTenantIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            identityUserResponse && showAssignConfirm({ tenantIds, identityUser: identityUserResponse.Identity })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [identityUserResponse, identityUserTenants, showAssignConfirm, tenantIds, setTenantIds]);

  return useMemo(
    () => ({
      IdentityUserTenantsManagementForm,
      updating,
      error,
    }),
    [IdentityUserTenantsManagementForm, updating, error]
  );
};

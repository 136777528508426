/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from 'history';

// Models
import { Locale } from '../../models/Locale';

// Utils
import { getFromLocalStorage, LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { isMobile } from '../../constants/Settings/ThemeSettings';

// State
interface AppSettingsState {
  pathname: string;
  sidebarOpen: boolean;
  width: number;
  locale?: Locale;
  lastChecked?: Date;
}

const initialState: AppSettingsState = {
  pathname: '/',
  sidebarOpen: false,
  width: window.innerWidth,
  locale: getFromLocalStorage(LocalStorageKeys.AppSettings.locale, {
    languageId: 'dutch',
    locale: 'nl',
    name: 'Dutch',
    icon: 'nl',
  }),
  lastChecked: getFromLocalStorage<Date>(LocalStorageKeys.AppSettings.lastChecked, undefined),
};

// Name
const storeName = '@APP_SETTINGS';

// Redux Actions|Reducers
const appSettingsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    changeLocation: (state, action: PayloadAction<Location>) => {
      state.pathname = action.payload.pathname;
      state.sidebarOpen = false;
    },
    switchLanguage: (state, action: PayloadAction<Locale>) => {
      state.locale = action.payload;
    },
    openSidebar: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
    },
    updateWindowWidth: (state, action: PayloadAction<number>) => {
      state.width = action.payload;
      state.sidebarOpen = state.sidebarOpen && isMobile(action.payload);
    },
  },
});

// Export Actions
export const { changeLocation, switchLanguage, openSidebar, updateWindowWidth } = appSettingsSlice.actions;

// Export Reducer
export default appSettingsSlice.reducer;

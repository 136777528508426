import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { ApiConfigurationResponse } from '../../../models/ApiConfiguration';

// Hooks
import { useApiConfigurationTenantsRedux } from './useApiConfigurationTenantsRedux';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface ApiConfigurationTenantsProps {
  apiConfigurationResponse: ApiConfigurationResponse | null;
  fetchApiConfiguration: (params: any) => Promise<void>;
  updatingApiConfiguration: boolean;
}

export const useApiConfigurationTenants = ({
  apiConfigurationResponse: apiConfigurationTenantsResponse,
  fetchApiConfiguration,
  updatingApiConfiguration: updatingApiConfigurationTenant,
}: ApiConfigurationTenantsProps) => {
  // Data
  const {
    data: apiConfigurationTenants,
    fetch: fetchApiConfigurationTenants,
    pending,
  } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const { ApiConfigurationTenantsManagementForm, updating, error } = useApiConfigurationTenantsRedux({
    apiConfigurationTenantsResponse,
    apiConfigurationTenants,
  });
  const prevUpdating = usePrevious(updating);

  // Component State
  const loading = pending && !apiConfigurationTenantsResponse;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchApiConfigurationTenants();
  }, [fetchApiConfigurationTenants]);

  useEffect(() => {
    // Fetch after updating
    if (apiConfigurationTenantsResponse && prevUpdating === true && updating === false && !error) {
      fetchApiConfiguration({ id: apiConfigurationTenantsResponse.ApiConfiguration.Id });
      fetchApiConfigurationTenants();
    }
  }, [
    prevUpdating,
    updating,
    error,
    fetchApiConfiguration,
    fetchApiConfigurationTenants,
    apiConfigurationTenantsResponse,
  ]);

  // Return Hook
  return useMemo(
    () => ({
      apiConfigurationTenants,
      ApiConfigurationTenantsManagementForm,
      loading,
      updating: updating || updatingApiConfigurationTenant,
    }),
    [apiConfigurationTenants, ApiConfigurationTenantsManagementForm, loading, updating, updatingApiConfigurationTenant]
  );
};

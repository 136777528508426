// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import {
  pacsAccessProfilesAssigned,
  pacsAccessProfilesNotAssigned,
  pacsAccessProfilesNotSynced,
  pacsAccessProfilesSynced,
} from './PACSAccessProfiles.redux';

// Events
const SignalREvents = {
  PACSAccessProfilesAssigned: `PACSAccessProfilesAssignedIntegrationEvent`,
  PACSAccessProfilesSynced: `PACSAccessProfilesSyncedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  PACSAccessProfilesNotAssigned: `PACSAccessProfilesNotAssignedIntegrationException`,
  PACSAccessProfilesNotSynced: `PACSAccessProfilesNotSyncedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.PACSAccessProfilesAssigned, (msg) => {
    store.dispatch(pacsAccessProfilesAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PACSAccessProfilesNotAssigned, (msg) => {
    store.dispatch(pacsAccessProfilesNotAssigned({ history, msg }));
  });

  // Sync
  hubConnection.on(SignalREvents.PACSAccessProfilesSynced, (msg) => {
    store.dispatch(pacsAccessProfilesSynced({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PACSAccessProfilesNotSynced, (msg) => {
    store.dispatch(pacsAccessProfilesNotSynced({ history, msg }));
  });
};

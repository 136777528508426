import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Location } from 'history';
import { usePrevious } from 'react-use';

// Redux
import { useAppDispatch } from './useRedux';
import { changeLocation } from '../../store/AppSettings/AppSettings.redux';

type LocationChangeEvent = (location: Location, prevLocation?: Location) => void;
type LocationChangeProps = (action: LocationChangeEvent) => void;

export const useLocationChange: LocationChangeProps = (action) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  useEffect(() => {
    action(location, prevLocation);
  }, [location, prevLocation, action]);
};

export const useReduxLocation = () => {
  const dispatch = useAppDispatch();
  useLocationChange(
    (location, prevLocation) => location.pathname !== prevLocation?.pathname && dispatch(changeLocation(location))
  );
};

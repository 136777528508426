import React from 'react';

import { Button } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { TableColumn } from '../../types/Table';
import { Kiosk } from '../../models/Kiosk';
import { Translated } from '../../components/UI/Core';

const StyledSpan = styled.span`
  margin-left: 5px !important;
`;

export const kioskTableColumns: Array<TableColumn<Kiosk>> = [
  {
    Header: <Translated id="kiosks.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="kiosks.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="kiosk.kiosk.url" />,
    id: 'KioskURL',
    accessor: 'Id',
    Cell: (value) => {
      const {
        row: {
          values: { Id },
        },
      } = value;

      return (
        <Button
          ghost
          type="primary"
          icon={<SelectOutlined />}
          key="Edit"
          onClick={(event) => {
            const url = `https://${process.env.REACT_APP_KIOSK_HOST}/${Id}`;
            window.open(url);
            event.stopPropagation();
          }}
          style={{ height: '32px', marginTop: '6px' }}
        >
          <StyledSpan>
            <Translated id="kiosks.open.button" defaultMessage="Open" />
          </StyledSpan>
        </Button>
      );
    },
    disableSortBy: true,
    disableFilters: true,
  },
];

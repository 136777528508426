import React from 'react';

// Props
import { TableActionsProps } from '../../../../hooks/Table/useTableProps';

// Components
import { Flex } from '../../Base';

// Render
export const TableActions = ({
  PageChanger,
  PageSizeChanger,
  PageItemsCount,
}: React.PropsWithChildren<TableActionsProps>) => (
  <Flex alignItems="center" justifyContent="space-between">
    {PageItemsCount && <PageItemsCount />}
    {PageChanger && <PageChanger />}
    {PageSizeChanger && <PageSizeChanger />}
  </Flex>
);

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IdentifierType } from '../../../models/IdentifierType';
import { SignalRAction } from '../../../models/SignalRAction';
import { Tenant } from '../../../models/Tenant';

// State
interface IdentifierTypeTenantsState {
  updating: boolean;
  error: boolean;
}

const initialState: IdentifierTypeTenantsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@ACCESS_PROFILE_TENANTS';

// Redux Actions|Reducers
const identifierTypeTenantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignIdentifierTypeTenants: (
      state,
      action: PayloadAction<{ identifierType: IdentifierType; tenants: Array<Tenant> }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    identifierTypeTenantsAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierTypeTenantsNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignIdentifierTypeTenants,
  identifierTypeTenantsAssigned,
  identifierTypeTenantsNotAssigned,
} = identifierTypeTenantsSlice.actions;

// Export Reducer
export default identifierTypeTenantsSlice.reducer;

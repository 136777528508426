import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Schedules
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { ScheduleConfiguration } from '../../models/ScheduleConfiguration';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  createScheduleConfiguration,
  deleteScheduleConfiguration,
  deleteScheduleConfigurations,
  scheduleConfigurationCreated,
  scheduleConfigurationDeleted,
  scheduleConfigurationNotCreated,
  scheduleConfigurationNotDeleted,
  scheduleConfigurationNotUpdated,
  scheduleConfigurationsDeleted,
  scheduleConfigurationsNotDeleted,
  updateScheduleConfiguration,
} from './ScheduleConfigurations.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createScheduleConfigurationSaga() {
  yield takeEvery(createScheduleConfiguration.type, createScheduleConfigurationRequest);
}

function* scheduleConfigurationCreatedSaga() {
  yield takeLatest(scheduleConfigurationCreated.type, createScheduleConfigurationResponse);
}

function* scheduleConfigurationNotCreatedSaga() {
  yield takeLatest(scheduleConfigurationNotCreated.type, createScheduleConfigurationError);
}

// Request
function* createScheduleConfigurationRequest(action: PayloadAction<ScheduleConfiguration>) {
  try {
    const { payload: scheduleConfiguration } = action;
    yield apiService.execute({
      url: 'ScheduleConfigurations',
      method: ApiRequestType.POST,
      data: scheduleConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: scheduleConfigurationNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createScheduleConfigurationResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('scheduleConfigurations.notifications.create');
  action.payload.history.push(`/ScheduleConfigurations/${action.payload.msg.entityId}`);
}

// Error
function createScheduleConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'scheduleConfigurations.notifications.createFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateScheduleConfigurationSaga() {
  yield takeEvery(updateScheduleConfiguration.type, updateScheduleConfigurationRequest);
}

function* scheduleConfigurationNotUpdatedSaga() {
  yield takeLatest(scheduleConfigurationNotUpdated.type, updateScheduleConfigurationError);
}

// Request
function* updateScheduleConfigurationRequest(action: PayloadAction<ScheduleConfiguration>) {
  try {
    const { payload: scheduleConfiguration } = action;
    yield apiService.execute({
      url: `ScheduleConfigurations/${scheduleConfiguration.Id}`,
      method: ApiRequestType.PUT,
      data: scheduleConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: scheduleConfigurationNotUpdated.type, payload: { msg: { message } } });
  }
}

// Error
function updateScheduleConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'scheduleConfigurations.notifications.updateFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteScheduleConfigurationSaga() {
  yield takeEvery(deleteScheduleConfiguration.type, deleteScheduleConfigurationRequest);
}

function* scheduleConfigurationDeletedSaga() {
  yield takeLatest(scheduleConfigurationDeleted.type, deleteScheduleConfigurationResponse);
}

function* scheduleConfigurationNotDeletedSaga() {
  yield takeLatest(scheduleConfigurationNotDeleted.type, deleteScheduleConfigurationError);
}

// Request
function* deleteScheduleConfigurationRequest(action: PayloadAction<ScheduleConfiguration>) {
  try {
    const { payload: scheduleConfiguration } = action;
    yield apiService.execute({
      url: `ScheduleConfigurations/${scheduleConfiguration.Id}`,
      method: ApiRequestType.DELETE,
      data: scheduleConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: scheduleConfigurationNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteScheduleConfigurationResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('scheduleConfigurations.notifications.delete');
  action.payload.history.push(`/ScheduleConfigurations`);
}

// Error
function deleteScheduleConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'scheduleConfigurations.notifications.deleteFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteScheduleConfigurationsSaga() {
  yield takeEvery(deleteScheduleConfigurations.type, deleteScheduleConfigurationsRequest);
}

function* scheduleConfigurationsDeletedSaga() {
  yield takeLatest(scheduleConfigurationsDeleted.type, deleteScheduleConfigurationsResponse);
}

function* scheduleConfigurationsNotDeletedSaga() {
  yield takeLatest(scheduleConfigurationsNotDeleted.type, deleteScheduleConfigurationsError);
}

// Request
function* deleteScheduleConfigurationsRequest(action: PayloadAction<Array<ScheduleConfiguration>>) {
  try {
    const { payload: scheduleConfigurations } = action;
    yield apiService.execute({
      url: `ScheduleConfigurations`,
      method: ApiRequestType.DELETE,
      data: { ids: scheduleConfigurations.map((scheduleConfiguration) => scheduleConfiguration.Id) },
    });
  } catch ({ message }) {
    yield put({ type: scheduleConfigurationsNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteScheduleConfigurationsResponse() {
  notificationService.showSuccess('scheduleConfigurations.notifications.deleteAll');
}

// Error
function deleteScheduleConfigurationsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'scheduleConfigurations.notifications.deleteAllFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createScheduleConfigurationSaga(),
    scheduleConfigurationCreatedSaga(),
    scheduleConfigurationNotCreatedSaga(),
    // Update
    updateScheduleConfigurationSaga(),
    scheduleConfigurationNotUpdatedSaga(),
    // Delete
    deleteScheduleConfigurationSaga(),
    scheduleConfigurationDeletedSaga(),
    scheduleConfigurationNotDeletedSaga(),
    // Delete All
    deleteScheduleConfigurationsSaga(),
    scheduleConfigurationsDeletedSaga(),
    scheduleConfigurationsNotDeletedSaga(),
  ]);
}

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { ServiceConfiguration } from '../../models/ServiceConfiguration';
import { SignalRAction } from '../../models/SignalRAction';

// Data
import { ServiceStatus } from '../../models/enums/ServiceStatus';

// Constants
import { LocalStorageKeys, saveToLocalStorage } from '../../constants/Utils/LocalStorage';
import { getCurrentUtcDate } from '../../constants/Utils/DateTime';

// Redux
import {
  createServiceConfiguration,
  deleteServiceConfiguration,
  deleteServiceConfigurations,
  importPACSAccessProfiles,
  importPACSEmployees,
  pacsAccessProfilesImported,
  pacsAccessProfilesNotImported,
  pacsEmployeesImported,
  pacsEmployeesNotImported,
  serviceConfigurationCreated,
  serviceConfigurationDeleted,
  serviceConfigurationNotCreated,
  serviceConfigurationNotDeleted,
  serviceConfigurationNotTested,
  serviceConfigurationNotUpdated,
  serviceConfigurationsDeleted,
  serviceConfigurationsNotDeleted,
  serviceConfigurationTested,
  testServiceConfiguration,
  testServiceConfigurations,
  updateServiceConfiguration,
} from './ServiceConfigurations.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createServiceConfigurationSaga() {
  yield takeEvery(createServiceConfiguration.type, createServiceConfigurationRequest);
}

function* serviceConfigurationCreatedSaga() {
  yield takeLatest(serviceConfigurationCreated.type, createServiceConfigurationResponse);
}

function* serviceConfigurationNotCreatedSaga() {
  yield takeLatest(serviceConfigurationNotCreated.type, createServiceConfigurationError);
}

// Request
function* createServiceConfigurationRequest(action: PayloadAction<ServiceConfiguration>) {
  try {
    const { payload: serviceConfiguration } = action;
    yield apiService.execute({
      url: 'ServiceConfigurations',
      method: ApiRequestType.POST,
      data: serviceConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: serviceConfigurationNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createServiceConfigurationResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('serviceConfigurations.notifications.create');
  action.payload.history.push(`/ServiceConfigurations/${action.payload.msg.entityId}`);
}

// Error
function createServiceConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'serviceConfigurations.notifications.createFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateServiceConfigurationSaga() {
  yield takeEvery(updateServiceConfiguration.type, updateServiceConfigurationRequest);
}

function* serviceConfigurationNotUpdatedSaga() {
  yield takeLatest(serviceConfigurationNotUpdated.type, updateServiceConfigurationError);
}

// Request
function* updateServiceConfigurationRequest(action: PayloadAction<ServiceConfiguration>) {
  try {
    const { payload: serviceConfiguration } = action;
    yield apiService.execute({
      url: `ServiceConfigurations/${serviceConfiguration.Id}`,
      method: ApiRequestType.PUT,
      data: serviceConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: serviceConfigurationNotUpdated.type, payload: { msg: { message } } });
  }
}

// Error
function updateServiceConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'serviceConfigurations.notifications.updateFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteServiceConfigurationSaga() {
  yield takeEvery(deleteServiceConfiguration.type, deleteServiceConfigurationRequest);
}

function* serviceConfigurationDeletedSaga() {
  yield takeLatest(serviceConfigurationDeleted.type, deleteServiceConfigurationResponse);
}

function* serviceConfigurationNotDeletedSaga() {
  yield takeLatest(serviceConfigurationNotDeleted.type, deleteServiceConfigurationError);
}

// Request
function* deleteServiceConfigurationRequest(action: PayloadAction<ServiceConfiguration>) {
  try {
    const { payload: serviceConfiguration } = action;
    yield apiService.execute({
      url: `ServiceConfigurations/${serviceConfiguration.Id}`,
      method: ApiRequestType.DELETE,
      data: serviceConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: serviceConfigurationNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteServiceConfigurationResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('serviceConfigurations.notifications.delete');
  action.payload.history.push(`/ServiceConfigurations`);
}

// Error
function deleteServiceConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'serviceConfigurations.notifications.deleteFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteServiceConfigurationsSaga() {
  yield takeEvery(deleteServiceConfigurations.type, deleteServiceConfigurationsRequest);
}

function* serviceConfigurationsDeletedSaga() {
  yield takeLatest(serviceConfigurationsDeleted.type, deleteServiceConfigurationsResponse);
}

function* serviceConfigurationsNotDeletedSaga() {
  yield takeLatest(serviceConfigurationsNotDeleted.type, deleteServiceConfigurationsError);
}

// Request
function* deleteServiceConfigurationsRequest(action: PayloadAction<Array<ServiceConfiguration>>) {
  try {
    const { payload: serviceConfigurations } = action;
    yield apiService.execute({
      url: `ServiceConfigurations`,
      method: ApiRequestType.DELETE,
      data: { ids: serviceConfigurations.map((serviceConfiguration) => serviceConfiguration.Id) },
    });
  } catch ({ message }) {
    yield put({ type: serviceConfigurationsNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteServiceConfigurationsResponse() {
  notificationService.showSuccess('serviceConfigurations.notifications.deleteAll');
}

// Error
function deleteServiceConfigurationsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'serviceConfigurations.notifications.deleteAllFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* TEST ***********************

// Worker Sagas
function* testServiceConfigurationSaga() {
  yield takeEvery(testServiceConfiguration.type, testServiceConfigurationRequest);
}

function* testServiceConfigurationsSaga() {
  yield takeEvery(testServiceConfigurations.type, testServiceConfigurationsRequest);
}

function* serviceConfigurationTestedSaga() {
  yield takeLatest(serviceConfigurationTested.type, testServiceConfigurationResponse);
}

function* serviceConfigurationNotTestedSaga() {
  yield takeLatest(serviceConfigurationNotTested.type, testServiceConfigurationError);
}

// Request
function* testServiceConfigurationRequest(action: PayloadAction<{ id: string }>) {
  try {
    const {
      payload: { id },
    } = action;

    yield apiService.execute({
      url: `ServiceConfigurations/${id}/Status`,
      method: ApiRequestType.POST,
      data: {},
    });
  } catch ({ message }) {
    yield put({ type: serviceConfigurationNotTested.type, payload: { msg: { message } } });
  }
}

function* testServiceConfigurationsRequest() {
  try {
    yield apiService.execute({
      url: `ServiceConfigurations/Status`,
      method: ApiRequestType.POST,
      data: {},
    });
  } catch ({ message }) {
    yield put({ type: serviceConfigurationNotTested.type, payload: { msg: { message } } });
  }
}

// Response
function testServiceConfigurationResponse(action: PayloadAction<SignalRAction>) {
  const {
    payload: { msg },
  } = action;

  if (msg.status === ServiceStatus.Operational) {
    saveToLocalStorage(LocalStorageKeys.AppSettings.lastChecked, getCurrentUtcDate());
    notificationService.showSuccessWithValues('serviceConfigurations.notifications.testOnline', {
      name: msg.entityName,
    });
  } else {
    notificationService.showErrorWithContentAndValues('serviceConfigurations.notifications.testOffline', msg.message, {
      name: msg.entityName,
    });
  }
}

// Error
function testServiceConfigurationError(action: PayloadAction<SignalRAction>) {
  const {
    payload: { msg },
  } = action;

  notificationService.showErrorWithContentAndValues('serviceConfigurations.notifications.testFailed', msg.message, {
    name: msg.entityName,
  });
}

// **************************************************
// ********************* Import Employees *****************

// Worker Sagas
function* importPacsUsersSaga() {
  yield takeEvery(importPACSEmployees.type, importPacsUserRequest);
}

function* pacsUsersImportedSaga() {
  yield takeLatest(pacsEmployeesImported.type, importPacsUsersResponse);
}

function* pacsUsersNotImportedSaga() {
  yield takeLatest(pacsEmployeesNotImported.type, importPacsUsersError);
}

// Request
function* importPacsUserRequest(action: PayloadAction<{ id: string }>) {
  try {
    const {
      payload: { id },
    } = action;

    yield apiService.execute({
      url: `ServiceConfigurations/${id}/ImportEmployees`,
      method: ApiRequestType.POST,
      data: {},
    });
  } catch ({ message }) {
    yield put({ type: pacsEmployeesNotImported.type, payload: { msg: { message } } });
  }
}

// Response
function importPacsUsersResponse() {
  notificationService.showSuccess('serviceConfigurations.notifications.import');
}

// Error
function importPacsUsersError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'serviceConfigurations.notifications.importFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* Import Access Profiles *****************

// Worker Sagas
function* importPacsAccessProfilesSaga() {
  yield takeEvery(importPACSAccessProfiles.type, importPacsAccessProfilesRequest);
}

function* pacsAccessProfilesImportedSaga() {
  yield takeLatest(pacsAccessProfilesImported.type, importPacsAccessProfilesResponse);
}

function* pacsAccessProfilesNotImportedSaga() {
  yield takeLatest(pacsAccessProfilesNotImported.type, importPacsAccessProfilesError);
}

// Request
function* importPacsAccessProfilesRequest(action: PayloadAction<{ id: string }>) {
  try {
    const {
      payload: { id },
    } = action;

    yield apiService.execute({
      url: `ServiceConfigurations/${id}/ImportAccessProfiles`,
      method: ApiRequestType.POST,
      data: {},
    });
  } catch ({ message }) {
    yield put({ type: pacsAccessProfilesNotImported.type, payload: { msg: { message } } });
  }
}

// Response
function importPacsAccessProfilesResponse() {
  notificationService.showSuccess('serviceConfigurations.notifications.importAccessProfiles');
}

// Error
function importPacsAccessProfilesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'serviceConfigurations.notifications.importAccessProfilesFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createServiceConfigurationSaga(),
    serviceConfigurationCreatedSaga(),
    serviceConfigurationNotCreatedSaga(),
    // Update
    updateServiceConfigurationSaga(),
    serviceConfigurationNotUpdatedSaga(),
    // Delete
    deleteServiceConfigurationSaga(),
    serviceConfigurationDeletedSaga(),
    serviceConfigurationNotDeletedSaga(),
    // Delete All
    deleteServiceConfigurationsSaga(),
    serviceConfigurationsDeletedSaga(),
    serviceConfigurationsNotDeletedSaga(),
    // Test
    testServiceConfigurationSaga(),
    testServiceConfigurationsSaga(),
    serviceConfigurationTestedSaga(),
    serviceConfigurationNotTestedSaga(),
    // Import Employees
    importPacsUsersSaga(),
    pacsUsersImportedSaga(),
    pacsUsersNotImportedSaga(),
    // Import Access Profiles
    importPacsAccessProfilesSaga(),
    pacsAccessProfilesImportedSaga(),
    pacsAccessProfilesNotImportedSaga(),
  ]);
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { ApiClient } from '../../models/ApiClient';

// State
interface ApiClientsState {
  updating: boolean;
  error: boolean;
  secret?: string;
}

const initialState: ApiClientsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@API_CLIENTS';

// Redux Actions|Reducers
const ApiClientsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createApiClient: (state, action: PayloadAction<ApiClient>) => {
      state.updating = true;
      state.error = false;
      state.secret = undefined;
    },
    apiClientCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
      state.secret = action?.payload?.msg?.secret;
    },
    apiClientNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
      state.secret = undefined;
    },

    // Update
    updateApiClient: (state, action: PayloadAction<ApiClient>) => {
      state.updating = true;
      state.error = false;
      state.secret = undefined;
    },
    apiClientUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
      state.secret = action?.payload?.msg?.secret;
    },
    apiClientNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
      state.secret = undefined;
    },

    // Delete All
    deleteApiClients: (state, action: PayloadAction<Array<ApiClient>>) => {
      state.updating = true;
      state.error = false;
    },
    apiClientsDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    apiClientsNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Clear Secret
    clearApiClientSecretValue: (state) => {
      state.secret = undefined;
    },

    // Validation Failed
    validationFailed: (state) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createApiClient,
  apiClientCreated,
  apiClientNotCreated,

  // Update
  updateApiClient,
  apiClientUpdated,
  apiClientNotUpdated,

  // Delete All
  deleteApiClients,
  apiClientsDeleted,
  apiClientsNotDeleted,

  // Clear Secret
  clearApiClientSecretValue,

  // Validation Failed
  validationFailed,
} = ApiClientsSlice.actions;

// Export Reducer
export default ApiClientsSlice.reducer;

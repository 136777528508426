/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IdentityUser } from '../../../models/IdentityUser';
import { SignalRAction } from '../../../models/SignalRAction';

// State
interface IdentityUserTenantsState {
  updating: boolean;
  error: boolean;
}

const initialState: IdentityUserTenantsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@IDENTITY_USER_TENANTS';

// Redux Actions|Reducers
const IdentityUserTenantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignIdentityUserTenants: (
      state,
      action: PayloadAction<{ identityUser: IdentityUser; tenantIds: Array<string> }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    identityUserTenantsAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identityUserTenantsNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignIdentityUserTenants,
  identityUserTenantsAssigned,
  identityUserTenantsNotAssigned,
} = IdentityUserTenantsSlice.actions;

// Export Reducer
export default IdentityUserTenantsSlice.reducer;

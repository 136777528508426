/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { ColumnInstance } from 'react-table';
import styled, { css } from 'styled-components';

// Constants
import { SELECTION_COLUMN } from './TableSelectionColumn';
import { EXPANSION_COLUMN } from './TableExpansionColumn';

// Hooks
import { HiddenColumnsProps } from '../../../../hooks/Table/useHiddenColumns';

// Components
import { SortIcon } from './Icons/SortIcon';

// Props
export interface TableHeadColumnProps<T extends object> extends HiddenColumnsProps<T> {
  children: React.ReactNode;
  column: ColumnInstance<T>;
}

// Render
const GenericHeadColumn = <T extends object>({
  column,
  children,
  // HiddenColumnsProps
  ColumnToggle,
  showAllColumns,
  hiddenColumns,
  onColumnHide,
  ...rest
}: React.PropsWithChildren<TableHeadColumnProps<T>>) => (
  <th {...rest}>
    {children}
    {column.id !== SELECTION_COLUMN && column.id !== EXPANSION_COLUMN && (
      <>
        {showAllColumns && <ColumnToggle column={column} />}
        {!showAllColumns && <SortIcon column={column} />}
        {!showAllColumns && column.canFilter && column.render('Filter')}
      </>
    )}
  </th>
);

// Styled
export const TableHeadColumn = styled(GenericHeadColumn)`
  display: flex;
  align-items: center;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0 !important;
  height: 54px;
  font-weight: 400 !important;
  padding: 0 0 0 0.45rem !important;

  ${(props) =>
    props.column.canSort &&
    !props.showAllColumns &&
    css`
      cursor: pointer;

      &:hover {
        background: #f2f2f2;
      }
    `};

  ${(props) =>
    props.column.isSorted &&
    !props.showAllColumns &&
    css`
      color: #08c;
      background: #f2f2f2;
    `};

  ${(props) =>
    props.column.id === SELECTION_COLUMN &&
    css`
      width: 40px !important;
      flex: none !important;
    `};
`;

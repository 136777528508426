import React, { useEffect } from 'react';
import { ApiOutlined, ImportOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button, Progress } from 'antd';

// Models
import { ServiceConfiguration } from '../../models/ServiceConfiguration';

// Data
import { AccessControlSystemTypes } from '../../models/enums/AccessControlSystemTypes';

// Redux
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import {
  importPACSAccessProfiles,
  importPACSEmployees,
  resetPacsImport,
  testServiceConfiguration,
} from '../../store/ServiceConfigurations/ServiceConfigurations.redux';

// Components
import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Spinner } from '../UI/Spinner/Spinner';
import { ServiceStatusTag } from '../UI/Tag/ServiceStatusTag';
import { BreadcrumbIcon } from '../UI/Icon/BreadcrumbIcon';

// Styled
const StyledApiOutlined = styled(ApiOutlined)`
  font-size: 24px;
`;

const StyledImportOutlined = styled(ImportOutlined)`
  font-size: 24px;
`;

const StyledSelectIcon = styled(BreadcrumbIcon)`
  font-size: 24px;
`;

// Props
interface ContactCardProps {
  serviceConfiguration: ServiceConfiguration | null;
  pending: boolean;
}

// Component
export const ContactCard = ({ serviceConfiguration, pending }: ContactCardProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { importing, item, totalItems, updatingEntityId } = useAppSelector(
    ({ serviceConfigurations }) => serviceConfigurations
  );

  // Effects
  useEffect(() => {
    dispatch(resetPacsImport());
  }, [dispatch]);

  return (
    <Widget title={<Translated id="app.serviceConfiguration" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updatingEntityId}>
        {/* Name */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <i className="icon icon-avatar gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="serviceConfigurations.accessControlSystemType" />
            </span>
            <div className="gx-mb-0">
              <span>
                {pending ? (
                  <ButtonSkeleton width={160} height={16} />
                ) : (
                  AccessControlSystemTypes.find((type) => type.Value === serviceConfiguration?.AccessControlSystemType)
                    ?.Name
                )}
              </span>
            </div>
          </div>
        </div>

        {/* Status */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <StyledApiOutlined className="gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="serviceConfigurations.status" />
            </span>
            <div className="gx-mb-0">
              <span>
                {pending || !serviceConfiguration ? (
                  <ButtonSkeleton width={120} height={10} />
                ) : (
                  <>
                    <ServiceStatusTag service={serviceConfiguration} />
                    <Button
                      type="primary"
                      ghost
                      style={{ marginBottom: 0 }}
                      size="small"
                      onClick={() =>
                        serviceConfiguration && dispatch(testServiceConfiguration({ id: serviceConfiguration.Id }))
                      }
                    >
                      <Translated id="serviceConfigurations.status.check" />
                    </Button>
                  </>
                )}
              </span>
            </div>
          </div>
        </div>

        {/* Access Profiles Import */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <StyledSelectIcon className="icon icon-select gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="pacs.importAccessProfiles" />
            </span>
            <div className="gx-mb-0">
              <span>
                <Button
                  type="primary"
                  ghost
                  style={{ marginBottom: 0 }}
                  size="small"
                  onClick={() =>
                    serviceConfiguration && dispatch(importPACSAccessProfiles({ id: serviceConfiguration.Id }))
                  }
                >
                  <Translated id="azureTenants.importUsers.button" />
                </Button>
              </span>
            </div>
          </div>
        </div>

        {/* Employees Import */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <StyledImportOutlined className="gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="pacs.importUsers" />
            </span>
            <div className="gx-mb-0">
              {!importing ? (
                <span>
                  <Button
                    type="primary"
                    ghost
                    style={{ marginBottom: 0 }}
                    size="small"
                    onClick={() =>
                      serviceConfiguration && dispatch(importPACSEmployees({ id: serviceConfiguration.Id }))
                    }
                  >
                    <Translated id="azureTenants.importUsers.button" />
                  </Button>
                </span>
              ) : (
                <Progress
                  status={item < totalItems ? 'active' : 'success'}
                  percent={Math.round((item / totalItems) * 100)}
                />
              )}
            </div>
          </div>
        </div>
      </Spinner>
    </Widget>
  );
};

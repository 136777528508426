import { Button, Divider, Form, Input } from 'antd';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';

import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Action, ActionFormValues } from '../../models/Action';
import { updateAction } from '../../store/Actions/Actions.redux';

const { Item } = Form;

interface ContactCardProps {
  action: Action | null;
  pending: boolean;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

export const ContactCard = ({ action, pending }: ContactCardProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { updatingSettings } = useAppSelector(({ actions }) => actions);

  // Form Values
  const initialValues: ActionFormValues = useMemo(
    () => ({
      Id: action?.Id,
      Name: action?.Name,
      DisplayLabel: action?.DisplayLabel,
      ServiceConfigurationId: action?.ServiceConfigurationId,
      Type: action?.Type,
      Tenants: [],
      Settings: action?.Settings,
    }),
    [action]
  );

  const SettingsForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values: Action) => dispatch(updateAction(values))}
      >
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="Type" hidden>
          <Input />
        </Item>
        <Item name="ServiceConfigurationId" hidden>
          <Input />
        </Item>
        <Item name="DisplayLabel" hidden>
          <Input />
        </Item>

        {/* Name */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <i className="icon icon-avatar gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="actions.name" />
            </span>
            <div className="gx-mb-0">{initialValues?.Name}</div>
          </div>
        </div>

        <Divider />
        <StyledButton type="primary" htmlType="submit">
          <Translated id="form.confirmButton" />
        </StyledButton>
      </Form>
    ),
    [initialValues, dispatch]
  );

  return (
    <Widget title={<Translated id="app.action" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updatingSettings || pending}>
        <SettingsForm />
      </Spinner>
    </Widget>
  );
};

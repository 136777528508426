import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useParams } from 'react-router';
import { useApiConfigurationsDetail } from '../../hooks/ApiConfigurations/useApiConfigurationsDetail';

// Components
import { Container } from '../../components/UI/Base';
import { ApiConfigurationTenants, ContactCard, ProfileHeader, Settings } from '../../components/ApiConfigurations';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const ApiConfigurationDetailPage = () => {
  const { id } = useParams();
  const {
    updating,
    getBreadcrumbMenuProps,
    getProfileHeaderProps,
    getContactCardProps,
    getFormDrawerProps,
    getApiConfigurationTenantsProps,
    getApiConfigurationProps,
  } = useApiConfigurationsDetail({
    id,
  });

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <Settings {...getApiConfigurationProps()} />
          </Col>

          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <ContactCard {...getContactCardProps()} />
          </Col>

          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <ApiConfigurationTenants {...getApiConfigurationTenantsProps()} />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};

import { kebabCase } from 'lodash';
import { css, FlattenSimpleInterpolation } from 'styled-components';

type Primitive = string | number;
type ResponsiveValue = null | undefined | Primitive;

type StyleCss = (propName: string, cssPropName?: string | undefined) => StyleFn;
type StyleFn = (props: Record<string, any>) => FlattenSimpleInterpolation | null;
type AddPX = (value: Primitive) => Primitive;

export const styleCss: StyleCss = (propName, cssPropName?) => (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const value: ResponsiveValue = props[propName];
  if (value == null) return null;

  const ruleName = cssPropName || kebabCase(propName);
  const ruleCss = `${ruleName}: ${addPx(value)}`;

  return css`
    ${ruleCss}
  `;
};

const addPx: AddPX = (value) => (value && typeof value === 'number' ? `${value}px` : value);

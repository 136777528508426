import React, { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';
import { SyncOutlined } from '@ant-design/icons';

// Components
import { IconButton } from '../../../components/UI/Button/IconButton';
import { Translated } from '../../../components/UI/Core';

// Models
import { AccessProfileResponse } from '../../../models/AccessProfile';

// Hooks
import { usePACSAccessProfilesRedux } from './usePACSAccessProfilesRedux';

// Props
interface PACSAccessProfilesProps {
  accessProfileResponse: AccessProfileResponse | null;
  fetchAccessProfile: (params: any) => Promise<void>;
  updatingAccessProfile: boolean;
}

export const usePACSAccessProfiles = ({
  accessProfileResponse,
  fetchAccessProfile,
  updatingAccessProfile,
}: PACSAccessProfilesProps) => {
  // Redux
  const { PACSAccessProfilesManagementForm, updating, error, showSyncConfirm } = usePACSAccessProfilesRedux({
    accessProfileResponse,
  });
  const prevUpdating = usePrevious(updating);

  // Sync PACSAccessProfiles Button
  const SyncButton = useMemo(
    () => (
      <IconButton
        title={<Translated id="accessProfiles.pacsAccessProfiles.syncButton" />}
        onClick={() => showSyncConfirm()}
        Icon={SyncOutlined}
      />
    ),
    [showSyncConfirm]
  );

  // Component State
  const loading = !accessProfileResponse;

  // Effects
  useEffect(() => {
    // Fetch after updating
    if (accessProfileResponse && prevUpdating === true && updating === false && !error) {
      fetchAccessProfile({ id: accessProfileResponse.AccessProfile.Id });
    }
  }, [prevUpdating, updating, error, fetchAccessProfile, accessProfileResponse]);

  // Return Hook
  return useMemo(
    () => ({
      pacsAccessProfiles: accessProfileResponse?.AvailablePacsAccessProfiles,
      PACSAccessProfilesManagementForm,
      loading,
      updating: updating || updatingAccessProfile,
      SyncButton,
    }),
    [accessProfileResponse, PACSAccessProfilesManagementForm, loading, updating, updatingAccessProfile, SyncButton]
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { AzureTenant } from '../../models/AzureTenant';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface AzureTenantState {
  updating: boolean;
  updatingDefault: boolean;
  error: boolean;
}

const initialState: AzureTenantState = {
  updating: false,
  updatingDefault: false,
  error: false,
};

// Name
const storeName = '@AZURE_TENANTS';

// Redux Actions|Reducers
const AzureTenantSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createAzureTenant: (state, action: PayloadAction<AzureTenant>) => {
      state.updating = true;
      state.error = false;
    },
    azureTenantCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    azureTenantNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateAzureTenant: (state, action: PayloadAction<AzureTenant>) => {
      state.updating = true;
      state.error = false;
    },
    azureTenantUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    azureTenantNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteAzureTenant: (state, action: PayloadAction<AzureTenant>) => {
      state.updating = true;
      state.error = false;
    },
    azureTenantDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    azureTenantNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteAzureTenants: (state, action: PayloadAction<Array<AzureTenant>>) => {
      state.updating = true;
      state.error = false;
    },
    azureTenantsDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    azureTenantsNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Import
    importAzureTenant: (state, action: PayloadAction<AzureTenant>) => {
      state.updating = true;
      state.error = false;
    },
    azureTenantImported: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    azureTenantNotImported: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createAzureTenant,
  azureTenantCreated,
  azureTenantNotCreated,
  // Update
  updateAzureTenant,
  azureTenantUpdated,
  azureTenantNotUpdated,
  // Delete
  deleteAzureTenant,
  azureTenantDeleted,
  azureTenantNotDeleted,
  // Delete All
  deleteAzureTenants,
  azureTenantsDeleted,
  azureTenantsNotDeleted,
  // Import
  importAzureTenant,
  azureTenantImported,
  azureTenantNotImported,
} = AzureTenantSlice.actions;

// Export Reducer
export default AzureTenantSlice.reducer;

import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row as TableRow } from 'react-table';

// Types
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { Action } from '../../models/Action';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { ActionType } from '../../models/enums/ActionType';
import { createAction, deleteAction, deleteActions, updateAction } from '../../store/Actions/Actions.redux';
import { useData } from '../App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';

const { confirm } = Modal;
const { Option } = Select;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface ActionsReduxProps {
  isEditing?: boolean;
  initialValues?: Store;
}

// Hook
export const useActionsRedux = <T extends object>({ isEditing, initialValues }: ActionsReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ actions }) => actions?.updating ?? false);
  const error = useAppSelector(({ actions }) => actions?.error ?? false);

  const { data: serviceConfigurationsData, fetch: fetchServiceConfigurations } = useData(
    ApiEndpoints.serviceConfigurations.list,
    null
  );

  // Submit Handling
  const onSubmit = useCallback(
    (action: Action) => {
      if (isEditing) {
        dispatch(updateAction(action));
      } else {
        dispatch(createAction(action));
      }
    },
    [dispatch, isEditing]
  );

  const options = Object.entries(ActionType)
    .filter(([, value]) => typeof value === 'number')
    .map(([key, value]) => (
      <option key={value} value={value}>
        {key}
      </option>
    ));

  // Components
  const CreateActionForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as Action)}
        initialValues={initialValues}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="actions.name" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'actions.form.warnings.name' }),
                  },
                ]}
              >
                <Input placeholder="Identifier Actions" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="DisplayLabel"
                label={<Translated id="actions.display.label" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'actions.form.warnings.displayLabel' }),
                  },
                ]}
              >
                <Input placeholder="Display label" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="ServiceConfigurationId"
                label={<Translated id="actions.serviceConfigurationId" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'actions.form.warnings.serviceConfigurationId' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="actions.serviceConfigurationId" />}>
                  {serviceConfigurationsData?.map((apiConfiguration) => (
                    <Option key={apiConfiguration.Id} value={apiConfiguration.Id}>
                      {apiConfiguration.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="Type"
                label={<Translated id="actions.action.type" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'actions.form.warnings.action.type' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="actions.action.type" />}>{options}</Select>
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, initialValues, intl, options, serviceConfigurationsData]
  );
  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'Actions',
        Form: CreateActionForm,
        labels: {
          createButton: <Translated id="actions.form.create" />,
          drawerForm: <Translated id={isEditing ? 'actions.form.edit' : 'actions.form.create'} />,
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateActionForm, isEditing]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (action: Action | null) => {
      if (action) {
        confirm({
          title: intl.formatMessage({
            id: 'actions.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'actions.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteAction(action)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const action = selectedFlatRows.map((d) => d.original) as Action[];
      if (action) {
        confirm({
          title: intl.formatMessage({
            id: 'actions.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'actions.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteActions(action)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // Effects
  useEffect(() => {
    fetchServiceConfigurations();
  }, [fetchServiceConfigurations]);

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
      showDeleteAllConfirm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm]
  );
};

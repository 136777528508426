/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { AccessProfile } from '../../../models/AccessProfile';
import { SignalRAction } from '../../../models/SignalRAction';
import { Tenant } from '../../../models/Tenant';

// State
interface AccessProfileTenantsState {
  updating: boolean;
  error: boolean;
}

const initialState: AccessProfileTenantsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@ACCESS_PROFILE_TENANTS';

// Redux Actions|Reducers
const accessProfileTenantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignAccessProfileTenants: (
      state,
      action: PayloadAction<{ accessProfile: AccessProfile; tenants: Array<Tenant> }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    accessProfileTenantsAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    accessProfileTenantsNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignAccessProfileTenants,
  accessProfileTenantsAssigned,
  accessProfileTenantsNotAssigned,
} = accessProfileTenantsSlice.actions;

// Export Reducer
export default accessProfileTenantsSlice.reducer;

import * as React from 'react';
import styled from 'styled-components';

// Assets
import { ReactComponent as ChevronRight } from '../../../../../assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from '../../../../../assets/icons/chevron-left.svg';

// Props
interface PaginationIconProps {
  left?: boolean;
  right?: boolean;
}

// Styles
const iconStyles = {
  width: '16px',
};

// Styled
const Span = styled.span`
  width: 16px;
  height: 16px;
  line-height: 12px;
  display: flex;
`;

// Render
export const PaginationIcon = ({ left, right }: PaginationIconProps) => (
  <Span>{left ? <ChevronLeft {...iconStyles} /> : right ? <ChevronRight {...iconStyles} /> : ''}</Span>
);

PaginationIcon.defaultProps = {
  left: null,
  right: null,
};

import React, { useCallback, useMemo } from 'react';
import { Col, Form, Input, Modal, Row, Select, Switch } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row as TableRow } from 'react-table';

// Types
import FormItemLabel from 'antd/lib/form/FormItemLabel';
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { ServiceConfiguration } from '../../models/ServiceConfiguration';
import { AccessControlSystemTypes } from '../../models/enums/AccessControlSystemTypes';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import {
  createServiceConfiguration,
  deleteServiceConfiguration,
  deleteServiceConfigurations,
  updateServiceConfiguration,
} from '../../store/ServiceConfigurations/ServiceConfigurations.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';

const { confirm } = Modal;
const { Option } = Select;
const { Item } = Form;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;

const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0px;
`;

// Props
interface ServiceConfigurationsReduxProps {
  isEditing?: boolean;
  initialValues?: Store | undefined;
}

// Hooks
export const useServiceConfigurationsRedux = <T extends object>({
  isEditing,
  initialValues,
}: ServiceConfigurationsReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ serviceConfigurations }) => serviceConfigurations?.updating ?? false);
  const error = useAppSelector(({ serviceConfigurations }) => serviceConfigurations?.error ?? false);

  // Submit Handling
  const onSubmit = useCallback(
    (serviceConfiguration: ServiceConfiguration) => {
      if (isEditing) {
        dispatch(updateServiceConfiguration(serviceConfiguration));
      } else {
        dispatch(createServiceConfiguration(serviceConfiguration));
      }
    },
    [dispatch, isEditing]
  );

  // Components
  const CreateServiceConfigurationForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as ServiceConfiguration)}
        initialValues={initialValues}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="serviceConfigurations.name" />}
                rules={[
                  { required: true, message: intl.formatMessage({ id: 'serviceConfigurations.form.warnings.name' }) },
                ]}
              >
                <Input placeholder="Service Configuration" />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="AccessControlSystemType"
                label={<Translated id="serviceConfigurations.accessControlSystemType" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'serviceConfigurations.form.warnings.accessControlSystemType' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="serviceConfigurations.accessControlSystemType" />}>
                  {AccessControlSystemTypes.map((type) => (
                    <Option key={type.Value} value={type.Value}>
                      {type.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <FormItemLabel label={<Translated id="serviceConfigurations.manageEmployees" />} prefixCls="cls" />
              <NoMarginBottomFormItem name="ManageEmployees" valuePropName="checked">
                <Switch
                  checkedChildren={<Translated id="pacsSetting.form.active" />}
                  unCheckedChildren={<Translated id="pacsSetting.form.disabled" />}
                />
              </NoMarginBottomFormItem>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, initialValues, intl]
  );
  // Form options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'ServiceConfigurations',
        Form: CreateServiceConfigurationForm,
        labels: {
          createButton: <Translated id="serviceConfigurations.form.create" />,
          drawerForm: (
            <Translated id={isEditing ? 'serviceConfigurations.form.edit' : 'serviceConfigurations.form.create'} />
          ),
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateServiceConfigurationForm, isEditing]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (serviceConfiguration: ServiceConfiguration | null) => {
      if (serviceConfiguration) {
        confirm({
          title: intl.formatMessage({
            id: 'serviceConfigurations.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'serviceConfigurations.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteServiceConfiguration(serviceConfiguration)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const serviceConfigurations = selectedFlatRows.map((d) => d.original) as ServiceConfiguration[];
      if (serviceConfigurations) {
        confirm({
          title: intl.formatMessage({
            id: 'serviceConfigurations.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'serviceConfigurations.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteServiceConfigurations(serviceConfigurations)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
      showDeleteAllConfirm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm]
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import styled from 'styled-components';
import { ServiceConfiguration, ServiceConfigurationResponse } from '../../../models/ServiceConfiguration';
import { Tenant } from '../../../models/Tenant';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { assignServiceConfigurationTenants } from '../../../store/ServiceConfigurations/ServiceConfigurationTenants/ServiceConfigurationTenants.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';

const { confirm } = Modal;

// Props
interface ServiceConfigurationTenantsFormProps {
  serviceConfigurationTenantsResponse: ServiceConfigurationResponse | null;
  serviceConfigurationTenants: Array<Tenant> | null;
}

interface AssignConfirmProps {
  tenantIds: Array<string>;
  serviceConfiguration: ServiceConfiguration;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useServiceConfigurationTenantsRedux = ({
  serviceConfigurationTenantsResponse,
  serviceConfigurationTenants,
}: ServiceConfigurationTenantsFormProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(
    ({ serviceConfigurationTenants: serviceConfigurationTenantsState }) =>
      serviceConfigurationTenantsState?.updating ?? false
  );
  const error = useAppSelector(
    ({ serviceConfigurationTenants: serviceConfigurationTenantsState }) =>
      serviceConfigurationTenantsState?.error ?? false
  );

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ tenantIds, serviceConfiguration }: AssignConfirmProps) => {
      if (tenantIds && serviceConfiguration) {
        confirm({
          title: intl.formatMessage({
            id: 'serviceConfigurations.Tenants.multiSelect.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'serviceConfigurations.Tenants.multiSelect.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignServiceConfigurationTenants({ tenantIds, serviceConfiguration })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // State
  const [tenantIds, setTenantIds] = useState<Array<string>>([]);

  // Sets the Keys when the response is updated
  // Fixes problem with initial values after API call
  useEffect(() => {
    setTenantIds(
      serviceConfigurationTenants
        ?.filter((tenant) =>
          serviceConfigurationTenantsResponse?.ServiceConfiguration.Tenants?.some(
            (serviceConfigurationTenant) => serviceConfigurationTenant.Id === tenant.Id
          )
        )
        .map((tenant) => tenant.Id) ?? []
    );
  }, [serviceConfigurationTenants, serviceConfigurationTenantsResponse]);

  // Components
  const ServiceConfigurationTenantsManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      serviceConfigurationTenants?.map<TransferItem>((serviceConfigurationTenant) => ({
        key: serviceConfigurationTenant.Id,
        title: serviceConfigurationTenant.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <ItemTransfer dataSource={dataSource} targetKeys={tenantIds} setTargetKeys={setTenantIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            serviceConfigurationTenantsResponse &&
            showAssignConfirm({
              tenantIds,
              serviceConfiguration: serviceConfigurationTenantsResponse.ServiceConfiguration,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [serviceConfigurationTenantsResponse, serviceConfigurationTenants, showAssignConfirm, tenantIds, setTenantIds]);

  return useMemo(
    () => ({
      ServiceConfigurationTenantsManagementForm,
      updating,
      error,
    }),
    [ServiceConfigurationTenantsManagementForm, updating, error]
  );
};

import { sortBy } from 'lodash';

// Models
import { PACSAccessProfile } from '../../models/PACSAccessProfile';
import { PACSIdentifierType } from '../../models/PACSIdentifierType';

// Components
import { TreeItem } from '../../components/UI/Tree/TreeForm';

// Utils
import { AccessControlSystemTypes } from '../../models/enums/AccessControlSystemTypes';

// Get all PACS Access Profiles as SingleTreeMultiSelectItems
export const getPACSAccessProfileTreeItems = (pacsAccessProfiles: Array<PACSAccessProfile> | null) => {
  const configurations =
    pacsAccessProfiles
      ?.flatMap((x) => x.ServiceConfiguration)
      ?.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.AccessControlSystemType === value.AccessControlSystemType && t.Name === value.Name && t.Id === value.Id
          )
      ) ?? [];

  const systemTypes = [...new Set(configurations.map((x) => x.AccessControlSystemType))];

  const pacsAccessProfilesTree = systemTypes.map((systemType) => ({
    PACSType: {
      Id: systemType.toString(),
      Name: AccessControlSystemTypes.find((x) => x.Value === systemType)?.Name ?? 'Unknown PACS',
    },
    ServiceConfigurations: sortBy(
      configurations
        .filter((configuration) => configuration.AccessControlSystemType === systemType)
        .map((configuration) => ({
          ServiceConfiguration: {
            Id: configuration.Id,
            Name: configuration.Name,
          },
          AccessProfiles:
            sortBy(
              pacsAccessProfiles?.filter(
                (profile) =>
                  profile.ServiceConfiguration.AccessControlSystemType === systemType &&
                  profile.ServiceConfiguration.Id === configuration.Id
              ),
              (profile) => profile.Name.toLowerCase()
            ) ?? [],
        })),
      (configuration) => configuration.ServiceConfiguration.Name.toLowerCase()
    ),
  }));

  return (
    pacsAccessProfilesTree?.map<TreeItem>((pacs, i1) => ({
      id: pacs.PACSType.Id,
      key: `0-${i1}`,
      title: pacs.PACSType.Name,
      children:
        pacs.ServiceConfigurations?.map<TreeItem>((configs, i2) => ({
          id: configs.ServiceConfiguration.Id,
          key: `0-${i1}-${i2}`,
          title: configs.ServiceConfiguration.Name,
          children:
            configs.AccessProfiles?.map<TreeItem>((profile, i3) => ({
              id: profile.Id,
              key: `0-${i1}-${i2}-${i3}`,
              title: profile.Name,
            })) ?? [],
        })) ?? [],
    })) ?? []
  );
};

// Get all PACS Identifier Types as SingleTreeMultiSelectItems
export const getPACSIdentifierTypeTreeItems = (pacsIdentifierTypes: Array<PACSIdentifierType> | null) => {
  const configurations =
    pacsIdentifierTypes
      ?.flatMap((x) => x.ServiceConfiguration)
      ?.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.AccessControlSystemType === value.AccessControlSystemType && t.Name === value.Name && t.Id === value.Id
          )
      ) ?? [];

  const systemTypes = [...new Set(configurations.map((x) => x.AccessControlSystemType))];

  const pacsIdentifierTypesTree = systemTypes.map((systemType) => ({
    PACSType: {
      Id: systemType.toString(),
      Name: AccessControlSystemTypes.find((x) => x.Value === systemType)?.Name ?? 'Unknown PACS',
    },
    ServiceConfigurations: sortBy(
      configurations
        .filter((configuration) => configuration.AccessControlSystemType === systemType)
        .map((configuration) => ({
          ServiceConfiguration: {
            Id: configuration.Id,
            Name: configuration.Name,
          },
          IdentifierTypes:
            sortBy(
              pacsIdentifierTypes?.filter(
                (profile) =>
                  profile.ServiceConfiguration.AccessControlSystemType === systemType &&
                  profile.ServiceConfiguration.Id === configuration.Id
              ),
              (profile) => profile.Name.toLowerCase()
            ) ?? [],
        })),
      (configuration) => configuration.ServiceConfiguration.Name.toLowerCase()
    ),
  }));

  return (
    pacsIdentifierTypesTree?.map<TreeItem>((pacs, i1) => ({
      id: pacs.PACSType.Id,
      key: `0-${i1}`,
      title: pacs.PACSType.Name,
      children:
        pacs.ServiceConfigurations?.map<TreeItem>((configs, i2) => ({
          id: configs.ServiceConfiguration.Id,
          key: `0-${i1}-${i2}`,
          title: configs.ServiceConfiguration.Name,
          children:
            configs.IdentifierTypes?.map<TreeItem>((profile, i3) => ({
              id: profile.Id,
              key: `0-${i1}-${i2}-${i3}`,
              title: profile.Name,
            })) ?? [],
        })) ?? [],
    })) ?? []
  );
};

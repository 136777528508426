import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useApiClientList } from '../../hooks/ApiClients/useApiClientsList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const ApiClientsPage = () => {
  const {
    updating,
    getBreadcrumbMenuProps,
    getClientsDataProps,
    getClientsTableProps,
    getClientsCrudProps,
    getFormDrawerProps,
  } = useApiClientList();

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        <Row>
          <Col span={24}>
            <Table {...getClientsDataProps()} {...getClientsTableProps()} {...getClientsCrudProps()} />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};

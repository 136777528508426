import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { AccessProfileResponse } from '../../../models/AccessProfile';

// Hooks
import { useAccessProfileTenantsRedux } from './useAccessProfileTenantsRedux';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface AccessProfileTenantsProps {
  accessProfileResponse: AccessProfileResponse | null;
  fetchAccessProfile: (params: any) => Promise<void>;
  updatingAccessProfile: boolean;
}

export const useAccessProfileTenants = ({
  accessProfileResponse: accessProfileTenantsResponse,
  fetchAccessProfile,
  updatingAccessProfile: updatingAccessProfileTenant,
}: AccessProfileTenantsProps) => {
  // Data
  const {
    data: accessProfileTenants,
    fetch: fetchAccessProfileTenants,
    pending,
  } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const { AccessProfileTenantsManagementForm, updating, error } = useAccessProfileTenantsRedux({
    accessProfileTenantsResponse,
    accessProfileTenants,
  });
  const prevUpdating = usePrevious(updating);

  // Component State
  const loading = pending && !accessProfileTenantsResponse;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchAccessProfileTenants();
  }, [fetchAccessProfileTenants]);

  useEffect(() => {
    // Fetch after updating
    if (accessProfileTenantsResponse && prevUpdating === true && updating === false && !error) {
      fetchAccessProfile({ id: accessProfileTenantsResponse.AccessProfile.Id });
      fetchAccessProfileTenants();
    }
  }, [prevUpdating, updating, error, fetchAccessProfile, fetchAccessProfileTenants, accessProfileTenantsResponse]);

  // Return Hook
  return useMemo(
    () => ({
      accessProfileTenants,
      AccessProfileTenantsManagementForm,
      loading,
      updating: updating || updatingAccessProfileTenant,
    }),
    [accessProfileTenants, AccessProfileTenantsManagementForm, loading, updating, updatingAccessProfileTenant]
  );
};

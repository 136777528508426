import React from 'react';
import { ColumnInstance } from 'react-table';
import styled, { css } from 'styled-components';

// Components
import { SELECTION_COLUMN } from './TableSelectionColumn';

// Props
export interface TableDataProps<T extends object> {
  children: React.ReactNode;
  className?: string;
  column?: ColumnInstance<T>;
}

// Render
const GenericTableData = <T extends object>({ children, ...rest }: React.PropsWithChildren<TableDataProps<T>>) => (
  <td {...rest}>{children}</td>
);

// Styled
export const TableData = styled(GenericTableData)`
  color: #616161;
  border-bottom: 1px solid #f2f2f2;
  height: 44px;
  line-height: 44px !important;
  padding: 0 8px !important;
  vertical-align: middle !important;

  &.no-border {
    border-bottom: none;
  }

  ${(props) =>
    props.column?.id === SELECTION_COLUMN &&
    css`
      width: 40px !important;
      flex: none !important;
    `};
`;

GenericTableData.defaultProps = {
  className: undefined,
  column: undefined,
};

import { CheckCircleFilled, ExclamationCircleFilled, StopFilled } from '@ant-design/icons';
import React from 'react';

export enum ServiceStatus {
  NotFound = -4,
  Error = -1,
  NotChecked = 0,
  Operational = 1,
}

export interface ServiceStatusType {
  NameId: string;
  Color: string;
  Icon: React.ForwardRefExoticComponent<any>;
  ServiceStatus: ServiceStatus;
}

export const ServiceStatusInfo: ServiceStatusType[] = [
  {
    NameId: 'serviceConfigurations.status.error',
    Color: 'error',
    Icon: StopFilled,
    ServiceStatus: ServiceStatus.Error,
  },
  {
    NameId: 'serviceConfigurations.status.notChecked',
    Color: 'warning',
    Icon: ExclamationCircleFilled,
    ServiceStatus: ServiceStatus.NotChecked,
  },
  {
    NameId: 'serviceConfigurations.status.operational',
    Color: 'success',
    Icon: CheckCircleFilled,
    ServiceStatus: ServiceStatus.Operational,
  },
];

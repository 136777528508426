import React, { useCallback, useEffect } from 'react';
import { Avatar, Button, Col, Row, Select } from 'antd';
import { DeleteOutlined, EditOutlined, SelectOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Kiosk } from '../../models/Kiosk';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Translated } from '../UI/Core';
import { DetailHeader } from '../App/AppLayout/DetailHeader';
import { useData } from '../../hooks/App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { updateKiosk } from '../../store/Kiosks/Kiosks.redux';
import { useAppDispatch } from '../../hooks/App/useRedux';

const StyledSpan = styled.span`
  margin-left: 5px !important;
`;

interface ProfileHeaderProps {
  kioskData: Kiosk | undefined;
  pending: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteConfirm: (kiosk: Kiosk | undefined) => void;
}

export const ProfileHeader = ({ kioskData, pending, setOpen, showDeleteConfirm }: ProfileHeaderProps) => {
  const { data: tenants, fetch: fetchTenants } = useData(ApiEndpoints.tenants.list, null);
  const dispatch = useAppDispatch();

  const url = `https://${process.env.REACT_APP_KIOSK_HOST}/${kioskData?.Id}`;

  // Effects
  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);

  const onEnviromentChange = useCallback(
    (value: string) => {
      const kiosk = kioskData;

      if (kiosk !== null && kiosk !== undefined) {
        kiosk.TenantId = value;
        dispatch(updateKiosk(kiosk));
      }
    },
    [kioskData, dispatch]
  );

  return (
    <DetailHeader>
      <div className="gx-profile-banner-top">
        <div className="gx-profile-banner-top-left">
          <div className="gx-profile-banner-avatar">
            <Avatar
              className="gx-size-90"
              alt="..."
              src="https://as2.ftcdn.net/jpg/02/60/78/83/500_F_260788352_x5sSHM4DGvpjHj9wz8sFltzAPktQwJCj.jpg"
            />
          </div>
          <div className="gx-profile-banner-avatar-info">
            <Row>
              <Col>
                <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                  {pending ? <ButtonSkeleton width={240} height={16} /> : kioskData?.Name}
                </h2>
              </Col>
            </Row>
            <Row>
              {pending ? (
                <ButtonSkeleton width={240} height={24} />
              ) : (
                <>
                  <Col>
                    <Button
                      ghost
                      type="primary"
                      icon={<SelectOutlined />}
                      key="Edit"
                      onClick={() => window.open(url)}
                      style={{ marginBottom: 0 }}
                    >
                      <StyledSpan>
                        <Translated id="kiosks.open.button" defaultMessage="Open" />
                      </StyledSpan>
                    </Button>
                  </Col>
                  <Col>
                    <Select
                      placeholder={<Translated id="kiosks.environment" />}
                      style={{ marginLeft: '10px', width: '200px' }}
                      onChange={onEnviromentChange}
                      value={kioskData?.TenantId}
                      defaultValue={kioskData?.TenantId}
                    >
                      {tenants?.map((tenant) => (
                        <Select.Option key={tenant.Id} value={tenant.Id}>
                          {tenant.Name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </div>
        <div className="gx-profile-banner-top-right">
          <Button ghost type="primary" icon={<EditOutlined />} key="Edit" onClick={() => setOpen(true)}>
            <StyledSpan>
              <Translated id="app.edit" defaultMessage="Edit" />
            </StyledSpan>
          </Button>
          <Button ghost danger icon={<DeleteOutlined />} key="Delete" onClick={() => showDeleteConfirm(kioskData)}>
            <StyledSpan>
              <Translated id="app.delete" defaultMessage="Delete" />
            </StyledSpan>
          </Button>
        </div>
      </div>
    </DetailHeader>
  );
};

// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import { accessProfileTenantsAssigned, accessProfileTenantsNotAssigned } from './AccessProfileTenants.redux';

// Events
const SignalREvents = {
  AccessProfileTenantsAssigned: `AccessProfileTenantsAssignedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  AccessProfileTenantsNotAssigned: `AccessProfileTenantsNotAssignedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.AccessProfileTenantsAssigned, (msg) => {
    store.dispatch(accessProfileTenantsAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AccessProfileTenantsNotAssigned, (msg) => {
    store.dispatch(accessProfileTenantsNotAssigned({ history, msg }));
  });
};

import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { AzureTenant } from '../../../models/AzureTenant';

// Hooks
import { useSettingsRedux } from './useSettingsRedux';

// Props
interface SettingProps {
  azureTenant: AzureTenant | undefined;
  fetchAzureTenant: (params: any) => Promise<void>;
  updatingAzureTenant: boolean;
}

export const useSettings = ({ azureTenant, fetchAzureTenant, updatingAzureTenant }: SettingProps) => {
  // Redux
  const { SsoSettingsForm, ImportSettingsForm, updating, error } = useSettingsRedux({
    azureTenant,
  });
  const prevUpdating = usePrevious(updating);

  // Component Effects
  useEffect(() => {
    // Fetch after updating
    if (azureTenant && prevUpdating === true && updating === false && !error) {
      fetchAzureTenant({ id: azureTenant.Id });
    }
  }, [prevUpdating, updating, error, fetchAzureTenant, azureTenant]);

  // Return Hook
  return useMemo(
    () => ({
      SsoSettingsForm,
      ImportSettingsForm,
      loading: !azureTenant,
      updating: updating || updatingAzureTenant,
    }),
    [SsoSettingsForm, ImportSettingsForm, azureTenant, updating, updatingAzureTenant]
  );
};

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { PACSSettingsFormValues } from '../../../models/PACSSettingFormValues';
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  assignPACSServiceConfiguration,
  pacsAdvancedSettingsNotUpdated,
  pacsAdvancedSettingsUpdated,
  pacsServiceConfigurationAssigned,
  pacsServiceConfigurationNotAssigned,
  updatePACSAdvancedSettings,
} from './PACSSettings.redux';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignPACSServiceConfigurationSaga() {
  yield takeEvery(assignPACSServiceConfiguration.type, assignPACSServiceConfigurationRequest);
}

function* pacsServiceConfigurationAssignedSaga() {
  yield takeLatest(pacsServiceConfigurationAssigned.type, assignPACSServiceConfigurationResponse);
}

function* pacsServiceConfigurationNotAssignedSaga() {
  yield takeLatest(pacsServiceConfigurationNotAssigned.type, assignPACSServiceConfigurationError);
}

// Request
function* assignPACSServiceConfigurationRequest(action: PayloadAction<PACSSettingsFormValues>) {
  try {
    const { payload: pacsSettingFormValues } = action;
    yield apiService.execute({
      url: `ServiceConfigurations/${pacsSettingFormValues.Id}`,
      method: ApiRequestType.PUT,
      data: pacsSettingFormValues,
    });
  } catch ({ message }) {
    yield put({ type: pacsServiceConfigurationNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignPACSServiceConfigurationResponse() {
  notificationService.showSuccess('serviceConfigurations.pacsSettings.notifications.assign');
}

// Error
function assignPACSServiceConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'serviceConfigurations.pacsSettings.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* ADVANCED SETTINGS *********************

// Worker Sagas
function* updatePACSAdvancedSettingsSaga() {
  yield takeEvery(updatePACSAdvancedSettings.type, updatePACSAdvancedSettingsRequest);
}

function* pacsAdvancedSettingsUpdatedSaga() {
  yield takeLatest(pacsAdvancedSettingsUpdated.type, updatePACSAdvancedSettingsResponse);
}

function* pacsAdvancedSettingsNotUpdatedSaga() {
  yield takeLatest(pacsAdvancedSettingsNotUpdated.type, updatePACSAdvancedSettingsError);
}

// Request
function* updatePACSAdvancedSettingsRequest(action: PayloadAction<PACSSettingsFormValues>) {
  try {
    const { payload: pacsSettingFormValues } = action;
    yield apiService.execute({
      url: `ServiceConfigurations/${pacsSettingFormValues.Id}/AdvancedSettings`,
      method: ApiRequestType.PUT,
      data: pacsSettingFormValues,
    });
  } catch ({ message }) {
    yield put({ type: pacsAdvancedSettingsNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updatePACSAdvancedSettingsResponse() {
  notificationService.showSuccess('serviceConfigurations.pacsSettings.notifications.assign');
}

// Error
function updatePACSAdvancedSettingsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'serviceConfigurations.pacsSettings.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignPACSServiceConfigurationSaga(),
    pacsServiceConfigurationAssignedSaga(),
    pacsServiceConfigurationNotAssignedSaga(),
    // Advanced Settings
    updatePACSAdvancedSettingsSaga(),
    pacsAdvancedSettingsUpdatedSaga(),
    pacsAdvancedSettingsNotUpdatedSaga(),
  ]);
}

import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { CalendarEventConfiguration } from '../../../models/CalendarEventConfiguration';

// Hooks
import { useSettingsRedux } from './useSettingsRedux';

// Props
interface SettingProps {
  calendarEventConfiguration: CalendarEventConfiguration | undefined;
  fetchCalendarEventConfiguration: (params: any) => Promise<void>;
  updatingCalendarEventConfiguration: boolean;
}

export const useSettings = ({
  calendarEventConfiguration,
  fetchCalendarEventConfiguration,
  updatingCalendarEventConfiguration,
}: SettingProps) => {
  // Redux
  const { SettingsForm, updating, error } = useSettingsRedux({
    calendarEventConfiguration,
  });
  const prevUpdating = usePrevious(updating);

  // Component Effects
  useEffect(() => {
    // Fetch after updating
    if (calendarEventConfiguration && prevUpdating === true && updating === false && !error) {
      fetchCalendarEventConfiguration({ id: calendarEventConfiguration.Id });
    }
  }, [prevUpdating, updating, error, fetchCalendarEventConfiguration, calendarEventConfiguration]);

  // Return Hook
  return useMemo(
    () => ({
      SettingsForm,
      loading: !calendarEventConfiguration,
      updating: updating || updatingCalendarEventConfiguration,
    }),
    [SettingsForm, calendarEventConfiguration, updating, updatingCalendarEventConfiguration]
  );
};

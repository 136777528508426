import Color from 'color';

// Definitions
export const BaseColors = {
  white: '#FFFFFF',
  whiteSmoke: '#F5F5F5',
  black: '#000000',
  dark: '#262626',
  light: '#545454',
  lightGray: '#D3D3D3',
  darkGray: '#A9A9A9',
};

export const AppColors = {
  main: '#02bbdc',
  pink: '#eb2f96',
  red: '#f5222d',
  volcano: '#fa541c',
  orange: '#fa8c16',
  gold: '#faad14',
  yellow: '#fadb14',
  sky: '#c5eded',
  cyan: '#13c2c2',
  teal: '#20c997',
  lime: '#a0d911',
  green: '#52c41a',
  blue: '#1890ff',
  geekblue: '#2f54eb',
  purple: '#722ed1',
  indigo: '#3c1991',
};

// Types
export type AppColor = keyof typeof AppColors;

// Helpers
export const getColor = (color: AppColor) => AppColors[color];
export const getLighterColor = (color: AppColor) => new Color(getColor(color)).lightness(95).hex();
export const getDarkerColor = (color: AppColor) => new Color(getColor(color)).lightness(45).hex();

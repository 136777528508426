// Constants
import { ApplicationPaths, Authority, QueryParameterNames } from '../Settings/AuthSettings';

// Redirect URL
export const getRedirectUrl = (path: string) => {
  const link = document.createElement('a');
  link.href = path;

  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;

  return redirectUrl;
};

// Return URL
export const getReturnUrl = (state?: any) => {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get(QueryParameterNames.ReturnUrl);

  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
  }

  return (state && state.returnUrl) || fromQuery || `${window.location.origin}`;
};

// IDP Profile URL
export const profileUrl = `${ApplicationPaths.Profile}`;

// IDP Logout URL
export const logoutUrl = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

// Navigation Helpers
export const navigateToUrl = (url: string, timeout: number | null = null) => {
  if (timeout) setTimeout(() => window.location.replace(url), timeout);
  else window.location.replace(url);
};

export const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
  window.location.replace(`${Authority}${apiAuthorizationPath}`);
};

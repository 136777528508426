import React from 'react';

import { TableColumn } from '../../types/Table';
import { ScheduleConfiguration } from '../../models/ScheduleConfiguration';
import { Translated } from '../../components/UI/Core';
import { ScheduleConfigurationTypes } from '../../models/enums/ScheduleConfigurationTypes';

export const ScheduleConfigurationTableColumns: Array<TableColumn<ScheduleConfiguration>> = [
  {
    Header: <Translated id="scheduleConfigurations.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="scheduleConfigurations.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="scheduleConfigurations.scheduleConfigurationType" />,
    id: 'ScheduleConfigurationType',
    accessor: (row) => ScheduleConfigurationTypes.find((type) => type.Value === row.ScheduleConfigurationType)?.Name,
  },
];

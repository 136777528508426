import React from 'react';

import { TableColumn } from '../../types/Table';
import { Action } from '../../models/Action';
import { Translated } from '../../components/UI/Core';
import { ActionType } from '../../models/enums/ActionType';

export const ActionTableColumns: Array<TableColumn<Action>> = [
  {
    Header: <Translated id="action.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="action.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="actions.display.label" />,
    id: 'DisplayLabel',
    accessor: 'DisplayLabel',
  },
  {
    Header: <Translated id="actions.action.type" />,
    id: 'Type',
    accessor: (row) => getActionTypeString(row.Type),
  },
];

const getActionTypeString = (actionType: ActionType): string => {
  switch (actionType) {
    case ActionType.Encode:
      return 'Encode';
    case ActionType.OpenDoor:
      return 'OpenDoor';
    default:
      return 'Unknown';
  }
};

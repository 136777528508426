// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  azureTenantCreated,
  azureTenantDeleted,
  azureTenantImported,
  azureTenantNotCreated,
  azureTenantNotDeleted,
  azureTenantNotImported,
  azureTenantNotUpdated,
  azureTenantsDeleted,
  azureTenantsNotDeleted,
  azureTenantUpdated,
} from './AzureTenants.redux';

// Events
const SignalREvents = {
  AzureTenantCreated: `AzureTenantCreatedIntegrationEvent`,
  AzureTenantUpdated: `AzureTenantUpdatedIntegrationEvent`,
  AzureTenantDeleted: `AzureTenantDeletedIntegrationEvent`,
  AzureTenantsDeleted: `AzureTenantsDeletedIntegrationEvent`,
  AzureTenantImported: `UserImportCompletedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  AzureTenantNotCreated: `AzureTenantNotCreatedIntegrationException`,
  AzureTenantNotUpdated: `AzureTenantNotUpdatedIntegrationException`,
  AzureTenantNotDeleted: `AzureTenantNotDeletedIntegrationException`,
  AzureTenantsNotDeleted: `AzureTenantsNotDeletedIntegrationException`,
  AzureTenantNotImported: `UserImportFailedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.AzureTenantCreated, (msg) => {
    store.dispatch(azureTenantCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AzureTenantNotCreated, (msg) => {
    store.dispatch(azureTenantNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.AzureTenantUpdated, (msg) => {
    store.dispatch(azureTenantUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AzureTenantNotUpdated, (msg) => {
    store.dispatch(azureTenantNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.AzureTenantDeleted, (msg) => {
    store.dispatch(azureTenantDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AzureTenantNotDeleted, (msg) => {
    store.dispatch(azureTenantNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.AzureTenantsDeleted, (msg) => {
    store.dispatch(azureTenantsDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AzureTenantsNotDeleted, (msg) => {
    store.dispatch(azureTenantsNotDeleted({ history, msg }));
  });

  // Import Employees
  hubConnection.on(SignalREvents.AzureTenantImported, (msg) => {
    store.dispatch(azureTenantImported({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AzureTenantNotImported, (msg) => {
    store.dispatch(azureTenantNotImported({ history, msg }));
  });
};

import React, { useCallback, useEffect, useState } from 'react';

// Settings
import { ApplicationPaths, LoginActions, QueryParameterNames } from '../../../constants/Settings/AuthSettings';

// Helpers
import { getReturnUrl, navigateToUrl, redirectToApiAuthorizationPath } from '../../../constants/Utils/Urls';

// Services
import { AuthenticationResultStatus, authService } from '../../../services/ApiAuthorization/AuthorizeService';

// Components
import { AuthSpinner } from './AuthSpinner';

// Props
interface LoginProps {
  action: string;
}

// Component
export const Login = ({ action }: LoginProps) => {
  const [message, setMessage] = useState<string>();

  const login = useCallback(async (returnUrl: string) => {
    const state = { returnUrl };
    const result = await authService.signIn(state);

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;

      case AuthenticationResultStatus.Success:
        navigateToUrl(returnUrl);
        break;

      case AuthenticationResultStatus.Fail:
        setMessage(result.data);
        break;

      default:
        throw new Error(`Invalid status result ${result.status}.`);
    }
  }, []);

  const processLoginCallback = useCallback(async () => {
    const url = window.location.href;
    const result = await authService.completeSignIn(url);

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        throw new Error('Should not redirect.');

      case AuthenticationResultStatus.Success:
        navigateToUrl(getReturnUrl(result.data));
        break;

      case AuthenticationResultStatus.Fail:
        setMessage(result.data);
        break;

      default:
        throw new Error(`Invalid authentication result status '${result.status}'.`);
    }
  }, []);

  const redirectToRegister = useCallback(() => {
    redirectToApiAuthorizationPath(
      `${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`
    );
  }, []);

  const redirectToProfile = useCallback(() => {
    redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
  }, []);

  // Init
  useEffect(() => {
    // Mount
    switch (action) {
      case LoginActions.Login:
        login(getReturnUrl());
        break;

      case LoginActions.LoginCallback:
        processLoginCallback();
        break;

      case LoginActions.LoginFailed: {
        const params = new URLSearchParams(window.location.search);
        const error = params.get(QueryParameterNames.Message);
        if (error) setMessage(error);
        break;
      }

      case LoginActions.Profile:
        redirectToProfile();
        break;

      case LoginActions.Register:
        redirectToRegister();
        break;

      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }, [action, login, processLoginCallback, redirectToProfile, redirectToRegister]);

  if (message) {
    return <AuthSpinner message={message} />;
  }

  switch (action) {
    case LoginActions.Login:
      return <AuthSpinner message="Logging in..." />;

    case LoginActions.LoginCallback:
      return <AuthSpinner message="Successful login, redirecting..." />;

    case LoginActions.Profile:
    case LoginActions.Register:
      return <AuthSpinner message="Redirecting..." />;

    default:
      throw new Error(`Invalid action '${action}'`);
  }
};

import React from 'react';

import { TableColumn } from '../../types/Table';
import { ServiceConfiguration } from '../../models/ServiceConfiguration';
import { Translated } from '../../components/UI/Core';
import { AccessControlSystemTypes } from '../../models/enums/AccessControlSystemTypes';
import { ServiceStatusTag } from '../../components/UI/Tag/ServiceStatusTag';
import { TranslatedTag } from '../../components/UI/Tag/TranslatedTag';

export const serviceConfigurationTableColumns: Array<TableColumn<ServiceConfiguration>> = [
  {
    Header: <Translated id="serviceConfigurations.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="serviceConfigurations.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="serviceConfigurations.accessControlSystemType" />,
    id: 'AccessControlSystemType',
    accessor: (row) => AccessControlSystemTypes.find((type) => type.Value === row.AccessControlSystemType)?.Name,
  },
  {
    Header: <Translated id="serviceConfigurations.status" />,
    id: 'Status',
    accessor: (row) => <ServiceStatusTag service={row} />,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="serviceConfigurations.manageEmployees" />,
    id: 'ManageEmployees',
    accessor: (row) =>
      row.ManageEmployees ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
];

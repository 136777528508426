import { Locale } from 'antd/lib/locale-provider';
import { en } from './entries/en-US';
import { nl } from './entries/nl_NL';

interface AppLocale {
  messages: {
    [key: string]: any | undefined;
  };
  antd: Locale;
  locale: string;
}

interface AppLocales {
  [key: string]: AppLocale;
}

export const AppLocale: AppLocales = {
  en,
  nl,
};

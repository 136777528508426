import React from 'react';
import { Skeleton } from 'antd';

interface ButtonSkeletonProps {
  width: number;
  height: number;
  className?: string;
}

export const ButtonSkeleton = ({ width, height, className }: ButtonSkeletonProps) => (
  <Skeleton.Button
    active
    style={{ width: `${width}px`, minWidth: `${width}px`, height: `${height}px`, lineHeight: `${height}px` }}
    className={className}
  />
);

ButtonSkeleton.defaultProps = {
  className: null,
};

import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { IdentityUserResponse } from '../../../models/IdentityUser';

// Hooks
import { useIdentityUserTenantsRedux } from './useIdentityUserTenantsRedux';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface IdentityUserTenantsProps {
  identityUserResponse: IdentityUserResponse | null;
  fetchIdentityUser: (params: any) => Promise<void>;
  updatingIdentityUser: boolean;
}

export const useIdentityUserTenants = ({
  identityUserResponse,
  fetchIdentityUser,
  updatingIdentityUser,
}: IdentityUserTenantsProps) => {
  // Data
  const {
    data: identityUserTenants,
    fetch: fetchIdentityUserTenants,
    pending,
  } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const { IdentityUserTenantsManagementForm, updating, error } = useIdentityUserTenantsRedux({
    identityUserResponse,
    identityUserTenants,
  });
  const prevUpdating = usePrevious(updating);

  // Component State
  const loading = pending && !identityUserResponse;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchIdentityUserTenants();
  }, [fetchIdentityUserTenants]);

  useEffect(() => {
    // Fetch after updating
    if (identityUserResponse && prevUpdating === true && updating === false && !error) {
      fetchIdentityUser({ id: identityUserResponse.Identity.Id });
      fetchIdentityUserTenants();
    }
  }, [prevUpdating, updating, error, fetchIdentityUser, fetchIdentityUserTenants, identityUserResponse]);

  // Return Hook
  return useMemo(
    () => ({
      identityUserTenants,
      IdentityUserTenantsManagementForm,
      loading,
      updating: updating || updatingIdentityUser,
    }),
    [identityUserTenants, IdentityUserTenantsManagementForm, loading, updating, updatingIdentityUser]
  );
};

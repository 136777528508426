import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { History } from 'history';

// Settings
import { SignalRHubs } from '../../constants/Settings/SignalRSettings';
import { SignalREvent } from '../../models/SignalREvent';

// Services
import { authService } from '../ApiAuthorization/AuthorizeService';

// Props
interface ConfigureSignalRProps {
  store: any;
  history: History;
  configureReduxEvents: ({ hubConnection, store, history }: SignalREvent) => void;
}

// Hub Configuration
const hubConnection = new HubConnectionBuilder()
  .withUrl(SignalRHubs.ApiGatewayHub, {
    accessTokenFactory: () => authService.getAccessToken(true),
  })
  .configureLogging(LogLevel.Error)
  .withAutomaticReconnect()
  .build();

// Init
const startSignalR = () => {
  hubConnection.start().then().catch();
};

// Events
export const configureSignalR = ({ store, history, configureReduxEvents }: ConfigureSignalRProps) => {
  // Configures SignalR + Redux Events
  configureReduxEvents({ hubConnection, store, history });

  // Starts the service
  startSignalR();
};

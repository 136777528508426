import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useAzureTenantsList } from '../../hooks/AzureTenants/useAzureTenantsList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const AzureTenantsPage = () => {
  const {
    updating,
    getBreadcrumbMenuProps,
    getAzureTenantsDataProps,
    getAzureTenantsTableProps,
    getAzureTenantsCrudProps,
    getFormDrawerProps,
  } = useAzureTenantsList();

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        <Row>
          <Col span={24}>
            <Table {...getAzureTenantsDataProps()} {...getAzureTenantsTableProps()} {...getAzureTenantsCrudProps()} />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};

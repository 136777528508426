/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

// Models
import { ServiceConfiguration } from '../../models/ServiceConfiguration';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface ServiceConfigurationsState {
  updating: boolean;
  error: boolean;
  updatingEntityId?: string;
  importing: boolean;
  item: number;
  totalItems: number;
}

const initialState: ServiceConfigurationsState = {
  updating: false,
  error: false,
  updatingEntityId: undefined,
  importing: false,
  item: 0,
  totalItems: 0,
};

// Name
const storeName = '@SERVICE_CONFIGURATIONS';

// Redux Actions|Reducers
const serviceConfigurationsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createServiceConfiguration: (state, action: PayloadAction<ServiceConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    serviceConfigurationCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    serviceConfigurationNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateServiceConfiguration: (state, action: PayloadAction<ServiceConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    serviceConfigurationUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    serviceConfigurationNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteServiceConfiguration: (state, action: PayloadAction<ServiceConfiguration>) => {
      state.updating = true;
      state.error = false;
    },
    serviceConfigurationDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    serviceConfigurationNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteServiceConfigurations: (state, action: PayloadAction<Array<ServiceConfiguration>>) => {
      state.updating = true;
      state.error = false;
    },
    serviceConfigurationsDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    serviceConfigurationsNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Test
    testServiceConfiguration: (state, action: PayloadAction<{ id: string }>) => {
      state.updatingEntityId = action.payload.id;
      state.error = false;
    },
    testServiceConfigurations: (state, action: PayloadAction) => {
      state.updatingEntityId = uniqueId();
      state.error = false;
    },
    serviceConfigurationTested: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingEntityId = undefined;
      state.error = false;
    },
    serviceConfigurationNotTested: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingEntityId = undefined;
      state.error = true;
    },

    // Import employees
    resetPacsImport: (state, action: PayloadAction) => {
      state.importing = false;
      state.totalItems = 0;
      state.item = 0;
    },
    importPACSEmployees: (state, action: PayloadAction<{ id: string }>) => {
      state.updatingEntityId = action.payload.id;
      state.error = false;
      state.item = 0;
    },
    pacsEmployeesImported: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingEntityId = undefined;
      state.error = false;
      if (action.payload.msg.total > 0) {
        state.importing = true;
        state.totalItems = action.payload.msg.total;
      }
    },
    pacsEmployeesNotImported: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingEntityId = undefined;
      state.error = true;
    },
    pacsUserAttached: (state, action: PayloadAction<SignalRAction>) => {
      state.item += 1;
    },
    pacsUserNotAttached: (state, action: PayloadAction<SignalRAction>) => {
      state.item += 1;
    },

    // Import Access Profiles
    importPACSAccessProfiles: (state, action: PayloadAction<{ id: string }>) => {
      state.updatingEntityId = action.payload.id;
      state.error = false;
    },
    pacsAccessProfilesImported: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingEntityId = undefined;
      state.error = false;
    },
    pacsAccessProfilesNotImported: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingEntityId = undefined;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createServiceConfiguration,
  serviceConfigurationCreated,
  serviceConfigurationNotCreated,
  // Update
  updateServiceConfiguration,
  serviceConfigurationUpdated,
  serviceConfigurationNotUpdated,
  // Delete
  deleteServiceConfiguration,
  serviceConfigurationDeleted,
  serviceConfigurationNotDeleted,
  // Delete All
  deleteServiceConfigurations,
  serviceConfigurationsDeleted,
  serviceConfigurationsNotDeleted,
  // Test
  testServiceConfiguration,
  testServiceConfigurations,
  serviceConfigurationTested,
  serviceConfigurationNotTested,
  // Employees Import
  resetPacsImport,
  importPACSEmployees,
  pacsEmployeesImported,
  pacsEmployeesNotImported,
  pacsUserAttached,
  pacsUserNotAttached,
  // Access Profiles Import
  importPACSAccessProfiles,
  pacsAccessProfilesImported,
  pacsAccessProfilesNotImported,
} = serviceConfigurationsSlice.actions;

// Export Reducer
export default serviceConfigurationsSlice.reducer;

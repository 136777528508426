/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IdentifierType } from '../../models/IdentifierType';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface IdentifierTypesState {
  updating: boolean;
  updatingSettings: boolean;
  error: boolean;
}

const initialState: IdentifierTypesState = {
  updating: false,
  updatingSettings: false,
  error: false,
};

// Name
const storeName = '@IDENTIFIER_TYPES';

// Redux Actions|Reducers
const identifierTypesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createIdentifierType: (state, action: PayloadAction<IdentifierType>) => {
      state.updating = true;
      state.error = false;
    },
    identifierTypeCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierTypeNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateIdentifierType: (state, action: PayloadAction<IdentifierType>) => {
      state.updating = true;
      state.error = false;
    },
    identifierTypeUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierTypeNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteIdentifierType: (state, action: PayloadAction<IdentifierType>) => {
      state.updating = true;
      state.error = false;
    },
    identifierTypeDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierTypeNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteIdentifierTypes: (state, action: PayloadAction<Array<IdentifierType>>) => {
      state.updating = true;
      state.error = false;
    },
    identifierTypesDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierTypesNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateIdentifierTypeSettings: (state, action: PayloadAction<IdentifierType>) => {
      state.updatingSettings = true;
      state.error = false;
    },
    identifierTypeSettingsUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingSettings = false;
      state.error = false;
    },
    identifierTypeSettingsNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updatingSettings = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createIdentifierType,
  identifierTypeCreated,
  identifierTypeNotCreated,
  // Update
  updateIdentifierType,
  identifierTypeUpdated,
  identifierTypeNotUpdated,
  // Delete
  deleteIdentifierType,
  identifierTypeDeleted,
  identifierTypeNotDeleted,
  // Delete All
  deleteIdentifierTypes,
  identifierTypesDeleted,
  identifierTypesNotDeleted,
  // Update
  updateIdentifierTypeSettings,
  identifierTypeSettingsUpdated,
  identifierTypeSettingsNotUpdated,
} = identifierTypesSlice.actions;

// Export Reducer
export default identifierTypesSlice.reducer;

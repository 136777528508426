import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

// Components
import { Spinner } from '../Spinner/Spinner';

// Styled
const StyledCol = styled(Col)`
  text-align: center;
  padding-top: 120px;
`;

// Props
interface AuthSpinnerProps {
  message?: string;
}

// Component
export const AuthSpinner = ({ message }: AuthSpinnerProps) => (
  <Row>
    <StyledCol span={24}>
      <Spinner spinning message={message} />
    </StyledCol>
  </Row>
);

AuthSpinner.defaultProps = {
  message: null,
};

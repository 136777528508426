import React, { useState } from 'react';
import { Button, Card, Tree } from 'antd';
import styled from 'styled-components';

// Components
import { Translated } from '../Core';

// Types
export interface TreeItem {
  id: string;
  key: string;
  title: string;
  children?: Array<TreeItem>;
}

export type CheckedKeysProps = React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] };

// Props
interface CheckableTreeProps {
  dataSource: Array<TreeItem>;
  initialKeys: Array<string>;
  onSave: (values: Array<string>) => void;
}

// Styled
const StyledCard = styled(Card)`
  padding: 12px 4px 8px;
  margin-bottom: 12px;
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Component
export const TreeForm = ({ dataSource, initialKeys, onSave }: CheckableTreeProps) => {
  const [expandedKeys, setExpandedKeys] = useState<Array<React.Key>>(initialKeys);
  const [checkedKeys, setCheckedKeys] = useState<CheckedKeysProps>(initialKeys);

  const onExpand = (expandedKeysValue: Array<React.Key>) => {
    setExpandedKeys(expandedKeysValue);
  };

  const onCheck = (checkedKeysValue: CheckedKeysProps) => {
    setCheckedKeys(checkedKeysValue);
  };

  // Render
  return (
    <>
      <StyledCard>
        <Tree
          checkable
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          selectable={false}
          treeData={dataSource}
        />
      </StyledCard>
      <StyledButton type="primary" onClick={() => onSave(checkedKeys as Array<string>)}>
        <Translated id="form.confirmButton" />
      </StyledButton>
    </>
  );
};

import styled from 'styled-components';

// Components
import { Widget } from '../../Widget/Widget';

// Render
export const TableCard = styled(Widget)`
  margin-top: 20px;

  & .ant-card-head {
    background: #fafafa;
  }

  & .ant-card-body {
    height: ${(props) => props.height || 480}px;
    padding: 16px 24px;
  }

  & .ant-card-actions {
    padding: 16px 24px;
    border-radius: 0 0 10px 10px;

    > li {
      margin: 0;

      & > span:hover {
        color: unset;
        cursor: unset;
      }
    }
  }
`;

// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  tenantCreated,
  tenantDeleted,
  tenantNotCreated,
  tenantNotDeleted,
  tenantNotUpdated,
  tenantsDeleted,
  tenantsNotDeleted,
  tenantUpdated,
} from './Tenants.redux';

// Events
const SignalREvents = {
  TenantCreated: `TenantCreatedIntegrationEvent`,
  TenantUpdated: `TenantUpdatedIntegrationEvent`,
  TenantDeleted: `TenantDeletedIntegrationEvent`,
  TenantsDeleted: `TenantsDeletedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  TenantNotCreated: `TenantNotCreatedIntegrationException`,
  TenantNotUpdated: `TenantNotUpdatedIntegrationException`,
  TenantNotDeleted: `TenantNotDeletedIntegrationException`,
  TenantsNotDeleted: `TenantsNotDeletedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.TenantCreated, (msg) => {
    store.dispatch(tenantCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.TenantNotCreated, (msg) => {
    store.dispatch(tenantNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.TenantUpdated, (msg) => {
    store.dispatch(tenantUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.TenantNotUpdated, (msg) => {
    store.dispatch(tenantNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.TenantDeleted, (msg) => {
    store.dispatch(tenantDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.TenantNotDeleted, (msg) => {
    store.dispatch(tenantNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.TenantsDeleted, (msg) => {
    store.dispatch(tenantsDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.TenantsNotDeleted, (msg) => {
    store.dispatch(tenantsNotDeleted({ history, msg }));
  });
};

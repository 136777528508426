import React, { useState } from 'react';
import { Button, Input, Popover, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { UseFiltersColumnProps } from 'react-table';

// Props
interface SearchOverlayProps<T extends object> {
  column: UseFiltersColumnProps<T>;
}

interface SearchButtonProps {
  $filterValue?: any;
}

// Styled
const StyledSearchIcon = styled(SearchOutlined)`
  font-size: 12px;
`;
const StyledSearchButton = styled(Button)<SearchButtonProps>`
  display: flex;
  height: 100%;
  width: 30px;
  margin: 0;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  border-radius: 0;

  &:hover {
    background: #e5e5e5;

    ${StyledSearchIcon} {
      color: rgba(0, 0, 0, 0.45);
    }
  }

  ${StyledSearchIcon} {
    color: #bfbfbf;

    ${(props) =>
      props.$filterValue &&
      css`
        color: #08c;
      `};
  }

  ${(props) =>
    props.$filterValue &&
    css`
      background: #e5e5e5;
    `};
`;
const SearchInput = styled(Input)`
  width: 188px;
  margin-bottom: 8px;
  display: block;
`;
const SearchContainerButton = styled(Button)`
  width: 90px;
  margin-bottom: 0;
`;

// Render
export const TableHeadColumnSearch = <T extends object>({
  column: { filterValue, setFilter },
}: SearchOverlayProps<T>) => {
  const [value, setValue] = useState<string | undefined>(filterValue);

  return (
    <Popover
      content={
        <>
          <SearchInput
            autoFocus
            value={value || ''}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setValue(e.target.value || undefined)}
            onPressEnter={() => setFilter(value)}
            placeholder="Search"
          />
          <Space>
            <SearchContainerButton
              onClick={(e) => {
                e.stopPropagation();
                setValue(undefined);
                setFilter(undefined);
              }}
              size="small"
            >
              Reset
            </SearchContainerButton>
            <SearchContainerButton
              onClick={(e) => {
                e.stopPropagation();
                setFilter(value);
              }}
              size="small"
              type="primary"
              icon={<SearchOutlined />}
            >
              Search
            </SearchContainerButton>
          </Space>
        </>
      }
      trigger="click"
      placement="bottomRight"
    >
      <StyledSearchButton
        type="text"
        icon={<StyledSearchIcon />}
        onClick={(e) => e.stopPropagation()}
        $filterValue={filterValue}
      />
    </Popover>
  );
};

import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useParams } from 'react-router';
import { useIdentityUserDetail } from '../../hooks/IdentityUsers/useIdentityUserDetail';

// Components
import { Container } from '../../components/UI/Base';
import { ContactCard, IdentityUserRoles, IdentityUserTenants, ProfileHeader } from '../../components/IdentityUsers';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const IdentityUserDetailPage = () => {
  const { id } = useParams();
  const { updating, getBreadcrumbMenuProps, getProfileHeaderProps, getContactCardProps, getIdentityUsersProps } =
    useIdentityUserDetail({ id });

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <IdentityUserTenants {...getIdentityUsersProps()} />
            <IdentityUserRoles {...getIdentityUsersProps()} />
          </Col>

          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <ContactCard {...getContactCardProps()} />
          </Col>
        </Row>
      </Container>
    </Spinner>
  );
};

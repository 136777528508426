import React, { useCallback, useMemo } from 'react';

// Hooks
import { useActionsRedux } from './useActionsRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { ActionTableColumns } from '../../data/Table/ActionTableColumns';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

export const useActionList = <T extends object>() => {
  // Data
  const { data: Actions, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.Actions.list, null);
  const data = useMemo(() => Actions, [Actions]);

  // Redux
  const { formOptions, updating, error, showDeleteAllConfirm } = useActionsRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.Actions.list }), []);
  const getActionsDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.Actions.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-setting" style={{ marginRight: 8 }} />
          <Translated id="appMenu.actions" />
        </>
      ),
      data: data ?? [],
      columns: getTableColumns(ActionTableColumns, LocalStorageKeys.Actions.table.hiddenColumns),
      fetchData: fetch,
    }),
    [fetch, data]
  );
  const getActionsTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getActionsCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getActionsDataProps,
      getActionsTableProps,
      getActionsCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getActionsDataProps,
      getActionsTableProps,
      getFormDrawerProps,
      getActionsCrudProps,
    ]
  );
};

import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { useAccessProfilesRedux } from './useAccessProfilesRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { useAppSelector } from '../App/useRedux';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Props
interface AccessProfileDetailProps {
  id?: string;
}

// Hook
export const useAccessProfileDetail = <T extends object>({ id }: AccessProfileDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Redux State
  const { updatingSettings } = useAppSelector(({ accessProfiles }) => accessProfiles);
  const prevUpdatingSettings = usePrevious(updatingSettings);

  // Data
  const { data: accessProfileResponse, fetch, pending } = useData(ApiEndpoints.accessProfiles.detail, null);
  const prevPending = usePrevious(pending);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useAccessProfilesRedux<T>({
    isEditing: true,
    initialValues: accessProfileResponse?.AccessProfile as Store,
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.accessProfiles.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      accessProfile: accessProfileResponse?.AccessProfile ?? null,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [accessProfileResponse, pending, getFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      accessProfile: accessProfileResponse?.AccessProfile ?? null,
      pending,
    }),
    [accessProfileResponse, pending]
  );
  const getAccessProfileTenantsProps = useCallback(
    () => ({
      accessProfileResponse,
      fetchAccessProfile: fetch,
      updatingAccessProfile: pending,
    }),
    [accessProfileResponse, fetch, pending]
  );
  const getPACSAccessProfilesProps = useCallback(
    () => ({
      accessProfileResponse,
      fetchAccessProfile: fetch,
      updatingAccessProfile: pending,
    }),
    [accessProfileResponse, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && pending === false && !accessProfileResponse) {
      notificationService.showError('accessProfiles.notFound');
      navigate('/AccessProfiles');
    }
  }, [prevPending, pending, accessProfileResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (
      ((prevUpdating === true && updating === false) ||
        (prevUpdatingSettings === true && updatingSettings === false)) &&
      !error
    ) {
      fetch({ id });
    }
  }, [prevUpdating, updating, prevUpdatingSettings, updatingSettings, error, fetch, id]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getPACSAccessProfilesProps,
      getAccessProfileTenantsProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getPACSAccessProfilesProps,
      getAccessProfileTenantsProps,
    ]
  );
};

import { isEmpty } from 'lodash';
import { isInThePast } from './DateTime';
import {
  EnvironmentService,
  EnvironmentStatsResponse,
} from '../../models/Dashboard/ApiResponses/EnvironmentStatsResponse';
import { ServiceConfiguration } from '../../models/ServiceConfiguration';
import { ServiceStatus, ServiceStatusInfo } from '../../models/enums/ServiceStatus';
import { EnvironmentStatus, EnvironmentStatusInfo } from '../../models/enums/EnvironmentStatus';
import { FilterStatus, FilterStatusInfo } from '../../models/enums/FilterStatus';
import { ApiConfiguration } from '../../models/ApiConfiguration';
import { ApiClientStatusInfo } from '../../models/enums/ApiClientStatus';
import { ApiClient } from '../../models/ApiClient';

export const isOutdated = (service: ServiceConfiguration | ApiConfiguration | EnvironmentService) =>
  isInThePast(service.LastChecked);

export const isNonOperational = (service: ServiceConfiguration | EnvironmentService) =>
  !isOutdated(service) && service.Status !== ServiceStatus.Operational;

export const requiresAttention = (service: ServiceConfiguration | EnvironmentService) =>
  isOutdated(service) || isNonOperational(service);

export const getServiceStatus = (service: ServiceConfiguration | ApiConfiguration | EnvironmentService) => {
  const status = isOutdated(service) ? ServiceStatus.NotChecked : service?.Status;
  const statusInfo = ServiceStatusInfo.find((x) => x.ServiceStatus === status);

  return statusInfo || ServiceStatusInfo[0];
};

export const getApiClientStatus = (apiClient: ApiClient) => {
  const statusInfo = ApiClientStatusInfo.find((x) => x.Status === apiClient.ApiClientStatus);

  return statusInfo || ApiClientStatusInfo[0];
};

export const getEnvironmentStatus = (services: Array<EnvironmentService>) => {
  // Variables
  const totalCount = services.length;
  const errorCount = services.filter((s) => isNonOperational(s)).length;
  const warningCount = services.filter((s) => isOutdated(s)).length;
  const problemCount = errorCount + warningCount;

  // Boolean Validation
  const hasServices = totalCount > 0;
  const hasProblems = problemCount > 0;
  const hasErrors = errorCount > 0;
  const allErrors = hasProblems && errorCount >= totalCount;

  // Environment Status
  const getEnvironmentStatusEnum = () => {
    if (allErrors) {
      return EnvironmentStatus.AllError;
    }
    if (hasErrors) {
      return problemCount > 1 ? EnvironmentStatus.Errors : EnvironmentStatus.Error;
    }
    if (hasProblems) {
      return warningCount > 1 ? EnvironmentStatus.Warnings : EnvironmentStatus.Warning;
    }
    if (!hasServices) {
      return EnvironmentStatus.NotFound;
    }
    return EnvironmentStatus.Operational;
  };
  const environmentStatus = getEnvironmentStatusEnum();

  const statusInfo =
    EnvironmentStatusInfo.find((x) => x.EnvironmentStatus === environmentStatus) || EnvironmentStatusInfo[0];

  return { statusInfo, problemCount };
};

export const getFilterStatus = (filterCount: number, content?: Array<EnvironmentStatsResponse>) => {
  // Variables
  const totalCount = content?.length ?? 0;
  const problemCount =
    content?.filter((e) => isEmpty(e.Services) || e.Services.some((s) => requiresAttention(s)))?.length ?? 0;

  // Boolean Validation
  const hasResults = totalCount > 0;
  const hasProblems = problemCount > 0;
  const hasFilters = filterCount > 0;
  const showInfo = !hasProblems && hasFilters && !hasResults;

  // Filter Status
  const getfilterStatusEnum = () => {
    if (hasProblems) {
      return problemCount > 1 ? FilterStatus.Warnings : FilterStatus.Warning;
    }
    return showInfo ? FilterStatus.NotFound : FilterStatus.Operational;
  };
  const filterStatus = getfilterStatusEnum();

  const statusInfo = FilterStatusInfo.find((x) => x.FilterStatus === filterStatus) || EnvironmentStatusInfo[0];

  return { statusInfo, problemCount };
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Form, Input, Select, Space, Switch } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import FormItemLabel from 'antd/es/form/FormItemLabel';
import {
  ImportAzureAdUsersSettings,
  ImportAzureAdUsersSettingsFormValues,
  ScheduleIntervals,
} from '../../../../models/ScheduleSettingFormValues';
import { ScheduleConfigurationResponse } from '../../../../models/ScheduleConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';
import { ApiEndpoints } from '../../../../data/ApiEndpoints';
import { useData } from '../../../App/useData';
import { AzureTenant } from '../../../../models/AzureTenant';

// Components
const { Item } = Form;
const { Option } = Select;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0px;
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface ImportAzureAdSettingsProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
}

// Hook
export const useImportAzureAdUsersSettings = ({
  // updating,
  scheduleConfigurationResponse,
}: ImportAzureAdSettingsProps) => {
  // Intl
  const intl = useIntl();

  // State
  const [filteredAzureTenants, setFilteredAzureTenants] = useState<AzureTenant[] | null>(null);

  // Form Values
  const initialValues: ImportAzureAdUsersSettingsFormValues = useMemo(
    () => ({
      Id: scheduleConfigurationResponse?.Id,
      Name: scheduleConfigurationResponse?.Name,
      Enabled: scheduleConfigurationResponse?.Enabled,
      Tenant: scheduleConfigurationResponse?.Tenant,
      Interval: scheduleConfigurationResponse?.Interval,
      ScheduleConfigurationType: scheduleConfigurationResponse?.ScheduleConfigurationType,
      CustomConfiguration: JSON.parse(
        scheduleConfigurationResponse?.CustomConfiguration ?? '{}'
      ) as ImportAzureAdUsersSettings,
    }),
    [scheduleConfigurationResponse]
  );

  const { data: azureTenants, fetch, pending, fetched } = useData(ApiEndpoints.azureTenants.list, null);

  // Hooks
  useEffect(() => {
    fetch({ size: 999 });
  }, [fetch]);

  useEffect(() => {
    if (pending || !fetched) {
      return;
    }
    const filtered = azureTenants?.filter(
      (x) =>
        x.Tenant.BusinessClientId === scheduleConfigurationResponse?.Tenant.BusinessClientId &&
        x.Tenant.CompanyId === scheduleConfigurationResponse.Tenant.CompanyId &&
        x.Tenant.LocationId === scheduleConfigurationResponse.Tenant.LocationId
    );
    if (filtered !== undefined) setFilteredAzureTenants(filtered);
  }, [scheduleConfigurationResponse, azureTenants, pending, fetched]);

  // Components
  const ImportAzureAdSettingsForm = useCallback(
    () => (
      <>
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="ScheduleConfigurationType" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>

        <Form.Item name={['Tenant', 'CompanyId']} hidden>
          <Input />
        </Form.Item>
        <Form.Item name={['Tenant', 'LocationId']} hidden>
          <Input />
        </Form.Item>
        <Form.Item name={['Tenant', 'BusinessClientId']} hidden>
          <Input />
        </Form.Item>

        {/* Form Values */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name="Interval"
            label={<Translated id="scheduleConfigurations.form.interval" />}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select placeholder={<Translated id="scheduleConfigurations.form.interval" />}>
              {ScheduleIntervals?.map((interval) => (
                <Option key={interval.Value} value={interval.Value}>
                  <Translated id={interval.Translation} />
                </Option>
              ))}
            </Select>
          </NoSidePaddingFormItem>
        </StyledSpace>
        <Space>
          <FormItemLabel label={<Translated id="scheduleConfigurations.form.enabled" />} prefixCls="cls" />
          <NoMarginBottomFormItem name="Enabled" valuePropName="checked">
            <Switch
              checkedChildren={<Translated id="scheduleConfigurations.form.active" />}
              unCheckedChildren={<Translated id="scheduleConfigurations.form.disabled" />}
            />
          </NoMarginBottomFormItem>
        </Space>
        <Divider />
        {/* Custom */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['CustomConfiguration', 'AzureTenantId']}
            label={<Translated id="scheduleConfigurations.form.AzureTenantId" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'scheduleConfigurations.form.warnings.AzureTenantId' }),
              },
            ]}
          >
            <Select placeholder={<Translated id="scheduleConfigurations.form.AzureTenantId" />}>
              {filteredAzureTenants?.map((tenant) => (
                <Option key={tenant.Id} value={tenant.Id}>
                  {tenant.Name}
                </Option>
              ))}
            </Select>
          </NoSidePaddingFormItem>
        </StyledSpace>
      </>
    ),
    [intl, filteredAzureTenants]
  );

  return useMemo(
    () => ({
      ImportAzureAdSettingsForm,
      initialValues,
    }),
    [ImportAzureAdSettingsForm, initialValues]
  );
};

import React from 'react';
import { Button, Result } from 'antd';
import { FallbackProps } from 'react-error-boundary';

// Components
import { Translated } from '../../components/UI/Core';

export const ErrorPage = ({ error, resetErrorBoundary }: FallbackProps) => (
  <Result
    status="500"
    title={<Translated id="app.pages.error" />}
    subTitle={error.message}
    extra={[
      <Button key="error-button" onClick={resetErrorBoundary} type="primary">
        <Translated id="app.goBack" />
      </Button>,
    ]}
  />
);
